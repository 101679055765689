
import { AccordionDetails, Box } from '@mui/material'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import ManageWebApi from 'api/application/manageWeb'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Loading from 'component/Loading'
import { colors } from 'constants/theme'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { useTranslation } from 'react-i18next'
import { getBoolean, getBranch, insertDashesIphone } from 'utils/app.utils'
import * as UseStyles from 'features/application/manage-web/contact-us/style'
import _ from 'lodash'
import ButtonCustom from 'component/Button/ButtonCustom'
import BtnEdit from 'assets/button/BtnEdit'
import BtnDelFilled from 'assets/button/BtnDelFilled'
import { swalActive } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import ContactUsForm from './ContactUsForm'
import { WebContactUsProps } from 'api/application/manageWeb-interface'
import BtnCopy from 'assets/button/BtnCopy'
import BtnWebsite from 'assets/button/BtnWebsite'
import BtnFacebook from 'assets/button/BtnFacebook'
import BtnLine from 'assets/button/BtnLine'
import BtnTiktok from 'assets/button/BtnTiktok'
import BtnIG from 'assets/button/BtnIG'
import InputTextField from 'component/Input/InputTextField'
import { branchInfo } from 'app/slice/user.slice';

type DayProps = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat'
const ContactUs = () => {
    const { t } = useTranslation()
    const t_CONTACTUS = "MANAGE_WEB.CONTACT_US"
    const [load, setLoad] = useState<boolean>(false)
    const [branchContact, setBranchContact] = useState<any>([])
    const branchId = getBranch()
    const [dataEdit, setDataEdit] = useState<any>({})
    const [statusAdd, setStatusAdd] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [webContact, setWebContact] = useState<any>([]);
    const [officeTimes, setOfficeTimes] = useState<any>({})
    const [branchTimes, setBranchTimes] = useState([])
    const [clear, setClear] = useState<boolean>(false)
    const data = {
        name: '', email: '', phone: '', detail: '', addressMap: '', website: '', tiktok: '', facebook: '', line: '', instagram: '', officeTimes: []
    }
    const Day = { Sun: t('MANAGE_WEB.DAY.SUN'), Mon: t('MANAGE_WEB.DAY.MON'), Tue: t('MANAGE_WEB.DAY.TUE'), Wed: t('MANAGE_WEB.DAY.WED'), Thu: t('MANAGE_WEB.DAY.THU'), Fri: t('MANAGE_WEB.DAY.FRI'), Sat: t('MANAGE_WEB.DAY.SAT') }

    useEffect(() => {
        loadData()
    }, [])


    const loadData = async () => {
        setLoad(false)
        try {
            const res = await ManageWebApi.getContactUs();
            setBranchContact(res.data);

            if (res.data?.length !== 0) {
                const arr = await Promise.all(res.data.map(async (item: any) => {
                    const res = await ManageWebApi.getWebContactUs(item.branchId);
                    return Object.assign(res.data, { isEdit: res.data.name !== undefined, isPull: res.data.name !== undefined });
                }));

                arr.sort((obj1, obj2) => obj1.branchId - obj2.branchId);

                setWebContact(arr);
                setLoad(true)
            }
        }
        catch (error) {
            console.error("Error:", error);
        } finally {
            setLoad(false);
        }
    };


    const onEdit = (i: number, status: boolean, item?: any) => {
        if (item && item.name !== undefined) {
            setDataEdit(item)
            webContact[i].isPull = !status
            webContact[i].isEdit = status
            setIsEdit(status)
        } else {
            webContact[i].isPull = !status
            setDataEdit({})
        }

        if (status && item.name !== undefined) {
            setDataEdit({})
        }
    }


    const onDel = async (id: number) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 400px">${t(t_CONTACTUS + '.NOTI_DEL_START')}${t(t_CONTACTUS + '.TITLE')}${t(t_CONTACTUS + '.NOTI_DEL_END')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t(t_CONTACTUS + '.NOTI_DEL_START')}สาขา${id}${t(t_CONTACTUS + '.NOTI_DEL_END')}</p>`,
            async (res: any) => {
                if (res) {
                    const resDel = await ManageWebApi.delWebContactUs(id)
                    if (resDel === "pass") {
                        loadData()
                        setDataEdit({})
                        notiSuccess(t(`${t_CONTACTUS}.DEL_SUC`))
                    } else {
                        notiError(t(`${t_CONTACTUS}.SAVE_ERROR`))
                        return
                    }
                }
            }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }


    const renderTimeWork = useCallback((officeTimes: any) => {
        const renderTime = (time: string) => { return moment(time, 'HH:mm:ss').format('HH:mm') }
        const renderDay = (day: DayProps[]) => {
            if (day?.length === 7) {
                return t('MANAGE_WEB.CONTACT_US.OPEN')
            } else if (day?.length > 1) {
                return `${Day[day[0]]} - ${Day[day[day.length - 1]]}`
            } else {
                return `${Day[day[0]]}`
            }
        }

        const newTime = officeTimes?.map((item: any) => {
            return item.officeStatus === 'CLOSE' ? '' : `${renderDay(item.day)} ${renderTime(item?.timeIn)}น. - ${renderTime(item?.timeOut)}น.`
        })
        return newTime || []
    }, [])

    const onSave = async (ele: any, i: number) => {
        const { status, newPayload, pass } = ele
        if (pass === 'pass') {
            notiSuccess(t(`${t_CONTACTUS}.SAVE_SUC`))
            loadData()
        } else if (pass === 'error') {
            notiError(t(`${t_CONTACTUS}.SAVE_ERROR`))
        } else {
            webContact[i].isEdit = status
            loadData()
        }
    }

    return (
        <>
            <Loading show={load} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto' }}>
                {webContact.map((item: any, index: number) => {
                    return (
                        <UseStyles.Accordion key={item.branchId} defaultExpanded={false} className={` ${!getBoolean(true) ? 'd-none' : ''} w-100`}>
                            <UseStyles.AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                                <div className={'header-text'}>
                                    <h5 className={'m-0'}>{`${t(`${t_CONTACTUS}.BRANCH`)}${item.branchId}`}</h5>
                                </div>
                            </UseStyles.AccordionSummary>
                            <AccordionDetails>
                                {item.isEdit && <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                                            {t(`${t_CONTACTUS}.TITLE`)}
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <ButtonCustom
                                                startIcon={<BtnEdit />}
                                                style={{ marginRight: '4px' }}
                                                variant={'outlined'}
                                                textButton={t(`${t_CONTACTUS}.BTN_EDIT`)}
                                                onClick={() => { onEdit(index, false, item) }}
                                            />
                                            <ButtonCustom
                                                startIcon={<BtnDelFilled />}
                                                mode={'del'}
                                                textButton={t(`${t_CONTACTUS}.BTN_DEL`)}
                                                onClick={() => { onDel(item.branchId) }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box sx={{ marginTop: '32px', height: 'fit-content', overflow: 'auto', transition: "all 0.2s", }}>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', minWidth: 'max-content' }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.TIME_WORK`)}:
                                                </Box>
                                                <Box>
                                                    {renderTimeWork(item?.officeTimes).map((item: any, index: number) => {
                                                        return (
                                                            <Box key={index} sx={{ marginRight: '8px', minWidth: 'fit-content' }}>
                                                                {item}
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', minWidth: 'max-content', marginTop: "8px" }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.EMAIL`)}:
                                                </Box>
                                                <Box>
                                                    {item?.email || ''}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', minWidth: 'max-content', marginTop: "8px" }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.PHONE`)}:
                                                </Box>
                                                <Box>
                                                    {item?.phone || ''}
                                                </Box>
                                            </Box>
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', marginTop: "8px" }}>
                                                <Box sx={{ marginRight: '8px', minWidth: 'fit-content', fontWeight: 'bold' }}>
                                                    {t(`${t_CONTACTUS}.FULL_ADDRESS`)}:
                                                </Box>
                                                <Box>
                                                    {item?.detail || ''}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{ paddingTop: '8px', width: '50%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            {item?.addressMap !== '' && <InputTextField
                                                disabled={true}
                                                value={`${item?.addressMap ?? ''}`}
                                            /> || <></>}
                                        </Box>
                                        <Box sx={{ marginTop: '32px', height: 'fit-content', overflow: 'auto', transition: "all 0.2s", }}>
                                            <Box
                                                sx={{
                                                    iframe: { width: '50%', height: '300px' }
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: `${item?.addressMap ?? ''}`
                                                }}
                                            />
                                        </Box>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnWebsite color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.website || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnFacebook color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.facebook || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnLine color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.line || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnIG color="#808080" width='26' height='26' /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.instagram || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                        <UseStyles.ContainerSocial>
                                            <Box sx={{ width: '10%', paddingRight: '30px' }}><BtnTiktok color="#808080" /></Box>
                                            <Box sx={{ width: '90%' }}>
                                                {item?.tiktok || ''}
                                            </Box>
                                        </UseStyles.ContainerSocial>
                                    </Box>
                                </Box> || <ContactUsForm
                                        dataContact={dataEdit}
                                        branchContact={branchContact}
                                        index={index}
                                        status={item.isEdit}
                                        setStatus={(e) => { onSave(e, index) }}
                                    />
                                }
                            </AccordionDetails>
                        </UseStyles.Accordion>
                    )
                })}
            </Box >
        </>
    )
}

export default ContactUs

