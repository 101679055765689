import { useCallback, useEffect, useState } from 'react'
import { Box, TableCell, TableRow, Typography, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

/** UTILS || SLICE */
import { treatment, setTreatmentByKey, setSummeryTreatments, TreatmentStateInterface, ProductStickerType, StickerType } from 'app/slice/treatment.slice'
import { getBranch, getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { showModal, resetModal, handleShow } from 'app/slice/modal.slice'

/** API */
import ComponentApi, { Bookmark } from 'api/components-api'
import TreatmentProductApi, { AllProductType, AllProduct, ProductType } from 'api/dentists/treatmentProduct.api'

/** CONSTANTS */
import { fileUrl } from 'api/api'
import { colors } from 'constants/theme'
import { Icons, icons, onImgError } from 'constants/images'

/** COMPONENT */
import InputTextSearch from 'component/Input/InputTextSearch'
import AlertProductOutOfStock, { AlertProductOutOfStockType } from 'component/Treatments/AlertProductOutOfStock'
import PopupManageProduct, { initialProductState } from 'component/Treatments/PopupManageProduct'
import AddBoxIcon from '@mui/icons-material/AddBox'

/** STYLE */
import { IconAddBox, IconBookmark } from 'features/treatments/MainMenu/OPD/style/UseStyle'
import { TypeTabs, ButtonType, ProductItem, ProductBox, ProductCard, InventoryChip, ProductQty } from 'features/treatments/MainMenu/OPD/style/ProductUseStyle'
import useWindowSize from 'utils/useWindowSize'
import TableCustom from './Table/TableCustom'
import { selectMe } from 'app/slice/user.slice'
import { notiError } from 'component/notifications/notifications'

export const ResponsiveTxTable = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    tableLayout: 'fixed',
    minWidth: '100%',
    '.MuiTableHead-root': {
      '.MuiTableCell-head': {
        '&:nth-of-type(1)': {
          width: '6%',
          [theme.breakpoints.down('lg')]: {
            width: 130
          }
        },
        '&:nth-of-type(2)': {
          width: '13%',
          [theme.breakpoints.down('lg')]: {
            width: 'auto'
          }
        },
        '&:nth-of-type(3)': {
          width: 130,
          [theme.breakpoints.down('lg')]: {
            width: 130
          }
        },
        '&:nth-of-type(4)': {
          [theme.breakpoints.down('lg')]: {
            width: 120
          }
        },
        '&:nth-of-type(5)': {
          paddingLeft: 4,
          paddingRight: 4,
          width: 130,
        },
        '&:nth-of-type(6)': {
          width: 110
        },
        '&:last-of-type': {
          width: 85,
          [theme.breakpoints.down('lg')]: {
            width: 80
          }
        },
      }
    },
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        padding: '6px 15px',
        verticalAlign: 'middle',
        '&:nth-of-type(3)': {
          paddingLeft: 4,
          paddingRight: 4,
        },
        '&:last-of-type': {
          paddingLeft: 4,
          paddingRight: 4,
        },
      }
    }
  },
  '.MuiTablePagination-root': {
    '.MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiInputBase-root': {
      display: 'none'
    }
  }
}))

type ProdType = {
  type: 'Treatment' | 'Payment' | 'RetrospectiveOrder'
  userId?: number
  patient?: any
  patientCaseId: number
  hasChangeProductPrice: 'PERMISS' | 'UNPERMISS'
  hasChangeProductDiscountAdditional: 'PERMISS' | 'UNPERMISS'
  pdShowType?: '1' | '0'
}

type ImageType = {
  fileId?: number
  filePath?: string
  fileName?: string
}

const initProductOutOfStock = {
  name: '',
  code: '',
  qty: 0,
  unit: ''
}

export default function PD(props: ProdType) {
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const modal: boolean = useSelector(handleShow)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const user = useSelector(selectMe)
  const branch_ = getClinicInfo()

  const userId = props.userId
  const branchId = Number(getBranch())
  const [typeSelected, setTypeSelected] = useState<string | number>('All')
  const [productsType, setProductsType] = useState([])
  const [products, setProducts] = useState<any[]>([])
  const [popupProductOutOfStock, setPopupProductOutOfStock] = useState<AlertProductOutOfStockType>(initProductOutOfStock)

  const [numberPage, setNumberPage] = useState(1)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(props.pdShowType === '1' ? 6 : 50)
  const [rowCount, setRowCount] = useState(0)
  const [search, setSearch] = useState('')

  // popup manage product
  const [popupManageProduct, setPopupManageProduct] = useState<ProductStickerType>(initialProductState)
  const [isShowPopupProduct, setIsShowPopupProduct] = useState(false)

  const [isNotAddProduct, setIsNotAddProduct] = useState<boolean>(false)

  useEffect(() => {
    setIsNotAddProduct((user?.hasCreateProduct === 'UNPERMISS' && props.type === 'Payment') ? true : false)
  }, [user])

  const loadProductsType = async () => {
    const params: AllProductType = {
      group: 'PRODUCT'
    }
    const resp = await TreatmentProductApi.findAllProductType(params)
    if (resp.status === 200) setProductsType(resp.data)
  }

  const loadProduct = useCallback(
    async (valueUserId: number) => {
      let condition: AllProduct = { page: page, pageLimit: pageLimit, branchId: branchId, userId: valueUserId, group: 'PRODUCT' }
      if (search) condition = { ...condition, search: search }
      if (typeSelected === 'BOOKMARK') condition = { ...condition, isBookmark: '1' }
      if (typeSelected && typeSelected !== 'BOOKMARK' && typeSelected !== 'All' && typeSelected !== 'OUT_OF_STOCK') condition = { ...condition, typeId: Number(typeSelected) }

      if (typeSelected === 'OUT_OF_STOCK') {
        const resp = await TreatmentProductApi.findAllProductOutOfStock(condition)
        if (resp.status === 200) {
          setProducts(resp.data.map((item: any) => { return { ...item, isOutOfStock: true } }))
          setRowCount(resp.headers['x-total'])
        }
      } else {
        const product = await TreatmentProductApi.findAllProduct(condition)
        if (product.status === 200) {
          setProducts(product.data)
          setRowCount(product.headers['x-total'])
        }
      }

    },
    [search, typeSelected, page]
  )

  const handleChangeType = (type: string | number) => {
    if (type === 'BOOKMARK') {
      setTypeSelected('BOOKMARK')
    } else if (type === 'All') setTypeSelected(0)
    else if (type === 'OUT_OF_STOCK') setTypeSelected('OUT_OF_STOCK')
    else setTypeSelected(Number(type))
  }

  const handleBookmark = async (id: number, bookmarkId: string) => {
    const payload: Bookmark = {
      bookmarkType: 'PRODUCT',
      userId: userId || 0,
      itemId: id,
      isBookmark: bookmarkId ? '0' : '1'
    }
    const resp = await ComponentApi.bookmark(payload)
    if (resp.status === 201) loadProduct(userId || 0)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleAddProduct = (prod: any) => {
    const hasOverdue = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId })

    if (prod.qty < 1) {
      setPopupProductOutOfStock({ name: prod.itemName || prod.itemNameEn, code: prod.itemCode, qty: prod.qty, unit: prod.itemUnitName || prod.itemUnitNameEn })
      dispatch(showModal())
      return
    }
    const item = {
      ...prod,
      total: prod.price,
      doctorId: !hasOverdue ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
      discount: 0,
      additional: 0,
      discountUnit: 'BAHT',
      additionalUnit: 'BAHT',
      stock: prod.qty,
      isEdit: null
    }
    const hasItemInSummary = _.some(treatments.summeryTreatments, (sum, index) => sum.itemId === item.itemId)
    if (!hasItemInSummary) {
      const doctorIsInConsentDocument = _.some(treatments?.summeryTreatments, { isEdit: '0', doctorId: _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId })
      const sticker: StickerType = {
        itemId: prod.itemId,
        itemName: prod.itemName,
        itemNameEn: prod.itemNameEn,
        itemCode: prod.itemCode,
        image: {
          fileId: prod?.image?.fileId || null,
          filePath: prod?.image?.filePath || '',
          fileName: prod?.image?.fileName || ''
        },
        stock: prod.qty,
        defaultProductUnitName: prod.itemUnitName || prod.itemUnitNameEn
      }
      if (props.patientCaseId && props.type !== "RetrospectiveOrder") {
        TreatmentProductApi.findStickerById(props.patientCaseId, item.itemId)
          .then(({ data }) => {
            const newItem: ProductStickerType = {
              ...prod,
              sticker: JSON.stringify(sticker),
              tempOrderProductId: data.tempOrderProductId,
              doctorId: !doctorIsInConsentDocument ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
              qty: 1,
              price: prod.price,
              discount: 0,
              discountUnit: 'BAHT',
              additional: 0,
              additionalUnit: 'BAHT',
              total: prod.price
            }
            setPopupManageProduct(newItem)
            setIsShowPopupProduct(true)
            dispatch(showModal())
          })
          .catch((e) => {
            return
          })
      } else {
        const newItem: ProductStickerType = {
          ...prod,
          sticker: JSON.stringify(sticker),
          // tempOrderProductId: data.tempOrderProductId,
          doctorId: !doctorIsInConsentDocument ? _.get(treatments.summeryTreatments[0], 'pinDoctorId') || userId : null,
          qty: 1,
          price: prod.price,
          discount: 0,
          discountUnit: 'BAHT',
          additional: 0,
          additionalUnit: 'BAHT',
          total: prod.price
        }
        setPopupManageProduct(newItem)
        setIsShowPopupProduct(true)
        dispatch(showModal())
      }
    } else {
      const index = _.findIndex(treatments.summeryTreatments, { itemId: item.itemId })
      if (index < 0) dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...treatments.summeryTreatments, { ...item, qty: 1 }] }))
      else {
        const currentQty = Number(treatments.summeryTreatments[index].qty) + 1
        if (currentQty > prod.qty) {
          setPopupProductOutOfStock({ name: prod.itemName || prod.itemNameEn, code: prod.itemCode, qty: prod.qty, unit: prod.itemUnitName || prod.itemUnitNameEn })
          dispatch(showModal())
          return
        } else {
          dispatch(setSummeryTreatments({ index: index, key: 'qty', value: currentQty <= prod.qty ? currentQty : prod.qty }))
          dispatch(setSummeryTreatments({ index: index, key: 'total', value: currentQty <= prod.qty ? currentQty * prod.price : prod.qty * prod.price }))
        }
      }
    }
  }

  const renderImage = (image: ImageType) => {
    if (image?.filePath && image?.fileName) return `${fileUrl}/${image.filePath}/${image.fileName}`
    else return icons.prodDefault
  }

  const renderDateExpired = (date: string, day: number) => {
    if (date && day) {
      const now = moment()
      const diff = moment(date).add('days', 1).diff(now, 'days')
      if (diff <= day) return colors.themeThirdColor
      else return colors.textLightGray
    } else return colors.textLightGray
  }

  const desktopHeadCells = [
    { id: 'no', disablePadding: false, label: '#' },
    { id: 'type', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_TYPE') }
  ]

  const mobileHeadCells = [{ id: 'action', disablePadding: false, label: 'Action', align: width >= 768 ? 'center' : 'right', class: 'pr-1 pr-md-3' }]

  const tableHeadCells = [
    { id: 'code', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_CODE'), align: 'center', width: '130px' },
    { id: 'service', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_NAME') },
    { id: 'unit', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_UNIT_NAME'), align: 'center' },
    { id: 'price', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_PRICE'), align: 'right' },
    { id: 'exp', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_EXPIRE_DATE'), align: 'center' },
    { id: 'qty', disablePadding: false, label: t('TREATMENT_PRODUCT.TABLE.PRODUCT_QTY'), align: 'center' }
  ]

  const headCells = width >= 768 ? (width >= 1200 && [...desktopHeadCells, ...tableHeadCells, ...mobileHeadCells]) || [...tableHeadCells, ...mobileHeadCells] : [...mobileHeadCells]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1

    const { amounDayBeforeExpired, bookmark, bookmarkId, itemId, itemCode, expireDate, group, inventoryId, itemName, itemTypeName, itemUnitName, minStock, price, qty, qtyExpireDate, isOutOfStock } = objData

    return (
      <TableRow key={itemId}>
        {(width >= 768 && (
          <>
            {width >= 1200 && (
              <>
                <TableCell align="center" scope="row">
                  {no}
                </TableCell>
                <TableCell align="left" scope="row" className="text-ellipsis" title={itemTypeName}>
                  {itemTypeName}
                </TableCell>
              </>
            )}
            <TableCell align="center" scope="row" className="text-ellipsis">
              {itemCode}
            </TableCell>
            <TableCell align="left" scope="row" className="text-ellipsis">
              {itemName}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis">
              {itemUnitName}
            </TableCell>
            <TableCell align="right" scope="row" className="text-ellipsis">
              {numberFormat(price)}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis" style={{ color: renderDateExpired(expireDate, amounDayBeforeExpired) }}>
              {`${toBuddhistYear(moment(expireDate), 'DD/MM/YY') === 'NaN' ? '-' : toBuddhistYear(moment(expireDate), 'DD/MM/YY')}`}{`${toBuddhistYear(moment(expireDate), 'DD/MM/YY') === 'NaN' ? '' : `(${numberFormat(qtyExpireDate || 0, 0, 0)})`}`}
            </TableCell>
            <TableCell align="center" scope="row" className="text-ellipsis" style={{ color: qty < minStock ? 'red' : '' }}>
              {qty}
            </TableCell>
            <TableCell align="center" scope="row">
              <IconBookmark key={itemId} className="p-0 mr-1" onClick={() => handleBookmark(itemId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
              </IconBookmark>
              <IconAddBox disabled={(props?.hasChangeProductPrice === 'PERMISS' ? false : true) || isOutOfStock || isNotAddProduct} key={itemId} className="p-0 ml-1" onClick={() => handleAddProduct(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                <AddBoxIcon style={{ width: 30, height: 30 }} />
              </IconAddBox>
            </TableCell>
          </>
        )) || (
            <>
              <TableCell align="left" scope="row">
                <Box className="d-flex justify-content-between">
                  <Box>
                    <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                      {t('TREATMENT_PRODUCT.TABLE.PRODUCT_CODE')}
                    </Typography>
                    <Typography className="d-inline">{itemCode}</Typography>
                  </Box>
                  <Box>
                    <IconBookmark key={itemId} className="p-0 mr-1" onClick={() => handleBookmark(itemId, bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                      {bookmarkId ? <Icons.StarActive width="24" height="24" /> : <Icons.StarInActive width="24" height="24" />}
                    </IconBookmark>
                    <IconAddBox disabled={props?.hasChangeProductPrice === 'PERMISS' ? false : true || isNotAddProduct} key={itemId} className="p-0 ml-1" onClick={() => handleAddProduct(objData)} sx={{ backgroundColor: 'transparent !important' }}>
                      <AddBoxIcon style={{ width: 30, height: 30 }} />
                    </IconAddBox>
                  </Box>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.PRODUCT_NAME')}
                  </Typography>
                  <Typography className="d-inline">{itemName}</Typography>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.PRODUCT_UNIT_NAME')}
                  </Typography>
                  <Typography className="d-inline">{itemUnitName}</Typography>
                </Box>
                <Box>
                  <Typography className="d-inline-block mr-3" sx={{ width: '85px', fontWeight: 600 }}>
                    {t('TREATMENT_PRODUCT.TABLE.PRODUCT_PRICE')}
                  </Typography>
                  <Typography className="d-inline">{numberFormat(price)}</Typography>
                </Box>
              </TableCell>
            </>
          )}
      </TableRow>
    )
  }

  const onCancelPopupManageProduct = () => {
    dispatch(resetModal())
    setPopupManageProduct(initialProductState)
    setIsShowPopupProduct(false)
  }

  useEffect(() => {
    loadProductsType()
  }, [])

  useEffect(() => {
    setPage(1)
  }, [typeSelected])

  useEffect(() => {
    if (userId) loadProduct(userId)
  }, [loadProduct, userId])

  useEffect(() => {
    if (!modal) {
      onCancelPopupManageProduct()
      setPopupProductOutOfStock(initProductOutOfStock)
      dispatch(resetModal())
    }
  }, [modal])

  const handleCannotAddProduct = () => {
    if (isNotAddProduct) {
      return notiError(t('TREATMENT_PRODUCT.PRODUCT.ERROR.NOTADD'))
    }
  }

  return (
    <Box>
      <Box className="d-flex justify-content-between align-items-center pb-3">
        <Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('TREATMENT_PRODUCT.TITLE.PRODUCT')}</Typography>
        <Box className="w-100 pl-3" sx={{ maxWidth: 379 }}>
          <InputTextSearch
            keyInput={'search'}
            label={t('INPUT.SEARCH')}
            value={search}
            onchange={(event) => {
              setSearch(event.target.value)
            }}
            onClear={(event) => {
              setSearch('')
            }}
          />
        </Box>
      </Box>
      <TypeTabs className="d-flex flex-wrap custom-scroll">
        <Box className="px-1 pt-2">
          <ButtonType variant="outlined" title={t('ALL')} className={`${typeSelected === 'All' || typeSelected === 0 ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={() => handleChangeType('All')} key={`type-all`}>
            <span className="text-ellipsis">{t('ALL')}</span>
          </ButtonType>
        </Box>
        <Box className="px-1 pt-2" style={{ width: 150 }} >
          <ButtonType variant="outlined" title={t('TREATMENT_PRODUCT.PRODUCT.PD_OUT')} className={`${typeSelected === 'OUT_OF_STOCK' || typeSelected === 'OUT_OF_STOCK' ? 'active' : ''} overflow-hidden`} data-value={'OUT_OF_STOCK'} onClick={() => handleChangeType('OUT_OF_STOCK')} key={`out-of-stock`}>
            <span className="text-ellipsis">{t('TREATMENT_PRODUCT.PRODUCT.PD_OUT')}</span>
          </ButtonType>
        </Box>
        <Box className="px-1 pt-2">
          <ButtonType variant="outlined" title={t('BOOKMARK')} className={`${typeSelected === 'BOOKMARK' ? 'active' : ''}`} data-value={'BOOKMARK'} onClick={() => handleChangeType('BOOKMARK')} key={`type-bookmark`}>
            {typeSelected === 'BOOKMARK' ? <Icons.StarActive fill={colors.white} /> : <Icons.StarInActive fill={colors.textPrimary} />}
          </ButtonType>
        </Box>
        {_.map(productsType, (type: ProductType, index: number) => {
          return (
            <Box key={`prod-type-${index}`} className="px-1 pt-2">
              <ButtonType
                variant="outlined"
                key={type.productTypeId}
                title={type.productTypeName || type.productTypeNameEn}
                onClick={() => handleChangeType(type.productTypeId)}
                className={`${Number(typeSelected) === Number(type.productTypeId) ? 'active' : ''} text-ellipsis`}
              >
                <span className="text-ellipsis">{type.productTypeName || type.productTypeNameEn}</span>
              </ButtonType>
            </Box>
          )
        })}
      </TypeTabs>
      {(props.pdShowType === '0' || branch_?.pdShowType === '0') && (
        <ProductItem className="d-flex align-items-start flex-wrap custom-scroll mt-3 mt-xl-4">
          {(!_.isEmpty(products) &&
            _.map(products, (prod: any, index: number) => {
              return (
                <ProductBox key={`prod-${index}`} className="px-1 px-xl-2 mb-2 mb-xl-3">
                  <ProductCard>
                    {(_.get(_.find(treatments.summeryTreatments, { itemId: prod.itemId }), 'qty') && <ProductQty>{_.get(_.find(treatments.summeryTreatments, { itemId: prod.itemId }), 'qty')}</ProductQty>) || <></>}
                    <Box onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="card-image">
                      <Box className="position-relative">
                        {prod.isOutOfStock && <span className='position-absolute top-50 start-50 translate-middle fw-bolder' style={{ fontSize: '22px', opacity: 0.9, zIndex: 1, borderBottom: '3px solid', paddingBottom: '' }}>{t('TREATMENT_PRODUCT.PRODUCT.OUT_STOCK')}</span> || <span></span>}
                        <img src={renderImage(prod.image)} alt={prod.itemName} className={`${_.isEmpty(prod.image) || !prod.image?.filePath || !prod.image?.fileName ? 'no-prod-image' : ''}`} onError={onImgError} style={{ ...(prod.isOutOfStock && { opacity: 0.5 }) }} />
                      </Box>
                    </Box>
                    <Box className="card-detail">
                      {(prod.itemCode && (
                        <Typography onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="text-ellipsis" sx={{ paddingBottom: '2px', color: colors.textLightGray, fontSize: '11px', fontWeight: 500 }}>
                          {prod.itemCode}
                        </Typography>
                      )) || <></>}
                      <Box className="d-flex align-items-center justify-content-between">
                        <Typography onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="text-ellipsis" sx={{ flex: 1, lineHeight: 1.6 }}>
                          {prod.itemName || prod.itemNameEn}
                        </Typography>
                        <IconBookmark className="p-0 ml-2" onClick={() => handleBookmark(prod.itemId, prod.bookmarkId)} sx={{ backgroundColor: 'transparent !important' }}>
                          {prod.isBookmark ? <Icons.StarActive width="20" height="20" /> : <Icons.StarInActive width="20" height="20" />}
                        </IconBookmark>
                      </Box>
                      <Typography onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="text-ellipsis" sx={{ marginTop: '-2px', fontSize: '14px', fontWeight: 500 }}>
                        {numberFormat(prod.price)} {t('CURRENCY_CHANGE.TH')}
                      </Typography>
                      {prod.isOutOfStock === 0 &&
                        <Box onClick={() => prod.isOutOfStock || isNotAddProduct ? handleCannotAddProduct() : handleAddProduct(prod)} className="d-flex align-items-center justify-content-between pt-1">
                          <Typography
                            className="text-ellipsis"
                            sx={{
                              color: renderDateExpired(prod.expireDate, prod.amounDayBeforeExpired),
                              fontSize: '11px',
                              fontWeight: 500
                            }}
                          >
                            {(prod.expireDate && `EXP ${toBuddhistYear(moment(prod.expireDate), 'DD/MM/YY')} (${numberFormat(prod.qtyExpireDate, 0, 0)})`) || `Lot (${numberFormat(prod.qtyExpireDate, 0, 0)})`}
                          </Typography>
                          <InventoryChip label={`${t('TREATMENT_PRODUCT.CARD.QUANTITY')}: ${numberFormat(prod.qty, 0, 0)}`} limit={prod.qty <= prod.minStock} className="ml-2 p-0" />
                        </Box>
                        ||
                        <Box sx={{ height: '22px' }}>
                        </Box>
                      }

                    </Box>
                  </ProductCard>
                </ProductBox>
              )
            })) || (
              <Typography className="w-100 text-center" sx={{ color: colors.disabledGray, paddingTop: '48px' }}>
                {t('TREATMENT_PRODUCT.TITLE.EMPTY_PRODUCT')}
              </Typography>
            )}
        </ProductItem>
      )}
      {(props.pdShowType === '1' || branch_?.pdShowType === '1') && (
        <ResponsiveTxTable className="mt-2">
          <TableCustom
            page={page}
            pageLimit={pageLimit}
            setPageLimit={handleChangeRowsPerPage}
            setPage={handleChangePage}
            rowCount={rowCount}
            headCells={headCells}
            rowsData={products.map((val, i) => {
              return renderData(val, i)
            })}
          />
        </ResponsiveTxTable>
      )}

      {popupProductOutOfStock.name && <AlertProductOutOfStock type="product" name={popupProductOutOfStock.name} code={popupProductOutOfStock.code} qty={popupProductOutOfStock.qty} unit={popupProductOutOfStock.unit} />}

      {isShowPopupProduct ? (
        <PopupManageProduct
          page={props.type}
          group="PRODUCT"
          type="thumbnail"
          item={popupManageProduct}
          patientCaseId={props.patientCaseId}
          onCancel={onCancelPopupManageProduct}
          onSubmit={onCancelPopupManageProduct}
          patientName={`${props.patient.firstname} ${props.patient.lastname}`}
          hasChangeProductPrice={props.hasChangeProductPrice}
          hasChangeProductDiscountAdditional={props.hasChangeProductDiscountAdditional}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
