import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

/** API */
import LabOrderApi, {
    LabOrderAllDetailInterface,
    LabOrderDetailInterface,
    PayloadLabOrderDetails,
} from 'api/lab-order.api';
import LabGroupsApi from 'api/setting/default-labs/lab-group.api'

import ModalCustom from 'component/ModalCustom/ModalCustom'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { dateToView } from 'utils/date.utils';
import InputCheckbox from 'component/Input/InputCheckbox'

export interface IViewLabOrderProps {
    labOrderId: number
    showPopup: boolean
    onClose: () => void
}

export default function ViewLabOrder(props: IViewLabOrderProps) {
    const { t } = useTranslation()

    /** DATA LIST */
    const [labGroup, setLabGroup] = useState<any>(null)
    const [labOrder, setLabOrder] = useState<LabOrderAllDetailInterface | null>(null)
    const [labOrderDetails, setLabOrderDetails] = useState<PayloadLabOrderDetails[]>([])

    useEffect(() => {
        props.showPopup && loadData()
    }, [props.showPopup])

    const getLabelLabOrderStatus = (status?: string) => {
        let label: any = ''
        if (status === 'ORDER_LAB') label = t('LAB_ORDER.LAB_STATUS.ORDER_LAB')
        else if (status === 'SEND_LAB') label = t('LAB_ORDER.LAB_STATUS.SEND_LAB')
        else if (status === 'RECEIVE_LAB') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_LAB')
        else if (status === 'RECEIVE_BILL') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_BILL')
        else if (status === 'REPLY_LAB') label = t('LAB_ORDER.LAB_STATUS.REPLY_LAB')
        else if (status === 'CANCEL_LAB') label = t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')
        else if (status === 'CLOSE_LAB') label = t('LAB_ORDER.LAB_STATUS.CLOSE_LAB')
        return label
    }


    const loadData = async () => {
        await LabOrderApi.findById(props.labOrderId).then(async ({ data }) => {
            const tempDetails: PayloadLabOrderDetails[] | any[] = data?.labOrderDetails?.map((detail: LabOrderDetailInterface) => {
                return {
                    labId: detail.labId,
                    labTypeId: detail.labTypeId,
                    inputSupport: detail.inputSupport,
                    labText: detail?.labText || ''
                }
            }) || []
            setLabOrder(data)
            setLabOrderDetails(tempDetails)
            if (data?.labGroupId)
                await LabGroupsApi.findById(Number(data.labGroupId)).then(({ data: labGroupData }) => {
                    setLabGroup(labGroupData)
                })
        })
    }

    return props.showPopup ? (
        <ModalCustom
            size={'xl'}
            title={t('LAB_ORDER.VIEW_LAB.TITLE')}
            alignFooter={'end'}
            fullscreen={'xxl-down'}
            closeButton
            onClose={props.onClose}
            component={
                <>
                    <div className="head-lab">
                        <Row className="mx-0 col-sm-6 px-0">
                            <Col sm={6} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.VIEW_LAB.NO_LAB')}:</strong> {labOrder?.labOrderNumber || ''}
                            </Col>
                            <Col sm={6} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.VIEW_LAB.STATUS')}:</strong>  {getLabelLabOrderStatus(labOrder?.labOrderStatus) || ''}
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.TABLE.PATIENT_NAME')}</strong> {labOrder?.patientFullName || ''}
                            </Col>
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('VN')}</strong> {labOrder?.vnNumber || ''}
                            </Col>
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('USER_DOCTOR')}</strong> {labOrder?.doctorFullname || ''}
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.FORM.DATE_ORDER')}</strong> {dateToView(labOrder?.dateOrder || '')}
                            </Col>
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.FORM.TIME_ORDER')}</strong> {labOrder?.timeOrder || ''}
                            </Col>
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.TABLE.DATE_EXPORT')}</strong>  {labOrder?.dateExport ? dateToView(labOrder?.dateExport) : '-'}
                            </Col>
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.TABLE.DATE_RECEIVE')}</strong>  {labOrder?.dateImport ? dateToView(labOrder?.dateImport) : '-'}
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <Col sm={6} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.TABLE.COMPANY')}</strong> {labOrder?.labCompanyName || ''}
                            </Col>
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.VIEW_LAB.DATE_RECEIVE')}</strong>  {labOrder?.dateReceive ? dateToView(labOrder?.dateReceive) : '-'}
                            </Col>
                            <Col sm={3} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.VIEW_LAB.DATE_BILL')}</strong>  {labOrder?.dateBill ? dateToView(labOrder?.dateBill) : '-'}
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <Col sm={6} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.VIEW_LAB.OPERATIVE_NAME')}</strong> {labOrder?.operativeName || ''}
                            </Col>
                            <Col sm={6} className="px-1 mb-2">
                                <strong>{t('LAB_ORDER.VIEW_LAB.LAB_FORM_TYPE')}</strong> {labOrder?.labGroupName || ''}
                            </Col>
                        </Row>
                    </div>
                    <Row className="mx-0 overflow-auto custom-scroll my-2" style={{ maxHeight: 500 }}>
                        {_.map(_.groupBy(labGroup?.labGroupDetails, 'labTypeId'), (detail: any) =>
                            <Row className="mx-0 px-0">
                                {_.map(detail, (lab: any, i: number) => {
                                    const { labId, labTypeId } = lab
                                    const findDetail: PayloadLabOrderDetails | undefined = [...labOrderDetails].find((labOrderDetailData: any) => labOrderDetailData.labId === labId && labOrderDetailData.labTypeId === labTypeId)
                                    const isChecked: boolean = findDetail ? true : false
                                    return (
                                        <>
                                            {i === 0 && <div className="p-2 mb-2" style={{ backgroundColor: colors.themeSecondColor10, fontSize: 18, fontWeight: 500 }}>{lab.labTypeName}</div>}
                                            <Col className="col-12 col-sm-6 text-ellipsis align-items-center">
                                                <InputCheckbox label={`${lab.labName}   ${isChecked ? `${isChecked && ` (${t('LAB_ORDER.VIEW_LAB.MORE')}: ${findDetail?.labText || '-'})`}` : ''}`} checked={isChecked} disabled={!isChecked} />

                                            </Col>
                                        </>
                                    )
                                })}
                            </Row>
                        )}
                    </Row>
                    <div className="p-2 mb-2" style={{ backgroundColor: colors.themeSecondColor10, fontSize: 18, fontWeight: 500 }}>{t('LAB_ORDER.VIEW_LAB.REMARK')}</div>
                    <div className='pl-2'>{t('LAB_ORDER.VIEW_LAB.REMARK')} {labOrder?.remark || '-'}</div>
                </>
            }
        />
    ) : (<></>)
}
