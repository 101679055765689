import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InputTextarea from 'component/Input/InputTextarea'
import { colors } from 'constants/theme'
import * as useStyles from 'features/admin/clinic-management/style'
import { ButtonInfoSimple } from 'component/Button/ButtonInfo'

interface Data {
  onchange?: (value: any) => void
  onkeypress?: (value: any) => void
  valArr?: any
}

export default function AlertMsg(props: Data) {
  const { t } = useTranslation()
  const [dataABout, setDataAbout] = useState<any>(props.valArr)

  useEffect(() => {
    if (props.onchange) props.onchange(dataABout)
  }, [dataABout])

  useEffect(() => {
    setDataAbout(props.valArr)
  }, [props.valArr])

  return (
    <div className="px-4 pt-4 pb-4">
      <Grid container spacing={2} className="m-0 h-100 w-100">
        <Grid lg={8} className={`w-100 pr-3`} sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` } }}>
          <useStyles.TitleFirst className={`d-flex align-items-center `}>
            {t('CLINIC_MANAGEMENT.TITLE.SETTING_MSG')}
          </useStyles.TitleFirst>
          <Grid className="d-flex pt-4">
            <div className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_CREATE')}
              <br />
              <div className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentCreate?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </div>
            </div>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentCreate || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_CREATE')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentCreate: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <div className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_EDIT')}
              <br />
              <div className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentUpdate?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </div>
            </div>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentUpdate || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_EDIT')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentUpdate: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <div className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_1DAY')}
              <br />
              <div className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentPreday?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </div>
            </div>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentPreday || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_1DAY')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentPreday: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <div className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_TODAY')}
              <br />
              <div className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentDay?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </div>
            </div>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentDay || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_TODAY')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentDay: e.target.value })
              }}
            />
          </Grid>
          <Grid className="d-flex pt-4">
            <div className="col-sm-3">{t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_STATUS')}
              <br />
              <div className='d-flex align-content-center'>
                ({dataABout?.notilineAppointmentStatus?.length || 0}/1000)
                <ButtonInfoSimple text={t('CLINIC_MANAGEMENT.MESSAGE.ALERT_LINE_NOTI')} />
              </div>
            </div>
            <InputTextarea
              hookValue={true}
              inputProps={{ maxLength: 1000 }}
              name="appoinment"
              key={'appoinment'}
              value={dataABout?.notilineAppointmentStatus || ''}
              label={t('CLINIC_MANAGEMENT.FORM.APPOINTMENT_STATUS')}
              height={96}
              onchange={(e) => {
                setDataAbout({ ...dataABout, notilineAppointmentStatus: e.target.value })
              }}
            />
          </Grid>
        </Grid>
        <Grid lg={4} className={`w-100 px-3`}>
          <useStyles.TitleFirst className={`d-flex align-items-center`}>{t('CLINIC_MANAGEMENT.TITLE.TEMPLATE')}</useStyles.TitleFirst>
          <div className="d-flex">
            <p className="col-sm-7">{t('CLINIC_MANAGEMENT.TEMPLATE.USE_TEMPLATE')}</p>
            <p>{t('CLINIC_MANAGEMENT.TEMPLATE.DATA_DISPLAY')}</p>
          </div>
          <div className="d-flex">
            <p className="col-sm-7">:OPERATIVE_NAME</p>
            <useStyles.bgMsg >{t('CLINIC_MANAGEMENT.TEMPLATE.SERVICE_NAME')}</useStyles.bgMsg>
          </div>
          <div className="d-flex ">
            <p className="col-sm-7">:PATIENT_FULLNAME</p>
            <useStyles.bgMsg >{t('CLINIC_MANAGEMENT.TEMPLATE.PATIEN_NAME')}</useStyles.bgMsg>
          </div>
          <div className="d-flex ">
            <p className="col-sm-7">:CLINIC_NAME</p>
            <useStyles.bgMsg >{t('CLINIC_MANAGEMENT.TEMPLATE.CLINIC_NAME')}</useStyles.bgMsg>
          </div>
          <div className="d-flex ">
            <p className="col-sm-7">:BRANCH_NAME</p>
            <useStyles.bgMsg >{t('CLINIC_MANAGEMENT.TEMPLATE.BRANCH_NAME')}</useStyles.bgMsg>
          </div>
          <div className="d-flex ">
            <p className="col-sm-7">:DATE_APPOINTMENT</p>
            <useStyles.bgMsg >{t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_DAY')}</useStyles.bgMsg>
          </div>
          <div className="d-flex ">
            <p className="col-sm-7">:TIME_APPOINTMENT</p>
            <useStyles.bgMsg >{t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_TIME')}</useStyles.bgMsg>
          </div>
          <div className="d-flex ">
            <p className="col-sm-7">:STATUS_APPOINTMENT</p>
            <useStyles.bgMsg  >{t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_STATUS')}</useStyles.bgMsg>
          </div>
          <div className="d-flex ">
            <p className="col-sm-7">:LINK_CHECKIN_APPOINTMENT</p>
            <useStyles.bgMsg >{t('CLINIC_MANAGEMENT.TEMPLATE.APPOINTMENT_LINK')}</useStyles.bgMsg>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
