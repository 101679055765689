import { useState, useEffect, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Divider, FormControl, FormControlLabel, RadioGroup } from '@mui/material'
import { Dropdown } from 'react-bootstrap'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { useSelector } from 'react-redux'
import { selectMe } from 'app/slice/user.slice'
import _ from 'lodash'
import { KEY_STORAGE } from 'constants/common'
import InputRadio from 'component/Input/InputRadio'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

/** STYLE */
import { colors } from 'constants/theme'

/** API */
import ClinicApi from 'api/master/clinic.api'

/** COMPONENT */
import InputCheckbox from 'component/Input/InputCheckbox'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'
import { setPatient } from 'app/slice/register/register.slice'
import { getBranch } from 'utils/app.utils'

/** UTIL */
import { UseSnCode } from 'utils/useSnCode'

const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  '&.disabled-patient-search': {
    '.dropdown-toggle': {
      backgroundColor: `${colors.disabledGray} !important`,
      pointerEvents: 'none'
    }
  }
}))

export const DropdownContainer = styled(Dropdown)<{ bodyHeight: number }>(({ theme, bodyHeight, bodyWidth }) => ({
  height: bodyHeight,
  width: bodyWidth
}))

export const DropdownToggle = styled(Dropdown.Toggle)(({ theme }) => ({
  width: '180px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  fontSize: 16,
  padding: '0 0.5rem 0 1rem',
  border: 'none !important',
  boxShadow: 'none !important',
  backgroundColor: `${colors.themeSecondColor} !important`,
  borderRadius: '4px 0 0 4px',
  '&:after': {
    marginLeft: 'auto'
  },
  '&:focus': {
    boxShadow: 'none !important'
  },
  '&[aria-expanded="true"]': {
    boxShadow: 'none !important',
    '&:after': {
      transform: 'rotate(180deg)'
    }
  },
  [theme.breakpoints.down('sm')]: {
    width: 120
  }
}))

export const DropdownMenu = styled(Dropdown.Menu)(({ theme }) => ({
  backgroundColor: colors.white,
  border: `1px solid ${colors.lightGray}`,
  borderRadius: 3,
  marginTop: 2,
  fontSize: '16px',
  zIndex: 1021,
  '.dropdown-item': {
    backgroundColor: 'transparent !important',
    paddingTop: 2,
    paddingBottom: 2,
    '&:hover': {
      background: `${colors.themeSecondColor06} !important`,
      color: colors.themeSecondColor
    }
  }
}))

export const PatientSearch = styled('div')<{ bodyHeight: number }>(({ theme, bodyHeight }) => ({
  position: 'relative',
  width: '100%',
  maxHeight: bodyHeight,
  '.MuiOutlinedInput-root': {
    height: `${bodyHeight}px !important`,
    padding: '0 !important',
    borderRadius: '0 4px 4px 0'
  },
  '.MuiInputLabel-root': {
    top: '50%',
    transform: 'translate(1rem ,-50%)',
    '&.Mui-focused, &[data-shrink="true"]': {
      opacity: 0,
      display: 'none'
    }
  },
  '.MuiOutlinedInput-input': {
    paddingLeft: '1rem !important',
    paddingRight: '2.25rem !important'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: '0 4px 4px 0',
    legend: {
      width: 0
    }
  },
  '.MuiIconButton-root': {
    '&.MuiAutocomplete-clearIndicator': {
      visibility: 'hidden !important',
      marginRight: 4
    },
    '&.MuiAutocomplete-popupIndicator': {
      display: 'none'
    }
  },
  '.icon-action': {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '0.688rem',
    zIndex: 1,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none',
    color: colors.black60
  }
}))

export interface SearchPatientValue {
  branchCnNumber: string;
  branchId: number | null;
  cnNumber: string;
  firstname: string;
  fullname: string;
  idCard: string;
  lastname: string;
  patientId: number;
  phone: string;
  snCode: string | null;
  specialInfo: string | null;
}

export interface ISearchPatientsProps {
  patientId?: number
  patient?: any
  minWidth?: any
  rootStyle?: any
  callbackPatientId?: (val: any) => void
  onClear?: () => void
  search?: any
  bodyHeight?: number
  bodyWidth?: string | number
  helperText?: string
  disabled?: boolean
  hideFilter?: boolean
  optionBelowSideBar?: boolean
  appointment?: boolean
  status?: string
  callbackPatientName?: (val: string) => void
  callbackPatient?: (val: SearchPatientValue) => void
  getAboutUs?: (data: any) => void
}

const setCondition = (condition: any, props: ISearchPatientsProps) => {
  let con: any = { pageLimit: 50 }
  if (condition?.search) con = { ...con, search: condition?.search }
  if (condition?.branch) con = { ...con, allBranch: condition?.branch }
  if (condition?.filterBy) con = { ...con, condition: condition?.filterBy, status: props.status }
  if (condition?.search) con = { ...con, search: condition?.search }
  if (props.appointment) con = { ...con, appointment: true }
  return con
}
type filterType = 'cnNumber' | 'branchCnNumber' | 'idCard' | 'phone' | 'snCode'
const getFilter = (filter: filterType, BranchPatientName: string, option: any, patientSelect: any) => {
  if (!option?.firstname && !patientSelect?.firstname) {
    return ''
  }

  return filter === 'branchCnNumber' ? BranchPatientName : `${_.get(option || patientSelect, filter) || _.get(patientSelect, filter) || ''} ${option.firstname || patientSelect.firstname} ${option.lastname || patientSelect.lastname}`
}
export default function SearchPatients(props: ISearchPatientsProps) {
  const user = useSelector(selectMe)
  const { t } = useTranslation()
  const [patients, setPatients] = useState([])
  const [keyValue, setKeyValue] = useState(false)
  const [userData, setUserData]: any = useState({})
  const [patientSelect, setPatientSelect]: any = useState({})
  const [loading, setLoading] = useState(false)
  const [patientSearch, setPatientSearch] = useState('')

  const branchId = getBranch()
  const [snType, setSnType] = useState(false)

  /** CONDITION  */
  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      if (!props.getAboutUs) {
        const sn = await UseSnCode()
        setSnType(sn)
      } else {
        const res = await UseSnCode({ type: 'data' })
        setSnType(res?.snType === '1' || false)
        props.getAboutUs(res)
      }
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const [filter, setFilter] = useState<filterType>((localStorage.getItem(KEY_STORAGE.FILTER_PATIENT_CONDITION) as filterType) ?? ('branchCnNumber' as filterType))

  const [allBranch, setAllBranch] = useState(Boolean(localStorage.getItem(KEY_STORAGE.FILTER_PATIENT_ALL_BRANCH)) || false)
  const [status] = useState('')

  const handleInputSearch = (condition: { search: string; branch: boolean; filterBy: string; status: string }) => {
    if (condition.search === '') {
      props?.onClear && props.onClear()
      setPatientSelect({})
    }
    setPatientSearch(condition.search)
    loadPatients({ search: condition.search, branch: condition.branch, filterBy: condition.filterBy, status: condition.status })
  }
  const loadPatients = (condition: { search: string; branch: boolean; filterBy: string; status: string }) => {
    setLoading(true)

    const con = setCondition(condition, props)

    ClinicApi.findAllPatients(con)
      .then(({ data }) => {
        const dataBranch = data.filter((item: any) => item.branchId === branchId)
        const dataOutBranch = data.filter((item: any) => item.branchId !== branchId)
        const newDataBranch: any = [...dataBranch, ...dataOutBranch.sort((a: any, b: any) => a.branchId - b.branchId)]

        setPatients(newDataBranch)
      })
      .catch((e) => {
        return
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const redirectTo = (patientId: number, patientName: string) => {
    if (props?.callbackPatientId) props?.callbackPatientId(patientId)
    if (props?.callbackPatientName) props?.callbackPatientName(patientName)
    // if ()
  }

  const renderBranchPatientName = (val: any) => {
    const branchPrefix = userData?.roles?.roleBranch?.branches?.branchCode || 'CN'
    const branch = _.split(val?.branchCnNumber, ',')
    const regex = new RegExp(branchPrefix)
    const branchCnNumberMatch = branch.find((i) => regex.exec(i))
    const branchCnNumber = branchCnNumberMatch ? branchCnNumberMatch : ''
    return `${branchCnNumber || branch.toString()} ${val?.firstname} ${val?.lastname}`
  }

  const handleChangeAllBranch = (value: boolean) => {
    setAllBranch(value)
    localStorage.setItem(KEY_STORAGE.FILTER_PATIENT_ALL_BRANCH, value.toString())
  }

  const handleChangeCondition = (value: 'cnNumber' | 'branchCnNumber' | 'idCard' | 'phone' | 'snCode') => {
    setFilter(value)
    localStorage.setItem(KEY_STORAGE.FILTER_PATIENT_CONDITION, value)
  }

  const renderPatientsData = (valueAllPatients: any, valuePatientSelect: any) => {
    const newPatients = _.filter(valueAllPatients, (p) => p?.patientId !== valuePatientSelect?.patientId)
    return !_.isEmpty(valuePatientSelect) ? [valuePatientSelect, ...newPatients] : newPatients
  }

  const PatientOptions = (val: any, option: any) => {
    console.log(getFilter(filter, renderBranchPatientName(option || patientSelect), option, patientSelect))
  }
  useEffect(() => {
    let search = ''
    if (props?.search) search = props.search
    setPatientSearch(search)
    loadPatients({ search: search, branch: allBranch, filterBy: filter, status: status })
  }, [allBranch, filter, status])

  useEffect(() => {
    setUserData(user)
  }, [user])

  return (
    <SearchContainer className={`${props.disabled ? 'disabled-patient-search' : ''}`} style={{ ...props?.rootStyle, minWidth: props?.minWidth ? props?.minWidth : '0px' }}>
      {props.hideFilter !== false && (
        <DropdownContainer bodyHeight={props.bodyHeight ?? 32} autoClose="outside">
          <DropdownToggle id="dropdown-autoClose-outside">{t('SEARCH_BY')}</DropdownToggle>
          <DropdownMenu>
            <Dropdown.Item onClick={() => handleChangeAllBranch(!allBranch)}>
              <InputCheckbox checked={allBranch} label={t('REGISTER.FORM.ALL_BRANCH')} />
            </Dropdown.Item>
            <Divider style={{ border: 'none', backgroundColor: colors.lightGray, opacity: 1, margin: '.25rem .5rem 0 !important' }} />
            <FormControl>
              <RadioGroup aria-labelledby="share-radio-buttons-group-label" name="condition" value={filter}>
                <Dropdown.Item onClick={() => handleChangeCondition('cnNumber')}>
                  <FormControlLabel key={`radio-con-cn-number`} value="cnNumber" control={<InputRadio classes={{ root: 'custom-radio' }} />} label={t('REGISTER.FORM.P_NUMBER')} />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleChangeCondition('branchCnNumber')}>
                  <FormControlLabel key={`radio-con-branch-cn-number`} value="branchCnNumber" control={<InputRadio classes={{ root: 'custom-radio' }} />} label={t('REGISTER.FORM.PB_NUMBER')} />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleChangeCondition('idCard')}>
                  <FormControlLabel key={`radio-con-id0card`} value="idCard" control={<InputRadio classes={{ root: 'custom-radio' }} />} label={t('REGISTER.FORM.P_CARD')} />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleChangeCondition('phone')}>
                  <FormControlLabel key={`radio-con-phone`} value="phone" control={<InputRadio classes={{ root: 'custom-radio' }} />} label={t('REGISTER.FORM.PHONE_NUMBER')} />
                </Dropdown.Item>
                {snType && (
                  <Dropdown.Item onClick={() => handleChangeCondition('snCode')}>
                    <FormControlLabel key={`radio-con-sn-number`} value="snCode" control={<InputRadio classes={{ root: 'custom-radio' }} />} label={t('REGISTER.FORM.SN_CODE')} />
                  </Dropdown.Item>
                )}
              </RadioGroup>
            </FormControl>
          </DropdownMenu>
        </DropdownContainer>
      )}
      <PatientSearch bodyHeight={props.bodyHeight ?? 32} className="w-100">
        <AutocompleteSelect
          labelId="searchPatient"
          inputLabel={t('SEARCH_PATIENT')}
          noOptionsText={t('PATIENT_NOT_FOUND')}
          options={renderPatientsData(patients, patientSelect)}
          getOptionLabel={(option: any) => {
            return patientSearch !== ''
              ? patientSearch
              : filter === 'branchCnNumber'
                ? renderBranchPatientName(option || patientSelect)
                : `${_.get(option || patientSelect, filter) || _.get(patientSelect, filter) || ''} ${option?.firstname || patientSelect?.firstname} ${option?.lastname || patientSelect?.lastname}`
          }}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {filter === 'branchCnNumber'
                ? renderBranchPatientName(option || patientSelect)
                : `${_.get(option || patientSelect, filter) || _.get(patientSelect, filter) || ''} ${option?.firstname || patientSelect?.firstname} ${option?.lastname || patientSelect?.lastname}`}
            </Box>
          )}
          errMessage={props.helperText ?? ''}
          onchange={(e, value: any, option: any) => {
            if (value) {
              setPatientSelect(value)
              redirectTo(value.patientId, value.fullname)
              setPatientSearch(getFilter(filter, renderBranchPatientName(option || patientSelect), option, patientSelect))
              if (props?.callbackPatient) {
                props.callbackPatient(value)
              }
            }
          }}
          inputProps={{
            onChange: (e: any) => {
              handleInputSearch({ search: e.target.value, branch: allBranch, filterBy: filter, status: status })
            }
          }}
          onOpen={(e: any, value: any) => {
            setKeyValue(true)
            setPatientSelect(value)
            loadPatients({ search: '', branch: allBranch, filterBy: filter, status: status })
          }}
          onClose={(e, reason: any) => {
            setKeyValue(false)
          }}
          value={patientSearch !== '' ? patientSearch : props.patientId ? _.find(renderPatientsData(patients, patientSelect), { patientId: props.patientId }) || props.patient : null}
          disabledBorder
          disabled={props.disabled}
          loading={loading}
          classesOption={`${props.optionBelowSideBar ? 'indexBelowSidebar' : ''}`}
        />
        {/* <AutocompleteSelect
          labelId="searchPatient"
          inputLabel={t('SEARCH_PATIENT')}
          noOptionsText={t('PATIENT_NOT_FOUND')}
          options={renderPatientsData(patients, patientSelect)}
          errMessage={props.helperText ?? ''}
          getOptionLabel={(option: any) => {

            return filter === 'branchCnNumber' ? renderBranchPatientName(option || patientSelect) : `${_.get(option || patientSelect, filter) || _.get(patientSelect, filter) || ''} ${option.firstname || patientSelect.firstname} ${option.lastname || patientSelect.lastname}`
 
          }}
          renderOption={(props, option: any) => (
            <Box component="li" {...props}>
              {getFilter(filter, renderBranchPatientName(option || patientSelect), option, patientSelect)}
            </Box>
          )}

          onchange={(e, value: any, option: any) => {
            if (value) {

              setPatientSelect(value)
              redirectTo(value.patientId, value.fullname)

              // setPatientSearch(getFilter(filter, renderBranchPatientName(option || patientSelect), option, patientSelect));
            }
          }}

          inputProps={{
            onChange: (e: any) => {
              handleInputSearch({ search: e.target.value, branch: allBranch, filterBy: filter, status: status })

            }
          }}

          onOpen={(e: any) => {
            setKeyValue(true)
            loadPatients({ search: '', branch: allBranch, filterBy: filter, status: status })

          }}
          onClose={(e, reason: any) => {

            setKeyValue(false)
          }}
          value={props.patientId ? _.find(renderPatientsData(patients, patientSelect), { patientId: props.patientId }) || props.patient : null}
          // value={props.patientId ? _.find(renderPatientsData(patients, patientSelect), { patientId: props.patientId }) || props.patient : null}

          //  value={patientSearch?patientSearch:null}
          disabledBorder
          disabled={props.disabled}
          loading={loading}
          classesOption={`${props.optionBelowSideBar ? 'indexBelowSidebar' : ''}`}
        /> */}

        {!props.patientId ? (
          <button className={'icon-action'} style={{ display: keyValue ? 'none' : 'flex', pointerEvents: 'none' }}>
            <FontAwesomeIcon icon={faSearch} style={{ fontSize: 15 }} />
          </button>
        ) : (
          <button
            className={'icon-action disabled'}
            onClick={() => {
              props?.onClear && props.onClear()
              setPatientSelect({})
              setPatientSearch('')
            }}
            style={{ display: keyValue || props.disabled ? 'none' : 'flex' }}
          >
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 16 }} />
          </button>
        )}
      </PatientSearch>
    </SearchContainer>
  )
}
