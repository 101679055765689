import { createSlice } from '@reduxjs/toolkit'

export const appointmentSlice = createSlice({
  name: 'formAppointment',
  initialState: {
    patient: {},
    formAppointment: {},
    reloadCareers: { reload: false, careeId: 0 }
  },
  reducers: {
    setFormAppointment: (state, action) => {
      state.formAppointment = action.payload
    },
  }
})

export const formAppointment = (state: any) => state.appointment.formAppointment

export const { setFormAppointment } = appointmentSlice.actions

export default appointmentSlice.reducer
