import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles from 'features/setting/default-products/product-types/style'

/** API */
import ProductTypesApi from 'api/setting/default-products/product-type.api'

/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextField from 'component/Input/InputTextField'
import InputTextSearch from 'component/Input/InputTextSearch'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import FilterSelect from 'component/Select/FilterSelect'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView()
}

const initStateErrorMessage = {
    MATERIAL_TYPE_NAME: ``,
    IS_DUPLICATE_PRODUCT_TYPE_NAME: ``,
    MATERIAL_TYPE_NAME_STRING_BASE: ``,
    MATERIAL_TYPE_NAME_STRING_EMPTY: ``,
    MATERIAL_TYPE_NAME_MAXIMUM_LENGTH: ``,
    MATERIAL_TYPE_NAME_ANY_REQUIRED: ``,

    MATERIAL_TYPE_NAME_EN: ``,
    IS_DUPLICATE_PRODUCT_TYPE_NAME_EN: ``,
    MATERIAL_TYPE_NAME_EN_STRING_BASE: ``,
    MATERIAL_TYPE_NAME_EN_STRING_EMPTY: ``,
    MATERIAL_TYPE_NAME_EN_MAXIMUM_LENGTH: ``,
    MATERIAL_TYPE_NAME_EN_ANY_REQUIRED: ``
}
export default function MaterialTypes() {
    const classes = useStyles()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [productTypes, setProductTypes] = useState([])

    const [productTypeId, setProductTypeId] = useState(Number)
    const [productTypeName, setProductTypeName] = useState('')
    const [productTypeNameEn, setProductTypeNameEn] = useState('')
    const [productTypeStatus, setProductTypeStatus] = useState('')

    const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [sortBy, setSortBy] = useState('createAt')
    const [sortType, setSortType] = useState('ASC')
    const [search, setSearch] = useState('')
    const [status, setStatus] = useState('ALL')

    const loadData = useCallback(async () => {
        let condition: any = { group: 'MATERIAL' }
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (search) condition = { ...condition, search: search }
        if (sortBy) condition = { ...condition, sortBy: sortBy }
        if (sortType) condition = { ...condition, sortType: sortType }
        if (status) condition = { ...condition, status: status === 'ALL' ? '' : status }

        const res = await ProductTypesApi.findAll(condition)
        if (res.status === 200) {
            setRowCount(res.headers['x-total'])
            setProductTypes(res.data)
        }
    }, [page, pageLimit, search, sortBy, sortType, status])

    useEffect(() => {
        loadData()
    }, [loadData])

    const submit = () => {
        if (!productTypeName) return setErrorMessage({ ...errorMessage, ...{ MATERIAL_TYPE_NAME: t('MATERIAL_TYPE.MESSAGE.MATERIAL_TYPE_NAME') } })

        dispatch(submitModal())

        ProductTypesApi.create({ productTypeName, productTypeNameEn, group: 'MATERIAL' })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 201) {
                    notiSuccess(t('MATERIAL_TYPE.MESSAGE.SUCCESS.CREATE'), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_TYPE.MESSAGE.${err.message}`) } })
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_TYPE.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const submitEdit = () => {
        if (!productTypeName) return setErrorMessage({ ...errorMessage, ...{ MATERIAL_TYPE_NAME: t(`MATERIAL_TYPE.MESSAGE.MATERIAL_TYPE_NAME`) } })

        dispatch(submitModal())
        ProductTypesApi.update(productTypeId, { productTypeName, productTypeNameEn, status: productTypeStatus, group: 'MATERIAL' })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 200) {
                    notiSuccess(t(`MATERIAL_TYPE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
                    resetForm()
                    loadData()
                    dispatch(resetModal())
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_TYPE.MESSAGE.${err.message}`) } })
                    dispatch(unSubmitModal())
                }
            })
            .catch((e) => {
                const err = e.response.data
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`MATERIAL_TYPE.MESSAGE.${err.message}`) } })
                dispatch(unSubmitModal())
            })
    }

    const clearErrorMessage = () => {
        setErrorMessage(initStateErrorMessage)
    }

    const resetForm = () => {
        setProductTypeId(0)
        setProductTypeName('')
        setProductTypeNameEn('')
        setProductTypeStatus('')
        clearErrorMessage()
    }

    const onActive = (id: number, valueStatus: string) => {
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MATERIAL_TYPE.ALERT.CONFIRM_STATUS')}</p>`,
            null, (res: any) => {
                if (res) {
                    ProductTypesApi.updateStatus(id, valueStatus)
                        .then((resp) => {
                            notiSuccess(t('MATERIAL_TYPE.MESSAGE.SUCCESS.UPDATE_STATUS'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('MATERIAL_TYPE.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
    }
    const onDelete = (data: any) => {
        const { productTypeId: pdTypeId, productTypeName: pdTypeName, productTypeNameEn: pdTypeNameEn } = data

        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MATERIAL_TYPE.ALERT.CONFIRM_DELETE')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t('MATERIAL_TYPE.ALERT.CONFIRM_DELETE_', {
                productTypeName: pdTypeName,
                productTypeNameEn: pdTypeNameEn ? ' (' + pdTypeNameEn + ')' : ''
            })}</p>`,
            (res: any) => {
                if (res) {
                    ProductTypesApi.remove(pdTypeId)
                        .then((resp) => {
                            notiSuccess(t('MATERIAL_TYPE.MESSAGE.SUCCESS.DELETE'))
                            loadData()
                        })
                        .catch((e) => {
                            notiError(t('MATERIAL_TYPE.MESSAGE.ERROR'))
                        })
                }
            }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
    }

    const onEdit = (data: any) => {
        const { productTypeId: pdTypeId, productTypeName: pdTypeName, productTypeNameEn: pdTypeNameEn, status: pdTypeStatus } = data
        setProductTypeId(pdTypeId)
        setProductTypeName(pdTypeName)
        setProductTypeNameEn(pdTypeNameEn)
        setProductTypeStatus(pdTypeStatus)
        dispatch(showModal())
    }

    const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
        setSortType(sortTypeVal)
        setSortBy(sortByVal)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
    }
    const handleChangeStatus = (statusVal: string) => {
        setStatus(statusVal)
    }
    const headCells = [
        { id: 'no', disablePadding: false, label: '#' },
        { id: 'productTypeName', disablePadding: false, label: t('MATERIAL_TYPE.TABLE.CELL.MATERIAL_TYPE_NAME') },
        { id: 'productTypeNameEn', disablePadding: false, label: t('MATERIAL_TYPE.TABLE.CELL.MATERIAL_TYPE_NAME_EN') },
        { id: 'status', disablePadding: false, label: t('MATERIAL_TYPE.TABLE.CELL.STATUS') },
        { id: 'updatedBy', disablePadding: false, label: t('MATERIAL_TYPE.TABLE.CELL.UPDATED_BY') },
        { id: 'action', disablePadding: false, label: 'Action' }
    ]

    const renderData = (objData: any, no: number) => {
        no = page * pageLimit - pageLimit + no + 1
        const { productTypeId: pdTypeId, productTypeName: pdTypeName, productTypeNameEn: pdTypeNameEn, updatedBy, updatedAt, status: pdTypeStatus } = objData
        const statusBtnActive = pdTypeStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
        const statusBtnInActive = pdTypeStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled
        const objRenderData = {
            key: pdTypeId,
            id: pdTypeId,
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no },
                { option: 'TEXT', align: 'left', label: pdTypeName },
                { option: 'TEXT', align: 'left', label: pdTypeNameEn },
                { option: 'STATUS', align: 'center', label: pdTypeStatus },
                { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
                        { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
                        { option: 'DIVIDER', label: '', disabled: false },
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
                        { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
                    ]
                }
            ]
        }
        return (
            <TableRowCommon
                {...objRenderData}
                onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
                oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
                onedit={() => onEdit(objRenderData.obj)}
                ondelete={() => onDelete(objRenderData.obj)}
            />
        )
    }

    return (
        <div className={classes.root}>
            <Card className="border-0 h-100">
                <HeaderCard text={t('MATERIAL_TYPE.TITLE')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <Row className="align-items-center">
                            <Col md={8}>
                                <Row className="flax-row">
                                    <Col sm={12} md={6} xl={4} className="pt-2">
                                        <InputTextSearch
                                            keyInput={'search'}
                                            label={t('INPUT.SEARCH')}
                                            value={search}
                                            onchange={(event) => {
                                                setSearch(event.target.value)
                                                setPage(1)
                                            }}
                                            onClear={(event) => {
                                                setSearch('')
                                                setPage(1)
                                            }}
                                        />
                                    </Col>
                                    <Col sm={12} md={6} xl={4} className="pl-md-0 pt-2">
                                        <FilterSelect
                                            onchange={(event) => {
                                                const value = event.target.value
                                                if (value) {
                                                    handleChangeStatus(value)
                                                    setPage(1)
                                                }
                                            }}
                                            renderValue={() => `${t('STATUS.LABEL')}: ${t(`STATUS.${status}`)}`}
                                            label={t('STATUS.LABEL')}
                                            selectId="select-status"
                                            value={status}
                                            labelId="label-status"
                                            options={[
                                                <MenuItem key="1" value="ALL">
                                                    {t('STATUS.ALL')}
                                                </MenuItem>,
                                                <MenuItem key="2" value="ACTIVE">
                                                    {t('STATUS.ACTIVE')}
                                                </MenuItem>,
                                                <MenuItem key="3" value="INACTIVE">
                                                    {t('STATUS.INACTIVE')}
                                                </MenuItem>
                                            ]}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} className="mt-2 mt-md-auto">
                                <div className="pr-xl-2">
                                    <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => dispatch(showModal())} textButton={t('MATERIAL_TYPE.BUTTON.ADD')} className="w-auto ml-auto d-flex mt-auto" />
                                </div>
                            </Col>
                        </Row>
                    </Card.Title>

                    <div className={'mt-3 pl-xl-3 pr-xl-2'}>
                        <TableCustom
                            page={page}
                            pageLimit={pageLimit}
                            sortType={sortType}
                            sortBy={sortBy}
                            onSort={onRequestSort}
                            setPageLimit={handleChangeRowsPerPage}
                            setPage={handleChangePage}
                            rowCount={rowCount}
                            headCells={headCells}
                            rowsData={productTypes.map((val, i) => {
                                return renderData(val, i)
                            })}
                        />
                    </div>
                </Card.Body>
            </Card>

            <ModalCustom
                title={productTypeId ? t('MATERIAL_TYPE.TITLE_UPDATE') : t('MATERIAL_TYPE.TITLE_CREATE')}
                component={
                    <div className="pb-2">
                        {productTypeId ? (
                            <div>
                                <Form className="mx-0 mt-0 mb-3 p-0  d-inline-flex w-100">
                                    <Form.Label className=" mr-auto">{t('INPUT.LABEL_STATUS')}</Form.Label>
                                    <Form.Check type="switch" id="custom-switch" onChange={() => setProductTypeStatus(productTypeStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')} checked={productTypeStatus === 'ACTIVE' ? true : false} style={{ marginLeft: 'auto' }} />
                                </Form>
                            </div>
                        ) : (
                            ''
                        )}

                        <InputTextField
                            onchange={(event) => {
                                setProductTypeName(event.target.value)
                                clearErrorMessage()
                            }}
                            value={productTypeName}
                            label={t('MATERIAL_TYPE.INPUT.MATERIAL_TYPE_NAME')}
                            helperText={
                                errorMessage.MATERIAL_TYPE_NAME ||
                                errorMessage.IS_DUPLICATE_PRODUCT_TYPE_NAME ||
                                errorMessage.MATERIAL_TYPE_NAME_STRING_BASE ||
                                errorMessage.MATERIAL_TYPE_NAME_STRING_EMPTY ||
                                errorMessage.MATERIAL_TYPE_NAME_MAXIMUM_LENGTH ||
                                errorMessage.MATERIAL_TYPE_NAME_ANY_REQUIRED
                            }
                            required={true}
                            style={{ marginBottom: '1rem' }}
                        />

                        <InputTextField
                            key="productTypeNameEn"
                            onchange={(event) => {
                                setProductTypeNameEn(event.target.value)
                                clearErrorMessage()
                            }}
                            value={productTypeNameEn}
                            helperText={
                                errorMessage.MATERIAL_TYPE_NAME_EN ||
                                errorMessage.IS_DUPLICATE_PRODUCT_TYPE_NAME_EN ||
                                errorMessage.MATERIAL_TYPE_NAME_EN_STRING_BASE ||
                                errorMessage.MATERIAL_TYPE_NAME_EN_STRING_EMPTY ||
                                errorMessage.MATERIAL_TYPE_NAME_EN_MAXIMUM_LENGTH ||
                                errorMessage.MATERIAL_TYPE_NAME_EN_ANY_REQUIRED
                            }
                            label={t('MATERIAL_TYPE.INPUT.MATERIAL_TYPE_NAME_EN')}
                            required={false}
                        />
                    </div>
                }
                onReset={resetForm}
                onSubmit={productTypeId ? submitEdit : submit}
                textBtnCancel={t('MATERIAL_TYPE.BUTTON.CANCEL')}
                textBtnConfirm={t('MATERIAL_TYPE.BUTTON.SAVE')}
            />
        </div>
    )
}
