import { fileUrl } from 'api/api'
import _ from 'lodash'


/** CONSTANTS */

/** STYLE */
import Xray from 'features/xray/xray'



type XRayType = {
  isClose?: boolean
  patient?: any
  type?: 'Treatment' | 'HistoryTreatment' | 'RetrospectiveEdit'
  onSaveTreatment?: () => void
}

export default function XRay(props: XRayType) {

  return <Xray
    isClose={props.isClose}
    opd={true}
    patientId={props.patient.patientId}
    patient={props.patient}
    vnNumber={props.patient?.patientCase?.vnNumber}
    onSaveTreatment={props?.onSaveTreatment && props.onSaveTreatment}
  />
}
