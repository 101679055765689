import { Box } from '@mui/material'
import ManageWebApi from 'api/application/manageWeb'
import BtnAdd from 'assets/button/BtnAdd'
import ButtonCustom from 'component/Button/ButtonCustom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReviewForm from './ReviewForm'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import ReviewList from './ReviewList'
import Loading from 'component/Loading'
import { ReviewProps } from 'api/application/manageWeb-interface'
import { swalActive, swalDelete } from 'component/Alert/Swal'

// type Props = {}

const Review = () => {
    const { t } = useTranslation()
    const t_REVIEW = "MANAGE_WEB.REVIEW"

    const [load, setLoad] = useState<boolean>(true)
    const [statusDel, setStatusDel] = useState<boolean>(false)
    const [statusAdd, setStatusAdd] = useState<boolean>(false)

    const [dataReview, setDataReview] = useState<any>([])
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [dataEdit, setDataEdit] = useState<any>({})
    const [dataEditId, setDataEditId] = useState<any>({})

    const loadData = async () => {
        setLoad(true)
        await ManageWebApi.getReview().then((res) => {
            const newData = res.data.map((item: any, index: number) => {
                return { ...item, sorting: index + 1 }
            })
            setDataReview(newData)
        }).catch(() => {
            return
        }).finally(() => {
            setLoad(false)
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    const onEdit = (status: boolean, item?: any) => {
        if (item) {
            setDataEdit(item)
            setDataEditId(item)
        }
        if (!status) {
            setDataEdit({})
            setDataEditId({})
        }
        setIsEdit(status)
    }

    const onSave = async () => {
        setStatusAdd(true)
        const id = dataEditId?.reviewId || 0
        const newPayload: ReviewProps = dataEdit
        if (id === 0) {
            const res = await ManageWebApi.createReview(newPayload)
            if (res === "pass") {
                onEdit(false)
                loadData()
                notiSuccess(t(`${t_REVIEW}.SAVE_SUC`))
            } else {
                notiError(t(`${t_REVIEW}.SAVE_ERROR`))
            }
        } else {
            const res = await ManageWebApi.updateReview(id, newPayload)
            if (res === "pass") {
                onEdit(false)
                loadData()
                notiSuccess(t(`${t_REVIEW}.SAVE_SUC`))
            } else {
                notiError(t(`${t_REVIEW}.SAVE_ERROR`))
            }
        }
        setStatusAdd(false)
    }

    const onDel = async (id: number, name: string) => {
        setStatusDel(true)
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 415px">${t(t_REVIEW + '.NOTI_DEL_START')}${t(t_REVIEW + '.TITLE')}${t(t_REVIEW + '.NOTI_DEL_END')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 325px">${t(t_REVIEW + '.NOTI_DEL_START')}${name}${t(t_REVIEW + '.NOTI_DEL_END')}</p>`,
            async (res: any) => {
                if (res) {
                    if (id !== 0) {
                        const res = await ManageWebApi.delReview(id)
                        if (res === "pass") {
                            notiSuccess(t(`${t_REVIEW}.DEL_SUC`))
                        } else {
                            notiError(t(`${t_REVIEW}.SAVE_ERROR`))
                        }
                    } else {
                        notiError(t(`${t_REVIEW}.SAVE_ERROR`))
                    }
                    loadData()
                }
            }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )
        setStatusDel(false)



    }

    return (
        <>
            <Loading show={load} />
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ fontSize: '20px', fontWeight: "600" }}>
                        {t(`${t_REVIEW}.TITLE`)}
                    </Box>
                    {!isEdit &&
                        <Box>
                            <ButtonCustom
                                startIcon={<BtnAdd width='20px' height='20px' />}
                                textButton={t(`${t_REVIEW}.BTN_ADD`)}
                                onClick={() => { onEdit(true) }}
                            />
                        </Box>
                        ||
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ButtonCustom
                                style={{ marginRight: '4px' }}
                                variant={'outlined'}
                                textButton={t(`${t_REVIEW}.BTN_CANCEL`)}
                                onClick={() => { onEdit(false) }}
                            />
                            <ButtonCustom
                                textButton={t(`${t_REVIEW}.BTN_SAVE`)}
                                disabled={statusAdd}
                                onClick={() => { onSave() }}
                            />
                        </Box>
                    }
                </Box>
                <Box sx={{ marginTop: '32px', height: '100%', overflow: 'auto' }}>
                    {!isEdit &&
                        <ReviewList dataList={dataReview} onEdit={onEdit} onDel={onDel} statusDel={statusDel} reLoad={loadData} />
                        ||
                        <ReviewForm dataEdit={dataEdit} setDataEdit={setDataEdit} />
                    }
                </Box>
            </Box >
        </>
    )
}

export default Review