import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export type findAppointmentProps = {
    phone: string
    branchId: number
}

export type checkInAppointmentProps = {
    appointmentId: number,
    isCheckin: "1" | "0",
}

const path = 'check-in'

export default class CheckInApi extends BaseAPI {
    static findAppointment(props: findAppointmentProps): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } })
    }
    static checkInAppointment(props: checkInAppointmentProps): Promise<any> {
        return this.api.patch(`${path}/check-in`, props)
    }
}

