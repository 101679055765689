import { Box } from '@mui/material'

/** STYLE */
import { styled } from '@mui/material/styles'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** API */
import { APPROVE_STATUS } from 'api/warehouses/adjustment.api'

export const ResponsiveTable = styled(Box)<{ type?: 'form' }>(({ theme, type }) => ({
    '.MuiTable-root': {
        '.MuiTableBody-root': {
            '.MuiTableCell-root': {
                '&:first-of-type': {
                    paddingLeft: 4,
                    paddingRight: 4
                },
                '&:not(.update-by)': {
                    paddingTop: type === 'form' ? 6 : 10,
                    paddingBottom: type === 'form' ? 6 : 10
                }
            },
            '.row-add-new-row': {
                '.btn-add-new-row': {
                    padding: '0 0 0 14px',
                    fontSize: 16,
                    color: colors.themeSecondColor,
                    backgroundColor: 'transparent !important',
                    fontWeight: 400,
                    span: {
                        fontSize: 20,
                        color: colors.themeSecondColor
                    },
                    '&:hover': {
                        fontWeight: 600
                    },
                    '&.Mui-disabled, &.Mui-disabled span': {
                        color: colors.disabledGray,
                    }
                },
                '.MuiTableCell-root:hover': {
                    cursor: 'pointer',
                    '.btn-add-new-row': {
                        fontWeight: 600
                    }
                }
            }
        }
    }
}))

export const ResponsiveTableForm = styled(ResponsiveTable)(({ theme }) => ({
    overflow: 'auto',
    '.MuiPaper-root': {
        overflow: 'auto',
        minHeight: '100% !important',
        height: '100%'
    },
    '.MuiPaper-root table thead': {
        backgroundColor: `${colors.themeMainColor} !important`,
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    '.MuiPaper-root table thead tr th': {
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },
    '.MuiTableContainer-root': {
        borderTopLeftRadius: `4px !important`,
        borderTopRightRadius: `4px !important`,
        '.MuiTable-root': {
            '.MuiTableHead-root': {
                // borderColor: `${colors.lightGray} !important`,
                '.MuiTableCell-root': {
                    // color: `${colors.themeMainColor} !important`,
                    // backgroundColor: colors.transparent,
                    borderWidth: '1px 0 1px 0 !important',
                    borderColor: `${colors.lightGray} !important`,
                }
            }
        }
    }
}))

export const ApproveStatus = styled(Box)<{ status: string }>(({ theme, status }) => ({
    borderRadius: 8,
    padding: '1px 8px',
    backgroundColor: status === APPROVE_STATUS.APPROVE ? colors.approveStatus.approve : status === APPROVE_STATUS.PENDING ? colors.approveStatus.pending : colors.approveStatus.cancel,
    color: colors.white,
    width: 'max-content',
    textDecoration: 'none'
}))

export const ActionButton = styled('img')<{ isPending: boolean }>(({ theme, isPending }) => ({
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    pointerEvents: isPending ? 'auto' : 'none',
    opacity: isPending ? 1 : .5,
    '&.btn-edit': {
        opacity: isPending ? 1 : .4,
    }
}))