import { Dropdown } from 'react-bootstrap'
import { TableCell, TableRow } from '@material-ui/core'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { dateTimeToView } from 'utils/date.utils'
import InputCheckbox from 'component/Input/InputCheckbox'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

/** CONSTANT */
import { colors } from 'constants/theme'
import { numberFormat } from 'utils/app.utils'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** CONSTANT */
import { Icons, icons } from 'constants/images'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip, Fade } from '@mui/material';
import { useEffect } from 'react'
import { Draggable } from 'react-beautiful-dnd'
const DropdownMenu = styled(Dropdown.Menu)(({ theme }) => ({}))

const TableRowCustom = styled(TableRow)<{ rowLink?: boolean }>(({ theme, rowLink }) => ({
  '& td': { verticalAlign: 'middle !important' },
  '&.row-link:hover  td:not(.no-link)': {
    color: colors.themeSecondColor, textDecoration: 'underline', cursor: 'pointer'
  }
}))

export const DropdownItem = styled(Dropdown.Item)(({ theme }) => ({
  color: colors.textPrimary,
  img: {
    width: '16px !important',
    height: '16px !important',
    padding: '0 !important'
  },
  '&  span': {
    fontSize: '16px !important',
  },
  '&:hover': {
    backgroundColor: colors.themeSecondColor06,
    color: colors.themeSecondColor
  }
}))

type TableRowCommonProps = {
  key: string | number
  id: string | number
  obj: any
  columns: any
  rowSelect?: boolean
  rowLink?: boolean
  onactive?: (val: any) => any
  oninactive?: (val: any) => any
  onedit?: (val: any) => void
  onView?: (val: any) => void
  ondelete?: (val: any) => void
  oncopy?: (val: any) => void
  onCheck?: (val: any) => void
  onClick?: (e: any, val: any) => void
  classes?: string
  providerDraggable?: any
}

export default function TableRowCommon(props: TableRowCommonProps) {
  const { t } = useTranslation()
  const providerDraggable = props?.providerDraggable ?
    {
      ...props?.providerDraggable?.draggableProps,
      ref: props?.providerDraggable?.innerRef
    } :
    {}

  return (
    <TableRowCustom key={props.key} className={`${props.rowSelect ? 'MuiTableRow-root-active' : ''} ${props.rowLink ? 'row-link' : ''} ${props.classes}`} style={{ height: 'auto' }}  {...providerDraggable}>
      {props.columns.map((column: any) => {
        const drag = column?.isDrag ? { ...props?.providerDraggable?.dragHandleProps } : {}
        return (
          (column.option === 'TEXT' && (
            <TableCell className={`${column.class} text`} align={column.align || 'left'} scope="row" onClick={(e) => props.onClick && props.onClick(e, props.obj)} style={{ ...column.style }} {...drag}>
              {column.label}
            </TableCell>
          )) ||
          (column.option === 'PRICE' && (
            <TableCell className={`${column.class} text`} align={column.align || 'left'} scope="row" onClick={(e) => props.onClick && props.onClick(e, props.obj)} style={{ ...column.style }}>
              {numberFormat(column.label)}
            </TableCell>
          )) ||
          (column.option === 'STATUS' && (
            <TableCell className={`${column.class} status`} align={column.align || 'left'} onClick={(e) => props.onClick && props.onClick(e, props.obj)} style={{ ...column.style }}>
              <FontAwesomeIcon icon={faCircle} color={column.label === 'ACTIVE' ? colors.statusActive : colors.statusInactive} />
              <span className="ml-2">{t(`STATUS.${column.label}`)}</span>
            </TableCell>
          )) ||
          column.option === 'UPDATE_BY' && (
            column.label.updatedBy.length > 10 ? (
              <Tooltip
                title={column.label.updatedBy}
                TransitionComponent={Fade}
                placement="top"
              >
                <TableCell
                  align={column.align || 'left'}
                  className={`update-by no-link ${column.class}`}
                  onClick={(e) => props.onClick && props.onClick(e, props.obj)}
                  style={{ ...column.style }}
                >
                  <Box className={`position-relative text-center text-ellipsis ${column.align === 'center' ? 'mx-auto' : ''}`} sx={{ bottom: '8px' }}>
                    {column.label.updatedBy}
                    <br />
                    <span style={{ color: colors.textLightGray }}>{dateTimeToView(column.label.updatedAt, false)}</span>
                  </Box>
                </TableCell>
              </Tooltip>
            ) : (
              <TableCell
                align={column.align || 'left'}
                className={`update-by no-link ${column.class}`}
                onClick={(e) => props.onClick && props.onClick(e, props.obj)}
                style={{ ...column.style }}
              >
                <Box className={`position-relative text-center text-ellipsis ${column.align === 'center' ? 'mx-auto' : ''}`} sx={{ bottom: '8px' }}>
                  {column.label.updatedBy}
                  <br />
                  <span style={{ color: colors.textLightGray }}>{dateTimeToView(column.label.updatedAt, false)}</span>
                </Box>
              </TableCell>
            )
          ) ||
          (column.option === 'ACTION' && (
            <TableCell align={column.align || 'left'} className="action" style={{ ...column.style, width: column?.width || 160 }}>
              <Dropdown>
                <Dropdown.Toggle variant="link" bsPrefix="p-0  " className="btn-table-action text-black-50 shadow-none">
                  <Icons.Meatball />
                </Dropdown.Toggle>
                <DropdownMenu className="dropdown-table-action position-fixed">
                  {column.values.map((objVal: any, i: number) => {
                    return (
                      (objVal.option === 'STATUS_ACTIVE' && (
                        <DropdownItem onClick={props.onactive} disabled={objVal.disabled}>
                          {objVal.label}
                        </DropdownItem>
                      )) ||
                      (objVal.option === 'STATUS_INACTIVE' && (
                        <DropdownItem onClick={props.oninactive} disabled={objVal.disabled}>
                          {objVal.label}
                        </DropdownItem>
                      )) ||
                      (objVal.option === 'DIVIDER' && <Dropdown.Divider style={{ backgroundColor: 'transparent', borderColor: colors.lightGray, opacity: 1 }} />) ||
                      (objVal.option === 'COMPONANT' && objVal.label) ||
                      (objVal.option === 'EDIT' && (
                        <DropdownItem onClick={props.onedit} disabled={objVal.disabled}>
                          <img src={icons.dropdownEdit} alt="" className="align-baseline" />
                          <span className="ml-2 align-text-bottom">{objVal.label}</span>
                        </DropdownItem>
                      )) ||
                      (objVal.option === 'VIEW' && (
                        <DropdownItem onClick={props.onView} disabled={objVal.disabled}>
                          <VisibilityIcon className="align-text-bottom" sx={{ color: `${colors.textPrimary} !important`, fontSize: '18px' }} />
                          <span className="ml-2 align-text-bottom">{objVal.label}</span>
                        </DropdownItem>
                      )) ||
                      (objVal.option === 'DELETE' && (
                        <DropdownItem onClick={props.ondelete} disabled={objVal.disabled}>
                          <img src={icons.dropdownTrash} alt="" className="align-baseline" />
                          <span className="ml-2 align-text-bottom">{objVal.label}</span>
                        </DropdownItem>
                      )) ||
                      (objVal.option === 'COPY' && (
                        <DropdownItem onClick={props.oncopy} disabled={objVal.disabled}>
                          <img src={icons.iconCopy} alt="" className="align-baseline" />
                          <span className="ml-2 align-text-bottom">{objVal.label}</span>
                        </DropdownItem>
                      ))
                    )
                  })}
                </DropdownMenu>
              </Dropdown>
            </TableCell>
          )) ||
          (column.option === 'COMPONENT' && (
            <TableCell className={`${column.class}`} align={column.align || 'left'} style={{ ...column.style, width: column?.width || 160, textDecoration: 'none' }}>
              {column.component}
            </TableCell>
          )) ||
          (column.option === 'CHECKBOX' && (
            <TableCell className={`${column.class} checkbox`} align={column.align || 'left'} style={{ ...column.style }}>
              <InputCheckbox onChange={props.onCheck} value={column.label} checked={props.rowSelect} disabled={column.disabled} className="hover-none-bg" />
            </TableCell>
          )) || (column.option === 'MERGE' && (
            <TableCell align={column.align || 'left'} colSpan={column.colSpan} onClick={(e) => props.onClick && props.onClick(e, props.obj)} style={{ ...column.style }}></TableCell>
          ))
        )
      })}
    </TableRowCustom>
  )
}
