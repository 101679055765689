import { useState, useEffect, useCallback, useRef } from 'react'
import { Grid, Box, Typography, Button, Divider, MenuItem, Chip, FormControl, RadioGroup, FormControlLabel, Tooltip } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** API */
import ClinicApi from 'api/master/clinic.api'
import AppointmentDurationApi from 'api/setting/defaults/appointment-duration.api'
import AppointmentApi from 'api/counter/appointment.api'
import ComponentApi, { TreatmentItemsInterface } from 'api/components-api'
import BranchApi from 'api/setting/branch-service-points/branch.api'

/** UTILS */
import { DentistsInterface } from 'features/counter/appointment/appointment-interface'
import * as modalSlice from 'app/slice/modal.slice'
import useWindowSize from 'utils/useWindowSize'
import { treatment, TreatmentStateInterface, setSummeryTreatments, NextVisitInterface, TreatmentNextVisitInterface } from 'app/slice/treatment.slice'
import { getBranch, getClinicInfo, numberOnly } from 'utils/app.utils'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import InputTimePicker from 'component/Input/InputTimePicker'
import InputTextarea from 'component/Input/InputTextarea'
import SectionFilterDoctor from 'component/Home/filterDoctor'
import SectionAppointment from 'component/Appointment/GraphAppointment'
import CardAppointment from 'component/Appointment/CardAppointment'
import Loading from 'component/Loading'
import CardBreak from 'component/Appointment/CardBreak'
import { swalDelete } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'

/** STYLE */
import { styled } from '@mui/material/styles'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputRadio from 'component/Input/InputRadio'
import { swalWarning } from 'component/Alert/Swal'
import { useLocation } from 'react-router-dom'

const FragmentTabs = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${colors.white}`
}))

const ButtonTab = styled(Button)(({ theme }) => ({
  width: '50%',
  height: 40,
  color: colors.textPrimary,
  padding: 0,
  borderBottom: `1px solid transparent`,
  borderRadius: 0,
  '&.active': {
    color: colors.themeSecondColor,
    borderColor: colors.themeSecondColor
  }
}))

const FragmentBox = styled(Box)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '50%'
  }
}))

const FragmentDetail = styled(FragmentBox)(({ theme }) => ({}))

const FragmentSchedule = styled(FragmentBox)(({ theme }) => ({}))

const DoctorInfo = styled(Box)(({ theme }) => ({
  marginLeft: '3.05rem',
  '.more-dentists': {
    display: 'none'
  },
  [theme.breakpoints.up('sm')]: {
    pointerEvents: 'none',
    '.MuiButton-endIcon': {
      display: 'none'
    }
  }
}))

const CustomSectionSchedule = styled('div')(({ theme }) => ({
  '& .container-schedule': {
    marginRight: 0
  }
}))

const FooterButton = styled(Box)(({ theme }) => ({
  button: {
    height: '44px',
    fontSize: '14px',
    '&.btn-close-visit': {
      marginRight: '0.5rem'
    },
    '&.btn-cancel-visit': {
      marginLeft: '0.5rem',
      marginRight: '0.5rem'
    },
    '&.btn-save-visit': {
      marginLeft: '0.5rem'
    }
  },
  '.form-button-wrap': {
    '> div': {
      display: 'flex',
      margin: 0
    }
  },
  [theme.breakpoints.down('sm')]: {
    '.form-button-wrap': {
      width: '100%',
      '> div': {
        width: '100%'
      },
      button: {
        width: '100%',
        fontSize: '13px'
      }
    }
  },
  '&.has-cancel-visit': {
    button: {
      [theme.breakpoints.down('sm')]: {
        '&.btn-cancel-visit': {
          marginRight: 0
        },
        '&.btn-save-visit': {
          marginLeft: 0
        }
      }
    }
  }
}))

interface IPopupNextVisit {
  itemId?: number
  userId?: number
  patient?: IPatient
  onSubmit?: () => void
  onReset?: () => void
}

interface IPatient {
  patientId?: number
  patientName?: string
}

interface BreakTime {
  breakTimeEnd: string
  breakTimeStart: string

}
export default function PopupNextVisit(props: IPopupNextVisit) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  let countAp = 0
  let checkTimeStart = ''
  const modal: boolean = useSelector(modalSlice.handleShow)
  const treatments: TreatmentStateInterface = useSelector(treatment)

  const [width] = useWindowSize()
  const tabs: string[] = ['TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TAB.APPOINTMENT_DETAIL', 'TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.TAB.DOCTOR_SCHEDULE']
  const [activeTab, setActiveTab] = useState(0)
  const [patient, setPatient] = useState<IPatient>()
  const [timeRange, setTimeRange] = useState<any>([])
  const [officeTimeByDay, setOfficeTimeByDay] = useState<{ officeStatus?: 'OPEN' | 'CLOSE'; timeIn?: string; timeOut?: string }>({})

  const [appointLoading, setAppointLoading] = useState(false)
  const [scheduleLoading, setScheduleLoading] = useState(false)

  const [branches, setBranches] = useState<any>([])
  const [officeTime, setOfficeTime] = useState<any>([])
  const [doctors, setDoctors] = useState<DentistsInterface[]>([])
  const [durationMinutes, setDurationMinutes] = useState([])
  const [operativeTypes, setOperativeTypes] = useState<TreatmentItemsInterface[]>([])

  const [previewDoctors, setPreviewDoctors] = useState([])
  const [previewDoctor, setPreviewDoctor] = useState({})
  const [previewDate, setPreviewDate] = useState<string>('')

  const branchId = Number(getBranch())
  const [selectBranchId, setSelectBranchId] = useState<number>(getBranch())
  const [doctorId, setDoctorId] = useState<number | null>(0)
  const [date, setDate] = useState<string>('')
  const [timeStart, setTimeStart] = useState<string>('')
  const [durationMinute, setDurationMinute] = useState<number>()
  const [operativeType, setOperativeType] = useState<TreatmentNextVisitInterface[]>([])
  const [price, setPrice] = useState<number>(0)
  const [practice, setPractice] = useState<string>('')
  const [remark, setRemark] = useState<string>('')

  const [lab, setLab] = useState<boolean>(false)
  const [xray, setXray] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [patientCodeAppointment, setPatientCodeAppointment] = useState<number>(0)

  const [treatmentItemsPage, setTreatmentItemsPage] = useState<number>(1)
  const [treatmentItemsSearch, setTreatmentItemsSearch] = useState<number>(1)
  const [countTreatmentItems, setCountTreatmentItems] = useState<number>(0)
  const [countCurrentTI, setCountCurrentTI] = useState<number[]>([0])
  const [contentMaxTI, setContentMaxTI] = useState<number>(0)
  const treatmentItemsRef = useRef<any>(null)
  const [newTreatmentItems, setNewTreatmentItems] = useState<any>([])

  const templateErrorMessage = {
    EMPTY_DATE: '',
    EMPTY_DOCTOR: '',
    EMPTY_TIME_START: '',
    EMPTY_DURATION: '',
    EMPTY_TREATMENT: '',
    PRACTICE_REQUIRED: '',
    PRICE_REQUIRED: '',
    REMARK_REQUIRED: ''
  }
  const [errorMessage, setErrorMessage] = useState(templateErrorMessage)

  const [formAppointment, setFormAppointment] = useState<any>([])
  const branchInfo = getClinicInfo()

  const [statusTimeMin, setStatusTimeMin] = useState<boolean>(false)

  useEffect(() => {
    if (location.pathname === "/treatments/patient-EMR") {
      setStatusTimeMin(true)
    }
  }, [location])

  useEffect(() => {
    if (branchInfo?.formAppointment && branchInfo?.formAppointment !== "" && branchInfo?.formAppointment !== "[]") {
      const form = JSON.parse(branchInfo.formAppointment)?.sub[0]?.inputName || []
      setFormAppointment(form)
    }
  }, [])

  const loadData = () => {
    handleTimeBranch(selectBranchId || branchId || 0)
    loadAllBranches()
    loadAppointmentDuration()
  }

  useEffect(() => {
    const loadDoctor = async () => {
      const visit: NextVisitInterface = treatments.summeryTreatments[props.itemId || 0]?.nextVisit
      // alert(`doc : ${visit?.doctorId}`)
      if (visit?.doctorId) {

        setDoctorId(visit.doctorId ? visit.doctorId : 0)
        // alert(`doc : ${doctorId}`)
      }
    };
    loadDoctor()

  }, [treatments])

  useEffect(() => {
    loadOfficeTime()
  }, [selectBranchId])
  const loadOfficeTime = () => ClinicApi.findAllOfficeTimeBranches(selectBranchId).then(({ data }) => data && setOfficeTime(data))


  const loadAllBranches = () => ClinicApi.findAllActiveBranches().then(({ data }) => data && setBranches(data))

  const loadAppointmentDuration = () => AppointmentDurationApi.findAll({ status: 'ACTIVE', pageLimit: 50, sortBy: 'durationMinute' }).then(({ data }) => data && setDurationMinutes(data))

  // const loadDataTreatmentItems = useCallback((search = '') => {
  //   setOperativeTypes([])
  //   ComponentApi.findAllTreatmentItems({ search: search, branch: selectBranchId }).then(({ data }) => data && setOperativeTypes(data))

  useEffect(() => {
    loadDataTreatmentItems()
  }, [])

  const loadDataTreatmentItems = useCallback(async (search = '', nextPage?: boolean) => {
    const page = nextPage ? (treatmentItemsPage + 1) : 1
    setTreatmentItemsPage(page)
    setTreatmentItemsSearch(search)
    const condition = { search: search, page: page, pageLimit: 30 }
    await ComponentApi.findAllTreatmentItems(condition).then((res) => {
      setCountTreatmentItems(Number(res?.headers['x-total']) || 0)
      if (page === 1) {
        setCountCurrentTI([res?.data?.length || 0])
        setNewTreatmentItems([])
        res?.data && setOperativeTypes(res.data)
      } else {
        setCountCurrentTI([...countCurrentTI, res?.data?.length || 0])
        setNewTreatmentItems([...newTreatmentItems, ...res?.data])
      }
    })
  }, [treatmentItemsPage, treatmentItemsSearch, newTreatmentItems])

  const loadDoctor = (valueDate: string) => {
    setScheduleLoading(true)
    const params = {
      branchId: selectBranchId,
      date: valueDate
    }
    if (params.date) {
      countAp = 0
      checkTimeStart = ''
      AppointmentApi.displayDay(params)
        .then((resp) => {
          setDoctors(resp.data)
          setPreviewDoctors(resp.data)
        })
        .finally(() => setScheduleLoading(false))
    }
  }

  const loadPreviewDoctor = () => {
    setScheduleLoading(true)
    const params = { branchId: branchId, date: previewDate }
    AppointmentApi.displayDay(params)
      .then((resp) => setPreviewDoctors(resp.data))
      .finally(() => setScheduleLoading(false))
  }
  const handlePatientCode = (item: any) => {
    const apCode = Number(patientCodeAppointment)
    if (apCode === 1) {
      return item.cnNumber

    }
    if (apCode === 0) {
      return item.branchCnNumber
    }
    if (apCode === 2) {
      return item.snCode
    }
  }

  const handleTimeBranch = async (id: number, date = '') => {
    if (id) {
      const branch: any = await BranchApi.findById(id)
      if (branch && !_.isEmpty(branch.data)) {
        setPatientCodeAppointment(branch.data.patientCodeAppointment)
        const day = _.find(branch.data.officeTimes, (time) => time.dayOfWeek === (date ? moment(date).locale('en').format('ddd') : moment().locale('en').format('ddd')))

        setOfficeTimeByDay(day || {})
        let start: any = moment(day?.timeIn, 'HH:mm')
        let end: any = moment(day?.timeOut, 'HH:mm')
        if (parseInt(start.format('mm')) > 0) start = moment(branch.data.timeIn, 'HH:mm').startOf('hour')
        if (parseInt(end.format('mm')) > 0) end = moment(branch.data.timeOut, 'HH:mm').endOf('hour').add(1, 'minute')
        const timeEnd = parseInt(end.format('HH')) === 0 ? 24 : parseInt(end.format('HH'))
        const range: any = []
        for (let index = parseInt(start.format('HH')); index <= timeEnd; index++) {
          range.push(`${String(index).padStart(2, '0')}:00`)
        }
        if (!_.isEmpty(range)) setTimeRange(range)
        else setTimeRange(['9.00', '10.00', '11.00', '12.00', '13.00', '14.00', '15.00', '16.00', '17.00', '18.00'])
      }
    }
  }

  const handleCheckReq = () => {
    const newDataCheckReq: any = {}
    formAppointment.forEach((item: any) => {
      if (!item.isDisabled && item.isRequired) {
        if (item.id === 'price' && !price ||
          item.id === 'prictice' && practice === '' ||
          item.id === 'note' && remark === '') {
          newDataCheckReq[`${item.text}_REQUIRED`] = t(`APPOINTMENT.MESSAGE.${item.text}_REQUIRED`)
        }
      }
    })
    return newDataCheckReq
  }

  const handleSubmit = () => {

    const dataError = handleCheckReq()

    if (!date || !timeStart || !durationMinute || !doctorId || _.isEmpty(operativeType) || Object.values(dataError)?.length) {
      return setErrorMessage({
        ...errorMessage,
        EMPTY_DATE: !date ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.ERROR.EMPTY_DATE') : '',
        EMPTY_DOCTOR: !doctorId ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.ERROR.EMPTY_DOCTOR') : '',
        EMPTY_TIME_START: !timeStart ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.ERROR.EMPTY_TIME_START') : '',
        EMPTY_DURATION: !durationMinute ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.ERROR.EMPTY_DURATION') : '',
        EMPTY_TREATMENT: _.isEmpty(operativeType) ? t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.ERROR.EMPTY_TREATMENT') : '',
        ...dataError
      })
    }

    let durationText = ''
    const duration: any = _.find(durationMinutes, { durationMinute: durationMinute })
    if (duration) durationText = `${duration.duration} ${t('TIME_DURATION_TYPE.' + duration.durationType)}`
    const payload: NextVisitInterface = {
      branchId: selectBranchId,
      date: date || '',
      timeStart: timeStart,
      durationMinute: durationMinute || 0,
      durationText: durationText,
      doctorId: doctorId,
      treatment: operativeType,
      price: price,
      practice: practice,
      remark: remark,
      hasLab: lab ? 'HAS' : 'NOT_HAS',
      hasXray: xray ? 'HAS' : 'NOT_HAS'
    }
    dispatch(setSummeryTreatments({ index: props.itemId, key: 'nextVisit', value: payload }))
    dispatch(modalSlice.resetModal())
    clearForm()
    clearErrorMessage()
  }

  const clearForm = () => {
    setDate('')
    setTimeStart('')
    setDurationMinute(0)
    setOperativeType([])
    setPrice(0)
    setPractice('')
    setRemark('')
  }

  const clearErrorMessage = () => {
    setErrorMessage(templateErrorMessage)
  }

  const handleSelectDoctor = (event: any) => {
    setAppointLoading(true)
    const id = event.target.value || event.target.offsetParent.value || event.target.alt
    setPreviewDoctor(_.find(previewDoctors, { doctorId: id }) || {})
    setTimeout(() => {
      setAppointLoading(false)
    }, 500)
  }

  const handleCancelNextVisit = () => {
    setIsDelete(true)
    dispatch(setSummeryTreatments({ index: props.itemId, key: 'nextVisit', value: null }))
    props.onReset && props.onReset()
    setIsDelete(false)
    notiSuccess(t(`ลบ Next Visit สำเร็จ`), '', null, null)
  }
  const onDelete = () => {
    return swalDelete(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.MESSAGE.CANCEL_NEXTVISIT')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 306px">${t(`TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.MESSAGE.YESNO`, {
      })}</p>`,
      (res: any) => {
        if (res && !isDelete) {
          handleCancelNextVisit()
        }
      },
      { confirmButtonText: t('TREATMENT_SUMMARY.POPUP.TREATMENT_NEXT_VISIT.BTN_CANCEL_NEXTVISIT') }
    )
  }

  useEffect(() => {
    loadDoctor(date)
    handleTimeBranch(selectBranchId || branchId || 0, date)

  }, [date, selectBranchId])

  useEffect(() => {
    if (width && width < 768) loadPreviewDoctor()
  }, [previewDate, width])

  useEffect(() => {
    setAppointLoading(true)
    if (doctorId && !_.isEmpty(previewDoctors)) {
      setPreviewDoctor(_.find(previewDoctors, { doctorId: doctorId }) || {})
    } else {
      setPreviewDoctor({})
    }
    setTimeout(() => {
      setAppointLoading(false)
    }, 500)
  }, [doctorId, previewDoctors])

  useEffect(() => {
    if (modal) {
      clearErrorMessage()
      loadData()
      const visit: NextVisitInterface = treatments.summeryTreatments[props.itemId || 0]?.nextVisit

      if (!_.isEmpty(visit)) {
        let newTreatment = []
        if (typeof visit.treatment === 'string') {
          const step1 = visit.treatment.replace(/\\/g, '');
          const step2 = step1.replace(/^"|"$/g, '');
          newTreatment = JSON.parse(step2);
        }
        else newTreatment = visit.treatment
        setDoctorId(visit.doctorId || null)
        setDate(visit.date || '')
        setPreviewDate(visit.date || '')
        setTimeStart(moment(visit.timeStart, 'HH:mm:ss').format('HH:mm') || '')
        setDurationMinute(visit.durationMinute)
        setOperativeType(newTreatment)
        setPrice(visit.price || 0)
        setPractice(visit.practice || '')
        setRemark(visit.remark || '')
        setLab(visit.hasLab === 'HAS' ? true : false)
        setXray(visit.hasXray === 'HAS' ? true : false)
      } else {
        setDoctorId(null)
        setDate('')
        setPreviewDate('')
        setTimeStart('')
        setDurationMinute(0)
        setOperativeType([])
        setPrice(0)
        setPractice('')
        setRemark('')
        setLab(false)
        setXray(false)
      }
    }
  }, [modal, props.userId])

  useEffect(() => {
    setPatient(props.patient)
  }, [props.patient])
  const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  const handleLengthOperative = () => {
    const newCount = countCurrentTI.reduce((a, b) => a + b, 0,)
    return newCount < countTreatmentItems
  }

  useEffect(() => {
    if (treatmentItemsRef && treatmentItemsRef.current) {
      treatmentItemsRef.current.scrollTop = contentMaxTI - 385;
    }
  }, [newTreatmentItems])

  const renderForm = (id: string, status?: 'isShow' | 'isRequired') => {
    if (!formAppointment?.length) {
      if (status === 'isRequired') {
        return false
      }
      return true
    }
    const keyName = status || 'isShow'
    const permiss = _.find(formAppointment, { id })
    return permiss ? _.get(permiss, keyName) ? true : false : false
  }


  return (
    <ModalCustom
      size={'xl'}
      title={t('Next Visit')}
      alignFooter={'end'}
      fullscreen
      // onReset={props.onReset}
      // onSubmit={handleSubmit}
      // onDelete={(id: number, obj: any) => handleCancelNextVisit()}
      modalStyle={'modal-next-visit'}
      component={
        <Box className="h-100 d-flex flex-column overflow-hidden">
          <Box className="mb-4 overflow-auto d-flex flex-column">
            <FragmentTabs className="d-md-none mb-2">
              {_.map(tabs, (tab: string, indexTab: number) => {
                return (
                  <ButtonTab className={`${indexTab === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(indexTab)}>
                    {t(tab)}
                  </ButtonTab>
                )
              })}
            </FragmentTabs>
            <Box className="d-flex h-100 overflow-hidden">
              <FragmentDetail className={`w-100 ${activeTab === 0 ? 'd-flex' : 'd-none'} d-md-flex flex-column pr-md-2 custom-scroll overflow-auto`}>
                <Grid container spacing={2} alignItems="center" style={{ marginTop: '2px' }}>
                  <Grid item lg={6} className="w-100">
                    {/* <InputTextField
                    disabled
                    inputProps={{
                      endAdornment: <FontAwesomeIcon icon={faCaretDown} />
                    }}
                    value={_.get(_.find(branches, { branchId: branchId }), 'branchName')}
                  /> */}
                    <AutocompleteSelect
                      inputLabel={t('APPOINTMENT.FORM.BRANCH')}
                      labelId="branchId"
                      noOptionsText={t('NOT_FOUND')}
                      options={branches}
                      getOptionLabel={(option: any) => option
                      }
                      renderOption={(props, option: any) => (
                        <Box component="li" {...props} style={{ justifyContent: 'space-between', display: 'flex' }} id={option.branchId}>
                          {option.branchName}
                        </Box>
                      )}
                      onchange={(event) => {
                        setSelectBranchId(Number(event.target.id))
                        clearErrorMessage()
                      }}
                      value={branches ? _.get(_.find(branches, { branchId: selectBranchId }), 'branchName') || '' : null}
                      // value={_.get(_.find(branches, { branchId: selectBranchId }), 'branchName')}
                      disabledBorder
                      disableClearable={true}
                    />
                  </Grid>
                  <Grid item lg={6} className="w-100">
                    <InputTextField
                      disabled
                      inputProps={{
                        endAdornment: <FontAwesomeIcon icon={faCaretDown} />
                      }}
                      value={patient?.patientName}
                    />
                  </Grid>
                  <Grid item lg={6} className="w-100">
                    <InputSecondNewDatePicker
                      required
                      key={'date'}
                      label={t('APPOINTMENT.FORM.DATE')}
                      value={date}
                      helperText={errorMessage.EMPTY_DATE}
                      onchange={(e: any) => {
                        setTimeStart('')
                        setDate(moment(e).format('YYYY-MM-DD'))
                        setPreviewDate(moment(e).format('YYYY-MM-DD'))
                        const findOfficeTime = officeTime.find((item: any) => item.dayOfWeek === dayOfWeek[moment(e).weekday()])
                        if (!findOfficeTime || findOfficeTime.officeStatus === 'CLOSE') {
                          swalWarning(t('CLINIC_CLOSE'))
                        }
                        setDoctorId(null)
                        clearErrorMessage()
                      }}
                      disablePast={statusTimeMin}
                      minDate={new Date()}
                    />
                  </Grid>
                  <Grid item lg={6} className="w-100">
                    <BasicSelect
                      required={true}
                      label={t('APPOINTMENT.FORM.DOCTOR')}
                      value={doctorId}
                      onchange={(event) => {
                        setDoctorId(event.target.value)
                        clearErrorMessage()
                      }}
                      selectId="dentist"
                      labelId="dentist"
                      options={
                        !_.isEmpty(doctors) ? (
                          _.map(doctors, (dentist: DentistsInterface) => (
                            <MenuItem key={dentist.doctorId} value={dentist.doctorId}>
                              {dentist.doctorFullname}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem key={'0'} disabled value={0}>
                            {t('HOME.TITLE.NO_DOCTOR_SCHEDULE')}
                          </MenuItem>
                        )
                      }
                      helperText={errorMessage.EMPTY_DOCTOR}
                    />
                  </Grid>
                  <Grid item lg={6} className="w-100">
                    <InputTimePicker
                      required={true}
                      key={'time'}
                      label={t('APPOINTMENT.FORM.TIME_START')}
                      value={timeStart}
                      helperText={errorMessage.EMPTY_TIME_START}
                      disabled={officeTimeByDay?.officeStatus === 'CLOSE' || false}
                      scopeTimeStart={officeTimeByDay?.timeIn || '00:00'}
                      scopeTimeEnd={officeTimeByDay?.timeOut || '00:00'}
                      inputText={true}
                      onChange={(e: any) => {
                        const checkOfficeTimeByDay = () => {
                          if (e.format('HH') === `${officeTimeByDay?.timeOut?.slice(0, 2)}`) {
                            return moment(officeTimeByDay?.timeOut, 'HH:mm:ss').format('HH:mm')
                          } else {
                            return e.format('HH:mm')
                          }
                        }
                        if (e?.target?.value) {
                          setTimeStart(e.target.value)
                        } else {
                          if (!e?.target) {
                            setTimeStart(
                              e.format('HH') === '00'
                                ? `${officeTimeByDay?.timeIn?.slice(0, 2)}:${e.format('mm')}`
                                : checkOfficeTimeByDay()
                            );
                          } else {
                            setTimeStart('')
                          }
                        }
                        clearErrorMessage()
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} className="w-100">
                    <BasicSelect
                      required={true}
                      label={t('APPOINTMENT.FORM.DURA_MINUTE')}
                      value={durationMinute || ''}
                      onchange={(event) => {
                        setDurationMinute(event.target.value)
                        clearErrorMessage()
                      }}
                      selectId="durationMinute"
                      labelId="durationMinute"
                      options={durationMinutes.map((duration: any, i: number) => (
                        <MenuItem key={i} value={duration.durationMinute}>
                          {`${duration.duration} ${t('TIME_DURATION_TYPE.' + duration.durationType)}`}
                        </MenuItem>
                      ))}
                      disabled={officeTimeByDay.officeStatus === 'CLOSE'}
                      helperText={errorMessage.EMPTY_DURATION}
                    />
                  </Grid>
                  <Grid item xs={12} className="w-100">
                    <AutocompleteSelect
                      multiple
                      required={true}
                      labelId="lb-operative-id"
                      inputLabel={t('REGISTER.SEND_EXAMINATION.TREATMENT_LIST')}
                      noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                      options={[...operativeTypes, ...newTreatmentItems]}
                      getOptionLabel={(option: any) => (option.operativeId ? option.operativeName : option.operativeTypeName)}
                      renderOption={(props, option: any) => (
                        <Box
                          key={`${option.operativeId ? `operativeId-${option.operativeId}` : `operativeTypeId-${option.operativeTypeId}`}`}
                          component="li"
                          style={{
                            pointerEvents: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? 'none' : '',
                            backgroundColor: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor06 : colors.white,
                            color: _.some(operativeType, (i: any, index: number) => i.operativeTypeId === option.operativeTypeId && i.operativeId === option.operativeId) ? colors.themeSecondColor : null
                          }}
                          {...props}
                        >
                          <span style={{ paddingLeft: option.operativeId ? '1rem' : '' }}>{option.operativeId ? option.operativeName : option.operativeTypeName}</span>
                        </Box>
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option: any, index: any) => {
                          const labelChip = option.operativeName ? option.operativeName : option.operativeTypeName
                          return (
                            <Tooltip title={`${labelChip}`} placement="left" key={`index-${option.operativeId}`}>
                              <div style={{ overflow: 'hidden' }}>
                                <Chip
                                  label={
                                    option.operativeName || option.operativeTypeName
                                      ? option.operativeName || option.operativeTypeName
                                      : _.find(operativeTypes, { operativeId: option.operativeId, operativeTypeId: option.operativeTypeId })?.operativeName ||
                                      _.find(operativeTypes, { operativeId: option.operativeId, operativeTypeId: option.operativeTypeId })?.operativeTypeName
                                  }
                                  style={{ fontSize: '1rem', backgroundColor: option.color || _.find(operativeTypes, { operativeId: option.operativeId, operativeTypeId: option.operativeTypeId })?.color }}
                                  {...getTagProps({ index })}
                                />
                              </div>
                            </Tooltip>
                          )
                        })
                      }
                      onchange={(event, val) => {
                        setOperativeType(_.map(val, (v) => v))
                        clearErrorMessage()
                      }}
                      onInputChange={(event: any, newValue: any) => loadDataTreatmentItems(newValue)}
                      value={operativeType ? operativeType : []}
                      key={'treatment'}
                      height={43}
                      limitTags={1}
                      errMessage={errorMessage.EMPTY_TREATMENT}
                      params={{
                        PaperComponent: undefined,
                        ListboxProps: {
                          ref: treatmentItemsRef,
                          onScroll: (event: any) => {
                            const elementScrollCurrent = (event?.target?.scrollTop + event?.target?.offsetHeight) || 0
                            const elementScrollMax = event?.target?.scrollHeight || 0
                            if ((elementScrollCurrent && elementScrollMax) &&
                              ((elementScrollCurrent + 5) >= elementScrollMax) && handleLengthOperative() && (operativeTypes?.length + newTreatmentItems?.length) < countTreatmentItems) {
                              setContentMaxTI(elementScrollMax)
                              loadDataTreatmentItems(treatmentItemsSearch, true)
                            }
                          }
                        },
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} className={`w-100 ${!renderForm('price') && 'd-none'}`}>
                    <InputTextField
                      label={t('APPOINTMENT.FORM.PRICE')}
                      value={price}
                      inputProps={{ shrink: true }}
                      onchange={(e) => {
                        clearErrorMessage()
                        const val = Number(numberOnly(e))
                        if (val < 0) setPrice(0)
                        else if (val > 900000) setPrice(900000)
                        else setPrice(val)
                      }}
                      hideControl
                      required={renderForm('price', 'isRequired')}
                      helperText={errorMessage?.PRICE_REQUIRED || ''}
                    />
                  </Grid>

                  <Grid item lg={12} className={`w-100 ${!renderForm('lab') && 'd-none'}`}>
                    <div className="d-flex align-items-center">
                      <span className={`formLabel fw-700 mr-4`}>
                        {t('APPOINTMENT.FORM.LIST_ORDER_LAB')}
                        {renderForm('lab', 'isRequired') && <span style={{ color: colors.statusInactive }}>*</span>}
                      </span>
                      <span>
                        <FormControl>
                          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel value="lab" control={<InputRadio checked={lab === true} className="radio" onClick={(e: any) => setLab(true)} />} label={t('APPOINTMENT.FORM.HAS_LAB')} />
                            <FormControlLabel value="nonlab" control={<InputRadio checked={lab === false} onClick={(e: any) => setLab(false)} />} label={t('APPOINTMENT.FORM.NOT_HAS_LAB')} />
                          </RadioGroup>
                        </FormControl>
                      </span>
                    </div>
                  </Grid>

                  <Grid item lg={12} className={`w-100 pt-0 ${!renderForm('xray') && 'd-none'}`}>
                    <div className="d-flex align-items-center">
                      <span className={`formLabel fw-700 mr-4`}>
                        {t('APPOINTMENT.FORM.LIST_ORDER_XRAY')}
                        {renderForm('xray', 'isRequired') && <span style={{ color: colors.statusInactive }}>*</span>}
                      </span>
                      <span>
                        <FormControl>
                          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel value="xray" control={<InputRadio checked={xray === true} onClick={(e: any) => setXray(true)} />} label={t('APPOINTMENT.FORM.HAS_XRAY')} />
                            <FormControlLabel value="nonxray" control={<InputRadio checked={xray === false} onClick={(e: any) => setXray(false)} />} label={t('APPOINTMENT.FORM.NOT_HAS_XRAY')} />
                          </RadioGroup>
                        </FormControl>
                      </span>
                    </div>
                  </Grid>

                  <Grid item xs={12} className={`w-100 ${!renderForm('prictice') && 'd-none'}`}>
                    <InputTextarea
                      value={practice}
                      onchange={(event) => {
                        clearErrorMessage()
                        setPractice(event.target.value)
                      }}
                      label={t('APPOINTMENT.FORM.PRACTICE')}
                      required={renderForm('prictice', 'isRequired')}
                      helperText={errorMessage?.PRACTICE_REQUIRED || ''}
                    />
                  </Grid>
                  <Grid item xs={12} className={`w-100 ${!renderForm('prictice') && 'd-none'}`}>
                    <Divider className="m-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                  </Grid>
                  <Grid item xs={12} className={`w-100 ${!renderForm('note') && 'd-none'}`}>
                    <InputTextarea
                      value={remark}
                      onchange={(event) => {
                        clearErrorMessage()
                        setRemark(event.target.value)
                      }}
                      label={t('APPOINTMENT.FORM.REMARK')}
                      required={renderForm('note', 'isRequired')}
                      helperText={errorMessage?.REMARK_REQUIRED || ''}
                    />
                  </Grid>
                </Grid>
              </FragmentDetail>
              <FragmentSchedule className={`w-100 ${activeTab === 1 ? 'd-flex' : 'd-none'} d-md-flex flex-column pl-md-2`}>
                {(date && (
                  <>
                    <Box className="mb-2 d-md-none">
                      <InputSecondNewDatePicker key={'date'} label={''} value={previewDate} onchange={(e: any) => setPreviewDate(moment(e).format('YYYY-MM-DD'))} />
                    </Box>
                    {(!scheduleLoading && (
                      <>
                        <DoctorInfo className={`${!scheduleLoading ? '' : 'opacity-0'}`} sx={{ flex: 'none' }}>
                          <SectionFilterDoctor doctors={previewDoctors} filterDoctor={previewDoctor} onClick={(event) => handleSelectDoctor(event)}></SectionFilterDoctor>
                        </DoctorInfo>
                        {/* <CustomSectionSchedule className={`h-100 overflow-hidden ${!scheduleLoading ? '' : 'opacity-0'}`}> */}
                        <div className={`h-100 overflow-hidden d-flex flex-row ${!scheduleLoading ? '' : 'opacity-0'}`}>
                          <Box
                            className={'graph-appointment'}
                            style={{ width: 'calc(100% + 0px)' }}
                            sx={{
                              'div .container-schedule': {
                                marginRight: '0'
                              }
                            }}
                          >
                            <SectionAppointment
                              timeRange={timeRange}
                              showMinute
                              card={_.map(_.get(previewDoctor, 'appointments'), (i, index: number) => {
                                if (checkTimeStart !== i.start) {
                                  checkTimeStart = i.start
                                  countAp = 0
                                } else {
                                  countAp = countAp + 1
                                }

                                const allRow = _.filter(_.get(previewDoctor, 'appointments'), (list: any) => list.start === i.start).length
                                const conDiv = document.querySelector('.container-schedule')

                                return (
                                  !_.isEmpty(timeRange) &&
                                  !_.isEmpty(previewDoctor) &&
                                  !appointLoading && (
                                    <CardAppointment
                                      key={index}
                                      appointmentId={i.appointmentId}
                                      serviceText={i.serviceText}
                                      rangeTimeText={i.rangeTimeText}
                                      colorBackground={i.color}
                                      status={i.statusAppointment}
                                      branchCnNumber={handlePatientCode(i) ? handlePatientCode(i) : ''}
                                      patientFullName={i.patientFullname}
                                      start={moment(i.start, 'HH:mm').format('HH:mm') || i.rangeTimeText.split(' - ')[0].replace('.', ':')}
                                      end={i.end || i.rangeTimeText.split(' - ')[1].replace('.', ':')}
                                      hasLab={i.hasLab === 'HAS'}
                                      hasXray={i.hasXray === 'HAS'}
                                      timeStart={timeRange[0]}
                                      classes={`pe-none`}
                                      // translateX={(60 * countAp)}
                                      specialInfo={i?.specialInfo}
                                      width={(conDiv?.clientWidth || 452) / allRow}
                                      translateX={((conDiv?.clientWidth || 452) / allRow) * countAp}
                                      phone={i?.phone}
                                    />
                                  )
                                )
                              })}

                              break={_.map([previewDoctor], (doctorData: BreakTime, index: number) => {
                                const conDiv = document.querySelector('.container-schedule')

                                return (
                                  !_.isEmpty(timeRange) &&
                                  !_.isEmpty(previewDoctor) &&
                                  !appointLoading && (
                                    <CardBreak
                                      start={doctorData.breakTimeStart}
                                      end={doctorData.breakTimeEnd}
                                      timeStart={timeRange[0]}
                                      width={(conDiv?.clientWidth || 0)}
                                      translateX={(conDiv?.clientWidth || 0)}
                                    />
                                  ))


                              })}

                            ></SectionAppointment>
                          </Box>
                        </div>
                        {/* </CustomSectionSchedule> */}
                      </>
                    )) || (
                        <Box className="m-auto">
                          <Loading show={true} type="softLoading" />
                        </Box>
                      )}
                  </>
                )) || (
                    <Box className="d-flex align-items-center justify-content-center h-100">
                      <Typography sx={{ color: colors.textExtraLightGray }}>{t('REGISTER.NO_ITEM')}</Typography>
                    </Box>
                  )}
              </FragmentSchedule>
            </Box>
          </Box>
          <FooterButton className={`d-flex align-items-center justify-content-end mt-auto ${!_.isEmpty(treatments.summeryTreatments[props.itemId || 0]?.nextVisit) && 'has-cancel-visit flex-column flex-md-row'}`}>
            <Box className="d-flex align-items-center form-button-wrap">
              <ButtonCustom onClick={props.onReset} textButton={t('BUTTON.CANCEL')} variant={'outlined'} className={'btn-close-visit mt-3'} />
              {!_.isEmpty(treatments.summeryTreatments[props.itemId || 0]?.nextVisit) && <ButtonCustom onClick={onDelete} textButton={t('TREATMENTS.BUTTON.CANCEL_NEXT_VISIT')} mode={'del'} className={'btn-cancel-visit mt-3'} />}
            </Box>
            <Box className="form-button-wrap">
              <ButtonCustom onClick={handleSubmit} textButton={t('BUTTON.SAVE')} className={'btn-save-visit mt-3'} />
            </Box>
          </FooterButton>
        </ Box>
      }
    />
  )
}
