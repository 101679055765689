import { Box, Divider, Grid, Typography, styled } from '@mui/material'
import ClinicApi, { DentistInterface } from 'api/master/clinic.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import InputTextField from 'component/Input/InputTextField'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { colors } from 'constants/theme'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getClinicInfo, numberFormat, toBuddhistYear } from 'utils/app.utils'
import { SignBox } from './style/popupTreatmentConsentUseStyled'
import CanvasDraw from 'react-canvas-draw'
import FunctionPrint from 'component/Print/print'
import PdfOutStandingPayment from 'component/Print/pdfOutStandingPayment'
import { useSelect } from 'react-select-search'
import { TreatmentStateInterface, setTreatment, treatment } from 'app/slice/treatment.slice'
import { useDispatch } from 'react-redux'
import Loading from 'component/Loading'
import { useLocation } from 'react-router-dom'
import OverdueApi, { PdfOutOverdueProps } from 'api/finances/overdue.api'
import { notiSuccess } from 'component/notifications/notifications'

export const BoxPopupOutStandingPayment = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
        '.pl-12-xl': {
            marginTop: '16px',
            paddingLeft: '0px',
        },
        '.pr-12-xl': {
            paddingRight: '0px',
        },
    },
    '.pl-12-xl': {
        paddingLeft: '12px',
        [theme.breakpoints.down('lg')]: {
            paddingLeft: '0px',
        }
    },
    '.pr-12-xl': {
        paddingRight: '12px',
        [theme.breakpoints.down('lg')]: {
            paddingRight: '0px',
        }
    },
    ".pop-body": {
        margin: ' 0 0 24px'
    },
    ".pop-footer": {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    }
}))

type PopupOutStandingPaymentProps = {
    onClose: () => void
    patient: any
    treatments: any
    onSave: (data: any) => void
    type?: 'OUT' | 'OUT_FINISHED'
    disabledSelect_OutFinished?: boolean
}

export type PDFPayloadOutStandingPaymentProps = {
    overdueTotal?: number,
    overdueDate?: string,
    overdueDateStart?: string,
    patientSignature?: string,
    doctorId?: number,
    doctorName?: string,
    doctorSignature?: string,
    managerName?: string,
    managerSignature?: string
}

const PopupOutStandingPayment = (props: PopupOutStandingPaymentProps) => {
    const { t, i18n } = useTranslation()
    const pdfTitle = props?.type !== 'OUT_FINISHED' ? "MODAL_OUTSTANDINGPAYMENT" : "MODAL_OUTSTANDINGPAYMENT_FINISHED"
    const dispatch = useDispatch()
    const location = useLocation()

    const branchInfo = getClinicInfo()
    const [load, setLoad] = useState<boolean>(false)
    const [doctors, setDoctors] = useState<DentistInterface[]>([])
    const [dataOut, setDataOut] = useState<any>({})
    const [disabledSave, setDisabledSave] = useState<boolean>(false)

    const canvasDoctorRef: any = useRef()
    const canvasPatientRef: any = useRef()
    const canvasManagerRef: any = useRef()
    const pdfRef: any = useRef()

    const [totalTreatment, setTotalTreatment] = useState<number>(0)

    const handleTotalTreatment = () => {
        const newPath: any = location
        if (newPath?.pathname !== '/overdue-detail') {
            const newTotalPD = props.treatments?.summeryProducts?.map((item: any) => item?.total || 0)
                .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            const newTotal = props.treatments?.summeryTreatments?.map((treatment: any) => treatment?.total || 0)
                .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
            setTotalTreatment((newTotal + newTotalPD) || 0)
        } else {
            const newTotal = props.treatments?.total
            setTotalTreatment(newTotal)
        }
    }

    useEffect(() => {
        handleTotalTreatment()
    }, [props.treatments, location])

    const loadDoctor = async () => {
        const resp = await ClinicApi.findAllDoctors()
        if (resp.status === 200) {
            const newData = resp.data.map((item: any) => {
                return {
                    dentistId: item.userId,
                    dentistFullname: item.fullname
                }
            })
            setDoctors(newData)
        }
    }

    useEffect(() => {
        loadDoctor()
    }, [])

    useEffect(() => {
        if (dataOut?.dateStart && dataOut?.dateEnd &&
            dataOut?.price && dataOut?.doctorId &&
            (props?.type === 'OUT_FINISHED' || (dataOut?.doctorSignature || dataOut?.signature?.doctorSignature)) &&
            (dataOut?.patientSignature || dataOut?.signature?.patientSignature) &&
            (props?.type !== 'OUT_FINISHED' || (dataOut?.managerSignature || dataOut?.signature?.managerSignature))) {
            setDisabledSave(false)
        } else {
            setDisabledSave(true)
        }
    }, [dataOut])

    useEffect(() => {
        const patient = props.patient
        const outStandingPayment = props?.treatments?.outStandingPayment
        const checkSig = props?.type === 'OUT_FINISHED'
        const newDataOut = {
            patientFullName: `${patient?.prefix?.prefixName}${patient?.firstname} ${patient?.lastname}`,
            patientFullNameEn: `${patient?.prefix?.prefixNameEn || patient?.prefix?.prefixName}${patient?.firstnameEn || patient?.firstname} ${patient?.lastnameEn || patient?.firstname}`,
            patientCode: branchInfo?.patientCodeAppointment === '1' ? patient?.cnNumber : branchInfo?.patientCodeAppointment === '0' ? patient?.branchCnNumber : patient?.snCode || '-',
            price: outStandingPayment?.overdueTotal || 0,
            dateEnd: outStandingPayment?.overdueDate || '',
            dateStart: outStandingPayment?.overdueDateStart || moment().format('YYYY-MM-DD') || '',
            doctorId: outStandingPayment?.doctorId || 0,
            doctorName: outStandingPayment?.doctorName || 0,
            managerName: outStandingPayment?.managerName || '',
            ...props.treatments?.dataOut,
            signature: {
                patientSignature: checkSig ? '' : outStandingPayment?.patientSignature || '',
                doctorSignature: checkSig ? '' : outStandingPayment?.doctorSignature || '',
                managerSignature: checkSig ? '' : outStandingPayment?.managerSignature || '',
            },
        }
        setDataOut({ ...dataOut, ...newDataOut })
    }, [props.patient])

    const handleChangeDataOut = (key: string, value: any) => {
        const newDataOut = { ...dataOut }
        newDataOut[key] = value
        setDataOut(newDataOut)
    }

    const clearCanvas = (type: 'doctor' | 'patient' | 'manager') => {
        if (type === 'doctor' && canvasDoctorRef.current) {
            canvasDoctorRef.current.clear()
            canvasDoctorRef.current.imgSrc = ''
            canvasDoctorRef.current.resetView()
            setDataOut({ ...dataOut, doctorSignature: '', signature: { ...dataOut?.signature, doctorSignature: '' } })
        }

        if (type === 'patient' && canvasPatientRef.current) {
            canvasPatientRef.current.clear()
            canvasPatientRef.current.imgSrc = ''
            canvasPatientRef.current.resetView()
            setDataOut({ ...dataOut, patientSignature: '', signature: { ...dataOut?.signature, patientSignature: '' } })
        }

        if (type === 'manager' && canvasManagerRef.current) {
            canvasManagerRef.current.clear()
            canvasManagerRef.current.imgSrc = ''
            canvasManagerRef.current.resetView()
            setDataOut({ ...dataOut, managerSignature: '', signature: { ...dataOut?.signature, managerSignature: '' } })
        }
    }

    const renderCanvas = (type: 'doctor' | 'patient' | 'manager') => {
        if (type === 'doctor') {
            if (canvasDoctorRef.current) {
                const base64 = canvasDoctorRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
                let base64string: any = `${base64}`.split(',')
                base64string = base64string[base64string.length - 1]
                setDataOut({ ...dataOut, doctorSignature: base64string })
            }
        } else if (type === 'manager') {
            if (canvasManagerRef.current) {
                const base64 = canvasManagerRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
                let base64string: any = `${base64}`.split(',')
                base64string = base64string[base64string.length - 1]
                setDataOut({ ...dataOut, managerSignature: base64string })
            }
        } else {
            if (canvasPatientRef.current) {
                const base64 = canvasPatientRef.current.canvasContainer.childNodes[1].toDataURL('image/jpg')
                let base64string: any = `${base64}`.split(',')
                base64string = base64string[base64string.length - 1]
                setDataOut({ ...dataOut, patientSignature: base64string })
            }
        }
    }

    const onSubmit = async () => {
        setLoad(true)
        const newPath: any = location
        if (newPath?.pathname !== '/overdue-detail') {
            const newData = {
                overdueTotal: Number(dataOut?.price) || 0,
                overdueDate: dataOut?.dateEnd || '',
                overdueDateStart: dataOut?.dateStart || '',
                patientSignature: dataOut?.patientSignature || dataOut?.signature?.patientSignature || '',
                doctorId: dataOut?.doctorId || 0,
                doctorName: dataOut?.doctorName || '',
                doctorSignature: dataOut?.doctorSignature || dataOut?.signature?.doctorSignature || '',
                managerName: '',
                managerSignature: dataOut?.managerSignature || dataOut?.signature?.managerSignature || '',
            }
            dispatch(setTreatment({ value: { ...props.treatments, outStandingPayment: { ...newData } } }))
            props.onSave(newData)
            props.onClose()
        } else {
            const newData: PdfOutOverdueProps = {
                overdueTotal: Number(dataOut?.price) || 0,
                hasPrint: props?.type !== 'OUT_FINISHED' ? 0 : 1,
                patientCaseId: dataOut?.patientCaseId || 0,
                overdueDate: dataOut?.dateEnd || '',
                overdueDateStart: dataOut?.dateStart || '',
                patientSignature: dataOut?.patientSignature || dataOut?.signature?.patientSignature || '',
                doctorId: dataOut?.doctorId || 0,
                doctorName: dataOut?.doctorName || '',
                doctorSignature: dataOut?.doctorSignature || dataOut?.signature?.doctorSignature || '',
                managerName: '',
                managerSignature: dataOut?.managerSignature || dataOut?.signature?.managerSignature || '',
            }
            await OverdueApi.pdfOutOverdue(dataOut?.orderId, newData).then((res) => {
                notiSuccess(t('TREATMENT_SUMMARY.MESSAGE.SUCCESS.SAVE'), '', null, null, 'register-page')
            }).finally(() => {
                props.onSave(newData)
                props.onClose()
            })
        }
        setLoad(false)
    }

    const handleFloat = (input: number) => {
        if (input?.toString()?.length && input?.toString()[input?.toString()?.length - 1] === '.') {
            const newInput: number = input
            return newInput
        } else {
            return input
        }
    }

    const renderNumberPrice = () => {
        const decimalPart = dataOut?.price?.toString()?.split('.')[1];
        const newPrice = dataOut?.price?.toString()?.split('.')[0]
        if (dataOut?.price?.toLocaleString() === '0') {
            return ''
        }
        const newDot = decimalPart !== undefined ? `.${decimalPart}` : ''
        return `${Number(newPrice)?.toLocaleString()}${newDot}`
    }

    return (
        <>
            <Loading show={load} />
            <ModalCustom
                title={t(`${pdfTitle}.TITLE`)}
                modalStyle={`modal-outstandingpayment`}
                closeButton
                size={'xl'}
                onClose={() => props.onClose()}
                component={(
                    <BoxPopupOutStandingPayment>
                        <Box className={`pop-body`}>
                            <Box className={`mb-3`}>
                                {t('MODAL_OUTSTANDINGPAYMENT.FORM.HOSPITALNAME')}{branchInfo?.hospitalName || ''}
                            </Box>
                            <Box className={`mb-3`}>
                                <Grid container xs={12} className={`mb-3`}>
                                    <Grid item lg={6} xs={12}>
                                        <Box className={`pr-12-xl`}>
                                            <InputSecondNewDatePicker
                                                required
                                                key={'dateStart'}
                                                label={t('MODAL_OUTSTANDINGPAYMENT.FORM.DATESTART')}
                                                value={dataOut?.dateStart || ''}
                                                onchange={(e: any) => { handleChangeDataOut('dateStart', e) }}
                                                allowClear={(props?.type !== "OUT_FINISHED")}
                                                onClear={() => handleChangeDataOut('dateStart', '')}
                                                maxDate={dataOut?.dateEnd ? new Date(dataOut?.dateEnd) : ''}
                                                disableFuture
                                                disabled
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Box className={`pl-12-xl`}>
                                            <AutocompleteSelect
                                                labelId='doctorId'
                                                inputLabel={t('MODAL_OUTSTANDINGPAYMENT.FORM.DOCTOR')}
                                                required
                                                options={doctors}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <Box {...props}>
                                                            {option?.dentistFullname || ''}
                                                        </Box>
                                                    )
                                                }}
                                                onchange={(e, val) => {
                                                    const newData = {
                                                        doctorId: val?.dentistId || 0,
                                                        doctorName: val?.dentistFullname || ''
                                                    }
                                                    setDataOut({ ...dataOut, ...newData })
                                                }}
                                                value={dataOut?.doctorName || ''}
                                                disabled={props?.disabledSelect_OutFinished}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} className={`mb-3`}>
                                    <Grid item lg={6} xs={12}>
                                        <Box className={`pr-12-xl`}>
                                            <InputSecondNewDatePicker
                                                required
                                                key={'dateEnd'}
                                                label={t('MODAL_OUTSTANDINGPAYMENT.FORM.DATEEND')}
                                                value={dataOut?.dateEnd || ''}
                                                onchange={(e: any) => { handleChangeDataOut('dateEnd', e) }}
                                                allowClear
                                                onClear={() => handleChangeDataOut('dateEnd', '')}
                                                disablePast
                                                minDate={dataOut?.dateStart ? new Date(dataOut?.dateStart) : ''}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item lg={6} xs={12}>
                                        <Box className={`pl-12-xl`} sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                                            <Box>
                                                {t('MODAL_OUTSTANDINGPAYMENT.FORM.PRICE')}<span style={{ color: colors.statusInactive }}>*</span>
                                            </Box>
                                            <Box>
                                                <InputTextField
                                                    name={'price'}
                                                    value={renderNumberPrice() || ''}
                                                    onchange={(e) => {
                                                        const inputValue = e.target.value;
                                                        const sanitizedValue = inputValue.replace(/,/g, '');
                                                        const pattern = /^[0-9.]+$/;
                                                        if (pattern.test(sanitizedValue) && !isNaN(sanitizedValue)) {
                                                            const value: number = sanitizedValue;
                                                            const nonNegativeValue = value >= 0 ? handleFloat(value) : 0;
                                                            const newPrice = Number(totalTreatment || 0) >= Number(nonNegativeValue || 0) ? nonNegativeValue : totalTreatment
                                                            handleChangeDataOut('price', newPrice)
                                                        } else {
                                                            const newData = e.target.value === "" ? 0 : dataOut?.price || 0
                                                            const newPrice = Number(totalTreatment || 0) >= Number(newData || 0) ? newData : totalTreatment
                                                            handleChangeDataOut('price', newPrice)
                                                        }
                                                    }}
                                                    disabled={props?.disabledSelect_OutFinished}
                                                />
                                            </Box>
                                            <Box>
                                                {t('CURRENCY_CHANGE.TH')}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={`mb-3 w-100`} sx={{ display: 'flex' }}>
                                <Box className={`w-50`}>
                                    {t('MODAL_OUTSTANDINGPAYMENT.FORM.IAM', { patientName: dataOut?.patientFullName || '' })}
                                </Box>
                                <Box className={`w-50`}>
                                    {dataOut?.patientCode}
                                </Box>
                            </Box>
                            <Box className={`mb-3`}>
                                {t(`${pdfTitle}.FORM.DETAIL_EXAM`,
                                    {
                                        hospitalName: branchInfo?.hospitalName,
                                        branchName: branchInfo?.branchName,
                                        dateStart: dataOut?.dateStart ? toBuddhistYear(moment(dataOut?.dateStart)) : '-',
                                        dateEnd: dataOut?.dateEnd ? toBuddhistYear(moment(dataOut?.dateEnd)) : '-',
                                        price: dataOut?.price ? numberFormat(dataOut?.price) : '-',
                                        paymentId: dataOut?.paymentNumber || '-'
                                    })}
                            </Box>
                            <Box className={`mb-3`} sx={{ display: 'flex' }}>
                                <Box className={`signatureWrap-box w-100 mt-3 mt-md-0`}>
                                    <Box>
                                        <SignBox className='mx-auto'>
                                            <Box className={`clear-box `}>
                                                <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('patient')} />
                                            </Box>
                                            {dataOut?.signature?.patientSignature && (
                                                <img src={`data:image/png;base64,${dataOut?.signature?.patientSignature}`} className="w-100 h-100" alt="" />
                                            )}
                                            <Box className="canvas-box" sx={{ '.box-sig': { backgroundColor: dataOut?.signature?.patientSignature ? '#9f9f9f3d !important' : '' } }}>
                                                <CanvasDraw
                                                    className={'box-sig'}
                                                    hideGrid
                                                    hideInterface
                                                    brushRadius={2}
                                                    lazyRadius={2}
                                                    disabled={dataOut?.signature?.patientSignature}
                                                    brushColor={colors.black}
                                                    ref={canvasPatientRef}
                                                    onChange={() => renderCanvas('patient')}
                                                />
                                            </Box>
                                        </SignBox>
                                    </Box>
                                    <Box className='text-center mt-2'>
                                        <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{`${dataOut?.patientFullName || ''}`}<span className='d-md-none'>)</span></Typography>
                                        <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_TREATMENT_CONSENT.SIGN.PATIENT')}</Typography>
                                        <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Patient{"'"}s Signature</Typography>
                                    </Box>
                                </Box>
                                {props?.type !== 'OUT_FINISHED'
                                    ?
                                    <Box className={`signatureWrap-box w-100 mt-3 mt-md-0`}>
                                        <Box>
                                            <SignBox className='mx-auto'>
                                                <Box className={`clear-box `}>
                                                    <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('doctor')} />
                                                </Box>
                                                {dataOut?.signature?.doctorSignature && (
                                                    <img src={`data:image/png;base64,${dataOut?.signature?.doctorSignature}`} className="w-100 h-100" alt="" />
                                                )}
                                                <Box className="canvas-box" sx={{ '.box-sig': { backgroundColor: dataOut?.signature?.doctorSignature ? '#9f9f9f3d !important' : '' } }}>
                                                    <CanvasDraw
                                                        className={'box-sig'}
                                                        hideGrid
                                                        hideInterface
                                                        brushRadius={2}
                                                        lazyRadius={2}
                                                        disabled={dataOut?.signature?.doctorSignature}
                                                        // imgSrc={dataOut?.signature?.doctorSignature ? `data:image/png;base64,${dataOut?.signature?.doctorSignature}` : ''}
                                                        brushColor={colors.black}
                                                        ref={canvasDoctorRef}
                                                        onChange={() => renderCanvas('doctor')} />
                                                </Box>
                                            </SignBox>
                                        </Box>
                                        <Box className='text-center mt-2'>
                                            <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{dataOut?.doctorName || ''}<span className='d-md-none'>)</span></Typography>
                                            <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_TREATMENT_CONSENT.SIGN.DOCTOR')}</Typography>
                                            <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Dentist{"'"}s Signature</Typography>
                                        </Box>
                                    </Box>
                                    :
                                    <Box className={`signatureWrap-box w-100 mt-3 mt-md-0`}>
                                        <Box>
                                            <SignBox className='mx-auto'>
                                                <Box className={`clear-box `}>
                                                    <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('manager')} />
                                                </Box>
                                                {dataOut?.signature?.managerSignature && (
                                                    <img src={`data:image/png;base64,${dataOut?.signature?.managerSignature}`} className="w-100 h-100" alt="" />
                                                )}
                                                <Box className="canvas-box" sx={{ '.box-sig': { backgroundColor: dataOut?.signature?.managerSignature ? '#9f9f9f3d !important' : '' } }}>
                                                    <CanvasDraw
                                                        className={'box-sig'}
                                                        hideGrid
                                                        hideInterface
                                                        brushRadius={2}
                                                        lazyRadius={2}
                                                        disabled={dataOut?.signature?.managerSignature}
                                                        brushColor={colors.black}
                                                        // imgSrc={dataOut?.signature?.managerSignature ? `data:image/png;base64,${dataOut?.signature?.managerSignature}` : ''}
                                                        ref={canvasManagerRef}
                                                        onChange={() => renderCanvas('manager')} />
                                                </Box>
                                            </SignBox>
                                        </Box>
                                        <Box className='text-center mt-2'>
                                            {/* <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{`(.........................)`}<span className='d-md-none'>)</span></Typography> */}
                                            <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_OUTSTANDINGPAYMENT.FORM.MANAGER')}</Typography>
                                            <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Manager</Typography>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            {props?.type !== "OUT_FINISHED" &&
                                <Box className={`mb-3`} >
                                    <Box className={`signatureWrap-box w-100 mt-3 mt-md-0`}>
                                        <Box>
                                            <SignBox className='mx-auto'>
                                                <Box className={`clear-box `}>
                                                    <ButtonCustom variant={'outlined'} textButton={t('MODAL_TREATMENT_CONSENT.BUTTON.CLEAR_CANVAS')} onClick={() => clearCanvas('manager')} />
                                                </Box>
                                                {dataOut?.signature?.managerSignature && (
                                                    <img src={`data:image/png;base64,${dataOut?.signature?.managerSignature}`} className="w-100 h-100" alt="" />
                                                )}
                                                <Box className="canvas-box" sx={{ '.box-sig': { backgroundColor: dataOut?.signature?.managerSignature ? '#9f9f9f3d !important' : '' } }}>
                                                    <CanvasDraw
                                                        className={'box-sig'}
                                                        hideGrid
                                                        hideInterface
                                                        brushRadius={2}
                                                        lazyRadius={2}
                                                        disabled={dataOut?.signature?.managerSignature}
                                                        brushColor={colors.black}
                                                        // imgSrc={dataOut?.signature?.managerSignature ? `data:image/png;base64,${dataOut?.signature?.managerSignature}` : ''}
                                                        ref={canvasManagerRef}
                                                        onChange={() => renderCanvas('manager')} />
                                                </Box>
                                            </SignBox>
                                        </Box>
                                        <Box className='text-center mt-2'>
                                            {/* <Typography sx={{ fontWeight: 500 }}><span className='d-md-none'>(</span>{`(.........................)`}<span className='d-md-none'>)</span></Typography> */}
                                            <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>{t('MODAL_OUTSTANDINGPAYMENT.FORM.MANAGER')}</Typography>
                                            <Typography className='d-none d-md-block' sx={{ fontSize: 14 }}>Manager</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            }
                            <Divider sx={{ margin: '0 !important', borderColor: '#E5E5E5 !important' }} />
                            {props?.type === "OUT_FINISHED" &&
                                <Box className='mt-3'>
                                    {t('MODAL_OUTSTANDINGPAYMENT_FINISHED.FORM.REMARK')}
                                </Box>
                            }
                        </Box>
                        <Box className={`pop-footer`}>
                            <Box sx={{ width: '100px', button: { width: '100%' } }}>
                                <FunctionPrint
                                    content={() => pdfRef.current}
                                    removeIfram
                                    trigger={() => (
                                        <ButtonCustom
                                            disabled={disabledSave}
                                            variant={"outlined"}
                                            textButton={t("BUTTON.PRINT")}
                                        // onClick={() =>}
                                        />
                                    )}
                                    style={'@page { size: 210mm 450px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                                    beforePrint={() => {
                                        onSubmit()
                                    }}
                                />

                            </Box>
                            <Box sx={{ display: 'flex', gap: '16px' }}>
                                <Box sx={{ width: '100px', button: { width: '100%', filter: 'grayscale(100%) sepia(0%) brightness(35%) contrast(120%)' } }}>
                                    <ButtonCustom
                                        variant={"outlined"}
                                        textButton={t("BUTTON.CANCEL")}
                                        onClick={props.onClose}
                                    />
                                </Box>
                                <Box sx={{ width: '100px', button: { width: '100%' } }}>
                                    <ButtonCustom
                                        disabled={disabledSave}
                                        textButton={t("BUTTON.SAVE")}
                                        onClick={onSubmit}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {/* className={'print-show'} */}
                        <Box ref={pdfRef} className={'print-show'}>
                            <PdfOutStandingPayment
                                dataOut={dataOut}
                                type={props?.type || 'OUT'}
                            />
                        </Box>
                    </BoxPopupOutStandingPayment>
                )}
            />
        </>

    )
}

export default PopupOutStandingPayment