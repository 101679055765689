import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export enum PRODUCT_GROUP {
    PRODUCT = 'PRODUCT',
    MEDICINE = 'MEDICINE',
    MATERIAL = 'MATERIAL'
}

const path = 'inventory'

export default class InventoryApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(path, { params: { ...props } }).then((res) => res)
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`).then((res) => res)
    }

    static findImportHistoryById(id: number, props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}/import-history/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findOrderHistoryById(id: number, props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}/order-history/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findAdjustmentHistoryById(id: number, props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}/adjustment-history/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findInventoryHistoryById(id: number, props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}/warehouses/${id}`, { params: { ...props } }).then((res) => res)
    }

    static findDisposeLot(id: number, props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}/dispose-lot/${id}`, { params: { ...props } }).then((res) => res)
    }

}
