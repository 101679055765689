import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
/** UTIILS */

export interface MaterialsInterface {
    group: string
    productCode: string
    productName: string
    productNameEn: string
    productTypeId: number | null
    // productFormatId: number | null
    productUnitId: number | null
    productInstructionId: number | null
    productPreserveId: number | null
    // productUseId: number | null
    // strength: string
    salePrice: number
    costPrice: number
    minStock: number
    explanation: string
    showOnReceipt: string
    status?: string
    files: any
    image: any
    // warnings?: string | null
    numberBarcode?: string
}

export default class MaterialsApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get('materials', { params: { ...props } }).then((res) => res)
    }
    static create(payload: any) {
        return this.api.post('materials', payload)
    }
    static findById(id: number): Promise<MaterialsInterface> {
        return this.api.get(`materials/${id}`)
    }
    static update(id: number, body: MaterialsInterface) {
        return this.api.patch(`materials/${id}`, body)
    }
    static updateStatus(id: number, status: string): Promise<any> {
        return this.api.patch(`materials/${id}/status`, { id, status })
    }
    static remove(id: number) {
        return this.api.delete(`materials/${id}`)
    }
}
