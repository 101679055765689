import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { Box, Typography } from '@mui/material'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import useStyles, { CustomTable } from 'features/warehouses/products/style'

/** API */
import { routeName } from 'routes/routes-name'
import ProductsApi from 'api/warehouses/product.api'

/** UTILS */
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'

/** COMPONENT */
import { swalActive } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import TableCustom from 'component/Table/TableCustom'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import TableRowCommon from 'component/Table/TableRowCommon'
import AutocompleteSelect from 'component/Select/AutocompleteSelect';
import { numberFormat } from 'utils/app.utils'
import TableRowExport from 'component/Pdf/TableRowExport'
import ButtonExport from 'component/Button/ButtonExport'
import { dateTimeToView } from 'utils/date.utils';

const permissions = {
  isCreate: isCreate(),
  isDelete: isDelete(),
  isUpdate: isUpdate(),
  isView: isView()
}

type StatusProps = {
  status: 'ACTIVE' | 'INACTIVE' | 'ALL'
  name: string
}

export default function Products() {
  const classes = useStyles()
  const { t } = useTranslation()

  const [products, setProducts] = useState([])
  const [exportData, setExportData] = useState([])

  const [productTypes, setProductTypes] = useState([]);
  const [productType, setProductType]: any = useState({});
  const [prodTypeLoading, setProdTypeLoading] = useState(false);

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(10)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('productCode')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')
  const [statusSearch, setStatusSearch] = useState<StatusProps>({ status: "ALL", name: t('ALL') })

  const loadData = useCallback(async () => {
    let condition: any = { group: 'PRODUCT', status: '' }
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search || !_.isEmpty(productType)) condition = { ...condition, search: search || productType?.productTypeName || '' }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (statusSearch) condition = { ...condition, status: statusSearch.status === "ALL" ? '' : statusSearch.status }

    const res = await ProductsApi.findAll(condition)
    if (res.status === 200) {
      setRowCount(res.headers['x-total'])
      setProducts(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, productType, statusSearch])

  useEffect(() => {
    loadData()
  }, [loadData])

  const loadDataForExport = useCallback(async () => {
    let condition: any = { group: 'PRODUCT', status: '' }
    condition.export = 1
    if (page) condition = { ...condition, page: page }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (search || !_.isEmpty(productType)) condition = { ...condition, search: search || productType?.productTypeName || '' }
    if (sortBy) condition = { ...condition, sortBy: sortBy }
    if (sortType) condition = { ...condition, sortType: sortType }
    if (statusSearch) condition = { ...condition, status: statusSearch.status === "ALL" ? '' : statusSearch.status }
    const res = await ProductsApi.findAll(condition)
    if (res.status === 200) {
      setExportData(res.data)
    }
  }, [page, pageLimit, search, sortBy, sortType, productType, statusSearch])

  useEffect(() => {
    loadDataForExport()
  }, [loadDataForExport])

  const loadProductTypes = (medSearch?: string) => {
    setProdTypeLoading(true)
    const condition: any = { group: 'PRODUCT', pageLimit: 10, status: 'ACTIVE', search: medSearch || '' }
    ProductTypesApi.findAll(condition)
      .then(({ data }) => setProductTypes(data))
      .catch((e) => { return })
      .finally(() => setProdTypeLoading(false))
  }

  const handleInputSearch = useCallback(
    _.debounce((medSearch?: string) => {
      loadProductTypes(medSearch)
      setPage(1)
    }, 1000), []);

  useEffect(() => {
    loadProductTypes()
  }, []);

  const onEdit = (data: any) => {
    window.location.href = `${routeName.product}/${data.productId}`
  }

  const onCreate = () => {
    window.location.href = routeName.product + '/create'
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const onActive = (id: number, valueStatus: string) => {
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PRODUCT.TEXT.CHANGE_STATUS')}</p>`,
      null, (res: any) => {
        if (res) {
          ProductsApi.updateStatus(id, valueStatus)
            .then((resp) => {
              notiSuccess(t('PRODUCT.MSG.UPDATE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('PRODUCT.MSG.UN_SUCCESS'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }

  const onDelete = (data: any) => {
    const { productId, productName, productNameEn } = data
    swalActive(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('PRODUCT.TEXT.DEL_MED1')}</p>`,
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t(`PRODUCT.TEXT.DEL_MED2`, { name: productName })}</p>`,
      (res: any) => {
        if (res) {
          ProductsApi.remove(productId)
            .then((resp) => {
              notiSuccess(t('PRODUCT.MSG.DELETE'))
              loadData()
            })
            .catch((e) => {
              notiError(t('PRODUCT.MSG.UN_SUCCESS'))
            })
        }
      }, null, t('BUTTON.SUBMIT'), t('BUTTON.CANCEL'))
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', align: 'center' },
    { id: 'productTypeId', disablePadding: false, label: t('PRODUCT.TABLE.CELLS_PRODUCT.PRODUCT_TYPE') },
    { id: 'productCode', disablePadding: false, label: t('PRODUCT.TABLE.CELLS_PRODUCT.PRODUCT_CODE') },
    { id: 'productName', disablePadding: false, label: t('PRODUCT.TABLE.CELLS_PRODUCT.PRODUCT_NAME') },
    { id: 'price', disablePadding: false, label: t('PRODUCT.TABLE.CELLS_PRODUCT.PRICE'), align: 'right' },
    { id: 'status', disablePadding: false, label: t('PRODUCT.TABLE.CELLS_PRODUCT.STATUS'), align: 'center' },
    { id: 'updatedBy', disablePadding: false, label: t('PRODUCT.TABLE.CELLS_PRODUCT.UPDATED_BY'), align: 'center' },
    { id: 'action', disablePadding: false, label: 'Action' }
  ]

  const renderData = (objData: any, no: number, valueExportData: boolean) => {
    const num = valueExportData === true ? no + 1 : page * pageLimit - pageLimit + no + 1
    const { productId, productType, productCode, productName, salePrice, updatedBy, updatedAt, status: productStatus } = objData

    const statusBtnActive = productStatus === 'ACTIVE' ? true : permissions.isUpdate.disabled
    const statusBtnInActive = productStatus === 'INACTIVE' ? true : permissions.isUpdate.disabled

    const colUpdateExport = {
      option: 'TEXT', align: 'left', label: `${updatedBy} ${dateTimeToView(updatedAt)}`
    }
    const renderColUpdate = valueExportData ? colUpdateExport : { option: 'UPDATE_BY', align: 'center', label: { updatedBy: updatedBy, updatedAt: updatedAt } }

    const objRenderData = {
      key: productId,
      id: productId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: num },
        { option: 'TEXT', align: 'left', label: productType?.productTypeName },
        { option: 'TEXT', align: 'left', label: productCode },
        { option: 'TEXT', align: 'left', label: productName },
        { option: 'TEXT', align: 'right', label: numberFormat(salePrice) },
        { option: 'STATUS', align: 'center', label: productStatus },
        { ...renderColUpdate },
        {
          option: 'ACTION',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`STATUS.ACTIVE`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`STATUS.INACTIVE`), disabled: statusBtnInActive },
            { option: 'DIVIDER', label: '', disabled: false },
            { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
            { option: 'DELETE', label: t(`BUTTON.DELETE`), disabled: permissions.isDelete.disabled }
          ]
        }
      ]
    }
    return (
      <>
        {valueExportData === true && <TableRowExport {...objRenderData} /> ||
          <TableRowCommon
            {...objRenderData}
            onactive={() => onActive(Number(objRenderData.id), 'ACTIVE')}
            oninactive={() => onActive(Number(objRenderData.id), 'INACTIVE')}
            onedit={() => onEdit(objRenderData.obj)}
            ondelete={() => onDelete(objRenderData.obj)}
          />
        }
      </>
    )
  }

  const filter = [
    { label: `${t('INPUT.SEARCH')}: ${search || '-'}` },
    { label: `${t('PRODUCT.TEXT.TYPE')}: ${!_.isEmpty(productType) ? productType?.productTypeName : '-'}` }
  ]

  return (
    <div className={classes.root}>
      <Card className="border-0 h-100">
        <HeaderCard text={t('PRODUCT.HEADER.TITLE')} />
        <Card.Body>
          <Card.Title className={'mb-0 pl-xl-3'}>
            <Row>
              <Col lg={8}>
                <Row className="">
                  <Col sm={12} md={4} xl={4} className="pt-2">
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('PRODUCT.TEXT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Col>
                  <Col sm={12} md={4} xl={4} className="pl-md-0 pt-2">
                    <Box className='position-relative' sx={{ '.MuiOutlinedInput-input': { paddingLeft: '70px !important' } }}>
                      <Typography className='position-absolute top-50 translate-middle-y' sx={{ left: '12px', zIndex: 1 }}>{t('PRODUCT.TEXT.TYPE')}:</Typography>
                      <AutocompleteSelect
                        labelId="medicineType"
                        noOptionsText={t('NOT_FOUND')}
                        options={productTypes}
                        getOptionLabel={(option: any) => option.productTypeName || t('ALL')}
                        renderOption={(props, option: any) => (
                          <Box component="li" {...props}>
                            {option.productTypeName}
                          </Box>
                        )}
                        onchange={(e, value: any) => {
                          setProductType(value)
                          setPage(1)
                        }}
                        inputProps={{
                          onChange: (e: any) => {
                            setProductTypes([])
                            handleInputSearch(e.target.value)
                          }
                        }}
                        onOpen={(e: any) => loadProductTypes()}
                        value={!_.isEmpty(productType) ? productType : ''}
                        loading={prodTypeLoading}
                        height={32}
                        disabledBorder
                        disableClearable={_.isEmpty(productType)}
                      />
                    </Box>
                  </Col>
                  <Col sm={12} md={4} xl={4} className="pl-md-0 pt-2">
                    <Box className='position-relative' sx={{ '.MuiOutlinedInput-input': { paddingLeft: '70px !important' } }}>
                      <Typography className='position-absolute top-50 translate-middle-y' sx={{ left: '12px', zIndex: 1 }}>{t('PRODUCT.TEXT.STATUS')}:</Typography>
                      <AutocompleteSelect
                        labelId="statusSearch"
                        noOptionsText={t('NOT_FOUND')}
                        options={[
                          { status: 'ALL', name: t("ALL") },
                          { status: 'ACTIVE', name: t("PRODUCT.FILTER.ACTIVE") },
                          { status: 'INACTIVE', name: t("PRODUCT.FILTER.INACTIVE") }
                        ]}
                        getOptionLabel={(option: any) => option?.name || ''}
                        renderOption={(props, option: any) => (
                          <Box component="li" {...props}>
                            {t(`PRODUCT.TEXT.STATUS_${option.status}`)}
                          </Box>
                        )}
                        onchange={(e, value: any) => {
                          setPage(1)
                          setStatusSearch(value)
                        }}
                        onOpen={(e: any) => loadProductTypes()}
                        value={!_.isEmpty(statusSearch) ? statusSearch : ''}
                        height={32}
                        disabledBorder
                        disableClearable={_.isEmpty(statusSearch)}
                      />
                    </Box>
                  </Col>
                </Row>
              </Col>
              <Col lg={4} className="mt-2 mt-lg-auto">
                <div className='d-flex flex-column flex-sm-row align-items-end align-items-sm-center justify-content-end pr-2'>
                  <div className='mb-3 mb-sm-0 pr-sm-3'>
                    <ButtonExport headCells={headCells} portrait={false} filter={filter} fileName={t('PRODUCT.BUTTON.EXPORT_FILENAME')}
                      rowsData={exportData.map((val, i) => {
                        return renderData(val, i, true)
                      })} />
                  </div>
                  <ButtonCustom mode="add" disabled={permissions.isCreate.disabled} onClick={() => onCreate()} textButton={t('PRODUCT.BUTTON.ADD_PRODUCT')} className="w-auto ml-auto d-flex mt-auto" style={{ margin: 0 }} />
                </div>
              </Col>
            </Row>
          </Card.Title>

          <div className={'mt-3 pl-xl-3 pr-xl-2'}>
            <CustomTable>
              <TableCustom
                page={page}
                pageLimit={pageLimit}
                sortType={sortType}
                sortBy={sortBy}
                onSort={onRequestSort}
                setPageLimit={handleChangeRowsPerPage}
                setPage={handleChangePage}
                rowCount={rowCount}
                headCells={headCells}
                rowsData={products.map((val, i) => {
                  return renderData(val, i, false)
                })}
              />
            </CustomTable>

          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
