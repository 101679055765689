import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Typography } from '@mui/material';
import { ReportsProps, ExportProps } from "features/report/report-constants";
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'


export const dfLabConfig: ReportsProps = {
    key: 'DF_LAB',
    textName: 'DF_LAB',
    portrait: false,
    renderDataCustom: false,
    filter: {
        branch: true,
        date: true,
        rangeDate: false,
        prefix: false,
        receiptStatus: false,
        operativeType: false,
        billStatus: false,
        operativeCode: false,
        doctor: true,
        patient: false,
    },
    export: {
        pdf: true,
        excel: true
    }
}

export interface FcComponent {
    page: number
    pageLimit: number
    rowCount: number
    sortBy: string
    sortType: string
    data: any
    onRequestSort: (sortBy: string, sortType: string) => void
    handleChangeRowsPerPage: (val: any) => void
    handleChangePage: (val: any) => void
    componentRef: any
    date: any
    filterTable: any
    dispay: any
}

export default function DfLabList(props: FcComponent) {
    const { t } = useTranslation()
    const convertFormatDate = (formatDate: any) => {
        const yearBE = moment(formatDate).year() + isBuddhistYear()
        const formattedDate = moment(formatDate).format("DD/MM");
        const result = formattedDate + "/" + yearBE
        return result
    }
    const convetFormatDate = convertFormatDate(props.date)

    const renderDfTable = (data: any): any => {
        const items = !_.isEmpty(data) ? _.chain([...data.rowDetail, ...data.rowDf,])
            .groupBy('doctorId')
            .map((value: any, key: string) => {
                const doctorDetail = _.find(props.data?.rowDetail, { doctorId: Number(key) || value[0]?.doctorId })
                return {
                    doctor: {
                        fullName: _.get(doctorDetail, 'doctorFullname') || '',
                        timeStart: _.get(doctorDetail, 'timeStart') || '',
                        timeEnd: _.get(doctorDetail, 'timeEnd') || '',
                        countAppointment: _.get(doctorDetail, 'countAppoitment') || '',
                        countTreatment: _.get(doctorDetail, 'countTreatment') || '',
                        handInsurance: _.get(doctorDetail, 'handInsurance') || '',
                    },
                    df: _.filter(value, (val) => { if (!_.has(val, 'lab') && _.has(val, 'operativeName')) return val })
                }
            }).value() : []
        return items
    }


    const renderDfMonthTable = (data: any): any => {
        const items = !_.isEmpty(data) ? _.chain([...data.rowDf,])
            .groupBy('doctorId')
            .map((value: any, key: string) => {
                const doctor = _.find(props.data?.rowDf, { doctorId: Number(key) })
                return {
                    doctor: {
                        fullName: _.get(doctor, 'doctorFullname') || ''
                    },
                    df: value
                }
            }).value() : []
        return items
    }

    const renderTimeWorking = (timeStart: string, timeEnd: string) => {
        return `${moment(timeStart, 'HH:mm').format('HH:mm')} - ${moment(timeEnd, 'HH:mm').format('HH:mm')} ${t('TIME_UNIT')}`
    }
    const [snType, setSnType] = useState(false);

    const handleSnCode = useCallback(() => {
        const fetchData = async () => {
            const sn = await UseSnCode();
            setSnType(sn);
        };

        fetchData();

    }, [snType]);

    useEffect(() => {
        handleSnCode()
    }, []);

    const headDfCells = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.CN', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.BRANCH_CN', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.SN_CODE', align: 'center', class: 'text-nowrap' },


        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.PATIENT_NAME', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.OPERATIVE_TYPE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.OPERATIVE_LIST', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.IS_LAB', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.TRUE_PRICE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.DF', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.DF2', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.CREDIT_NET', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.TREATMENT_NET', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.DF3', align: 'center', class: 'text-nowrap' },
    ]

    const headDfCellsMonth = [
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.DATE', align: 'left', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.TIME', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.APPOINTMENT', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.WRONG_APPOINTMENT', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.WALK_IN', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.SUM_APPOINTMENT', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.PRICE', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.DF4', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.CREDIT_NET', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.TREATMENT_NET', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.DF5', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.HAND', align: 'center', class: 'text-nowrap' },
        { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.DF.DF6', align: 'center', class: 'text-nowrap' },
    ]


    if (!snType) {
        headDfCells.splice(3, 1)
    }
    const renderDataDF = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
        if (render === 'data') {
            const { isLab, cnNumber, branchCnNumber, snCode, patientFullname, operativeTypeName, operativeName, dfNet, creditCost, total, valueDf, df, treatmentRight } = data
            const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
            const objRenderData = {
                key: num.toString(),
                id: no.toString(),
                obj: data,
                columns: [
                    { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: cnNumber || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: branchCnNumber || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: snCode || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: patientFullname || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: operativeTypeName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: operativeName || '-', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: isLab ? t('REPORT.UNIT.HAS') : t('REPORT.UNIT.NOT_HAS'), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(total || 0), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: valueDf, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(df || 0), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(creditCost || 0, 3, 3), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(treatmentRight || 0), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(dfNet || 0, 3, 3), class: 'text-nowrap' },
                ]
            }
            if (!snType) {
                objRenderData.columns.splice(3, 1)
            }
            return (
                <>
                    {exportData === true && <TableRowExport {...objRenderData} /> ||
                        <TableRowCommon  {...objRenderData} />
                    }
                </>
            )
        } else {
            const objRenderData = {
                key: no.toString(),
                id: no.toString(),
                obj: data,
                columns: [
                    { option: 'MERGE', align: 'center', colSpan: snType ? 7 : 6 },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>, class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: '', class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'df') || 0), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'creditCost') || 0, 3, 3), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'treatmentRight') || 0), class: 'text-nowrap' },
                    { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfNet') || 0, 3, 3), class: 'text-nowrap' },

                ],
                classes: 'row-summary'
            }

            return (
                <>
                    {exportData === true && <TableRowExport {...objRenderData} /> ||
                        <TableRowCommon  {...objRenderData} />
                    }
                </>
            )
        }
    }

    const renderDataDFMonth = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
        try {
            if (render === 'data') {
                const { appointments, creditCost, date, df, dfNet, handInsurance, notYet, timeEnd, timeStart, total, treatmentRight, walkIn } = data
                let workingTime = '-'

                if (timeStart && timeEnd) {
                    const timeStartSplit = timeStart.split(':')
                    const timeEndSplit = timeEnd.split(':')
                    workingTime = timeStartSplit[0] + ':' + timeStartSplit[1] + ' - ' + timeEndSplit[0] + ':' + timeEndSplit[1]
                }
                const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
                const objRenderData = {
                    key: num.toString(),
                    id: no.toString(),
                    obj: data,
                    columns: [
                        { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: moment(date).add(isBuddhistYear(), 'y').format('DD/MM/YYYY'), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: workingTime, class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: appointments || '0', class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: notYet || '0', class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: walkIn || '0', class: 'text-nowrap' },
                        { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: (walkIn || 0) + (appointments || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(total || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(df || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(creditCost || 0, 3, 3), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(treatmentRight || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(dfNet || 0, 3, 3), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(handInsurance || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: dfNet > handInsurance ? numberFormat(dfNet || 0, 3, 3) : numberFormat(handInsurance || 0, 0, 3), class: 'text-nowrap' },

                    ]
                }

                return (
                    <>
                        {exportData === true && <TableRowExport {...objRenderData} /> ||
                            <TableRowCommon  {...objRenderData} />
                        }
                    </>
                )
            } else {
                const objRenderData = {
                    key: no.toString(),
                    id: no.toString(),
                    obj: data,
                    columns: [
                        { option: 'MERGE', align: 'center', colSpan: 6 },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>, class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'df') || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'creditCost') || 0, 3, 3), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'treatmentRight') || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfNet') || 0, 3, 3), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'handInsurance') || 0), class: 'text-nowrap' },
                        { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, (o: any) => o.dfNet > o.handInsurance ? o.dfNet : o.handInsurance) || 0, 3, 3), class: 'text-nowrap' },

                    ],
                    classes: 'row-summary'
                }

                return (
                    <>
                        {exportData === true && <TableRowExport {...objRenderData} /> ||
                            <TableRowCommon  {...objRenderData} />
                        }
                    </>
                )
            }
        } catch (error) {
            return <></>
        }

    }

    return (
        <>
            {
                (!_.isEmpty(props.data.rowDetail) || !_.isEmpty(props.data.rowDf) || !_.isEmpty(props.data.rowLab)) && (
                    _.map(props.dispay === 'day' ? renderDfTable(props.data) : renderDfMonthTable(props.data), (data, index: number) => {
                        if (props.dispay === 'day' ? data.doctor?.fullName : data.df.length > 0) {
                            return (
                                <Box key={index}>
                                    {index > 0 && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
                                    <Typography className={`mb-1`} sx={{ fontWeight: 500, fontSize: '20px' }}>
                                        <span className='pr-2'>{t('REPORT.FILTER.DOCTOR')}</span>
                                        {data.doctor.fullName || '-'}
                                    </Typography>
                                    {props.dispay === 'day' && <Box className='d-sm-flex'>
                                        <Box className='d-flex'>
                                            <><Typography className='mr-1' sx={{ fontWeight: 500 }}>
                                                {t('REPORT.TITLE.DATE')}
                                            </Typography>
                                                <Typography sx={{ marginRight: "5px" }} >
                                                    {convetFormatDate}
                                                </Typography>
                                                <Typography className='mr-1' sx={{ fontWeight: 500 }}>{t('REPORT.TITLE.TME_WORK')}
                                                </Typography>
                                                <Typography>
                                                    {data.doctor.timeStart && data.doctor.timeEnd ? renderTimeWorking(data.doctor.timeStart, data.doctor.timeEnd) : '-'}</Typography></>
                                        </Box>
                                        <Box className='d-flex ml-2'>
                                            <Typography className='mr-1' sx={{ fontWeight: 500 }}>{t('REPORT.TITLE.COUNT_APPOINTMENT')}</Typography>
                                            <Typography>{data.doctor.countAppointment ? `${data.doctor.countAppointment} ${t('REPORT.TITLE.APPOINTMENT_UNIT')}` : '-'}</Typography>
                                        </Box>
                                        <Box className='d-flex ml-2'>
                                            <Typography className='mr-1' sx={{ fontWeight: 500 }}>{t('REPORT.TITLE.COUNT_TREATMENT')}</Typography>
                                            <Typography>{data.doctor.countTreatment ? `${data.doctor.countTreatment} ${t('REPORT.TITLE.PEOPLE_UNIT')}` : '-'} </Typography>
                                        </Box>
                                        <Box className='d-flex ml-2'>
                                            <Typography className='mr-1' sx={{ fontWeight: 500 }}>{t('REPORT.TITLE.COUNT_HAND_INSURANCE')}</Typography>
                                            <Typography>{data.doctor.handInsurance ? `${data.doctor.handInsurance} ${t('REPORT.TITLE.HAND_INSURANCE_UNIT')}` : '-'} </Typography>
                                        </Box>
                                    </Box>}

                                    <Box>
                                        {props.dispay === 'day' && < UseStyled.ResponsiveReportTable className='mt-2' verticalAlign='top'>
                                            <TableCustom
                                                customScroll
                                                hidePagination
                                                page={props.page}
                                                pageLimit={props.pageLimit}
                                                sortType={props.sortType}
                                                sortBy={props.sortBy}
                                                rowCount={props.rowCount}
                                                onSort={props.onRequestSort}
                                                setPageLimit={props.handleChangeRowsPerPage}
                                                setPage={props.handleChangePage}
                                                headCells={headDfCells}
                                                rowsData={
                                                    !_.isEmpty(data.df) ?
                                                        [
                                                            _.map(data.df, (df: any, indexData: number) => {
                                                                if (df?.patientFullname) return renderDataDF(df, indexData, false, 'data')
                                                            }),
                                                            renderDataDF(data.df, 0, false, 'sum')
                                                        ] : []
                                                }
                                            />
                                        </UseStyled.ResponsiveReportTable>}
                                        {props.dispay === 'month' && < UseStyled.ResponsiveReportTable className='mt-2' verticalAlign='top'>
                                            <TableCustom
                                                customScroll
                                                hidePagination
                                                page={props.page}
                                                pageLimit={props.pageLimit}
                                                sortType={props.sortType}
                                                sortBy={props.sortBy}
                                                rowCount={props.rowCount}
                                                onSort={props.onRequestSort}
                                                setPageLimit={props.handleChangeRowsPerPage}
                                                setPage={props.handleChangePage}
                                                headCells={headDfCellsMonth}
                                                rowsData={
                                                    !_.isEmpty(data.df) ?
                                                        [
                                                            _.map(data.df, (df: any, indexData: number) => {
                                                                return renderDataDFMonth(df, indexData, false, 'data')
                                                            }),
                                                            renderDataDFMonth(data.df, 0, false, 'sum')
                                                        ] : []
                                                }
                                            />
                                        </UseStyled.ResponsiveReportTable>}
                                    </Box>
                                </Box >
                            )
                        }
                    })
                ) || <EmptyData />
            }

            <div className="print-show" ref={props.componentRef}>
                <DfLabExport
                    componant={
                        <>
                            <span style={{ fontWeight: 'bold' }}>{t(`REPORT.REPORT_NAME.DF_LAB`)}</span>
                            <table>
                                <tr>
                                    {props.filterTable.map((filter: any, index: number) => (
                                        <TableSortLabel key={index} >
                                            <td className='mr-2' style={{ textAlign: 'left', fontSize: 10, fontWeight: 800 }}>{t(filter.label)} : </td>
                                            <td style={{ textAlign: 'left', fontSize: 10, fontWeight: 500 }}>{filter.value}</td>
                                        </TableSortLabel >
                                    ))}
                                </tr>
                            </table>
                            {_.map(props.dispay === 'day' ? renderDfTable(props.data) : renderDfMonthTable(props.data), (data, index: number) => {
                                if (props.dispay === 'day' ? data.doctor?.fullName : data.df.length > 0) {
                                    return (
                                        <Box key={index}>
                                            {index > 0 && <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />}
                                            <Typography className={`mb-1`} sx={{ fontWeight: 500, fontSize: 12 }}>
                                                <span className='pr-2'>{t('REPORT.FILTER.DOCTOR')}</span>
                                                {data.doctor.fullName || '-'}
                                            </Typography>

                                            {props.dispay === 'day' && <table style={{ fontWeight: 300, fontSize: 10 }}>
                                                <td className='mr-1' >{t('REPORT.TITLE.TME_WORK')}</td>
                                                <td >{data.doctor.timeStart && data.doctor.timeEnd ? renderTimeWorking(data.doctor.timeStart, data.doctor.timeEnd) : '-'}</td>

                                                <td className='mr-1' >{t('REPORT.TITLE.COUNT_APPOINTMENT')}</td>
                                                <td >{data.doctor.countAppointment ? `${data.doctor.countAppointment} ${t('REPORT.TITLE.APPOINTMENT_UNIT')}` : '-'}</td>

                                                <td className='mr-1' >{t('REPORT.TITLE.COUNT_TREATMENT')}</td>
                                                <td >{data.doctor.countTreatment ? `${data.doctor.countTreatment} ${t('REPORT.TITLE.PEOPLE_UNIT')}` : '-'} </td>

                                            </table>}

                                            <Box>
                                                <UseStyled.Styles className="pt-3">
                                                    <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                                                        {props.dispay === 'day' && <thead>
                                                            <tr>
                                                                {_.map(headDfCells, (val: any) => {
                                                                    return (
                                                                        < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        </thead>}
                                                        {props.dispay === 'month' && <thead>
                                                            <tr>
                                                                {_.map(headDfCellsMonth, (val: any) => {
                                                                    return (
                                                                        < td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        </thead>}
                                                        {props.dispay === 'day' && <tbody>
                                                            {data.df.length > 0 && [
                                                                _.map(data.df, (df: any, indexData: number) => {
                                                                    if (df?.patientFullname) return renderDataDF(df, indexData, true, 'data')
                                                                }),
                                                                renderDataDF(data.df, 0, true, 'sum')
                                                            ]
                                                            }
                                                            {!data.df.length && (
                                                                <td colSpan={headDfCells.length}>
                                                                    {t('REPORT.TITLE.NO_DATA')}
                                                                </td>
                                                            )}
                                                        </tbody>}
                                                        {props.dispay === 'month' && <tbody>
                                                            {data.df.length > 0 && [
                                                                _.map(data.df, (df: any, indexData: number) => {
                                                                    return renderDataDFMonth(df, indexData, true, 'data')
                                                                }),
                                                                renderDataDFMonth(data.df, 0, true, 'sum')
                                                            ]
                                                            }
                                                            {!data.df.length && (
                                                                <td colSpan={headDfCells.length}>
                                                                    {t('REPORT.TITLE.NO_DATA')}
                                                                </td>
                                                            )}
                                                        </tbody>}
                                                    </table>
                                                </UseStyled.Styles >
                                            </Box>
                                        </Box>

                                    )
                                }
                            })}
                        </>
                    }
                />
            </div>
        </>
    )
}

export function DfLabExport(props: ExportProps) {
    return (
        <>
            {props.componant}
        </>
    )
}
