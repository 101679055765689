import _ from 'lodash'
import { colors } from 'constants/theme'
import { useTranslation } from 'react-i18next'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material'
import TableCustom from 'component/Table/TableCustom'
import * as UseStyled from 'features/report/useStyled'
import { ExportProps } from 'features/report/report-constants'
import moment from 'moment'
import { Income } from 'features/home/UseStyled'
import { selectMe } from 'app/slice/user.slice'
import { useSelector } from 'react-redux'

interface FcComponent {
  page: number
  pageLimit: number
  rowCount: number
  sortBy: string
  sortType: string
  data: any
  onRequestSort: (sortBy: string, sortType: string) => void
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  componentRef: any
  cash: boolean
  df: boolean
  filter: any
  treatmentRight: boolean
}

export default function RemittanceList(props: FcComponent) {
  const { t } = useTranslation()
  const user = useSelector(selectMe)
  const headCells = [
    { id: 'message', disablePadding: false, width: '70%', labelKey: 'REPORT.TABLE.REMITTANCE.LIST', align: 'center', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMITTANCE.QTY', align: 'right', class: 'text-nowrap' },
    { id: 'message', disablePadding: false, labelKey: 'REPORT.TABLE.REMITTANCE.ALL_QTY', align: 'right', class: 'text-nowrap' }
  ]
  const header = [props.df ? 'DF' : null, props.cash ? t('REPORT.TABLE.REMUNERATION.CASH') : null, props.treatmentRight ? t('REPORT.TABLE.REMUNERATION.TREATMENT_RIGHT') : null].filter(Boolean).join(', ')

  const renderData = (data: any, isExport?: boolean) => {
    /**
     * ? rowExpenses รายจ่าย
     * ? rowDf รายจ่าย
     * ? rowDailyBalance รายรับ
     * ? rowIncome รายรับ
     */
    let rowIncome
    if (Array.isArray(data.rowIncome)) {
      const combinedIncome = [...data.rowIncome, ...data.rowTreatmentRight]

      // Sort the combined array based on the paymentChannelId
      combinedIncome.sort((a, b) => {
        const aChannelId = a.paymentChannelId === null ? Number.MAX_SAFE_INTEGER : a.paymentChannelId
        const bChannelId = b.paymentChannelId === null ? Number.MAX_SAFE_INTEGER : b.paymentChannelId
        return aChannelId - bChannelId
      })
      rowIncome = combinedIncome
    }

    const total = data.rowDf?.length > 0 || data.rowExpenses?.length > 0 || data.rowTreatmentRight?.length > 0 ? _.sumBy(data.rowDf, 'price') + (props.treatmentRight ? 0 : _.sumBy(data.rowExpenses, 'price')) : 0

    const sum = _.sumBy(data.rowIncome, 'amountPaidCash') - _.sumBy(data.rowExpenses, 'price')
    return (
      <>
        {data.rowDailyBalance &&
          _.map(data.rowDailyBalance, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ textAlign: 'left !important' }}>
                    {t('REPORT.TABLE.REMITTANCE.NET_TOOLS')}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
                    {numberFormat(val.sumProduct)}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
                    {'-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ textAlign: 'left !important' }}>
                    {t('REPORT.TABLE.REMITTANCE.NET_OPERATIVE')}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
                    {numberFormat(val.sumTreatment)}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
                    {'-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align={isExport ? "right" : "left"} style={{ fontWeight: "bolder" }} scope="row"  >
                    {t('REPORT.TABLE.REMITTANCE.NET_DAILY')}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
                    {numberFormat(val.sumProduct + val.sumTreatment)}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
                    {'-'}
                  </TableCell>
                </TableRow>
              </>
            )
          })}
        {data.rowIncome &&
          _.map(rowIncome, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ color: colors.blue, textAlign: 'left !important' }}>
                    {val.detail || val.treatmentRightName}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.blue, textAlign: 'right !important' }}>
                    {numberFormat(val.price)}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.blue, textAlign: 'right !important' }}>
                    {numberFormat(val.total)}
                  </TableCell>
                </TableRow>
              </>
            )
          })}
        {data.rowDf &&
          _.map(data.rowDf, (val: any, index: number) => {
            return (
              <>
                {val.detail && (
                  <TableRow>
                    <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, textAlign: 'left !important' }}>
                      {val.detail}
                    </TableCell>
                    <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, textAlign: 'right !important' }}>
                      {numberFormat(val.price)}
                    </TableCell>
                    <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, textAlign: 'right !important' }}>
                      {numberFormat(val.total)}
                    </TableCell>
                  </TableRow>
                )}
              </>
            )
          })}
        {data.rowExpenses &&
          _.map(data.rowExpenses, (val: any, index: number) => {
            return (
              <>
                <TableRow>
                  <TableCell align="left" scope="row" sx={{ color: colors.statusInactive, textAlign: 'left !important' }}>
                    {val.expenseTypeName}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, textAlign: 'right !important' }}>
                    {numberFormat(val.price)}
                  </TableCell>
                  <TableCell align="right" scope="row" sx={{ color: colors.statusInactive, textAlign: 'right !important' }}>
                    {numberFormat(val.total)}
                  </TableCell>
                </TableRow>
              </>
            )
          })}

        <TableRow>
          <TableCell align={isExport ? "right" : "left"} scope="row"  >
            {t('REPORT.TABLE.REMITTANCE.SUM_INCOME_EXPENSES')}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
            {numberFormat(sum || 0)}
          </TableCell>
          <TableCell align="right" scope="row" sx={{ textAlign: 'right !important' }}>
            -
          </TableCell>
        </TableRow>
        {/*    <TableRow>
          <TableCell align={"right"} scope="row"  >
            {t('REPORT.TABLE.REMITTANCE.CREATED_BY')}
          </TableCell>
         <TableCell align="left" scope="row" colSpan={2} sx={{ textAlign: 'left !important' }}>
            {user?.fullname || '-'}
          </TableCell> 
        </TableRow>*/}
      </>
    )
  }

  return (
    <>
      <UseStyled.ResponsiveReportTable>
        <TableCustom
          page={props.page}
          pageLimit={props.pageLimit}
          sortType={props.sortType}
          sortBy={props.sortBy}
          rowCount={props.rowCount}
          onSort={props.onRequestSort}
          setPageLimit={props.handleChangeRowsPerPage}
          setPage={props.handleChangePage}
          headCells={headCells}
          rowsData={[renderData(props.data)]}
          hidePagination={true}
          customScroll
        />
      </UseStyled.ResponsiveReportTable>
      <div className="print-show" ref={props.componentRef}>
        <RemittanceExport
          filter={props.filter}
          header={
            <div style={{ fontWeight: 'bolder', fontSize: 20 }}>
              {t('REPORT.REPORT_NAME.REMITTANCE_SECOND')}
              {/* {header ? ` ( ${header} )` : ''} */}
            </div>
          }
          headCells={headCells}
          rowsData={[renderData(props.data, true)]} />
      </div>
    </>
  )
}

export function RemittanceExport(props: ExportProps) {
  const { t } = useTranslation()


  const head = props.headCells
  const { header, filter, rowsData } = props
  const headCells = head.filter((headCell: any) => headCell.id !== 'action')
  const TITLES = [
    { key: 'BRANCH', label: 'REPORT.TITLE.BRANCH' },
    { key: 'DATE', label: 'REPORT.TITLE.DATE' }
  ]

  const renderValue = (value: any, index: any) => {
    if (index === 1) {
      return toBuddhistYear(moment(value), 'DD/MM/YY')
    }
    return value
  }

  return (
    <>
      <div className='justify-content-center align-items-center w-100 text-center'  >
        {header}
        <div className="mr-2 col " style={{ fontSize: 18, fontWeight: 'bolder' }} >
          {TITLES.map((filter, index) => (
            <>{t(filter.label)} :{' '}{renderValue(props.filter[index], index)}{' '}</>
          ))}
        </div>
      </div>
      <UseStyled.StylesBorder className="pt-3">
        <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
          <thead>
            <tr>
              {_.map(headCells, (val: any, index: number) => {
                return <td style={{ textAlign: 'center', fontSize: 16, fontWeight: 800 }}> {t(val.labelKey)}</td>
              })}
            </tr>
          </thead>
          <tbody>
            {props.rowsData}
            {!props.rowsData.length && (
              <td colSpan={headCells.length} style={{ fontSize: 10 }}>
                {t('REPORT.TITLE.NO_DATA')}
              </td>
            )}
          </tbody>
        </table>
      </UseStyled.StylesBorder>
    </>
  )
}
