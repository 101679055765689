import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'
import { dateTimeToView, dateToView, timeToView } from 'utils/date.utils'

export interface AdvanceComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
  branchName: string
  rangeDate: string[]
  billStatus: string
}

interface AdvanceDecreaseIF {
  branchName: string
  createdAt: string
  paymentNumber: string
  statusOrder: string
  branchCnNumber: string
  cnNumber: string
  snCode: string
  patientFullname: string
  stage: number
  totalPrice: number
  amountPaid: number
  amountAdvance: number
}

const statusColor: any = {
  PAID: { color: colors.appointmentStatus.statusEnter },
  CANCEL_VISIT: { color: colors.appointmentStatus.statusCancel },
  CANCEL_BILL: { color: '#F47C00' },
  OVERDUE: { color: colors.appointmentStatus.statusUnConnect }
}

export default function AdvanceDecreaseList(props: AdvanceComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap', width: '30px', style: { width: '40px' } },
    { id: '3', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.BRANCH', align: 'center', class: 'text-nowrap', width: '150px', style: { width: '80px' } },
    { id: '4', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.DATE_ADVANCE', align: 'center', class: 'text-nowrap', width: '150px', style: { width: '60px' } },
    { id: '5', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.BILL', align: 'center', class: 'text-nowrap', width: '150px', style: { width: '80px' } },
    { id: '6', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.STATUS', align: 'center', class: 'text-nowrap', width: '250px', style: { width: '40px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.PATIENT_CN_NUMBER', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '50px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.BRANCH_CN_NUMBER', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '60px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.PATIENT_SN_CODE', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '50px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.PATIENT_NAME', align: 'left', class: 'text-nowrap', width: '350px' },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.TOTAL_BILL', align: 'right', class: 'text-nowrap', width: '350px' },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.TOTAL_DF', align: 'right', class: 'text-nowrap', width: '350px' },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.TOTAL_ADVANCE', align: 'right', class: 'text-nowrap', width: '350px' }
  ]

  if (!snType) {
    headCells.splice(3, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    const fontExportData = exportData ? 8 : 16
    if (render === 'data') {
      const advance: AdvanceDecreaseIF = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.branchName, class: 'text-nowrap' },
          {
            option: 'TEXT',
            align: 'center',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData },
            label: (
              <>
                {dateToView(advance.createdAt)} <br /> {timeToView(advance.createdAt)} น.
              </>
            ),
            class: 'text-nowrap'
          },
          {
            option: 'TEXT',
            align: 'center',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData },
            label:
              advance.stage > 1 ? (
                <span>
                  {advance.paymentNumber} <br /> <span style={{ color: 'red' }}> {t('REPORT.TABLE.REMUNERATION.IS_PAY_OVERDUE')}</span>
                </span>
              ) : (
                advance.paymentNumber || '-'
              ),
            class: 'text-nowrap'
          },
          {
            option: 'TEXT',
            align: 'center',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData, ...statusColor[advance.statusOrder] },
            label: t(`REPORT.TABLE.ADVANCE.STATUS.${advance.statusOrder}`),
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.cnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.branchCnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.snCode, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.patientFullname, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(advance.totalPrice, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(advance.amountPaid, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(advance.amountAdvance, 2), class: 'text-nowrap' }
        ]
      }
      if (!snType) {
        objRenderData.columns.splice(7, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: !snType ? 7 : 8 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData },
            label: <Typography sx={{ fontWeight: 500, fontSize: fontExportData }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(_.sumBy(data, 'totalPrice') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(_.sumBy(data, 'amountPaid') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(_.sumBy(data, 'amountAdvance') || 0), class: 'text-nowrap' }
        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            // tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.dataExport, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.ADVANCE_DECREASE`)}
          branchName={props.branchName}
          rangeDate={props.rangeDate}
          billStatus={props.billStatus}
          componant={
            <Box sx={{
              'tbody tr:last-child td': {
                paddingBottom: '8px'
              }
            }}>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', borderBottom: '1px solid' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 8, fontWeight: 500, height: '36px', ...val?.style }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      _.map(props.dataExport, (d: any, index: number) => {
                        return renderData(d, index, true, 'data')
                      }),
                      renderData(props.data, 0, true, 'sum')
                    ]}
                  </tbody>
                </table>
              </UseStyled.Styles>
            </Box>
          }
        />
      </div>
    </>
  )
}

type ExportDataDecreaseListProps = {
  headCells: string
  branchName: string
  componant: any
  rangeDate: string[]
  billStatus: string
}

export function ExportData(props: ExportDataDecreaseListProps) {
  const { t } = useTranslation()

  return (
    <>
      <Box sx={{ fontSize: '14px', fontWeight: "500" }}>{props.headCells}</Box>
      <Box sx={{ display: "flex", alignItems: 'center', gap: '8px' }}>
        <Box sx={{ fontSize: '10px' }}>{t('REPORT.TABLE.DECREASE.BRANCH')}: {props.branchName}</Box>
        <Box sx={{ fontSize: '10px' }}>{t('REPORT.TABLE.DECREASE.DATE_START')}: {toBuddhistYear(moment(props.rangeDate[0]))}</Box>
        {props.rangeDate[1] !== 'Invalid date' && <Box sx={{ fontSize: '10px' }}>{t('REPORT.TABLE.DECREASE.DATE_END')}: {toBuddhistYear(moment(props.rangeDate[1]))}</Box> || <></>}
        <Box sx={{ fontSize: '10px' }}>{t('REPORT.TABLE.DECREASE.STATUS')}: {`${props.billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${props.billStatus}`)}`}</Box>
      </Box>

      {props.componant}
    </>
  )
}
