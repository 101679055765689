import { menuSeconds } from "constants/menus";
import CheckIn from "features/check-in/check-in";
import { routeName } from "routes/routes-name";

const routeCheckIn = [
    {
        ...menuSeconds.CHECKIN,
        active: false,
        appBar: false,
        fullWidth: true,
        component: CheckIn
    }
]

export default routeCheckIn