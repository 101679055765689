
import { Box, TableCell, TableRow, IconButton } from '@mui/material'
import ClinicApi, { BranchInterface } from 'api/master/clinic.api'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputTextField from 'component/Input/InputTextField'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import { colors } from 'constants/theme'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { icons } from 'constants/images'
import { ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import InputCheckbox from 'component/Input/InputCheckbox'
import { forEach } from 'lodash'
import { numberOnly } from 'utils/app.utils'
import { useSelector } from 'react-redux'
import { showModal } from 'app/slice/modal.slice'
import { notiError, notiSuccess } from 'component/notifications/notifications'

type FormOpetaivesBranchProps = {
    onCloseModalManageBranch: () => void
    manageBranch: any
    handleChangeManageBranch: (value: dataManageProps) => void
    handleChangeOperativePrice: (event: any) => void
    operativeSalePrice: string
    errorMessage: any
    setErrorMessage: (value: any) => void
}

export type dataManageProps = {
    branches: dataManageBranchesProps[] | []
    defaultPrice: string | number | undefined
}

export type dataManageBranchesProps = {
    branchId: number[]
    price: string | number
}

export const initDataManage = {
    branches: [],
    defaultPrice: ''
}

const FormOpetaivesBranch = (props: FormOpetaivesBranchProps) => {

    const { onCloseModalManageBranch, manageBranch, handleChangeManageBranch, operativeSalePrice, handleChangeOperativePrice, errorMessage, setErrorMessage } = props

    const { t } = useTranslation()
    const modal = useSelector(showModal)

    const [branches, setBranches] = useState<BranchInterface[]>([])
    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [sortType, setSortType] = useState<string>('ASC')

    const [dataManage, setDataManage] = useState<dataManageProps>(initDataManage)
    const [selectBranch, setSelectBranch] = useState<number[]>([])
    const [errorDataManage, setErrorDataManage] = useState<boolean>(false)

    const loadData = () => {
        // ClinicApi.findAllBranches().then(({ data }) => setBranches(data))
        ClinicApi.findAllActiveBranches().then(({ data }) => data && setBranches(data))
    }

    useEffect(() => {
        loadData()
    }, [])

    const onChangeManageBranch = (value: dataManageBranchesProps | undefined, index: number, nameElement: string) => {
        if (nameElement === 'branches' && value) {
            const temp = [...dataManage?.branches]
            temp[index] = value
            setDataManage({ ...dataManage, [nameElement]: temp })
        }
    }

    const onDelManageBranch = (index: number) => {
        const newData = dataManage?.branches?.filter((item: any, idx: number) => index !== idx)
        setDataManage({ ...dataManage, ['branches']: newData })
    }

    const headCells = [
        { id: 'branch', disablePadding: false, label: t('OPERATIVE.BRANCH_PRICE.BRANCH'), class: 'text-nowrap', width: '40%' },
        { id: 'price', disablePadding: false, label: t('OPERATIVE.BRANCH_PRICE.PRICE_BATH'), class: 'text-nowrap', width: '40%', align: 'center' },
        { id: 'action', disablePadding: false, label: t('Action'), class: 'text-nowrap', width: '10%' },
    ]

    const onCheckBranchId = (branch: BranchInterface) => {
        const check = selectBranch.some((item: number) => item === branch.branchId)
        return check
    }

    const onChangeBranch = (item: any, index: number, branch: BranchInterface | "ALL") => {
        setErrorDataManage(false)
        if (branch === "ALL") {
            let uniqueArray: number[] = []
            branches.forEach((item) => {
                if (!selectBranch.includes(item.branchId)) {
                    uniqueArray = [...uniqueArray, item.branchId]
                }
            })
            const newValue = { ...item, branchId: uniqueArray }
            onChangeManageBranch(newValue, index, 'branches')
            return
        }

        let uniqueArray: number[] = []
        if (item?.branchId) {
            if (item?.branchId.includes(branch.branchId)) {
                uniqueArray = item.branchId.filter((item: number) => item !== branch.branchId)
            } else {
                uniqueArray = [...item?.branchId, branch.branchId]
            }
        } else {
            uniqueArray = [branch.branchId]
        }

        const newValue = { ...item, branchId: uniqueArray }
        onChangeManageBranch(newValue, index, 'branches')
    }

    const onCheckDisabled = (item: any, branch: BranchInterface) => {
        const check = selectBranch.includes(branch.branchId)
        const itemCheck = item?.branchId ? !item?.branchId.includes(branch.branchId) : true
        return check && itemCheck
    }
    const renderData = () => {

        return dataManage?.branches?.map((item: any, index: number) => {
            return (
                <TableRowCommon
                    key={String(index + 1)}
                    id={String(index + 1)}
                    obj={dataManage}
                    columns={[
                        {
                            option: 'COMPONENT',
                            component: (
                                <Box sx={{ '.MuiAutocomplete-popper': { transform: `unset !important`, inset: `unset !important` } }} >

                                    <AutocompleteSelect
                                        labelId="branches"
                                        key={`branches-${index}`}
                                        inputLabel={t('OPERATIVE.BRANCH_PRICE.BRANCH')}
                                        options={['ALL', ...branches]}
                                        getOptionLabel={(option: any) => `${option?.branchId || ''}`}
                                        noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                        renderOption={(props, option) => {
                                            if (option === 'ALL') {
                                                return (
                                                    <ListFilterDoctors
                                                        {...props}
                                                        key={option}
                                                        component="li"
                                                        value={option}
                                                        onClick={() => onChangeBranch(item, index, option)}
                                                    >
                                                        <InputCheckbox
                                                            label={t('ALL')}
                                                            checked={selectBranch?.length === branches?.length}
                                                            indeterminate={selectBranch?.length >= 1 && selectBranch?.length !== branches?.length}
                                                            className={'pe-none'}
                                                        />
                                                    </ListFilterDoctors>
                                                )
                                            } else {
                                                return (
                                                    <ListFilterDoctors
                                                        {...props}
                                                        key={option.dentistId}
                                                        component="li"
                                                        value={option.dentistId}
                                                        onClick={() => !onCheckDisabled(item, option) && onChangeBranch(item, index, option)}
                                                    >
                                                        <InputCheckbox
                                                            label={option.branchName}
                                                            checked={onCheckBranchId(option)}
                                                            className={'pe-none'}
                                                            disabled={onCheckDisabled(item, option)}
                                                        />
                                                    </ListFilterDoctors>
                                                )
                                            }
                                        }}
                                        renderTags={(tagValue, getTagProps) => {
                                            return (
                                                <>
                                                    <Box className='d-flex flex-wrap'>
                                                        {
                                                            tagValue.map((option: any, index: number) => {
                                                                return (
                                                                    <Box key={index}>
                                                                        {branches.find((item) => option === item?.branchId)?.branchName || ''}{(item?.branchId?.length || 1) - 1 !== index && ', '}
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </>
                                            )
                                        }}
                                        filterOptions={(option) => 'ALL' + option?.branchName || ''}
                                        value={item?.branchId || []}
                                        required
                                        multiple
                                        disableClearable
                                        errMessage={errorDataManage && !item?.branchId?.length ? t('OPERATIVE.BRANCH_PRICE.ERROR.BRANCH') : ''}
                                    />

                                </Box>
                            )
                        },
                        {
                            option: 'COMPONENT',
                            component: (
                                <Box sx={{ textAlign: 'center', '.MuiFormControl-root': { maxWidth: '160px' } }}>
                                    <InputTextField
                                        key={`branch-price`}
                                        onchange={(event) => {
                                            const value = event.target.value
                                            if (event.target.value === '') {
                                                const newValue = { ...item, price: 0 }
                                                onChangeManageBranch(newValue, index, 'branches')
                                                return
                                            }
                                            if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                                const newValue = { ...item, price: value }
                                                onChangeManageBranch(newValue, index, 'branches')
                                            }
                                        }}
                                        label={t('')}
                                        value={item?.price || ''}
                                        required
                                        disabled={!item?.branchId}
                                    />
                                </Box>
                            )
                        },
                        {
                            option: 'COMPONENT',
                            component: (
                                <Box sx={{ textAlign: 'center' }}>
                                    <IconButton onClick={() => onDelManageBranch(index)}>
                                        <img src={icons.btnTrash} alt="trash" />
                                    </IconButton>
                                </Box>
                            )
                        }
                    ]}
                />
            )
        }) || []
    }

    const handleAddManageBranch = () => {
        if (dataManage?.branches?.length >= branches?.length) {
            return
        }

        const dataBranch: dataManageBranchesProps[] = dataManage?.branches ? [...dataManage.branches, { branchId: [], price: '' }] : []
        const newData: dataManageProps = { ...dataManage, branches: dataBranch }
        setDataManage(newData)
    }

    const renderAddData = () => {
        return [
            <TableRow key={String(0)}>
                <TableCell colSpan={3}>
                    <Box sx={{ display: 'flex', alignItems: 'center', }} >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: dataManage?.branches?.length >= branches?.length ? colors.textExtraLightGray : colors.themeSecondColor,
                            }}
                            onClick={() => handleAddManageBranch()}
                        >
                            <Box sx={{
                                fontSize: '24px',
                                margin: '-8px 8px 0 0'
                            }} >+</Box>
                            <Box>
                                {t('OPERATIVE.BRANCH_PRICE.ADD_BRANCH')}
                            </Box>
                        </Box>
                    </Box>
                </TableCell>
            </TableRow>
        ]
    }

    useEffect(() => {
        setDataManage(manageBranch)
    }, [modal, manageBranch])

    useEffect(() => {
        let newValueBranchId: number[] = []
        dataManage?.branches?.forEach((item: any) => {
            if (item?.branchId) {
                newValueBranchId = [...newValueBranchId, ...item?.branchId]
            }
        })
        if (newValueBranchId) {
            setSelectBranch([...newValueBranchId])
        }
    }, [dataManage])

    const onUpdatePriceBranch = () => {
        if (!dataManage?.branches?.length || !dataManage?.defaultPrice) {
            return
        }

        const newDataBranch = dataManage.branches.map((item: any, index: number) => {
            if (item?.branchId) {
                return { ...item, price: dataManage.defaultPrice }
            } else {
                return item
            }
        })
        setDataManage({ ...dataManage, branches: newDataBranch })
    }

    const onSubmit = () => {

        const check = dataManage?.branches?.some((item) => !item?.price || item?.price === "")
        if (check || operativeSalePrice === '') {
            if (check) {
                setErrorDataManage(true)
                notiError(t('OPERATIVE.BRANCH_PRICE.ERROR.PRICE_BATH'))
            }
            if (operativeSalePrice === '') {
                setErrorMessage({
                    ...errorMessage,
                    ...{
                        EMPTY_OPERATIVE_SALE: !operativeSalePrice ? t('OPERATIVE.MESSAGE.OPERATIVE_SALE_PRICE') : ''
                    }
                })
            }
            return
        }
        setErrorDataManage(false)
        notiSuccess(t('MODAL_TREATMENT_CONSENT.MESSAGE.SUCCESS.SAVE'))
        handleChangeManageBranch(dataManage)
        onCloseModalManageBranch()
    }

    return (
        <>
            <ModalCustom
                size={'xl'}
                title={t('OPERATIVE.BRANCH_PRICE.TITLE')}
                closeButton
                onClose={() => {
                    onCloseModalManageBranch()
                    setErrorDataManage(false)
                    setDataManage(initDataManage)
                    setSelectBranch([])
                }}
                alignFooter={'end'}
                textBtnConfirm={t('OPERATIVE.BUTTON.SAVE')}
                onSubmit={() => onSubmit()}
                component={
                    <>
                        <Box sx={{ padding: '24px 0 32px 0' }}>
                            <Box sx={{ marginBottom: '24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px', }}>
                                    <Box>
                                        {t('OPERATIVE.BRANCH_PRICE.DEFAULT_PRICE')}
                                    </Box>
                                    <Box>
                                        <InputTextField
                                            onchange={(event) => {
                                                const value = event.target.value
                                                let inputValue: string | undefined = numberOnly(event)
                                                if (event.target.value === '') {
                                                    inputValue = undefined

                                                }
                                                if (/^\d*$/.test(value) && (value.length === 0 || value[0] !== '0')) {
                                                    inputValue = value
                                                }
                                                const newValue: dataManageProps = { ...dataManage, ['defaultPrice']: inputValue }
                                                setDataManage(newValue)

                                            }}
                                            label={t('')}
                                            value={dataManage?.defaultPrice || ''}
                                            required
                                        // helperText={productsError && !product?.qty ? t('กรุณากรอก จำนวน') : ''}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonCustom
                                            textButton={t('OPERATIVE.BRANCH_PRICE.UPDATE_DATA')}
                                            onClick={() => onUpdatePriceBranch()}
                                        />
                                    </Box>
                                </Box>
                                <Box>
                                    <InputTextField
                                        key="operativeSalePrice"
                                        onchange={(event) => { handleChangeOperativePrice(event) }}
                                        value={operativeSalePrice}
                                        helperText={errorMessage.OPERATIVE_SALE_PRICE || errorMessage.SALE_PRICE_ANY_REQUIRED || errorMessage.SALE_PRICE_NUMBER || errorMessage.EMPTY_OPERATIVE_SALE}
                                        label={t('OPERATIVE.INPUT.OPERATIVE_PRICE_DEFAULT')}
                                        required={true}
                                    />
                                </Box>

                            </Box>
                            <Box>
                                <TableCustom
                                    //  tableMinWidth={1536}
                                    page={page}
                                    pageLimit={pageLimit}
                                    sortType={'ASC'}
                                    sortBy={'id'}
                                    onSort={() => setSortType('ASC')}
                                    setPageLimit={() => setPage(1)}
                                    setPage={() => setPageLimit(10)}
                                    rowCount={10}
                                    headCells={headCells}
                                    rowsData={[...renderAddData(), ...renderData()]}
                                />
                            </Box>
                        </Box>
                    </>
                }
            />
        </>

    )
}

export default FormOpetaivesBranch