import { useEffect, useState } from 'react';
import { Typography, Grid } from '@mui/material';
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import InputCheckbox from 'component/Input/InputCheckbox'

/** UTILS */
import { numberFormat, numberToCurrencyFormat } from 'utils/app.utils';
import useWindowSize from 'utils/useWindowSize'

/** STYLE */
import { styled } from '@mui/material/styles'
import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'
import { useDispatch, useSelector } from 'react-redux'
import {
  setPaymentChannelByKey,
  setSummeryByKey,
  setSummerySubByKey,
  setSummerySubTreatmentRightByKey,
  summery,
  summerySub,
  summerySubTreatmentRight,
  summeryTreatmentRight,
} from 'app/slice/payment.slice';
import { swalWarning } from 'component/Alert/Swal'

export const CustomInputCheckbox = styled(InputCheckbox)(({ theme }) => ({
  marginRight: 0,
  alignItems: 'flex-start',
  paddingTop: 9,
  '.MuiCheckbox-root': {
    marginTop: -7,
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    '.MuiCheckbox-root': {
      flex: 1
    },
    '.MuiFormControlLabel-label': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',

    }
  },
}))

const initErrMsg = { OVER_MAX_PAID: '', OVER_BALANCE_PAID: '', }

type TreatmentListType = {
  treatments?: any
  readOnly?: boolean
  paymentChannelId: number
  patientTreatmentRightId?: number
  treatmentRight?: any
}

export default function TreatmentList(props: TreatmentListType) {
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const dispatch = useDispatch()
  const valSummery = useSelector(summery)
  const valSummerySub = useSelector(summerySub)
  const valSummeryTreatmentRight = useSelector(summeryTreatmentRight)
  const valSummerySubTreatmentRight = useSelector(summerySubTreatmentRight)
  const [errMsg, setErrMsg] = useState({ ...initErrMsg })
  const clearErrMsg = () => setErrMsg({ ...initErrMsg })
  const [sum, setSum] = useState(0)


  const handleValOther = (id: number, newIndex: number) => {
    const valSummery = Object.values(valSummerySub).map((item: any, index: number) => {
      let total = 0
      if (newIndex !== index) {
        item.map((item_2: any) => {
          if (item_2.id === id) {
            return total += item_2.value
          }
        })
      }
      return total
    })
    return valSummery.reduce((acc: any, curr: any) => acc + curr, 0)
  }

  const handleCalculate = (price: number, valueCalculate: any, index: number, balance: number) => {
    clearErrMsg()
    if (valueCalculate === '') valueCalculate = 0
    const foreignKey = props.paymentChannelId
    const patientTreatmentRightId = props?.patientTreatmentRightId
    if (patientTreatmentRightId) {
      if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][index].paidBalance <= 0) return

      const treatmentRight = props.treatmentRight
      let res = valSummeryTreatmentRight[foreignKey]
      if (res) res = res[patientTreatmentRightId]
      if (res.isCheck) {
        if (treatmentRight.isDivide === '1') {
          let sumTreatmentRight = _.sumBy(valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId].filter((treatmentRightByKey: any, i: number) => i !== index), 'value')
          sumTreatmentRight = sumTreatmentRight + parseFloat(valueCalculate)
          if (sumTreatmentRight > treatmentRight.balance || valueCalculate > treatmentRight.balance) return swalWarning(`ยอดเงินการใช้สิทธิ์ ${treatmentRight.treatmentRightName} ต้องไม่เกิน ${treatmentRight.balance} บาท`)
        }
      } else {
        valueCalculate = 0
      }

      const sub = valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][index]
      const selectSumTrSub: any[] = valSummerySubTreatmentRight[props.paymentChannelId][patientTreatmentRightId]

      const valOther = handleValOther(sub.id, props.paymentChannelId)
      const valLimit = sub.treatmentPrice - valOther


      dispatch(setSummerySubTreatmentRightByKey({
        key: foreignKey,
        keySub: index,
        patientTreatmentRightId: patientTreatmentRightId,
        value: {
          ...sub,
          value: parseFloat(valueCalculate) > valLimit ? valLimit : parseFloat(valueCalculate)
        }
      }))
    } else {


      const subChannel = valSummerySub[props.paymentChannelId]
      const sub = valSummerySub[props.paymentChannelId][index]

      if (props.paymentChannelId === 6) {
        dispatch(setPaymentChannelByKey({ key: 6, value: valueCalculate > 0 }))
      } else if (props.paymentChannelId === 1) {
        const currentVal = parseFloat(valueCalculate) > balance ? balance : parseFloat(valueCalculate)
        const filterCurrentVal = _.filter(valSummerySub[props.paymentChannelId], (sum, indexSum: number) => {
          if (indexSum !== index) return sum
        })
        const sumVal = _.sumBy(filterCurrentVal, 'value')
        dispatch(setSummeryByKey({ key: 1, value: sumVal + currentVal }))
      }


      valueCalculate = parseFloat(valueCalculate) > balance ? balance : parseFloat(valueCalculate)
      let sumPaid = _.sumBy(subChannel.filter((sc: any, i: number) => i !== index), 'value')
      sumPaid = sumPaid + valueCalculate
      dispatch(setSummeryByKey({ key: props.paymentChannelId, value: sumPaid }))
      dispatch(setSummerySubByKey({ key: props.paymentChannelId, keySub: index, value: { ...sub, value: valueCalculate } }))
    }

  }

  const handleCheckBox = (sourceKey: number) => {
    const foreignKey = props.paymentChannelId
    const patientTreatmentRightId = props?.patientTreatmentRightId
    if (patientTreatmentRightId) {
      const sub = valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]
      const isCheck = sub.isCheck ? false : true
      let disabled = sub.isDisabled
      const treatmentRight = props.treatmentRight
      let valueCalculate = 0
      if (treatmentRight.isDivide === '1') {
        if (isCheck) {
          disabled = false
        } else {
          disabled = true
        }
      } else {
        disabled = true
        if (isCheck) {
          const checkChecked = valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId].filter((patineTreatmentRightByKey: any, i: number) => i !== sourceKey && patineTreatmentRightByKey.isCheck)
          const channel: any = valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]

          const price = _.sumBy(channel, 'value')
          const sumPaidBalance = _.sumBy(channel, 'paidBalance')
          const paidBalance: any = valSummerySub[foreignKey][sourceKey].paidBalance

          if (checkChecked.length > 0 && price >= treatmentRight.price) return true

          if (treatmentRight.unit === "BAHT") {

            if (paidBalance > (treatmentRight.price - price)) {

              if (price > 0) valueCalculate = treatmentRight.price - price
              else valueCalculate = treatmentRight.price
            } else
              valueCalculate = paidBalance
          } else {
            valueCalculate = paidBalance * (treatmentRight.price / 100)
          }

        } else {
          disabled = true
        }
      }

      dispatch(setSummerySubTreatmentRightByKey({ key: foreignKey, keySub: sourceKey, patientTreatmentRightId: patientTreatmentRightId, value: { ...sub, isCheck: isCheck, value: valueCalculate, isDisabled: disabled } }))
    } else {
      const sub = valSummerySub[foreignKey][sourceKey]
      if (foreignKey === 6) {
        dispatch(setPaymentChannelByKey({ key: 6, value: sub.isCheck ? false : true }))
      }

      const sumPaid = valSummery[foreignKey] - (sub?.value || 0)
      dispatch(setSummeryByKey({ key: props.paymentChannelId, value: sumPaid }))

      dispatch(setSummerySubByKey({ key: foreignKey, keySub: sourceKey, value: { isCheck: sub.isCheck ? false : true, value: 0, treatmentPrice: sub.treatmentPrice, paidBalance: sub.paidBalance || 0, type: sub.type, id: sub.id, isDisabled: sub.isDisabled ? false : true } }))
    }
  }

  const isChecked = (sourceKey: number, sub?: any) => {
    const foreignKey = props.paymentChannelId
    const patientTreatmentRightId = props?.patientTreatmentRightId
    if (patientTreatmentRightId) {
      if (valSummerySubTreatmentRight[foreignKey]) {
        if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]) {
          if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]) {
            return valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]?.isCheck
          }
        }
      } return false
    } else {
      if (valSummerySub[foreignKey])
        if (valSummerySub[foreignKey][sourceKey]) return valSummerySub[foreignKey][sourceKey]?.isCheck || false
        else return false
    }
  }

  const isDisabled = (sourceKey: number) => {
    const foreignKey = props.paymentChannelId
    const patientTreatmentRightId = props?.patientTreatmentRightId
    if (patientTreatmentRightId) {
      if (valSummerySubTreatmentRight[foreignKey]) {
        if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]) {
          if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]) {
            return valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]?.isDisabled
          }
        }
      } return true
    } else {
      if (valSummerySub[foreignKey])
        if (valSummerySub[foreignKey][sourceKey]) return valSummerySub[foreignKey][sourceKey]?.isDisabled
        else return true
    }
  }

  const value = (sourceKey: number, sub?: any) => {
    const foreignKey = props.paymentChannelId
    const patientTreatmentRightId = props?.patientTreatmentRightId
    if (patientTreatmentRightId) {
      if (valSummerySubTreatmentRight[foreignKey]) {
        if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]) {
          if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]) {
            const dataChanelFive = valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]
            const [newValue] = dataChanelFive?.filter((item: any, index: number) => {
              if (sub.id === item.id) {
                return item
              }
            })
            return newValue?.value || 0
          }
        }
      } return 0
    } else {
      if (valSummerySub[foreignKey])
        if (valSummerySub[foreignKey][sourceKey]) {
          return valSummerySub[foreignKey][sourceKey]?.value || 0
        }
      return 0
    }
  }

  const totalPrice = (sourceKey: number) => {
    const foreignKey = props.paymentChannelId
    const patientTreatmentRightId = props?.patientTreatmentRightId
    if (patientTreatmentRightId) {
      if (valSummerySubTreatmentRight[foreignKey]) {
        if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId]) {
          if (valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]) {

            return valSummerySubTreatmentRight[foreignKey][patientTreatmentRightId][sourceKey]?.treatmentPrice
          }
        }
      } return 0
    } else {
      if (valSummerySub[foreignKey])
        if (valSummerySub[foreignKey][sourceKey]) {

          return valSummerySub[foreignKey][sourceKey]?.treatmentPrice
        }
        else return 0
    }
  }


  const checkStatus = () => {
    const newTreatmentRight = valSummerySubTreatmentRight[props.paymentChannelId]
    let status = false
    let sum = 0
    _.map(newTreatmentRight, (item: any, index: number) => {
      status = item.isCheck === true
      sum += _.sumBy(item, 'paidBalance')
    })
    if (status) return status
    else return false
  }

  return (
    <>
      <Grid container spacing={2} className={'my-0'}>
        <Grid item sm={5} sx={{ flex: 1 }}>
          <Typography sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TABLE.TREATMENT')}</Typography>
        </Grid>
        <Grid item sm={3} className={`d-none d-md-block`}>
          <Typography className='text-center' sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TABLE.BALANCE')}</Typography>
        </Grid>
        <Grid item sm={4} className={'pr-2'} sx={{ minWidth: '100px' }}>
          <Typography className={`${width < 768 ? 'text-right' : 'text-center'}`} sx={{ fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.TABLE.PRICE')}</Typography>
        </Grid>
      </Grid>
      {_.map(props.treatments?.order ? [...(props.treatments.order?.orderTreatments || []), ...(props.treatments.order?.orderProducts || [])] : props.treatments.summeryTreatments, (treatment, index) => {
        const sub: any = valSummerySub[props.paymentChannelId] ? valSummerySub[props.paymentChannelId][index] : null
        const sumPaidBalance = _.sumBy(valSummerySub[props.paymentChannelId], 'paidBalance')
        const sumTreatmentPrice = _.sumBy(valSummerySub[props.paymentChannelId], 'treatmentPrice')
        const newPaidBalance = sub?.paidBalance !== undefined ? sub?.paidBalance + (sub?.paymentOut || 0) : totalPrice(parseInt(index)) + (sub?.paymentOut || 0)
        return (
          <Grid container spacing={2} className={'my-0'}>
            <Grid item sm={5} sx={{ flex: 1 }} className={'overflow-hidden'}>
              {!props.readOnly && (
                <CustomInputCheckbox
                  label={`${width < 768 ? `[${'01-01'}]` : ''} ${treatment.operativeName || treatment.itemName} (${numberToCurrencyFormat(treatment.total)})`}
                  checked={isChecked(parseInt(index), sub)}
                  onChange={(e) => { handleCheckBox(parseInt(index)) }}
                  disabled={sumPaidBalance === 0 && sumTreatmentPrice !== 0}
                />
              ) || (
                  <Typography>{width < 768 ? `01-01 ${treatment.operativeName || treatment.itemName}` : treatment.operativeName || treatment.itemName}</Typography>
                )}
              <Typography className='d-md-none' sx={{ margin: '-8px 0 0 28px' }}>({numberFormat(totalPrice(parseInt(index)))})</Typography>
            </Grid>
            <Grid item sm={3} className={`d-none d-md-block`}>
              <Typography className='text-center' sx={{ paddingTop: !props.readOnly ? '10px' : 0 }}>{numberFormat(newPaidBalance)} {t('CURRENCY_CHANGE.TH')}</Typography>
            </Grid>
            <Grid item sm={4} className={`${!props.readOnly ? 'pr-2' : ''}`} sx={{ minWidth: '100px' }}>
              <UseStyled.CustomField className='justify-content-end'>
                {!props.readOnly && (
                  <>
                    <InputTextField
                      disabled={Number(totalPrice(parseInt(index))) === 0 || isDisabled(parseInt(index))}
                      value={value(parseInt(index), sub)}
                      type='number'
                      financeText
                      hideControl
                      onchange={(e) => {
                        handleCalculate(sub?.treatmentPrice, Number(String(e.target.value).replace(/[^0-9.]/g, '')), parseInt(index), sub?.paidBalance !== undefined ? Number(sub?.paidBalance + sub?.value) : Number(totalPrice(parseInt(index))))
                      }}
                      helperText={errMsg.OVER_MAX_PAID || ''}
                      inputTextAlign='right'
                    />
                    <Typography className='d-none d-md-block align-self-center ml-2'>{t('CURRENCY_CHANGE.TH')}</Typography>
                  </>
                ) || (
                    <Typography>{numberFormat(treatment.total)} {t('CURRENCY_CHANGE.TH')}</Typography>
                  )}
              </UseStyled.CustomField>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}
