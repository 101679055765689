import { Box } from '@mui/material'
import { branchInfo } from 'app/slice/user.slice'
import ButtonCustom from 'component/Button/ButtonCustom'
import QRCode from 'qrcode.react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getClinicInfo } from 'utils/app.utils'
import { BoxQrCode } from './style'
import ServicePointApi from 'api/setting/branch-service-points/service-point.api'

export type branchInfoProps = {
    branchId: string
    branchLogo: string
    clinicName: string
    clinicNameEn: string
    branchName: string
    branchNameEn: string
    [key: string]: string;
    servicePointId: string
    servicePointName: string
    servicePointNameEn: string

}

const FormQrCode = () => {

    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()
    const branchesInfo = getClinicInfo()

    const [valueQR, setValueQR] = useState<string>('')
    const [isCreate, setIsCreate] = useState<boolean>(false)
    const [dataServicePoint, setDataServicePoint] = useState<any>({})


    const loadData = async () => {
        const condition: any = {
            page: 1,
            pageLimit: 30,
            sortBy: 'createAt',
            sortType: "ASC"
        }

        const res = await ServicePointApi.findAll(condition)
        if (res.status === 200 && res?.data) {
            const newData = res.data?.find((item: any) => item.pointOfService === 'COUNTER')
            setDataServicePoint(newData)
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const onCreateQRcode = () => {
        setIsCreate(true)

        const newData: branchInfoProps = {
            branchId: String(branchesInfo?.branchId),
            branchLogo: branchesInfo?.branchLogo,
            clinicName: branchesInfo?.clinicName,
            clinicNameEn: branchesInfo?.clinicNameEn,
            branchName: branchesInfo?.branchName,
            branchNameEn: branchesInfo?.branchNameEn,
            servicePointId: dataServicePoint?.servicePointId,
            servicePointName: dataServicePoint?.servicePointName,
            servicePointNameEn: dataServicePoint?.servicePointNameEn
        }

        const queryString = Object.keys(newData).map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(String(newData[key]))}`).join('&');
        const url = window.location.origin
        const urlCheckin = `${url}/check-in?${queryString}`;
        setValueQR(urlCheckin)
    }

    const onDownloadQRcode = async () => {
        const canvas: any = document.querySelector('#QRCode canvas');
        if (canvas) {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = `QRCode-${branchesInfo?.clinicName}-branch-${branchesInfo?.branchId}.png`;
            link.click();
        }
    }

    const gotoCheckIn = () => {
        const canvas: any = document.querySelector('#QRCode canvas')
        if (!canvas) {
            return
        }
        const base64Image = canvas.toDataURL();
        const newData: branchInfoProps = {
            branchId: String(branchesInfo?.branchId),
            branchLogo: branchesInfo?.branchLogo,
            clinicName: branchesInfo?.clinicName,
            clinicNameEn: branchesInfo?.clinicNameEn,
            branchName: branchesInfo?.branchName,
            branchNameEn: branchesInfo?.branchNameEn,
            servicePointId: dataServicePoint?.servicePointId,
            servicePointName: dataServicePoint?.servicePointName,
            servicePointNameEn: dataServicePoint?.servicePointNameEn
        }

        history.push(`/check-in`, { ...newData, qrCode: base64Image });
    }

    return (
        <BoxQrCode>
            <Box className={`box-container-qrcode`}>
                <Box className={`text-header`}>
                    {t('QRCODE_CHECKIN.TITLE')}
                </Box>

                <Box className={`card-qrcode-checkin`} >
                    <Box className={`card-qrcode-checkin-content`}>
                        <Box className={`card-qrcode-img`}>
                            {branchesInfo?.branchLogo && branchesInfo.branchLogo !== '' && <img src={branchesInfo.branchLogo} />}
                        </Box>
                        <Box className={`card-qrcode-clinicname`}>
                            {branchesInfo?.clinicName || ''} {t('Check-in')}
                        </Box>
                        <Box>
                            {t('QRCODE_CHECKIN.TITLE_CREATE_QRCODE')}
                        </Box>
                        <Box className={`card-qrcode-content-qrcode`}>
                            <Box id={'QRCode'}>
                                <QRCode
                                    size={256}
                                    value={valueQR}
                                />
                            </Box>
                            {!isCreate &&
                                <Box className={`card-qrcode-create`}>
                                    <ButtonCustom
                                        textButton={t('QRCODE_CHECKIN.BUTTON.CREATE_QRCODE')}
                                        onClick={() => onCreateQRcode()}
                                    />
                                </Box>
                            }
                        </Box>
                        <Box className={`card-qrcode-btn ${!isCreate && 'd-none'}`}>
                            <ButtonCustom
                                textButton={t('QRCODE_CHECKIN.BUTTON.DOWNLOAD_QRCODE')}
                                onClick={() => onDownloadQRcode()}
                            />
                        </Box>

                        <Box className={`card-qrcode-btn ${!isCreate && 'd-none'}`}>
                            <ButtonCustom
                                variant={'outlined'}
                                textButton={t('QRCODE_CHECKIN.BUTTON.DOWNLOAD_SCAN_QRCODE')}
                                onClick={() => gotoCheckIn()}
                            />
                        </Box>

                    </Box>
                </Box>
            </Box>

        </BoxQrCode>
    )
}

export default FormQrCode