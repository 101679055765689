import { Box, styled } from '@mui/material'
import { Typography } from 'antd'
import { colors } from 'constants/theme'

export const CustomPopupManageMedicine = styled(Box)(({ theme }) => ({
    '.MuiFormHelperText-root.Mui-error': {
        whiteSpace: 'nowrap'
    }
}))

export const ImageMedicine = styled(Box)(({ theme }) => ({
    maxWidth: '150px',
    '.image-wrap': {
        paddingTop: '100%',
        img: {
            position: 'absolute',
            top: 0,
            left: 0,
            border: `1px solid ${colors.lightGray}`
        }
    },
    '.inventory': {
        p: {
            color: colors.textLightGray,
            fontSize: '14px'
        }
    }
}))

export const PreviewMedicineLabel = styled(Box)(({ theme }) => ({
    border: `1px solid ${colors.lightGray}`,
    backgroundColor: colors.white,
    minHeight: 230,
    '.header': {
        borderBottom: `1px solid ${colors.lightGray}`,
        '.logo': {
            maxWidth: 45,
            maxHeight: 25,
            objectFit: 'contain'
        },
        '.chip-tel': {
            minHeight: 14,
            minWidth: 63,
            maxWidth: '100%',
            borderRadius: 100,
            backgroundColor: colors.themeMainColor,
            color: colors.white,
            svg: {
                marginRight: '3px',
                fontSize: '8px'
            }
        }
    },
    '.body': {
        p: {
            fontSize: '9px'
        }
    }
}))

export const SummaryCost = styled(Box)(({ theme }) => ({
    minHeight: 48,
    backgroundColor: colors.themeMainColor06,
    p: {
        color: colors.themeMainColor,
        fontWeight: 500,
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        }
    }
}))

export const GroupButton = styled(Box)(({ theme }) => ({
    '.MuiButton-root': {
        height: 44,
        '&.MuiButton-outlined': {
            backgroundColor: colors.white
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '24px !important',
            paddingRight: '24px !important'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}))


export const TypographyText = styled(Box)(({ theme }) => ({
    '.text': {
        overflow: 'clip',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'column'
    }
}))
