import { useEffect } from 'react';
import { Tab, Box } from '@mui/material';
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import * as UseStyled from 'features/treatments/SummaryTreatment/UseStyle'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import {
  setTreatmentByKey,
  setEditTootChart,
  setToothChartSelected,
  setHistoryToothChart,
  resetToothChart
} from 'app/slice/treatment.slice';

/** COMPONENT */
import SummaryTreatment from 'features/treatments/SummaryTreatment/SummaryTreatment'
import Diagnosis from 'features/treatments/SummaryTreatment/Diagnosis'
import Recommend from 'features/treatments/SummaryTreatment/Recommend'
import { DoctorsType } from 'features/treatments/treatmentsIndex';

/** API */
import TreatmentSummaryApi from 'api/dentists/treatment-summary.api'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import OperativeApi from 'api/setting/treatments/operative.api';
import { PDFPayloadOutStandingPaymentProps } from 'component/Treatments/PopupOutStandingPayment';

type IndexSummaryTreatmentProps = {
  onSave?: (val: 'save') => void
  userId?: number
  tabs: string[]
  activeTab: number
  patientCaseId?: number
  patientVN?: number
  patientId?: number
  patientName?: string
  patientPhone?: string
  type: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveOrder' | 'RetrospectiveEdit'
  doctors: DoctorsType[]
  setActiveTab: (tab: number) => void
  hasTreatment: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentCost: 'PERMISS' | 'UNPERMISS'
  hasChangeTreatmentNote: 'PERMISS' | 'UNPERMISS'
  hasDiagnosis: 'PERMISS' | 'UNPERMISS'
  hasChangeProductPrice: 'PERMISS' | 'UNPERMISS'
  hasChangeProductDiscountAdditional: 'PERMISS' | 'UNPERMISS',
  hasDeleteTreatment: 'PERMISS' | 'UNPERMISS'
  diagnosis?: any
  recommends?: any
  summeryProducts?: any
  summeryTreatments?: any
  handleTreatmentCurrent?: (data: any) => void
  hasEditNextVisit?: boolean
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export default function IndexSummaryTreatment(props: IndexSummaryTreatmentProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    if (props.setActiveTab) props.setActiveTab(newValue)
    dispatch(setEditTootChart(null))
  }

  const handleSetSummeryTreatment = () => {
    if (props.summeryTreatments?.length) {
      const newData = props.summeryTreatments.map((item: any) => {
        if (item?.nextVisit && item?.nextVisit?.treatment && typeof item?.nextVisit?.treatment === 'string') {
          const step1 = item?.nextVisit?.treatment.replace(/\\/g, '');
          const step2 = step1.replace(/^"|"$/g, '');
          const newTreatment = JSON.parse(step2);
          return { ...item, nextVisit: { ...item?.nextVisit, treatment: newTreatment } }
        } else {
          return { ...item }
        }
      })
      return newData
    } else {
      return []
    }
  }

  const loadSummaryTreatment = async (patientId: number, patientCaseId: number) => {
    const summeryProducts: any = []
    const toothChartSelected: any = []
    const historyToothChartSelected: any = []

    if (props.type === 'RetrospectiveEdit') {
      const summeryTreatments = Array.isArray(props.summeryTreatments) ? handleSetSummeryTreatment() : []
      const summeryProducts = Array.isArray(props.summeryProducts) ? props.summeryProducts : []
      const summery = [...summeryTreatments, ...summeryProducts]
      const diagnosis = props.diagnosis
      const recommends = props.recommends

      dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: summery }))
      dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: diagnosis }))
      dispatch(setTreatmentByKey({ key: 'patientRecommends', value: recommends }))
      // dispatch(setTreatmentByKey({key:'outStandingPayment',value:{}}))

      if (!_.isEmpty(summeryTreatments)) {
        _.map(summeryTreatments, (sum, index) => {
          toothChartSelected.push(JSON.parse(sum.teethJson))
        })
      }
    }
    else if (props.type !== 'RetrospectiveOrder' && patientCaseId) {
      await TreatmentSummaryApi.findTreatmentSummary(patientCaseId)
        .then(async ({ data }) => {
          let operativeFixCode: any = []
          const pAlreadyTreatment: string = data.isFixCode === "1" ? localStorage.getItem('PATIENT_ALREADY_TREATMENT') || '' : ''
          const checkNewP: number[] = pAlreadyTreatment ? JSON.parse(pAlreadyTreatment) : []
          if (data.isFixCode === "1" && !_.includes(checkNewP, patientCaseId)) {
            await OperativeApi.findAllFixCode()
              .then(({ data }) => {
                _.forEach(data, (tx) => {
                  operativeFixCode = [
                    ...operativeFixCode,
                    {
                      ...tx,
                      additional: 0,
                      additionalUnit: "BAHT",
                      cfAmount: null,
                      cfAmountNet: 0,
                      cfDiscountAmount: 0,
                      cfDiscountUnit: "BAHT",
                      clinicFeeId: null,
                      dfAmount: null,
                      dfAmountNet: 0,
                      dfDiscountAmount: 0,
                      dfDiscountUnit: "BAHT",
                      dfRemark: "",
                      dfUnit: null,
                      discount: 0,
                      discountUnit: "BAHT",
                      doctorId: props.userId,
                      nextVisit: null,
                      note: "",
                      noteNextVisit: "",
                      operativeId: tx.operativeId,
                      price: tx.salePrice,
                      qty: 1,
                      refId: 0,
                      refType: null,
                      teeth: null,
                      teethJson: null,
                      tempOrderTreatmentId: null,
                      total: tx.salePrice,
                    }
                  ]
                })
              })
              .catch((e) => { return })
          }

          // if (!_.isEmpty(data.summeryProducts)) {
          //   _.map(data.summeryProducts, (prod, index) => {
          //     summeryProducts.push({ ...prod, total: ((prod.price * prod.qty) - prod.discount) + prod.additional })
          //   })
          // }

          if (!_.isEmpty(data.summeryTreatments)) {
            _.map(data.summeryTreatments, (sum, index) => {
              toothChartSelected.push(JSON.parse(sum.teethJson))
            })
          }

          props?.handleTreatmentCurrent && props.handleTreatmentCurrent(data.summeryTreatments)
          dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...operativeFixCode, ...data.summeryTreatments, ...data.summeryProducts] }))
          // dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: [...data.patientDiagnosis] }))
          // dispatch(setTreatmentByKey({ key: 'patientRecommends', value: [...data.patientRecommends] }))

          const newPatientDiagnosis: any = []
          if (!_.isEmpty(data.patientDiagnosis)) {
            _.map(data.patientDiagnosis, (prod, index) => {
              newPatientDiagnosis.push({ ...prod, refItemId: JSON.parse(prod.refItemId || []) })
            })
          }
          dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: [...newPatientDiagnosis] }))
          const newPatientRecommends: any = []
          if (!_.isEmpty(data.patientRecommends)) {
            _.map(data.patientRecommends, (prod, index) => {
              newPatientRecommends.push({ ...prod, refItemId: JSON.parse(prod.refItemId || []) })
            })
          }
          dispatch(setTreatmentByKey({ key: 'patientRecommends', value: [...newPatientRecommends] }))

          if (props.type === 'Payment') {
            const dataOutStandingPayment: PDFPayloadOutStandingPaymentProps = {
              overdueTotal: data?.overdueTotal,
              overdueDate: data?.overdueDate,
              overdueDateStart: data?.overdueDateStart,
              patientSignature: data?.patientSignature || '',
              doctorId: data?.doctorId || 0,
              doctorName: data?.doctorName || '',
              doctorSignature: data?.doctorSignature || '',
              managerName: data?.managerName || '',
              managerSignature: data?.managerSignature || ''
            }

            dispatch(setTreatmentByKey({ key: 'outStandingPayment', value: { ...dataOutStandingPayment } }))
          }

        })
        .catch(() => { return })
    }



    await TreatmentHistoryApi.findTreatmentHistoryToothChart(patientId)
      .then(({ data }) => {
        _.map(data, (tooths: any, index: any) => {
          if (tooths.teethJson) historyToothChartSelected.push(JSON.parse(tooths.teethJson))
        })
      })

    dispatch(setToothChartSelected([...historyToothChartSelected, ...toothChartSelected]))
    dispatch(setHistoryToothChart(historyToothChartSelected))
  }

  useEffect(() => {
    if (props.patientId) {
      loadSummaryTreatment(props.patientId, props.patientCaseId || 0)
    }
  }, [props.patientCaseId, props.patientId, props.summeryTreatments, props.summeryProducts, props.diagnosis, props.recommends])

  useEffect(() => {
    dispatch(setEditTootChart(null))
  }, [props.patientVN])

  return (
    <Box className="mt-4">
      {!_.isEmpty(props.tabs) && (
        <Box sx={{ marginBottom: '12px' }}>
          <UseStyled.CustomTabs value={props.activeTab} onChange={handleChangeTab} aria-label="summary tabs">
            {_.map(props.tabs, (tab: string, index: number) => {
              return <Tab key={index} label={t(tab)} {...a11yProps(index)} style={{ width: 'fit-content', maxWidth: '100%', padding: '0 10px' }} />
            })}
          </UseStyled.CustomTabs>
        </Box>
      )}
      <TabPanel value={props.activeTab} index={0}>
        <SummaryTreatment
          onSave={props?.onSave ? props?.onSave : undefined}
          userId={props.userId}
          patientCaseId={props.patientCaseId}
          patientId={props.patientId}
          patientName={props.patientName}
          patientPhone={props.patientPhone}
          type={props.type}
          doctors={props.doctors}
          hasTreatment={props.hasTreatment}
          hasChangeTreatmentCost={props.hasChangeTreatmentCost}
          hasChangeTreatmentNote={props.hasChangeTreatmentNote}
          hasChangeProductPrice={props.hasChangeProductPrice}
          hasChangeProductDiscountAdditional={props.hasChangeProductDiscountAdditional} hasDeleteTreatment={props.hasDeleteTreatment || 'PERMISS'}
          hasEditNextVisit={props?.hasEditNextVisit || false}
        />
      </TabPanel>
      <TabPanel value={props.activeTab} index={1}>
        <Diagnosis userId={props.userId} />
      </TabPanel>
      <TabPanel value={props.activeTab} index={2}>
        <Recommend />
      </TabPanel>
    </Box>
  )
}

function a11yProps(index: number) {
  return {
    id: `summary-tab-${index}`,
    'aria-controls': `summary-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`summary-tabpanel-${index}`} aria-labelledby={`summary-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
