import { fileUrl } from 'api/api'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'

export const exportSSO = () => {
  window.open(`${fileUrl}/default/pdf-sso.pdf`, '_blank')
}

export const exportOPDCover = async (patientId: number) => {
  const print = await TreatmentHistoryApi.printOPDCover(patientId)
  if (print?.status === 200) {
    window.open(`${fileUrl}/${print.data}`, '_blank')
    return true
  } else {
    return false
  }
}

export const exportOPD = async (patientId: number) => {
  const print = await TreatmentHistoryApi.printOPD(patientId)
  if (print?.status === 200) {
    window.open(`${fileUrl}/${print.data}`, '_blank')
    return true
  } else {
    return false
  }
}


export const exportOPDEmpty = async (patientId: number, props?: { type: '0' | '1' }) => {
  const print = await TreatmentHistoryApi.printOPDEmpty(patientId, props)
  if (print?.status === 200) {
    window.open(`${fileUrl}/${print.data}`, '_blank')
    return true
  } else {
    return false
  }
}
export const exportOPDV1 = async (patientId: number) => {
  const print = await TreatmentHistoryApi.printOPDV1(patientId)
  if (print?.status === 200) {
    window.open(`${fileUrl}/${print.data}`, '_blank')
    return true
  } else {
    return false
  }
}