import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import buttonSlice from 'app/slice/button.slice'
import formSlice from 'app/slice/form.slice'
import modalSlice from 'app/slice/modal.slice'
import registerSlice from 'app/slice/register/register.slice'
import selectSlice from 'app/slice/select.slice'
import userSlice from 'app/slice/user.slice'
import expiredSlice from 'app/slice/expired.slice'
import treatmentSlice from 'app/slice/treatment.slice'
import paymentSlice from 'app/slice/payment.slice'
import appointmentSlice from './slice/appointment.slice'

export default configureStore({
  reducer: {
    modal: modalSlice,
    select: selectSlice,
    buttons: buttonSlice,
    forms: formSlice,
    users: userSlice,
    register: registerSlice,
    expired: expiredSlice,
    treatment: treatmentSlice,
    payment: paymentSlice,
    appointment: appointmentSlice
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false
    })
  ],
  devTools: false
})
