import { Box, MenuItem } from '@mui/material'
import InputTextField from 'component/Input/InputTextField'
import FilterSelect from 'component/Select/FilterSelect'
import React, { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import Hue from 'react-color/lib/components/hue/Hue'
import { useTranslation } from 'react-i18next'
import { BoxManageColor, ColorTag } from './style'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'

export type FormManageColorProps = {
    color: string
    setColor: (value: string) => void
    handleChangeColor: ({ hex }: { hex: string }) => void
    onLoadOperativeType: (search: string) => void
    operativeTypes: any
    operativeType: any
    handleChangeOperative: (val: any) => void
}

const FormManageColor = (props: FormManageColorProps) => {
    const { color, setColor, handleChangeColor, onLoadOperativeType,
        operativeTypes, operativeType, handleChangeOperative } = props
    const { t } = useTranslation()

    const [renderColor, setRenderColor] = useState<string>('')

    useEffect(() => {
        if (color.includes('#')) {
            setRenderColor(color.slice(1))
        } else {
            setRenderColor(color)
        }

    }, [color])

    return (
        <BoxManageColor>
            <Box className={`content-select-color`}>
                <ChromePicker
                    color={color}
                    onChange={handleChangeColor}
                />
                <Hue
                    color={color}
                    onChange={handleChangeColor}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Box sx={{ '.MuiInputBase-root': { height: '43px' } }}>
                        {'HEX'}
                    </Box>
                    <Box sx={{
                        '.MuiInputBase-input': {
                            padding: "0 1rem 0 .4rem !important"
                        }
                    }}>
                        <InputTextField
                            value={renderColor}
                            onchange={(e) => {
                                const newValue = e.target.value
                                setColor(`#${newValue}`)
                            }}
                            inputProps={{
                                startAdornment: <Box>#</Box>
                            }}
                            placeholder={t('TAGS.FORM.OPERATIVETYPE')}
                        />
                    </Box>

                </Box>

            </Box>
            <Box sx={{ color: '#808080' }}>
                {t('TAGS.FORM.OPERATIVECOLOR')}
            </Box>
            <Box sx={{ '.MuiInputBase-root': { height: '43px', paddingRight: '30px !important' } }}>
                <AutocompleteSelect
                    labelId='select-color'
                    options={operativeTypes}
                    getOptionLabel={(option) => option?.operativeTypeName || ''}
                    renderOption={((props: any, option: any) => {
                        return (
                            <Box {...props}>
                                {option?.operativeTypeName || ''}
                            </Box>
                        )
                    })}
                    value={operativeType || ''}
                    onchange={(e, value) => {
                        handleChangeOperative(value)
                        if (value?.color && value?.color !== '') {
                            setColor(value?.color)
                        }
                    }}
                    noOptionsText={t('NOT_FOUND')}
                    onInputChange={(event, val) => {
                        if (event) {
                            onLoadOperativeType(val)
                        }
                    }}
                    onOpen={() => onLoadOperativeType('')}
                    onClose={() => onLoadOperativeType('')}
                />
            </Box>
            <Box>
                <ColorTag color={color || 'transparent'} />
            </Box>
        </BoxManageColor>
    )
}

export default FormManageColor