import { Box } from '@mui/material'
import { fileUrl } from 'api/api'
import BtnEdit from 'assets/button/BtnEdit'
import React, { useState } from 'react'
import { CardManageWeb } from '../useStyles'
import { ManegeWebAction, ManegeWebStatus } from '../ManageWeb'
import DrageMove from 'assets/icon/appointment/card/drag-move'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { colors } from 'constants/theme'
import { ServiceProps, ServiceSortProps } from 'api/application/manageWeb-interface'
import ManageWebApi from 'api/application/manageWeb'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { useTranslation } from 'react-i18next'
import { swalActive } from 'component/Alert/Swal'


type ServiceListProps = {
    dataServiceAll: any
    onEdit: (status: boolean, item?: any) => void
    onDel: (id: number, nameOp: string) => void
    reLoad: () => void
}

const ServiceList = (props: ServiceListProps) => {
    const { t } = useTranslation()
    const t_SERVICE = "MANAGE_WEB.SERVICE"

    const [isDragging, setIsDragging] = useState<boolean>(false)

    const onDragStart = (result: any) => {
        setIsDragging(true)
    }

    const onDragEnd = async (result: any) => {
        if (!result.destination) {
            setIsDragging(false)
            return
        }
        const { source, destination } = result;
        const data = props?.dataServiceAll
        const [removed] = data.splice(source.index, 1);
        data.splice(destination.index, 0, removed);

        const newData: ServiceSortProps[] = data.map((item: any, index: number) => {
            return { provideServiceId: item.provideServiceId, sorting: index + 1 }
        })
        await ManageWebApi.sortService(newData).finally(() => {
            setIsDragging(false)
            props.reLoad()
        })
    }

    const handleActive = async (status: 'ACTIVE' | 'INACTIVE', data: any) => {
        const id = data?.provideServiceId || 0
        const newPayload: ServiceProps = { ...data, status: status }
        const onSaveStatus = async () => {
            if (id !== 0) {
                const res = await ManageWebApi.updateService(id, newPayload)
                if (res === "pass") {
                    props.reLoad()
                    notiSuccess(t(`${t_SERVICE}.SAVE_STATUS`))
                } else {
                    notiError(t(`${t_SERVICE}.SAVE_ERROR`))
                }
            } else {
                notiError(t(`${t_SERVICE}.SAVE_ERROR`))
            }
        }
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 475px">${t(t_SERVICE + '.NOTI_CHANGE_STATUS')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 450px">${t(`${t_SERVICE}.ALERT.CONFIRM_STATUS_`, {
                subject: data.topic || '-',
                status: t(`STATUS.${status}`)
            })}</p>`,
            async (res: any) => {
                if (res) {
                    await onSaveStatus()
                }
            }, 'question', t('BUTTON.SUBMIT'), t('BUTTON.CANCEL')
        )

    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} >
                <Box sx={{ paddingRight: '16px', paddingBottom: isDragging ? '200px' : '' }}>
                    <Droppable droppableId={`${'Sevice'}`}>
                        {(dropProvided, dropSnapshot) => (
                            <Box ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}>
                                {props.dataServiceAll && props.dataServiceAll.map((item: any, index: number) => {
                                    return (
                                        <Draggable
                                            key={index}
                                            draggableId={`${item.provideServiceId}-${index}`}
                                            index={index}
                                        >
                                            {(dragProvided, dragSnapshot) => {
                                                return (
                                                    <CardManageWeb
                                                        {...dragProvided.draggableProps}
                                                        ref={dragProvided.innerRef}
                                                    >
                                                        <Box className={'content-card'} sx={{ width: 'calc(100%)' }}>
                                                            <Box className={'container-img'} >
                                                                <img src={`${fileUrl}/${item?.filePath}/${item?.fileName}`} />
                                                            </Box>
                                                            <Box className={'container-des'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <Box className={'text-nowrap-ellipsis'} sx={{ fontSize: "18px", fontWeight: '500', width: "100%" }}>
                                                                        {item?.topic || ''}
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                        <Box
                                                                            sx={{ cursor: 'pointer' }}
                                                                            {...dragProvided.dragHandleProps}
                                                                        >
                                                                            <DrageMove
                                                                                fill={dragSnapshot?.isDragging ? colors.themeSecondColor : undefined}
                                                                                width={'20px'}
                                                                                height={'20px'}
                                                                            />
                                                                        </Box>
                                                                        <Box sx={{ cursor: 'pointer', marginLeft: '8px' }}>
                                                                            <ManegeWebAction
                                                                                onActive={() => handleActive("ACTIVE", item)}
                                                                                onInActive={() => handleActive("INACTIVE", item)}
                                                                                disabledActive={item.status === "ACTIVE"}
                                                                                onEdit={() => props.onEdit(true, item)}
                                                                                onDelete={() => props.onDel(item.provideServiceId, item.topic)}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>

                                                                <Box sx={{ marginTop: '8px', paddingBottom: '16px', width: '100%', height: '100%' }}>
                                                                    <Box className={'content-des-text'} sx={{ margin: '0 !important', WebkitLineClamp: '3 !important' }}>
                                                                        {item?.subTopic || ''}
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                    <ManegeWebStatus status={item.status} />
                                                                </Box>

                                                            </Box>
                                                        </Box>
                                                    </CardManageWeb>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                            </Box>
                        )}
                    </Droppable>
                </Box>
            </DragDropContext>
        </>
    )
}

export default ServiceList