enum DOCUMENT_STATUS {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVAL = 'APPROVAL',
  UN_APPROVAL = 'UNAPPROVAL',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CANCEL = 'CANCEL'
}

enum STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED'
}
enum CHOICE_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
const ACTIVE = `ACTIVE`
const INACTIVE = `INACTIVE`
const DELETED = `DELETED`

enum DANGER_TYPE {
  ALL = `ALL`,
  ALCOHOL = `ALCOHOL`,
  CIGARETTE = `CIGARETTE`
}

const REF_TYPE = {
  USER: `USER`,
  PATIENT: `PATIENT`,
  BRANCH: `BRANCH`,
  NEWS: `NEWS`,
  PROMOTION: `PROMOTION`,
  ATTECHMENT: `ATTECHMENT`,
  SIGNATURE: `SIGNATURE`,
  PRODUCT: `PRODUCT`
}

const FILETYPE = {
  SM: `SM`,
  MD: `MD`,
  LG: `LG`,
  THUMNAIL: `THUMNAIL`,
  ORIGINAL: `ORIGINAL`
}

enum PRODUCT_CATEGORY {
  MEDICINE = `MEDICINE`,
  PRODUCT = `PRODUCT`
}

enum PRODUCT_GROUP {
  ALL = `ALL`,
  MEDICINE = `MEDICINE`,
  PRODUCT = `PRODUCT`
}

const PACKAGE = {
  STANDARD: `STANDARD`,
  PREMIUM: `PREMIUM`,
  ULTRA: `ULTRA`
}

enum ACTION {
  INSERT = 'INSERT',
  UPDATE = 'UDPATE',
  DELETED = 'DELETED'
}

enum DEFAULT {
  DEFAULT = '1',
  UN_DEFAULT = '0'
}

enum SHOW_ON_RECEIPT {
  SHOW = 'SHOW',
  HIDE = 'HIDE'
}

enum UNIT_PER_BAHT {
  BAHT = 'BAHT',
  PERCENT = 'PERCENT'
}

enum TRUE_FALSE {
  TRUE = '1',
  FALSE = '0'
}
enum PERMISSION {
  PERMISS = 'PERMISS',
  UNPERMISS = 'UNPERMISS'
}

enum IS_HAS {
  HAS = `HAS`,
  NOT_HAS = `NOT_HAS`
}

enum TREATMENT_ITEM_TYPE {
  OPERATIVE = 'OPERATIVE',
  MEDICINE = `MEDICINE`,
  PRODUCT = `PRODUCT`
}

enum GENDER {
  FEMALE = 'FEMALE',
  MALE = `MALE`,
  OTHER = `OTHER`,
  NOT_SPECIFIED = `NOT_SPECIFIED`
}

enum MARITAL_STATUS {
  SINGLE = `SINGLE`,
  MARRY = `MARRY`,
  DIVORCE = `DIVORCE`,
  OTHER = `OTHER`,
  NOT_SPECIFIED = `NOT_SPECIFIED`
}

enum RELIGION {
  BUDDHISM = 'BUDDHISM',
  CHRISTIANITY = 'CHRISTIANITY',
  HINDUISM = 'HINDUISM',
  ISLAM = 'ISLAM',
  OTHER = `OTHER`,
  NOT_SPECIFIED = `NOT_SPECIFIED`
}

enum BLOOD_TYPE {
  O = `O`,
  A = `A`,
  B = `B`,
  AB = `AB`
}

enum DURATION_TYPE {
  MINUTES = `MINUTES`,
  HOURS = `HOURS`,
  DAY = `DAY`,
  MONTH = `MONTH`
}

enum BACKGROUND_TYPE {
  EDUCATION_QUALIFICATION = `EDUCATION_QUALIFICATION`,
  TRAINING_COURSE = `TRAINING_COURSE`,
  CERTIFICATE = `CERTIFICATE`
}

enum ATTACHMENT_TYPE {
  ID_CARD = `ID_CARD`,
  HOUSE_REGISTRATION = `HOUSE_REGISTRATION`,
  DENTIST_LICENSE = `DENTIST_LICENSE`,
  DENTAL_COUNCIL_BOOK = `DENTAL_COUNCIL_BOOK`,
  TREATMENT_FEE_SHEET = `TRETMENT_FEE_SHEET`,
  DOCTOR_SCHEDULE = `DOCTOR_SCHEDULE`,
  OPERATING_SYSTEM = `OPERATING_SYSTEM`,
  PROMOTION = `PROMOTION`,
  IMAGE = `IMAGE`
}

enum INVENTORY_STATUS {
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE'
}

enum INVENTORY_TYPE {
  BUY = 'BUY',
  SELL = 'SELL',
  BORROW = 'BORROW',
  RETURN = 'RETURN',
  ADJUST = 'ADJUST',
  CANCEL = 'CANCEL'
}
enum KEYWORDS_TYPE {
  DISEASE = 'DISEASE',
  MEDICINE_USE = 'MEDICINE_USE',
  BE_ALLERGIC = 'BE_ALLERGIC',

}
enum CALCULATE_ADVANCE {
  INCREASE = `INCREASE`,
  DECREASE = `DECREASE`,
}
export {
  ACTIVE,
  DELETED,
  FILETYPE,
  INACTIVE,
  PACKAGE,
  PRODUCT_CATEGORY,
  REF_TYPE,
  /** ENUME */
  PRODUCT_GROUP,
  STATUS,
  CHOICE_STATUS,
  DOCUMENT_STATUS,
  ACTION,
  DANGER_TYPE,
  DEFAULT,
  SHOW_ON_RECEIPT,
  UNIT_PER_BAHT,
  TRUE_FALSE,
  PERMISSION,
  IS_HAS,
  TREATMENT_ITEM_TYPE,
  DURATION_TYPE,
  BACKGROUND_TYPE,
  ATTACHMENT_TYPE,
  INVENTORY_STATUS,
  INVENTORY_TYPE,
  /** PATIENT */
  GENDER,
  MARITAL_STATUS,
  BLOOD_TYPE,
  RELIGION,
  KEYWORDS_TYPE,


  CALCULATE_ADVANCE
}
