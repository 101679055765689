import { menuThirds } from 'constants/menus'
import MedicineFormats from 'features/setting/default-products/product-formats/medicine-formats'
import MedicineInstructions from 'features/setting/default-products/product-instructions/medicine-instructions'
import ProductInstructions from 'features/setting/default-products/product-instructions/product-instructions'
import MedicinePreserves from 'features/setting/default-products/product-preserves/medicine-preserves'
import ProductPreserves from 'features/setting/default-products/product-preserves/product-preserves'
import MedicineTypes from 'features/setting/default-products/product-types/medicine-types'
import ProductTypes from 'features/setting/default-products/product-types/product-types'
import ProductUnits from 'features/setting/default-products/product-units/product-units'
import MedicineUnits from 'features/setting/default-products/product-units/medicine-units'
import MedicineUses from 'features/setting/default-products/product-uses/medicine-uses'
import MedicineSymptoms from 'features/setting/default-products/product-symptoms/medicine-symptoms'
import MaterialTypes from 'features/setting/default-products/product-types/material-types'
import MaterialUnits from 'features/setting/default-products/product-units/material-units'
import MaterialPreserves from 'features/setting/default-products/product-preserves/material-preserves'
import MaterialInstructions from 'features/setting/default-products/product-instructions/material-instructions'

const routeDefaultProducts = [
  {
    ...menuThirds.MEDICINE_TYPE,
    component: MedicineTypes,
    appBar: true
  },
  {
    ...menuThirds.PRODUCT_TYPE,
    component: ProductTypes,
    appBar: true
  },
  {
    ...menuThirds.PRODUCT_UNIT,
    component: ProductUnits,
    appBar: true
  },
  {
    ...menuThirds.MEDICINE_FORMAT,
    component: MedicineFormats,
    appBar: true
  },
  {
    ...menuThirds.MEDICINE_UNIT,
    component: MedicineUnits,
    appBar: true
  },
  {
    ...menuThirds.MEDICINE_INSTRUCTION,
    component: MedicineInstructions,
    appBar: true
  },
  {
    ...menuThirds.MEDICINE_PRESERVE,
    component: MedicinePreserves,
    appBar: true
  },
  {
    ...menuThirds.MEDICINE_USE,
    component: MedicineUses,
    appBar: true
  },

  {
    ...menuThirds.PRODUCT_INSTRUCTION,
    component: ProductInstructions,
    appBar: true
  },
  {
    ...menuThirds.PRODUCT_PRESERVE,
    component: ProductPreserves,
    appBar: true
  },
  {
    ...menuThirds.MEDICINE_SYMPTOM,
    component: MedicineSymptoms,
    appBar: true
  },
  {
    ...menuThirds.MATERIAL_TYPE,
    component: MaterialTypes,
    appBar: true
  },

  {
    ...menuThirds.MATERIAL_PRESERVE,
    component: MaterialPreserves,
    appBar: true
  },
  {
    ...menuThirds.MATERIAL_UNIT,
    component: MaterialUnits,
    appBar: true
  },
  {
    ...menuThirds.MATERIAL_INSTRUCTION,
    component: MaterialInstructions,
    appBar: true
  }
]

export default routeDefaultProducts
