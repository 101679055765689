import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Box, Grid, Typography, Divider } from '@mui/material';
import _ from 'lodash'
import moment from 'moment'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import { NetPrice, CustomTable } from 'features/warehouses/import-products/style';
import { colors } from 'constants/theme'

/** API */
import { routeName } from 'routes/routes-name'
import ImportInventoryApi from 'api/warehouses/import-inventory.api'

/** UTILS || SLICE */
import { showModal } from 'app/slice/modal.slice';
import { toBuddhistYear, numberFormat } from 'utils/app.utils';
import { isUpdate } from 'utils/verification.utils';

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import PopupCancelImport from 'features/warehouses/import-products/popupCancelImport';

const permissions = {
  isUpdate: isUpdate()
}

export default function ImportProductList() {
  const location = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [document, setDocument]: any = useState({})
  const [popupCancel, setPopupCancel] = useState(false);

  const findDocumentById = async (inventoryDocumentId: number) => {
    const res = await ImportInventoryApi.findDocumentById(inventoryDocumentId)
    if (res.status === 200) {
      setDocument(res.data)
    }
  }

  useEffect(() => {
    if (location.state) {
      const data: any = location.state
      findDocumentById(data.inventoryDocumentId)
    } else {
      const queryParams = new URLSearchParams(window.location.search)
      const inventoryDocumentId = Number(queryParams.get("id"))
      findDocumentById(inventoryDocumentId)
    }
  }, [location])

  const onCancel = () => {
    dispatch(showModal())
    setPopupCancel(true)
  }

  const headCells = [
    { id: 'no', disablePadding: false, label: '#', width: '60px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.LOT_NO') },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.PRODUCT_LIST') },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.EXPIRATION_DATE'), width: '150px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.COUNT'), align: 'center', width: '90px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.UNIT'), align: 'left', width: '100px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.PRICE'), align: 'right', width: '155px' },
    { id: 'message', disablePadding: false, label: t('IMPORT_PRODUCT.FORM.TOTAL'), align: 'right', width: '155px', class: 'p-3' },
  ]

  const renderProduct = (objData: any, no: number) => {
    const { itemName, expireDate, qty, itemUnitName, salePrice, total, lotNo } = objData
    no = no + 1
    const objRenderData = {
      key: no.toString(),
      id: no.toString(),
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: lotNo || '-' },
        { option: 'TEXT', align: 'left', label: itemName || '-' },
        { option: 'TEXT', align: 'left', label: expireDate && expireDate !== '0000-00-00' ? toBuddhistYear(moment(expireDate)) : '-' },
        { option: 'TEXT', align: 'center', label: qty },
        { option: 'TEXT', align: 'left', label: itemUnitName || '-' },
        { option: 'TEXT', align: 'right', label: numberFormat(salePrice) },
        { option: 'TEXT', align: 'right', label: numberFormat(total || qty * salePrice) },
      ]
    }
    return <TableRowCommon  {...objRenderData} />
  }

  return (
    <div className={`container-card overflow-hidden`}>
      <div>
        <HeaderLabel
          text={t('IMPORT_PRODUCT.FORM.IMPORT_PRODUCT_DETAIL')}
          unDisplayButton={true}
          unDisplayCancel={true}
          ondelete={onCancel}
          disabledDelete={document.status === 'ACTIVE' ? (document.cInven > 0 ? true : permissions.isUpdate.disabled) : true}
          navigator={{ previousTo: routeName.importProduct, previousTitle: t('IMPORT_PRODUCT.TITLE'), currentTitle: t('IMPORT_PRODUCT.FORM.IMPORT_PRODUCT_DETAIL') }}
        />
      </div>
      <Card className="border shadow-sm mx-4 mb-4 h-auto card">
        <Card.Body className="h-100">
          <Grid container spacing={2}>
            <Grid item sm={6} lg={4} className='w-100'>
              <Box className='d-flex'>
                <Typography sx={{ width: 120, fontWeight: 500, flex: 'none' }}>{t('IMPORT_PRODUCT.TABLE.DATE')}</Typography>
                <Typography>{document.date && document.date !== '0000-00-00' ? toBuddhistYear(moment(document.date)) : '-'}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6} lg={4} className='w-100'>
              <Box className='d-flex'>
                <Typography sx={{ width: 120, fontWeight: 500, flex: 'none' }}>{t('IMPORT_PRODUCT.TABLE.DOCUMENT_NO')}</Typography>
                <Typography>{((document.prefix && document.documentNo) && `${document.prefix}-${document.documentNo}`) || document.documentNo || '-'}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6} lg={4} className='w-100'>
              <Box className='d-flex'>
                <Typography sx={{ width: 120, fontWeight: 500, flex: 'none' }}>{t('IMPORT_PRODUCT.TABLE.DOCUMENT_NAME')}</Typography>
                <Typography>{document.subject || '-'}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6} lg={4} className='w-100'>
              <Box className='d-flex'>
                <Typography sx={{ width: 120, fontWeight: 500, flex: 'none' }}>{t('IMPORT_PRODUCT.FORM.BRANCH')}</Typography>
                <Typography>{document.branch?.branchName || '-'}</Typography>
              </Box>
            </Grid>
            <Grid item sm={6} lg={4} className='w-100'>
              <Box className='d-flex'>
                <Typography sx={{ width: 120, fontWeight: 500, flex: 'none' }}>{t('IMPORT_PRODUCT.TABLE.UPDATEDBY')}</Typography>
                <Typography>{document.updatedBy || '-'}</Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider className="mx-0 my-4" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
          <CustomTable
            sx={{
              '.MuiTableContainer-root': {
                minHeight: 'unset !important',
                '.MuiTable-root': {
                  '.MuiTableHead-root': {
                    '.MuiTableCell-head': {
                      borderWidth: '1px 0 1px 0 !important'
                    }
                  }
                }
              }
            }}>
            <TableCustom
              hidePagination
              page={0}
              pageLimit={0}
              sortType={'ASC'}
              sortBy={''}
              rowCount={0}
              textEmptyData={t('REGISTER.NO_ITEM')}
              onSort={() => { return }}
              setPageLimit={() => { return }}
              setPage={() => { return }}
              headCells={headCells}
              rowsData={
                _.map(document.inventoryHistories, (history, index: number) => {
                  return renderProduct(history, index)
                })
              }
              tableFixedWidth
              tableMinWidth={1200}
            />
          </CustomTable>
          <NetPrice className={`d-flex align-items-center justify-content-between justify-content-md-end mt-3 px-3 px-md-4`}>
            {`${t('IMPORT_PRODUCT.FORM.PRODUCT_PRICEALL')} ${numberFormat(!_.isEmpty(document.inventoryHistories) ? _.sumBy(document.inventoryHistories, 'total') : 0)} ${t('IMPORT_PRODUCT.FORM.PRICE_UNIT')}`}
          </NetPrice>
        </Card.Body>
      </Card>

      {popupCancel && (
        <PopupCancelImport
          inventoryDocumentId={document.inventoryDocumentId}
          document={{ prefix: document.prefix, documentNo: document.documentNo }}
          onCancel={(show: boolean) => setPopupCancel(show)}
          onAfterSubmit={(show: boolean) => {
            setPopupCancel(show)
            findDocumentById(document.inventoryDocumentId)
          }}
        />
      )}
    </div>
  )
}

