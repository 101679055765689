import { Box, Collapse, Grid, Typography } from '@mui/material'
import { colors } from 'constants/theme'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InputCheckbox from 'component/Input/InputCheckbox'
import { BoxFormCollapse } from './style'

export type FFFInfoProps = {
    title: string;
    sub: FFFInfoSubProps[]
}

export type FFFInfoSubProps = {
    id: string;
    subTitle: string;
    isShow: boolean;
    isRequired: boolean;
    isDisabled?: boolean | undefined;
    inputName: FFFInfoSubInputProps[]
}

export type FFFInfoSubInputProps = {
    id: string;
    text: string;
    isDisabled?: boolean | undefined;
    isRequired: boolean;
    isShow: boolean;
}

export type FormFormatFormProps = {
    formRegister: { data: any, setData: (data: any) => void };
    formAppointment: { data: any, setData: (data: any) => void };
}

const FormFormatForm = (props: FormFormatFormProps) => {
    const initFormFormatForm: FFFInfoProps[] = [
        {
            title: "REGISTER",
            sub: [
                {
                    id: 'generalInformation',
                    subTitle: "INFORMATION",
                    isShow: true,
                    isRequired: true,
                    inputName: [
                        {
                            id: 'cnNumber',
                            text: "CN_NUMBER",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'branchCnNumber',
                            text: "BRANCH_NUMBER",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'firstnameEn',
                            text: "FIRST_NAME",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'lastnameEn',
                            text: "LAST_NAME",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'prefixId',
                            text: "PREFIXES",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'firstname',
                            text: "FIRST_NAME_TH",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'lastname',
                            text: "LAST_NAME_TH",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'displayName',
                            text: "DISPLAY_NAME",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'idCard',
                            text: "ID_CARD",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'passport',
                            text: "PASSPORT",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'workPermitNumber',
                            text: "WORK_PERMIT_NUMBER",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'gender',
                            text: "GENDER",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'dateOfBirth',
                            text: "BIRTHDAY",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        // 
                        {
                            id: 'nationalityId',
                            text: "NATIONALITY",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'originId',
                            text: "ORIGINS",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'religion',
                            text: "RELIGION",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'maritalStatus',
                            text: "MARITAL",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'careerId',
                            text: "CAREER",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'school',
                            text: "SCHOOL",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'accessChannelId',
                            text: "ACCESS_CHANNEL",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'serviceFactorId',
                            text: "SERVICE_FACTOR",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'phone',
                            text: "PHONE",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'telephone',
                            text: "TEL",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'line',
                            text: "LINE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'email',
                            text: "EMAIL",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'facebook',
                            text: "FACEBOOK",
                            isRequired: false,
                            isShow: true
                        },
                    ]
                },
                {
                    id: 'healthInformation',
                    subTitle: "HEALTH_INFORMATION",
                    isShow: true,
                    isRequired: false,
                    inputName: [
                        {
                            id: 'patientTypeId',
                            text: "PATIENT_TYPE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'bloodType',
                            text: "BLOOD_TYPE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'frequencyCigarette',
                            text: "FREQUENCY_CIGARETTE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'frequencyAlcohol',
                            text: "FREQUENCY_ALCOHOL",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'pregnantHistory',
                            text: "PREGNANT_HISTORY",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'surgeryHistory',
                            text: "SURGERY_HISTORY",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'surgeryDate',
                            text: "SURGERY_DATE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'disease',
                            text: "DISEASE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'medicineUse',
                            text: "MEDICINE_USE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'beAllergic',
                            text: "BE_ALLERGIC",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'importantSymptoms',
                            text: "IMPORTANT_SYMPTOMS",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'symptoms',
                            text: "SYMPTOMS",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'isPregnant',
                            text: "IS_PREGNANT",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'isRecuperate',
                            text: "IS_RECUPERATE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'isMedicalCertificate',
                            text: "IS_MEDICAL_CERTIFICATE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'isInsure',
                            text: "IS_INSURE",
                            isRequired: false,
                            isShow: true
                        },
                    ]
                },
                {
                    id: 'treatmentRight',
                    subTitle: "RIGHT_TO_TREATMENT",
                    isShow: true,
                    isRequired: false,
                    inputName: []
                },
                {
                    id: 'addressCard',
                    subTitle: "ADDRESS_ID_CARD",
                    isShow: true,
                    isRequired: false,
                    inputName: [
                        {
                            id: 'countryId',
                            text: "COUNTRY",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'address',
                            text: "SELECT_ADDRESS",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'address_detail',
                            text: "ADDRESS_DETAILS",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'address_detail_en',
                            text: "ADDRESS_DETAILS_EN",
                            isRequired: false,
                            isShow: true
                        },
                    ]
                },
                {
                    id: 'addressWork',
                    subTitle: "ADDRESS_AT_WORK",
                    isShow: true,
                    isRequired: false,
                    inputName: [
                        {
                            id: 'work_countryId',
                            text: "COUNTRY_WORK",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'work_address',
                            text: "SELECT_ADDRESS_WORK",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'work_address_detail',
                            text: "ADDRESS_DETAILS_WORK",
                            isRequired: false,
                            isShow: true
                        },
                    ]
                },
                {
                    id: 'parent',
                    subTitle: "PARENTS",
                    isShow: true,
                    isRequired: false,
                    inputName: []
                },
                {
                    id: 'vitalSign',
                    subTitle: "VITAL_SIGN",
                    isShow: true,
                    isRequired: false,
                    inputName: [
                        {
                            id: 'weight',
                            text: "WEIGHT",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'height',
                            text: "HEIGHT",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'bloodPressure',
                            text: "SYSTOLIC_BLOOD_PRESSURE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'waistline',
                            text: "WAISTLINE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'temperature',
                            text: "TEMPERATURE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'pulseRate',
                            text: "PULSE_RATE",
                            isRequired: false,
                            isShow: true
                        }, {
                            id: 'heartRate',
                            text: "HEART_RATE",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'regIrreq',
                            text: "REG_LRREQ",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'rr',
                            text: "RR",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'o2Sat',
                            text: "O2SAT",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'bmi',
                            text: "BMI",
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'bsa',
                            text: "BSA",
                            isRequired: false,
                            isShow: true
                        },
                    ]
                }
            ],

        },
        {
            title: "APPOINTMENT",
            sub: [
                {
                    id: 'add',
                    subTitle: "ADD",
                    isShow: true,
                    isRequired: true,
                    inputName: [
                        {
                            id: 'phone',
                            text: "PHONE",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'spacialInfo',
                            text: "SPACIAL_INFO",
                            isDisabled: false,
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'branch',
                            text: "BRANCH",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'date',
                            text: "DATEOF",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'doctor',
                            text: "DOCTOR",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'timeStart',
                            text: "TIME_START",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'duration',
                            text: "PERIOD",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'treatment',
                            text: "TREATEMENT",
                            isDisabled: true,
                            isRequired: true,
                            isShow: true
                        },
                        {
                            id: 'price',
                            text: "PRICE",
                            isDisabled: false,
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'lab',
                            text: "LIST_ORDER_LAB",
                            isDisabled: false,
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'xray',
                            text: "LIST_ORDER_XRAY",
                            isDisabled: false,
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'prictice',
                            text: "PRACTICE",
                            isDisabled: false,
                            isRequired: false,
                            isShow: true
                        },
                        {
                            id: 'note',
                            text: "REMARK",
                            isDisabled: false,
                            isRequired: false,
                            isShow: true
                        },
                    ]
                },
            ]
        }
    ]

    const handleOldForm = () => {
        const handleOldFormRegister = () => {
            if (props.formRegister?.data?.title !== "REGISTER") {
                const formRegister = initFormFormatForm.filter((item) => item.title === 'REGISTER')
                const formOld = props.formRegister?.data
                const newData = [{
                    ...formRegister[0], sub: formRegister[0]?.sub?.map((item) => {
                        if (formOld[item.id]) {
                            if (item?.inputName?.length) {
                                return {
                                    ...item,
                                    isShow: formOld[item.id] || formOld[item.id] === 'true' ? item?.isShow : false,
                                    inputName: item?.inputName?.map((info) => {
                                        if (formOld[info.id]) {
                                            return formOld[info.id] || formOld[info.id] === 'true' ? info : { ...info, isShow: false }
                                        } else {
                                            return info
                                        }
                                    }) || []
                                }
                            } else {
                                return { ...item, isShow: formOld[item.id] || formOld[item.id] === 'true' ? item?.isShow : false }
                            }
                        } else {
                            return item
                        }
                    })
                }]
                return newData
            }
            return [props.formRegister.data]
        }
        const handleOldFormAppointment = () => {
            if (props.formAppointment?.data?.title !== "APPOINTMENT") {
                const formAppointment = initFormFormatForm.filter((item) => item.title === 'APPOINTMENT')
                const formOld: any = props.formAppointment?.data
                const newData = [{
                    ...formAppointment[0],
                    ...formOld?.length && {
                        sub: formAppointment[0]?.sub?.map((item) => {
                            if (formOld[item.id]) {
                                if (item?.inputName?.length) {
                                    return {
                                        ...item,
                                        isShow: formOld[item.id] || formOld[item.id] === 'true' ? item?.isShow : false,
                                        inputName: item?.inputName?.map((info) => {
                                            if (formOld[info.id]) {
                                                return formOld[info.id] || formOld[info.id] === 'true' ? info : { ...info, isShow: false }
                                            } else {
                                                return info
                                            }
                                        }) || []
                                    }
                                } else {
                                    return { ...item, isShow: formOld[item.id] || formOld[item.id] === 'true' ? item?.isShow : false }
                                }
                            } else {
                                return item
                            }
                        })
                    }
                }]
                return newData
            }
            return [props.formAppointment.data]
        }
        return [
            ...handleOldFormRegister(),
            ...handleOldFormAppointment()
        ]
    }

    const [dataFormatForm, setDataFormatForm] = useState<FFFInfoProps[]>(handleOldForm())

    const handleChange = (data: FFFInfoProps, index: number) => {
        const newDataFormatForm = [...dataFormatForm]
        newDataFormatForm[index] = data
        setDataFormatForm(newDataFormatForm)
        if (data.title === 'REGISTER') {
            props.formRegister.setData(data)
        }
        if (data.title === 'APPOINTMENT') {
            props.formAppointment.setData(data)
        }
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                {dataFormatForm.map((item, index: number) => (
                    <CollapseFormatForm key={index} data={item} handleChange={(data) => handleChange(data, index)} />
                ))}
            </Box>
        </Box>
    )
}

export default FormFormatForm

type CollapseFormatForm = {
    data: FFFInfoProps
    handleChange: (data: FFFInfoProps) => void
}

const CollapseFormatForm = (props: CollapseFormatForm) => {
    const { data, handleChange } = props
    const { t } = useTranslation()

    const [open, setOpen] = useState<boolean>(true)

    const onHandleChangeCollapse = (dataFormChecker: FFFInfoSubProps, index: number) => {
        const newData = { ...data }
        newData.sub[index] = dataFormChecker
        handleChange(newData)
    }

    const onHandleChangeCollapseAll = (dataFormChecker: FFFInfoSubProps, type: 'Show' | 'Required', check: boolean, index: number) => {
        const newData = { ...data }
        newData.sub[index] = { ...dataFormChecker, [`is${type}`]: check }
        handleChange(newData)
    }

    return (
        // 
        <Box>
            <BoxFormCollapse >
                <Box>
                    {t(`FORMATFORM.TITLE.${data.title}`)}
                </Box>
                <Box
                    className={`box-icon-expand-more`}
                    sx={{ transform: `rotate(${open ? 0 : 180}deg)` }}
                    onClick={() => setOpen(!open)}
                >
                    <ExpandMoreIcon
                        sx={{
                            backgroundColor: colors.white,
                            border: `0.5px solid ${colors.textLightGray}`,
                            borderRadius: '50px',
                            color: colors.themeMainColor
                        }}
                    />
                </Box>
            </BoxFormCollapse>
            <Collapse in={open}  >
                {data?.sub?.map((item: FFFInfoSubProps, index: number) => {
                    return (
                        <FormChecker
                            key={index}
                            dataSub={item}
                            handleChange={(dataFormChecker) => onHandleChangeCollapse(dataFormChecker, index)}
                            handleChangeAll={(dataFormChecker, type, check) => onHandleChangeCollapseAll(dataFormChecker, type, check, index)}
                            title={data.title}
                        />
                    )
                })}
            </Collapse >
        </Box >
    )
}

type FormatFormCheckFormProps = {
    dataSub: FFFInfoSubProps
    handleChange: (data: FFFInfoSubProps) => void
    handleChangeAll: (dataFormChecker: FFFInfoSubProps, type: 'Show' | 'Required', check: boolean) => void
    title?: string
}

const FormChecker = (props: FormatFormCheckFormProps) => {
    const { dataSub, handleChange, handleChangeAll, title } = props

    const [dataInputName, setDataInputName] = useState<[FFFInfoSubInputProps[], FFFInfoSubInputProps[]] | []>([])
    const [checkTopic, setCheckTopic] = useState<boolean[]>([false, false])
    const [checkSub, setCheckSub] = useState<boolean[]>([false, false])

    const handleCheckAll = (data: FFFInfoSubInputProps[]) => {
        let checkShow = true
        let checkReq = true
        for (const index in data) {
            const dataIndex = data[index]
            if (!dataIndex?.isDisabled && dataIndex) {
                if (!dataIndex.isShow) {
                    checkShow = false
                }
                if (!dataIndex.isRequired) {
                    checkReq = false
                }
                if (!checkShow && !checkReq) break;
            }
        }
        return [checkShow, checkReq]
    }

    useEffect(() => {
        if (dataSub?.inputName && dataSub?.inputName?.length) {
            if (dataSub.inputName.length >= 3) {
                const middleIndex = Math.ceil(dataSub.inputName.length / 2);
                const data1 = dataSub.inputName.slice(0, middleIndex);
                const data2 = dataSub.inputName.slice(middleIndex);
                setDataInputName([data1, data2])

                setCheckTopic(handleCheckAll(data1) || [false, false])
                setCheckSub(handleCheckAll(data2) || [false, false])

            } else {
                setDataInputName([dataSub.inputName, []])
                setCheckTopic(handleCheckAll(dataSub.inputName) || [false, false])
            }
        }
    }, [dataSub])

    const onHandleChangeForm = (dataChecker: FFFInfoSubInputProps, index: number) => {
        const newDataSub = { ...dataSub }
        newDataSub.inputName[index] = dataChecker
        handleChange(newDataSub)
    }

    const onHandleChangeFormAll = (check: boolean, type: 'Show' | 'Required', status: 'TOPIC' | 'SUB') => {
        const index = status === "TOPIC" ? 0 : 1
        const newDataSubInput = dataSub.inputName.map((item) => {
            if (!item?.isDisabled && dataInputName[index]?.includes(item)) {
                if (type === 'Show') {
                    const checkedReq = check ? item[`isRequired`] : false
                    return { ...item, [`is${type}`]: check, [`isRequired`]: checkedReq }
                }
                return { ...item, [`is${type}`]: item.isShow ? check : false }
            } else {
                return item
            }
        })
        const newDataSub = { ...dataSub, inputName: newDataSubInput }
        if (type === 'Show' && !newDataSub?.inputName?.length) {
            newDataSub[`isRequired`] = false
        }

        const checkAll = newDataSubInput?.length ? newDataSubInput.some((item) => item[`is${type}`]) : !newDataSub[`is${type}`]

        handleChangeAll(newDataSub, type, checkAll)
    }

    return (
        <Box sx={{ padding: '16px 24px 0', }} >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: '100%',
                    paddingBottom: '16px',
                    borderBottom: `1px solid ${colors.lightGray}`
                }}
            >
                <Box sx={{ width: '50%' }}>
                    <FormCheckerHeader
                        dataSub={dataSub}
                        status={'TOPIC'}
                        checker={checkTopic}
                        handleChangeAll={onHandleChangeFormAll}
                        title={title}
                        dataInputName={dataInputName[0]}
                    />
                    {dataSub.inputName?.length && dataInputName[0]?.length &&
                        <Box sx={{ padding: '16px 0 0 0' }}>
                            {dataInputName[0].map((info, idx: number) => {
                                return (
                                    <InputCheckerBox
                                        key={idx}
                                        info={info}
                                        handleChange={(dataChecker) => onHandleChangeForm(dataChecker, idx)}
                                        title={title}
                                        subTitle={dataSub.subTitle}
                                    />
                                )
                            })}
                        </Box> || <></>
                    }
                </Box>
                {dataSub.inputName?.length && dataInputName[1]?.length &&
                    <Box sx={{ width: '50%' }}>
                        <FormCheckerHeader
                            dataSub={dataSub}
                            status={'SUB'}
                            checker={checkSub}
                            handleChangeAll={onHandleChangeFormAll}
                            dataInputName={dataInputName[1]}
                        />
                        <Box sx={{ padding: '16px 0 0 0' }}>
                            {dataInputName[1].map((info, idx: number) => {
                                return (
                                    <InputCheckerBox
                                        key={idx}
                                        info={info}
                                        handleChange={(dataChecker) => onHandleChangeForm(dataChecker, ((dataInputName[0]?.length || 0) + idx))}
                                        title={title}
                                        subTitle={dataSub.subTitle}
                                    />
                                )
                            })}
                        </Box>
                    </Box> || <></>
                }
            </Box>
        </Box>
    )
}

type FormCheckerHeaderProps = {
    dataSub: FFFInfoSubProps
    status: 'TOPIC' | 'SUB'
    checker: boolean[]
    handleChangeAll: (check: boolean, type: 'Show' | 'Required', status: 'TOPIC' | 'SUB') => void
    title?: string
    dataInputName?: FFFInfoSubInputProps[]
}

const FormCheckerHeader = (props: FormCheckerHeaderProps) => {
    const { t } = useTranslation()
    const { dataSub, status, checker, handleChangeAll, title, dataInputName } = props

    const [checkedDisabled, setCheckedDisabled] = useState<boolean>(false)

    const renderCheckerAll = (type: 'Show' | 'Required') => {
        if (!dataSub?.inputName?.length) {
            return dataSub[`is${type}`]
        } else {
            return checker[`${type === 'Show' ? 0 : 1}`]
        }
    }

    const renderDisabledAll = () => {
        if (!dataSub?.inputName?.length) {
            const check = dataSub[`isShow`]
            setCheckedDisabled(check)
        } else {
            const check = dataInputName?.some((item) => {
                if (item?.isDisabled) {
                    return false
                } else {
                    return item.isShow
                }
            })
            setCheckedDisabled(check || false)
        }
    }

    useEffect(() => {
        renderDisabledAll()
    }, [dataSub, dataInputName])

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
            <Grid xs={6}>
                <Typography sx={{ fontSize: '18px', fontWeight: '500' }}>
                    {status === "TOPIC" && title && t(`FORMATFORM.${title}.SUBTITLE.${dataSub.subTitle}`) || ''}
                </Typography>
            </Grid>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }} xs >
                <Grid xs={6}>
                    <InputCheckbox label={t('FORMATFORM.SHOW')} checked={renderCheckerAll('Show')} onChange={(value: any) => handleChangeAll(!renderCheckerAll('Show'), 'Show', status)} />
                </Grid>
                <Grid
                    xs={6}
                    sx={{
                        '.MuiTypography-root': {
                            color: 'black !important'
                        },
                        '.checkbox': {
                            filter: !checkedDisabled ? 'grayscale(1)' : '',
                            opacity: !checkedDisabled ? '0.7' : '',
                            background: !checkedDisabled ? '#1CB99A !important' : '',
                        }
                    }}>
                    <InputCheckbox
                        label={t('FORMATFORM.REQUIRED')}
                        checked={renderCheckerAll('Required')}
                        onChange={(value: any) => handleChangeAll(!renderCheckerAll('Required'), 'Required', status)}
                        disabled={!checkedDisabled}
                    />
                </Grid>
            </Grid>
        </Grid>
    )

}

type InputCheckerBoxProps = {
    info: FFFInfoSubInputProps
    handleChange: (data: FFFInfoSubInputProps) => void
    title?: string
    subTitle?: string
}

const InputCheckerBox = (props: InputCheckerBoxProps) => {
    const { t } = useTranslation()
    const { info, handleChange, title } = props

    const onHandleChangeChecker = (value: any, status: 'Show' | 'Required') => {
        if (status === 'Show') {
            const newCheck: FFFInfoSubInputProps = { ...info, [`is${status}`]: !info[`is${status}`], isRequired: false }
            handleChange(newCheck)
            return
        }
        const newCheck: FFFInfoSubInputProps = { ...info, [`is${status}`]: !info[`is${status}`] }
        handleChange(newCheck)
    }

    return (
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
            <Grid xs={6}>
                {t(`FORMATFORM.${title}.INPUTNAME.${info.text}`)}
            </Grid>
            <Grid
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '.MuiCheckbox-root': {
                        padding: '8px 0'
                    }
                }}
                xs
            >
                <Grid xs={6}>
                    <InputCheckbox
                        label={t('')}
                        checked={info.isShow}
                        disabled={info.isDisabled}
                        onChange={(value: any) => onHandleChangeChecker(value, 'Show')}
                    />
                </Grid>
                <Grid
                    xs={6}
                    sx={{
                        '.checkbox': {
                            filter: !info.isShow ? 'grayscale(1)' : '',
                            opacity: !info.isShow ? '0.7' : '',
                            background: !info.isShow ? '#1CB99A !important' : '',
                        }
                    }}
                >
                    <InputCheckbox
                        label={t('')}
                        checked={info.isRequired}
                        disabled={info.isDisabled || !info.isShow}
                        onChange={(value: any) => onHandleChangeChecker(value, 'Required')}
                    />
                </Grid>
            </Grid>
        </Grid >
    )
}