import { useState, useEffect, useRef } from 'react'
import { Typography, Box } from '@mui/material'
import { useDispatch } from 'react-redux';
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { resetModal } from 'app/slice/modal.slice'
import { CHECK_ALL_TYPE } from 'constants/common'
import { ProductStickerType, StickerType } from 'app/slice/treatment.slice';
import { DoctorsType } from 'features/treatments/treatmentsIndex'

/** API */
import TreatmentProductApi from 'api/dentists/treatmentProduct.api'

/** COMPONENT */
import ModalCustom from 'component/ModalCustom/ModalCustom'
import FunctionPrint from 'component/Print/print'
import PdfMedSticker from 'features/pdf/pdf-med-sticker'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'

/** STYLE */
import { styled } from '@mui/material/styles'

const ResponsiveTable = styled(Box)(({ theme }) => ({
    '.MuiTable-root': {
        '.MuiTableBody-root': {
            '.MuiTableCell-root': {
                padding: '15px',
                '&:first-of-type': {
                    paddingTop: 8,
                    paddingBottom: 8
                }
            }
        }
    }
}))

interface PopupSelectPrintProductProps {
    isShow: boolean
    setIsShow: (show: boolean) => void
    patientCaseId: number
    doctors: DoctorsType[]
}

export interface ProductStickerSelectType extends ProductStickerType {
    stickerObj: StickerType
}

export default function PopupSelectPrintProduct(props: PopupSelectPrintProductProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const componentRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLDivElement>(null)

    const [products, setProducts] = useState<ProductStickerSelectType[]>([]);
    const [productsSelect, setProductsSelect] = useState<ProductStickerSelectType[]>([]);
    const [isCheckAll, setIsCheckAll] = useState<CHECK_ALL_TYPE | null>(CHECK_ALL_TYPE.CHECKED)

    const loadStickerProduct = () => {
        if (props.patientCaseId) {
            TreatmentProductApi.findAllSticker(props.patientCaseId)
                .then(({ data }) => {
                    let newData: ProductStickerSelectType[] = []
                    _.forEach(data, (p: ProductStickerType) => {
                        if (p.sticker) newData = [...newData, { ...p, stickerObj: JSON.parse(p.sticker) }]
                    })
                    setProducts(newData)
                    setProductsSelect(newData)
                })
                .catch(() => { return })
        }
    }

    const onCheckAll = (event: any) => {
        const { checked } = event
        if (checked) setProductsSelect(Array.from(new Set([...productsSelect, ...products])))
        else setProductsSelect([])
    }

    const onCheck = (event: any) => {
        const { checked, value } = event
        if (checked) {
            const item: any = _.find(products, (p: ProductStickerSelectType) => p.stickerObj.itemId === Number(value))
            if (item) setProductsSelect([...productsSelect, item])
        } else {
            setProductsSelect(
                _.filter(productsSelect, (i: ProductStickerSelectType) => {
                    return Number(i.stickerObj.itemId) !== Number(value)
                })
            )
        }
    }

    const headCells = [
        {
            id: 'stickerSelect',
            disablePadding: false,
            label: 'CHECKBOX',
            indeterminate: isCheckAll === CHECK_ALL_TYPE.INDETERMINATE ? true : false,
            checked: isCheckAll === CHECK_ALL_TYPE.CHECKED ? true : false,
            disableDropdown: _.isEmpty(productsSelect),
            values: [],
            onCheckAll: (event: any) => onCheckAll(event.target),
            width: '70px'
        },
        { id: 'no', disablePadding: false, label: t('#'), width: '60px' },
        { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.POPUP.MANAGE_STICKER.TABLE.NAME'), width: '200px' },
        { id: 'message', disablePadding: false, label: t('TREATMENT_SUMMARY.POPUP.MANAGE_STICKER.TABLE.INSTRUCTION'), class: 'p-3' }
    ]

    const renderData = (objData: ProductStickerSelectType, no: number) => {
        const rowCheck = _.some(productsSelect, (product: ProductStickerSelectType) => Number(product.stickerObj.itemId) === Number(objData.stickerObj.itemId))

        const objRenderData = {
            key: no.toString(),
            id: no.toString(),
            obj: objData,
            rowSelect: rowCheck,
            columns: [
                { option: 'CHECKBOX', align: 'center', label: objData.stickerObj.itemId },
                { option: 'TEXT', align: 'center', label: no + 1 },
                { option: 'TEXT', align: 'left', label: objData.stickerObj.itemName || objData.stickerObj.itemNameEn || '-', class: 'text-ellipsis' },
                { option: 'TEXT', align: 'left', label: (!objData.stickerObj.productInstructionName && !objData.stickerObj.dosage && !objData.stickerObj.productUnitName && !objData.stickerObj.productUseDetail && '-') || `${objData.stickerObj.productInstructionName || ''} ${objData.stickerObj.dosage || ''} ${objData.stickerObj.productUnitName || ''} ${objData.stickerObj.productUseDetail || ''}`, class: 'text-ellipsis' }
            ]
        }

        return <TableRowCommon {...objRenderData} onCheck={(event) => onCheck(event.target)} />
    }

    const onPrint = () => {
        buttonRef.current?.click()
    }

    useEffect(() => {
        if (_.size(products) === _.size(productsSelect)) setIsCheckAll(CHECK_ALL_TYPE.CHECKED)
        else if (_.size(productsSelect) > 0) setIsCheckAll(CHECK_ALL_TYPE.INDETERMINATE)
        else setIsCheckAll(null)
    }, [productsSelect])

    useEffect(() => {
        loadStickerProduct()
    }, []);

    return (
        props.isShow && (
            <ModalCustom
                size={'xl'}
                title={t('TREATMENT_SUMMARY.POPUP.MANAGE_STICKER.TITLE')}
                alignFooter={'end'}
                footerInline
                onSubmit={onPrint}
                onReset={() => {
                    dispatch(resetModal())
                    props.setIsShow(false)
                }}
                textBtnCancel={t('BUTTON.CANCEL')}
                textBtnConfirm={t('BUTTON.PRINT')}
                modalStyle={'modal-popup-select-medicine'}
                component={
                    <>
                        <Typography className='pt-2' sx={{ fontSize: '18px', fontWeight: 500 }}>{t('TREATMENT_SUMMARY.POPUP.MANAGE_STICKER.SUBTITLE')}</Typography>
                        <ResponsiveTable className='mt-3'>
                            <TableCustom
                                hidePagination
                                tableFixedWidth
                                page={1}
                                pageLimit={1}
                                sortType={''}
                                sortBy={''}
                                rowCount={1}
                                onSort={() => { return }}
                                setPageLimit={() => { return }}
                                setPage={() => { return }}
                                headCells={headCells}
                                rowsData={_.map(products, (product: ProductStickerSelectType, index: number) => {
                                    return renderData(product, index)
                                })}
                            />
                        </ResponsiveTable>

                        {/* print */}
                        <>
                            <FunctionPrint
                                content={() => componentRef.current}
                                docTitle={t('TX_INFORMATION.TITLE')}
                                removeIfram
                                trigger={() => <div ref={buttonRef} className={`d-none`}>print</div>}
                                style={'@page { size: 302.36220472px 188.97637795px;  margin: 0cm;  } @media print { body { -webkit-print-color-adjust: exact; } '}
                            />
                            <div ref={componentRef} className="print-show h-100 w-100">
                                <PdfMedSticker stickerData={productsSelect} doctors={props.doctors} />
                            </div>
                        </>
                    </>
                }
            />
        ) || <></>
    )
}
