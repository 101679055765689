import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, Grid, TableSortLabel, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'

export interface DoctorFeeComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string,
  componentRef: any
}

export default function RevenueTransactionList(props: DoctorFeeComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: 'B', disablePadding: false, labelKey: 'วันที่', align: 'center', class: 'text-nowrap' },
    { id: 'C', disablePadding: false, labelKey: 'เลขที่ใบเสร็จ', align: 'center', class: 'text-nowrap' },
    { id: 'D', disablePadding: false, labelKey: 'สถานะใบเสร็จ', align: 'center', class: 'text-nowrap' },
    { id: 'E', disablePadding: false, labelKey: 'รหัสผู้ป่วย', align: 'center', class: 'text-nowrap' },
    { id: 'F', disablePadding: false, labelKey: 'รหัสผู้ป่วยในสาขา ', align: 'center', class: 'text-nowrap' },
    { id: 'G', disablePadding: false, labelKey: 'ชื่อผู้ป่วย', align: 'center', class: 'text-nowrap' },
    { id: 'H', disablePadding: false, labelKey: 'ทันตแพทย์', align: 'center', class: 'text-nowrap' },
    { id: 'I', disablePadding: false, labelKey: 'รหัสรายการหัตถการ', align: 'center', class: 'text-nowrap' },
    { id: 'J', disablePadding: false, labelKey: 'รายการ', align: 'center', class: 'text-nowrap' },
    { id: 'K', disablePadding: false, labelKey: 'ราคาต่อหน่วย', align: 'center', class: 'text-nowrap' },
    { id: 'L', disablePadding: false, labelKey: 'จำนวน', align: 'center', class: 'text-nowrap' },
    { id: 'M', disablePadding: false, labelKey: 'ราคาค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: 'N', disablePadding: false, labelKey: 'DF%', align: 'center', class: 'text-nowrap' },
    { id: 'O', disablePadding: false, labelKey: 'ยอดค่ารักษา DF', align: 'center', class: 'text-nowrap' },
    { id: 'P', disablePadding: false, labelKey: 'CF%', align: 'center', class: 'text-nowrap' },
    { id: 'Q', disablePadding: false, labelKey: 'ยอดค่ารักษา CF', align: 'center', class: 'text-nowrap' },
    { id: 'R', disablePadding: false, labelKey: 'Total Discount (THB)', align: 'center', class: 'text-nowrap' },
    { id: 'S', disablePadding: false, labelKey: 'Total Additional (THB)', align: 'center', class: 'text-nowrap' },
    { id: 'T', disablePadding: false, labelKey: 'ยอดรวมสุทธิค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: 'U', disablePadding: false, labelKey: 'จ่ายค่ารักษา', align: 'center', class: 'text-nowrap' },
    { id: 'V', disablePadding: false, labelKey: 'จ่ายค้างชำระ', align: 'center', class: 'text-nowrap' },
    { id: 'W', disablePadding: false, labelKey: 'Advance', align: 'center', class: 'text-nowrap' },
    { id: 'X', disablePadding: false, labelKey: 'ยอดรับเงินสุทธิ', align: 'center', class: 'text-nowrap' },
    { id: 'Y', disablePadding: false, labelKey: 'เงินสด', align: 'center', class: 'text-nowrap' },
    { id: 'Z', disablePadding: false, labelKey: 'เงินโอน', align: 'center', class: 'text-nowrap' },
    { id: 'AA', disablePadding: false, labelKey: 'เดบิต', align: 'center', class: 'text-nowrap' },
    { id: 'AB', disablePadding: false, labelKey: 'เครดิต', align: 'center', class: 'text-nowrap' },
    { id: 'AC', disablePadding: false, labelKey: 'Voucher', align: 'center', class: 'text-nowrap' },
    { id: 'AD', disablePadding: false, labelKey: 'สิทธิการรักษา', align: 'center', class: 'text-nowrap' },
    { id: 'AE', disablePadding: false, labelKey: 'Clear Advance', align: 'center', class: 'text-nowrap' },
    { id: 'AF', disablePadding: false, labelKey: 'Adv เงินสด', align: 'center', class: 'text-nowrap' },
    { id: 'AG', disablePadding: false, labelKey: 'Adv เงินโอน', align: 'center', class: 'text-nowrap' },
    { id: 'AH', disablePadding: false, labelKey: 'Adv เดบิต', align: 'center', class: 'text-nowrap' },
    { id: 'AI', disablePadding: false, labelKey: 'Adv เครดิต', align: 'center', class: 'text-nowrap' },
    { id: 'AJ', disablePadding: false, labelKey: 'ยอดรวมเครดิต', align: 'center', class: 'text-nowrap' },
    { id: 'AK', disablePadding: false, labelKey: 'Balance', align: 'center', class: 'text-nowrap' },
    { id: 'AL', disablePadding: false, labelKey: 'ผู้บันทึก', align: 'center', class: 'text-nowrap' },
    { id: 'AM', disablePadding: false, labelKey: 'รายละเอียด', align: 'center', class: 'text-nowrap' },
    { id: 'AN', disablePadding: false, labelKey: 'หมายเหตุยกเลิก', align: 'center', class: 'text-nowrap' },
    { id: 'AO', disablePadding: false, labelKey: 'ผู้ยกเลิก', align: 'center', class: 'text-nowrap' }
  ]

  if (!snType) {
    // headCells.splice(3, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    if (render === 'data') {
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.paymentNumber || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: t(`REPORT.BILL_STATUS.${data.statusOrder}`), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.cnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.branchCnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.patientFullname || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.doctorFullname || '-', class: 'text-nowrap' },
          {
            option: 'COMPONENT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            component: (<label>{data.code || '-'}</label>),
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.itemName || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.price, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.qty, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.totalOriginal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.dfPercentOriginal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.dfBahtOriginal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.cfPercentOriginal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.cfBahtOriginal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.discountTotal, 2), class: 'text-nowrap' },
          // { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.discountTotal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.additionalTotal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.total, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountPaid, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountPaidOverdue, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.receiptAdvance, 2), class: 'text-nowrap' },
          {
            option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountCash
              + data.amountTransfer
              + data.amountCredit
              + data.amountDebit
              + data.amountTreatmentRight
              + data.amountVoucher, 2), class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountCash, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountTransfer, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountDebit, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountCredit, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountVoucher, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountTreatmentRight, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.amountAdvance, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.receiptAdvanceCash, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.receiptAdvanceTransfer, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.receiptAdvanceDebit, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.receiptAdvanceCredit, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat((data.amountCredit || 0) + (data.receiptAdvanceCredit || 0), 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(data.overdue, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.staff, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.remark, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.cancelRemark, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: data.cancelBy, class: 'text-nowrap' }
        ]
      }
      // if (!snType) {
      //   objRenderData.columns.splice(3, 1)
      // }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: 8 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 },
            label: <Typography sx={{ fontWeight: 500, fontSize: exportData ? 10 : 16 }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'price') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'qty') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'totalOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfPercentOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'dfBahtOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfPercentOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'cfBahtOriginal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'discountTotal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'additionalTotal') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'total') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaid') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountPaidOverdue') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat((_.sumBy(data, 'amountPaid') || 0) + (_.sumBy(data, 'amountTransfer') || 0) + (_.sumBy(data, 'amountCredit') || 0) + (_.sumBy(data, 'amountDebit') || 0) + (_.sumBy(data, 'amountTreatmentRight') || 0) + (_.sumBy(data, 'amountVoucher') || 0)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountTreatmentRight') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountVoucher') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'amountAdvance') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceCash') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceTransfer') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceDebit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'receiptAdvanceCredit') || 0), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat((_.sumBy(data, 'amountCredit') || 0) + (_.sumBy(data, 'receiptAdvanceCredit') || 0)), class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: exportData ? 10 : 16 }, label: numberFormat(_.sumBy(data, 'overdue') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 4 }
        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }

  const headTotalCells = [
    { id: '1', disablePadding: false, labelKey: 'รายการ', align: 'center', class: 'text-nowrap', },
    { id: '2', disablePadding: false, labelKey: 'ยอดรวม', align: 'center', class: 'text-nowrap', },
  ]

  return (
    <>
      <Box>
        <Typography variant='h6' sx={{ marginRight: "5px" }} >
          Revenue Transaction
        </Typography>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data.rows, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.dataExport.rows, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Box>
        <br />
        <Grid container spacing={2} className="mt-1">
          {renderTableSum({ rowsData: props.dataExport.rows, headCells: headTotalCells })}
          {renderTableSum2({ rowsData: props.dataExport.rows, headCells: headTotalCells })}
          {renderTableSum3({ rowsData: props.dataExport.rows, headCells: headTotalCells })}
          {renderTableSum4({ rowsData: props.dataExport.rows, headCells: headTotalCells })}
          {renderTableSum5({ rowsData: props.dataExport.rows, headCells: headTotalCells })}
          {renderTableSum6({ rowsData: props.dataExport.rows, headCells: headTotalCells })}
          {renderTableSum7({ rowsData: props.dataExport.rows, headCells: headTotalCells })}
        </Grid>
      </Box>
      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`Revenue Transaction Report`)}
          componant={
            <>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500 }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      _.map(props.dataExport.rows, (d: any, index: number) => {
                        return renderData(d, index, false, 'data')
                      }),
                      renderData(props.dataExport.rows, 0, false, 'sum')
                    ]}
                  </tbody>
                </table>
              </UseStyled.Styles>

              <br />
              <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
                <tbody>
                  <tr>
                    <td>{renderTableSum({ rowsData: props.dataExport.rows, headCells: headTotalCells }, true)}</td>
                    <td></td>
                    <td></td>
                    <td>{renderTableSum2({ rowsData: props.dataExport.rows, headCells: headTotalCells }, true)}</td>
                  </tr>
                  <tr>
                    <td>{renderTableSum3({ rowsData: props.dataExport.rows, headCells: headTotalCells }, true)}</td>
                    <td></td>
                    <td></td>
                    <td>{renderTableSum4({ rowsData: props.dataExport.rows, headCells: headTotalCells }, true)}</td>
                  </tr>
                  <tr>
                    <td>{renderTableSum5({ rowsData: props.dataExport.rows, headCells: headTotalCells }, true)}</td>
                    <td></td>
                    <td></td>
                    <td>{renderTableSum6({ rowsData: props.dataExport.rows, headCells: headTotalCells }, true)}</td>
                    <td></td>
                    <td></td>
                    <td>{renderTableSum7({ rowsData: props.dataExport.rows, headCells: headTotalCells }, true)}</td>
                  </tr>
                </tbody>
              </table>
            </>
          }
        />
      </div>
    </>
  )
}


export function ExportData(props: ExportProps) {
  return (
    <>
      <div className="large">{props.headCells}</div>
      {props.componant}
    </>
  )
}

export interface SumProps {
  exportData?: boolean
  headCells?: any
  rowsData?: any
}

const renderTableSum = (props: SumProps, isExport = false) => {
  const countPatient = _.uniqBy(props.rowsData, 'patientId').length;
  const countPate = _.uniqBy(props.rowsData, 'date').length
  const countPaymentNumber = _.uniqBy(props.rowsData, 'paymentNumber').length


  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]} columns={[
              { option: 'TEXT', align: 'left', label: 'จำนวนผู้ป่วย' },
              { option: 'TEXT', align: 'right', label: (countPatient || 0) }
            ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]} columns={[
              { option: 'TEXT', align: 'left', label: 'จำนวนวัน' },
              { option: 'TEXT', align: 'right', label: (countPate || 0) }
            ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]} columns={
              [
                { option: 'TEXT', align: 'left', label: 'จำนวนใบเสร็จ' },
                { option: 'TEXT', align: 'right', label: (0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'จำนวนผู้ป่วย' },
            { option: 'TEXT', align: 'right', label: (countPatient || 0) }
          ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'จำนวนวัน' },
            { option: 'TEXT', align: 'right', label: (countPate || 0) }
          ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]} columns={
            [
              { option: 'TEXT', align: 'left', label: 'จำนวนใบเสร็จ' },
              { option: 'TEXT', align: 'right', label: (0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum2 = (props: SumProps, isExport = false) => {
  const dfNet = _.sumBy(props.rowsData, 'dfNet');
  const cfNet = _.sumBy(props.rowsData, 'cfNet')
  const amountCash = _.sumBy(props.rowsData, 'amountCash')
  const amountTransfer = _.sumBy(props.rowsData, 'amountTransfer')
  const amountCredit = _.sumBy(props.rowsData, 'amountCredit')
  const amountDebit = _.sumBy(props.rowsData, 'amountDebit')
  const amountVoucher = _.sumBy(props.rowsData, 'amountVoucher')
  const amountAdvance = _.sumBy(props.rowsData, 'amountAdvance')
  const amountTreatmentRight = _.sumBy(props.rowsData, 'amountTreatmentRight')
  const creditCost = _.sumBy(props.rowsData, 'creditCost')

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]} columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรวม DF' },
              { option: 'TEXT', align: 'right', label: numberFormat(dfNet || 0) }
            ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]} columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรวม CF' },
              { option: 'TEXT', align: 'right', label: numberFormat((((amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) - dfNet) - creditCost) || 0) }
            ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]} columns={
              [
                { option: 'TEXT', align: 'left', label: 'ยอดรวม Credit' },
                { option: 'TEXT', align: 'right', label: numberFormat(creditCost || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'ยอดรวม DF' },
            { option: 'TEXT', align: 'right', label: numberFormat(dfNet || 0) }
          ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]} columns={[
            { option: 'TEXT', align: 'left', label: 'ยอดรวม CF' },
            { option: 'TEXT', align: 'right', label: numberFormat((((amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) - dfNet) - creditCost) || 0) }
          ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]} columns={
            [
              { option: 'TEXT', align: 'left', label: 'ยอดรวม Credit' },
              { option: 'TEXT', align: 'right', label: numberFormat(creditCost || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum3 = (props: SumProps, isExport = false) => {
  const receiptAdvance = _.sumBy(props.rowsData, 'receiptAdvance');
  const receiptAdvanceCash = _.sumBy(props.rowsData, 'receiptAdvanceCash')
  const receiptAdvanceTransfer = _.sumBy(props.rowsData, 'receiptAdvanceTransfer')
  const receiptAdvanceCredit = _.sumBy(props.rowsData, 'receiptAdvanceCredit')
  const receiptAdvanceDebit = _.sumBy(props.rowsData, 'receiptAdvanceDebit')


  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {
            [
              <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'ยอดรับเงิน Advance' },
                  { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvance || 0) }
                ]} />,
              <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCash || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceTransfer || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceDebit || 0) }
                ]} />,
              <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
                columns={[
                  { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                  { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCredit || 0) }
                ]} />
            ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรับเงิน Advance' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvance || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(receiptAdvanceCredit || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum4 = (props: SumProps, isExport = false) => {
  const amountAdvance = _.sumBy(props.rowsData, 'amountAdvance');
  const amountAdvanceCash = _.sumBy(props.rowsData, 'amountAdvanceCash')
  const amountAdvanceTransfer = _.sumBy(props.rowsData, 'amountAdvanceTransfer')
  const amountAdvanceCredit = _.sumBy(props.rowsData, 'amountAdvanceCredit')
  const amountAdvanceDebit = _.sumBy(props.rowsData, 'amountAdvanceDebit')

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดใช้เงิน Advance' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCredit || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดใช้เงิน Advance' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvanceCredit || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum5 = (props: SumProps, isExport = false) => {
  const amountPaidOverdue = _.sumBy(props.rowsData, 'amountPaidOverdue');
  const amountCash = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountCash
    else return 0
  })
  const amountTransfer = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountTransfer
    else return 0
  })
  const amountCredit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountCredit
    else return 0
  })
  const amountDebit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountDebit
    else return 0
  })
  const amountVoucher = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountVoucher
    else return 0
  })
  const amountAdvance = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountAdvance
    else return 0
  })
  const amountTreatmentRight = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaidOverdue > 0) return o.amountTreatmentRight
    else return 0
  })

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค้างชำระ' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountPaidOverdue || 0) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค้างชำระ' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountPaidOverdue || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum6 = (props: SumProps, isExport = false) => {
  const amountPaid = _.sumBy(props.rowsData, (o: any) => {
    return (
      Number(o.amountPaid) +
      Number(o.receiptAdvance) +
      Number(o.amountTreatmentRight)
    )
  });
  let amountCash = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountCash
    else return 0
  })
  let amountTransfer = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountTransfer
    else return 0
  })
  let amountCredit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountCredit
    else return 0
  })
  let amountDebit = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountDebit
    else return 0
  })
  const amountVoucher = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountVoucher
    else return 0
  })
  const amountAdvance = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountAdvance
    else return 0
  })
  const amountTreatmentRight = _.sumBy(props.rowsData, (o: any) => {
    if (o.amountPaid > 0) return o.amountTreatmentRight
    else return 0
  })


  amountCash += _.sumBy(props.rowsData, (o: any) => { return Number(o.receiptAdvanceCash) });
  amountTransfer += _.sumBy(props.rowsData, (o: any) => { return Number(o.receiptAdvanceTransfer) });
  amountCredit += _.sumBy(props.rowsData, (o: any) => { return Number(o.receiptAdvanceCredit) });
  amountDebit += _.sumBy(props.rowsData, (o: any) => { return Number(o.receiptAdvanceDebit) });

  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค่ารักษา' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรวมจ่ายค่ารักษา' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash + amountTransfer + amountCredit + amountDebit + amountVoucher + amountAdvance + amountTreatmentRight) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}

const renderTableSum7 = (props: SumProps, isExport = false) => {
  const amountPaid = _.sumBy(props.rowsData, (o: any) => {
    return (
      Number(o.amountPaid) +
      Number(o.amountPaidOverdue) +
      Number(o.receiptAdvance) +
      Number(o.amountTreatmentRight)
      // Number(o.amountAdvance)
      // Number(o.overdue)
    )
  });
  // const amountPaid = _.sumBy(props.rowsData, (o: any) => {
  //   return (
  //     Number(o.amountCash) +
  //     Number(o.amountTransfer) +
  //     Number(o.amountCredit) +
  //     Number(o.amountDebit) +
  //     Number(o.amountAdvance) +
  //     Number(o.receiptAdvance)
  //   )
  // });
  const amountCash = _.sumBy(props.rowsData, (o: any) => { return Number(o.amountCash) + Number(o.receiptAdvanceCash) });
  const amountTransfer = _.sumBy(props.rowsData, (o: any) => { return Number(o.amountTransfer) + Number(o.receiptAdvanceTransfer) });
  const amountCredit = _.sumBy(props.rowsData, (o: any) => { return Number(o.amountCredit) + Number(o.receiptAdvanceCredit) });
  const amountDebit = _.sumBy(props.rowsData, (o: any) => { return Number(o.amountDebit) + Number(o.receiptAdvanceDebit) });
  const amountVoucher = _.sumBy(props.rowsData, (o: any) => { return Number(o.amountVoucher) });
  const amountAdvance = _.sumBy(props.rowsData, (o: any) => { return Number(o.amountAdvance) });
  const amountTreatmentRight = _.sumBy(props.rowsData, 'amountTreatmentRight')
  const balance = _.sumBy(props.rowsData, 'overdue')


  if (isExport)
    return <UseStyled.Styles className="pt-3">
      <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%' }}>
        <thead>
          <tr>
            {_.map(props.headCells, (val: any) => {
              return <td style={{ textAlign: val.align, fontSize: 10, fontWeight: 500, ...val.style }}> {val.labelKey}</td>
            })}
          </tr>
        </thead>
        <tbody>
          {[
            <TableRowExport key={`total-1`} id={`total-1`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ยอดรับหน้าร้าน' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountPaid || 0) }
              ]} />,
            <TableRowExport key={`total-2`} id={`total-2`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
              ]} />,
            <TableRowExport key={`total-3`} id={`total-3`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
              ]} />,
            <TableRowExport key={`total-9`} id={`total-9`} obj={[1]}
              columns={[
                { option: 'TEXT', align: 'left', label: 'ค้างชำระ (THB)' },
                { option: 'TEXT', align: 'right', label: numberFormat(balance || 0) }
              ]} />
          ]}
        </tbody>
      </table>
    </UseStyled.Styles>
  else return <Grid item lg={5} sm={12} className="order-sm-last  ">
    <UseStyled.ResponsiveReportTable  >
      <TableCustom
        tableFixedWidth
        hidePagination
        page={1}
        pageLimit={10}
        sortType={''}
        sortBy={''}
        rowCount={0}
        onSort={() => { return false }}
        setPageLimit={() => { return false }}
        setPage={() => { return false }}
        headCells={props.headCells}
        rowsData={[
          <TableRowCommon key={`total-1`} id={`total-1`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ยอดรับหน้าร้าน' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountPaid || 0) }
            ]} />,
          <TableRowCommon key={`total-2`} id={`total-2`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินสด (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCash || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'เงินโอน (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTransfer || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเดบิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountDebit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'บัตรเครดิต (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountCredit || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'Voucher (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountVoucher || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'สิทธิการรักษา (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountTreatmentRight || 0) }
            ]} />,
          <TableRowCommon key={`total-3`} id={`total-3`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'จ่ายด้วย Advance (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(amountAdvance || 0) }
            ]} />,
          <TableRowCommon key={`total-9`} id={`total-9`} obj={[1]}
            columns={[
              { option: 'TEXT', align: 'left', label: 'ค้างชำระ (THB)' },
              { option: 'TEXT', align: 'right', label: numberFormat(balance || 0) }
            ]} />
        ]}
        customScroll
      />
    </UseStyled.ResponsiveReportTable>
  </Grid>
}