import { Box, Chip, } from '@mui/material'
import ApprovalApi, { ApprovalProps } from 'api/approval/approval.api'
import { swalCustom } from 'component/Alert/Swal'
import ButtonCustom from 'component/Button/ButtonCustom'
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputCheckbox from 'component/Input/InputCheckbox'
import InputTextField from 'component/Input/InputTextField'
import Loading from 'component/Loading'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { ListFilterDoctors } from 'features/counter/appointment/head-appointment'
import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import { getBranch } from 'utils/app.utils'


const FormApproval = () => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [load, setLoad] = useState<boolean>(false)
    const [approvalId, setApprovalId] = useState<number>(0)

    const [listName, setListName] = useState<string>("")
    const [userOptions, setUserOptions] = useState<any>([])
    const [approvalUsers, setApprovalUsers] = useState<number[]>([])
    const funcLinkOptions = [
        {
            key: "hasCancelReciept",
            funcLinkName: 'ยกเลิกใบเสร็จ'
        },
        {
            key: "hasChangeCost",
            funcLinkName: 'จัดการค่ารักษา'
        }
    ]
    const [funcLinkSelect, setFuncLinkSelect] = useState<string[]>([])
    const [funcLinkCurrent, setFuncLinkCurrent] = useState<string[]>([])
    const [statusActive, setStatusActive] = useState<"ACTIVE" | "INACTIVE">("ACTIVE")
    const [approveChanelEmail, setApproveChanelEmail] = useState<"1" | "0">("0")
    const [approveChanelLine, setApproveChanelLine] = useState<"1" | "0">("0")

    const [errorMessage, setErrorMessage] = useState<any>({})

    useEffect(() => {
        const newLocation: any = location
        if (newLocation?.state) {
            const dataEdit = newLocation?.state
            setApprovalId(dataEdit?.approvalId)
            setListName(dataEdit?.approvalName)
            setApprovalUsers(dataEdit?.approvalUsers?.map((item: any) => item?.userId))
            const newFuncLinks = []
            if (dataEdit?.hasCancelReciept === "PERMISS") {
                newFuncLinks.push('hasCancelReciept')
            }
            if (dataEdit?.hasChangeCost === "PERMISS") {
                newFuncLinks.push('hasChangeCost')
            }
            setFuncLinkSelect(newFuncLinks)
            setFuncLinkCurrent(newFuncLinks)
            setStatusActive(dataEdit?.status || 'INACTIVE')
            setApproveChanelEmail(dataEdit?.approveChanelEmail || "0")
            setApproveChanelLine(dataEdit?.approveChanelLine || "0")
        }

    }, [location])

    const loadUsersApproval = async () => {
        await ApprovalApi.findUsersApproval().then((res) => {
            setUserOptions(res?.data)
        })
    }

    useEffect(() => {
        loadUsersApproval()
    }, [])

    const handleChangeApprovalUsers = (option: any) => {
        setErrorMessage({})
        const newData = [...approvalUsers]
        if (newData?.some((item: any) => item === option?.userId)) {
            setApprovalUsers(newData.filter((item: any) => item !== option?.userId))
        } else {
            setApprovalUsers([...newData, option?.userId])
        }
    }

    const handleChangeFuncLinks = (option: any) => {
        setErrorMessage({})
        const newData = [...funcLinkSelect]
        if (newData?.some((item: any) => item === option?.key)) {
            setFuncLinkSelect(newData.filter((item: any) => item !== option?.key))
        } else {
            setFuncLinkSelect([...newData, option?.key])
        }
    }

    const handleCheckApprovalPermiss = async () => {
        const delFuncLink = funcLinkCurrent?.filter((item) => !funcLinkSelect.includes(item))
        if (approvalId && delFuncLink?.length && statusActive !== "INACTIVE") {
            const checkApproval = await ApprovalApi.checkApprovePermiss()
            const newCheckApproval = checkApproval?.data
            delFuncLink?.forEach((item) => {
                newCheckApproval[item] = (checkApproval?.data[item] || 0) - 1
            })
            const newTextCheck: string[] = []
            Object.entries(newCheckApproval).forEach(([key, value]) => {
                if (!key.includes("Items") && Number(value) <= 0 && newCheckApproval[`${key}Items`]) {
                    newTextCheck.push(t(`APPROVAL.FORM.${String(key).toUpperCase()}`))
                }
            })
            return newTextCheck
        } else {
            return []
        }
    }

    const onSubmit = async () => {
        setLoad(true)
        const approvalCheck = await handleCheckApprovalPermiss()

        if (!listName || !approvalUsers?.length || !funcLinkSelect?.length || approvalCheck?.length) {
            if (approvalCheck?.length) {
                const textStatus = t(`APPROVELLIST.MESSAGE.CHECK_TITLE_EDIT`)
                swalCustom(
                    textStatus,
                    t('APPROVELLIST.MESSAGE.CHECK_SUB',
                        { textCheck: approvalCheck.join(", ") }),
                    () => console.log(),
                    'warning',
                    { cancelButton: { showCancelButton: false } }
                )
            }

            setErrorMessage({
                LISTNAME: !listName ? "APPROVAL.MESSAGE.ERROR.LISTNAME" : "",
                APPROVALUSERS: !approvalUsers?.length ? "APPROVAL.MESSAGE.ERROR.USERNAME_LINKS" : "",
                FUNCLINK: !funcLinkSelect?.length ? "APPROVAL.MESSAGE.ERROR.FUNC_LINKS" : ""
            })
            setLoad(false)
        } else {
            const newPayload: ApprovalProps = {
                approvalName: listName,
                hasCancelReciept: funcLinkSelect.includes('hasCancelReciept') ? "PERMISS" : "UNPERMISS",
                hasChangeCost: funcLinkSelect.includes('hasChangeCost') ? "PERMISS" : "UNPERMISS",
                approveChanelEmail: approveChanelEmail,
                approveChanelLine: approveChanelLine,
                status: statusActive,
                branchId: getBranch(),
                approvalUsers: approvalUsers.map((item) => { return { userId: item } })
            }
            if (!approvalId) {
                await ApprovalApi.createApproval(newPayload).then(() => {
                    notiSuccess(t('APPROVAL.MESSAGE.SUC_CREATE'))
                    history.push(routeName.approval)
                }).catch((e) => {
                    const err = e?.response?.data?.message
                    if (err === 'IS_DUPLICATE_NAME') {
                        notiError(t('APPROVAL.MESSAGE.ERROR.IS_DUPLICATE_NAME'))
                    } else {
                        notiError(t('APPROVAL.MESSAGE.ERROR.ERROR'))
                    }
                }).finally(() => {
                    setLoad(false)
                })
            } else {
                await ApprovalApi.updateApproval(approvalId, newPayload).then(() => {
                    notiSuccess(t('APPROVAL.MESSAGE.SUC_UPDATE'))
                    history.push(routeName.approval)
                }).catch((e) => {
                    const err = e?.response?.data?.message
                    if (err === 'IS_DUPLICATE_NAME') {
                        notiError(t('APPROVAL.MESSAGE.ERROR.IS_DUPLICATE_NAME'))
                    } else {
                        notiError(t('APPROVAL.MESSAGE.ERROR.ERROR'))
                    }
                }).finally(() => {
                    setLoad(false)
                })
            }
        }
    }

    return (
        <>
            <Box className={`container-card container-card h-100 overflow-hidden`}>
                <Loading show={load} />
                <Box className="d-flex flex-column h-100">
                    <HeaderLabel
                        text={approvalId ? t('APPROVAL.TITLE_UPDATE') : t('APPROVAL.TITLE_CREATE')}
                        navigator={{ previousTo: routeName.approval, previousTitle: t('APPROVAL.TITLE'), currentTitle: approvalId ? t('APPROVAL.TITLE_UPDATE') : t('APPROVAL.TITLE_CREATE') }}
                        componentBtnCustom={
                            <Box className="flex-row flex-end ml-auto gap-3">
                                <Box sx={{ width: '100px', button: { width: '100%', filter: 'grayscale(100%) sepia(0%) brightness(35%) contrast(120%)' } }}>
                                    <Link to={routeName.approval}>
                                        <ButtonCustom
                                            variant={"outlined"}
                                            textButton={t("BUTTON.CANCEL")}
                                        />
                                    </Link>
                                </Box>
                                <Box sx={{ width: '100px', button: { width: '100%', } }}>
                                    <ButtonCustom
                                        textButton={t("BUTTON.SAVE")}
                                        onClick={onSubmit}
                                    />
                                </Box>
                            </Box>
                        }
                    />
                    <Card className="border shadow-sm mx-4 mb-4 h-100 overflow-hidden card" style={{ maxHeight: '100%' }}>
                        <Box className="overflow-hidden p-4 h-100">
                            <Card.Body className="d-flex flex-column h-100 custom-scroll p-0" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                                <Row className={`pt-2`}>
                                    <Col lg={4} md={6} xs={12} className={`mb-3`}>
                                        <InputTextField
                                            required
                                            label={t("APPROVAL.FORM.LISTNAME")}
                                            value={listName}
                                            onchange={(e) => {
                                                setErrorMessage({})
                                                setListName(e.target.value)
                                            }}
                                            helperText={errorMessage?.LISTNAME && t(errorMessage?.LISTNAME)}
                                        />
                                    </Col>
                                    <Col lg={4} md={6} xs={12} className={`mb-3`}>
                                        <AutocompleteSelect
                                            required
                                            labelId='users'
                                            inputLabel={t('APPROVAL.FORM.USERNAME_LINKS')}
                                            options={userOptions}
                                            getOptionLabel={(option: any) => `${option?.branchId || ''}`}
                                            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                            renderOption={(props, option) => {
                                                const isDiasabled = option?.approvalId && option?.approvalId !== approvalId
                                                return (
                                                    <ListFilterDoctors
                                                        {...props}
                                                        key={option.userId}
                                                        component="li"
                                                        value={option.userId}
                                                        onClick={() => !isDiasabled && handleChangeApprovalUsers(option)}
                                                    >
                                                        <InputCheckbox
                                                            label={option.userfullName}
                                                            checked={approvalUsers?.includes(option?.userId)}
                                                            className={'pe-none'}
                                                            disabled={isDiasabled}
                                                        />
                                                    </ListFilterDoctors>
                                                )
                                            }}
                                            renderTags={(tagValue, getTagProps) => {
                                                return (
                                                    <>
                                                        <Box className='d-flex flex-wrap pl-2'>
                                                            {
                                                                tagValue.map((option: any, index: number) => {
                                                                    return (
                                                                        <Box key={index}>
                                                                            {userOptions.find((item: any) => option === item?.userId)?.userfullName || ''}{index + 1 !== tagValue?.length && ','}
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                        </Box>
                                                    </>
                                                )
                                            }}
                                            filterOptions={(option) => option?.userfullName || ''}
                                            value={approvalUsers}
                                            multiple
                                            disableClearable
                                            errMessage={errorMessage?.APPROVALUSERS && t(errorMessage?.APPROVALUSERS)}
                                        />
                                    </Col>
                                    <Col lg={8} md={12} xs={12}>
                                        <AutocompleteSelect
                                            required
                                            labelId='approval'
                                            inputLabel={t('APPROVAL.FORM.FUNC_LINKS')}
                                            options={funcLinkOptions}
                                            getOptionLabel={(option: any) => `${option?.key || ''}`}
                                            noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                            renderOption={(props, option) => {
                                                return (
                                                    <ListFilterDoctors
                                                        {...props}
                                                        key={option.userId}
                                                        component="li"
                                                        value={option.userId}
                                                        onClick={() => handleChangeFuncLinks(option)}
                                                    >
                                                        <InputCheckbox
                                                            label={t(`APPROVAL.FORM.${String(option.key).toUpperCase()}`)}
                                                            checked={funcLinkSelect?.includes(option.key)}
                                                            className={'pe-none'}
                                                        />
                                                    </ListFilterDoctors>
                                                )
                                            }}
                                            renderTags={(tagValue, getTagProps) => {
                                                return (
                                                    <>
                                                        <Box className='d-flex flex-wrap pl-2 gap-1'>
                                                            {
                                                                tagValue.map((option: any, index: number) => {
                                                                    const elementName = funcLinkOptions.find((item: any) => option === item?.key)
                                                                    return (
                                                                        <Chip
                                                                            key={index}
                                                                            label={elementName?.key ? t(`APPROVAL.FORM.${String(elementName?.key).toUpperCase()}`) : ''}
                                                                            onDelete={() => handleChangeFuncLinks(elementName)}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </Box>
                                                    </>
                                                )
                                            }}
                                            filterOptions={(option) => option?.funcLinkName || ''}
                                            value={funcLinkSelect}
                                            multiple
                                            disableClearable
                                            errMessage={errorMessage?.FUNCLINK && t(errorMessage?.FUNCLINK)}
                                        />
                                    </Col>
                                    <Box sx={{ padding: '16px 12px' }}>
                                        <Box>
                                            {t('APPROVAL.FORM.CHANEL_LINK')}
                                        </Box>
                                        <Box>
                                            <InputCheckbox
                                                label={t('Email')}
                                                checked={approveChanelEmail === '1'}
                                                onChange={() => setApproveChanelEmail(approveChanelEmail === '1' ? '0' : '1')}
                                            />
                                        </Box>
                                    </Box>
                                </Row>
                            </Card.Body>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </>

    )
}

export default FormApproval