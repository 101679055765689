import BaseAPI from 'api/api'
import { ListFilter, ListQueueFilter, PrintReceiptInterface } from 'api/counter/payment/payment-interface'

const path = 'payments'
export default class PaymentApi extends BaseAPI {
    static list(props: ListFilter): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } })
    }

    static detail(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`)
    }

    static updateDetail(id: number, payload: any): Promise<any> {
        return this.api.patch(`${path}/${id}/update`, payload)
    }

    static listQueue(props: ListQueueFilter): Promise<any> {
        return this.api.get(`${path}/list-queue`, { params: { ...props } })
    }

    static cancel(id: number, payload: any): Promise<any> {
        return this.api.patch(`${path}/${id}`, { ...payload })
    }

    static cancelNew(id: number, payload: any): Promise<any> {
        return this.api.post(`approval/${id}/cancel`, { ...payload })
    }

    static validate(id: number) {
        return this.api.get(`${path}/validate/${id}`)
    }

    static sendPayment(patientCaseId: number,): Promise<any> {
        return this.api.patch(`${path}/${patientCaseId}/status`)
    }


    static payment(patientCaseId: number, payload: any): Promise<any> {
        return this.api.post(`${path}/${patientCaseId}`, payload)
    }
    static resetPayment(orderPaymentId: number,): Promise<any> {
        return this.api.patch(`${path}/${orderPaymentId}/reset`)
    }
    static printThermal(orderPaymentId: number, props: PrintReceiptInterface): Promise<any> {
        return this.api.get(`${path}/${orderPaymentId}/thermal`, { params: { ...props } })
    }

    static printA5(orderPaymentId: number, props: PrintReceiptInterface): Promise<any> {
        return this.api.get(`${path}/${orderPaymentId}/a5`, { params: { ...props } })
    }

    static printA4(orderPaymentId: number, props: PrintReceiptInterface): Promise<any> {
        return this.api.get(`${path}/${orderPaymentId}/a4`, { params: { ...props } })
    }
}