import { menuSeconds } from 'constants/menus'
import LineConnect from 'features/counter/register/line/line-connect'
import Register from 'features/counter/register/register'
import { PACKAGE_ADDONS } from 'constants/package'
import FormQrCode from 'features/counter/register/qr-code/form-qr-code'

const routeRegister = [
  {
    ...menuSeconds.REGISTER,
    component: Register,
    appBar: true
  },
  {
    ...menuSeconds.REGISTER.name,
    key: menuSeconds.REGISTER.key,
    packageKey: PACKAGE_ADDONS.ADDON_LINE_NOTIFY,
    textname: `${menuSeconds.REGISTER.name}`,
    active: false,
    path: menuSeconds.REGISTER.path + '/line-connect',
    component: LineConnect,
    appBar: true
  },
  {
    ...menuSeconds.REGISTER.name,
    key: menuSeconds.REGISTER.key,
    // packageKey: PACKAGE_ADDONS.ADDON_LINE_NOTIFY,
    textname: `${menuSeconds.REGISTER.name}`,
    active: false,
    path: menuSeconds.REGISTER.path + '/qr-code',
    component: FormQrCode,
    appBar: true
  },
]

export default routeRegister
