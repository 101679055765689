import moment from 'moment'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { dateToView } from 'utils/date.utils'

/** STYLE */
import { styled } from '@mui/material/styles'
import { getBranch, getClinicInfo } from 'utils/app.utils'
import { useEffect, useState } from 'react'
import AboutUs from 'api/admin/clinic-manage/about.api'

export const Card = styled('div')(({ theme }) => ({
  margin: '1rem 1rem 0',
  borderRadius: 4,
  boxShadow: `0 3px 6px 0 ${colors.black16}`,
  backgroundColor: colors.white,
  color: colors.textPrimary,
  cursor: 'pointer',
  '& p': {
    margin: 0
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0.75rem',
    marginRight: '0.75rem'
  }
}))

export const CardBody = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: '1.5rem 1rem 0.5rem 1.25rem',
  border: `1px solid ${colors.extraLightGray}`,
  borderRadius: 4,
  minHeight: 123,
  '& .number': {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: 27,
    minHeight: 28,
    color: colors.white,
    background: colors.themeMainColor,
    borderRadius: '4px 0 20px',
    padding: '2px 0 0 4px',
    fontSize: 14,
    lineHeight: 'normal'
  },
  '& .queue': {
    position: 'absolute',
    top: 8,
    right: 8,
    color: colors.white,
    background: colors.themeMainColor,
    borderRadius: 2,
    padding: '1px 6.7px',
    fontSize: 14,
    lineHeight: 'normal'
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem'
  }
}))

export const CardFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  '> div': {
    width: '100%',
    '.MuiButton-outlined': {
      width: '100%',
      borderRadius: 0,
      borderColor: `${colors.extraLightGray} !important`,
      fontSize: 16,
      textTransform: 'capitalize',
      height: 49,
      img: {
        width: 16,
        height: 16
      },
      '.MuiButton-startIcon': {
        marginLeft: 0
      }
    },
    '&:first-of-type .MuiButton-outlined': {
      borderBottomLeftRadius: 4
    },
    '&:last-of-type .MuiButton-outlined': {
      borderBottomRightRadius: 4
    },
    '.btn-payment': {
      borderColor: 'transparent !important',
      paddingLeft: 0
    }
  },
  '.btn-icon-group': {
    display: 'flex',
    justifyContent: 'flex-end',
    '> div': {
      margin: '0 !important',
      '&:first-of-type .MuiButton-outlined': {
        borderRadius: '0 !important'
      }
    },
    '.btn-icon': {
      borderColor: 'transparent !important',
      minWidth: 48,
      padding: 0,
      '.MuiButton-startIcon': {
        margin: 0
      }
    }
  },
  [theme.breakpoints.down('sm')]: {
    button: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}))

const QueueLabel = styled('p')(({ theme }) => ({
  position: 'absolute',
  top: 4,
  right: 4,
  color: colors.white,

  borderRadius: 2,
  padding: '0.25rem 0.5rem',
  fontSize: 12,
  lineHeight: 'normal',

  maxWidth: 'calc(100% - 35px)'
}))

interface DataCardQueue {
  labOrderId?: number
  labOrderNumber?: string
  cnNumber: string
  branchCnNumber: string
  snCode: string
  patientFullName: string
  labCompanyName: string
  labOrderStatus: string | 'ORDER_LAB' | 'SEND_LAB' | 'RECEIVE_LAB' | 'RECEIVE_BILL' | 'REPLY_LAB' | 'CANCEL_LAB' | 'CLOSE_LAB'
  operativeName: string
  dateExport: string
  dateImport: string
  dateReceive: string
}

type CardQueueProp = {
  dataCard: DataCardQueue
  indexNumber: number
  hideFooterAction?: boolean
  type: 'EXPORT' | 'RECEIVE'
  branch: string
  onClick?: (val: any) => void
  onSendMessage?: (val: any) => void
}

export default function CardQueue(props: CardQueueProp) {
  const { t } = useTranslation()
  const [branch, setBranch] = useState<any>()



  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])

  const handlePatientCode = () => {
    if (branch?.patientCodeLab === '1') {
      return props?.dataCard?.cnNumber
    }
    if (branch?.patientCodeLab === '0') {
      return props?.dataCard?.branchCnNumber
    }
    if (branch?.patientCodeLab === '2') {
      return props?.dataCard?.snCode
    }
  }

  const renderStatus = (dataCard: DataCardQueue) => {
    let bgColor: any = colors.themeMainColor
    let label: any = ''
    if (props.type === 'EXPORT') {
      if (dataCard.labOrderStatus === 'ORDER_LAB') {
        bgColor = colors.memberRanking.background.rankingBronze
        label = t('LAB_ORDER.QUEUE.WAIT_SEND')
        const diff = moment().diff(moment(dataCard.dateExport, 'YYYY-MM-DD'), 'days')
        if (Number(diff) > 0) {
          bgColor = colors.themeThirdColor
          label = `${t('LAB_ORDER.QUEUE.DEADLINE')} ${dateToView(dataCard.dateExport, false)}`
        }
      }
      else if (dataCard.labOrderStatus === 'SEND_LAB') {
        bgColor = colors.servicePoint.label.roomServices
        label = t('LAB_ORDER.QUEUE.SENT')
      }
    } else {
      if (dataCard.labOrderStatus === 'SEND_LAB') {
        bgColor = colors.memberRanking.background.rankingBronze
        label = t('LAB_ORDER.QUEUE.WAIT_RECEIVE')
        const diff = moment().diff(moment(dataCard.dateImport, 'YYYY-MM-DD'), 'days')
        if (Number(diff) > 0) {
          bgColor = colors.themeThirdColor
          label = `${t('LAB_ORDER.QUEUE.PICKUP_SET')} ${dateToView(dataCard.dateImport, false)}`
        }
      } else if (dataCard.labOrderStatus === 'RECEIVE_LAB') {
        bgColor = colors.memberRanking.background.rankingGold
        label = t('LAB_ORDER.QUEUE.RECEIVE_LAB')
      } else if (dataCard.labOrderStatus === 'RECEIVE_BILL') {
        bgColor = colors.servicePoint.label.roomServices
        label = t('LAB_ORDER.QUEUE.RECEIVE_BILL')
      }
    }

    return <QueueLabel className='text-ellipsis' style={{ background: bgColor }}>{label}</QueueLabel>
  }

  const renderTooltip = () => {
    return `${handlePatientCode() ? `${handlePatientCode()}` : ''} ${props?.dataCard?.patientFullName || ''} ${t('LAB_ORDER.QUEUE.NO_LAB')} ${props?.dataCard?.labOrderNumber || ''} ${t('LAB_ORDER.QUEUE.SEND')} ${props.dataCard.labCompanyName} : ${props.dataCard.operativeName}`
  }

  return (
    <Card >
      <CardBody
        className={'d-flex flex-column h-100'}
        onClick={props.onClick}
        data-html="true"
        title={renderTooltip()}>
        <p className={'number'}>{String(props.indexNumber + 1).padStart(2, '0')}</p>
        {renderStatus(props.dataCard)}
        <div className={'d-flex mt-1'}>
          <div className={'pl-3 overflow-hidden'}>
            <p className={'text-ellipsis text-medium mt-2'} style={{ fontWeight: 500 }}>
              {handlePatientCode() ? `${handlePatientCode()}` : ''} {props?.dataCard?.patientFullName || ''}
            </p>
            <p className={'text-ellipsis text-muted mb-1'} style={{ fontSize: 14 }}>
              {t('LAB_ORDER.QUEUE.NO_LAB')} {props?.dataCard?.labOrderNumber || ''}
            </p>
            <p className={'-text-ellipsis text-medium mb-3'} style={{ fontSize: 14 }}>
              {t('LAB_ORDER.QUEUE.SEND')} {props.dataCard.labCompanyName} : {props.dataCard.operativeName}
            </p>

          </div>
        </div>
      </CardBody>
    </Card>
  )
}
