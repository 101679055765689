import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
import { FindDoctorFeeProps } from 'api/dentists/treatment.api'
import { getBranch } from 'utils/app.utils'

const path = 'approval'

export type ApprovalProps = {
    approvalName: string
    hasCancelReciept: "PERMISS" | "UNPERMISS"
    hasChangeCost: "PERMISS" | "UNPERMISS"
    approveChanelEmail: "0" | "1",
    approveChanelLine: "0" | "1",
    status: "ACTIVE" | "INACTIVE"
    branchId: number
    approvalUsers: { userId: number }[]
}

export type ApprovalCancelReceipt = {
    requestReason: string
    requestUserName: string
    requestUserId: number
    approveRemark: string
    refId: number
    branchId: number
    status: ApprovalStatus
}

export type ApprovalLinkProps = {
    approveRemark: string
    status: ApprovalStatus
    approveToken: string
}

export enum ApprovalStatus {
    APPROVED = "APPROVED",
    PENDING = "PENDING",
    REJECT = "REJECT",
    CANCEL = "CANCEL"
}

export default class ApprovalApi extends BaseAPI {

    static findApprovalAll(props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props, branchId: getBranch() } })
    }

    static createApproval(payload: ApprovalProps): Promise<any> {
        return this.api.post(`${path}`, { ...payload })
    }

    static updateApproval(id: number, payload: ApprovalProps): Promise<any> {
        return this.api.patch(`${path}/${id}`, { ...payload })
    }
    static updateStatusApproval(id: number, status: "ACTIVE" | "INACTIVE"): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { status: status })
    }

    static deteleApproval(id: number): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { status: 'DELETED' })
    }

    static findById(id: number): Promise<any> {
        return this.api.get(`${path}/${id}`)
    }

    static findUsersApproval(): Promise<any> {
        return this.api.get(`${path}/users/${getBranch()}`)
    }

    static findApprovalListAll(props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}/items`, { params: { ...props } })
    }

    static checkApprovePermiss(): Promise<any> {
        return this.api.get(`${path}/approve-permiss/${getBranch()}`)
    }

    static approvalCancelReceipt(id: number, payload: ApprovalCancelReceipt): Promise<any> {
        return this.api.patch(`${path}/approve/${id}`, { ...payload })
    }

    static findApprovalPayment(id: string): Promise<any> {
        return this.api.get(`${path}/payment/${id}`)
    }

    static findApprovalChangeCost(id: string): Promise<any> {
        return this.api.get(`${path}/change-cost/${id}`)
    }

    static findApprovalOperativeById(params: FindDoctorFeeProps): Promise<any> {
        return this.api.get(`${path}/operative-df`, { params: { ...params } })
    }

    static approvalLink(id: number, payload: ApprovalLinkProps): Promise<any> {
        return this.api.patch(`${path}/approve-link/${id}`, { ...payload },)
    }

}

