import { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import _, { forEach } from 'lodash'
import { Grid, Box, Typography, Tabs, Tab, MenuItem, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import domtoimage from 'dom-to-image'
import $ from 'jquery'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** ROUTE */
import { routeName } from 'routes/routes-name'

/** UTILS */
import { getBranch, getServicePoint, checkPackage, getClinicInfo } from 'utils/app.utils';
import { handleShow, showModal, resetModal } from 'app/slice/modal.slice'
import {
  treatment,
  TreatmentStateInterface,
  TreatmentStateType,
  isEditTootChart,
  setEditTootChart,
  setTreatmentByKey,
  setEditTootChartType,
  checkApprovalRight,
  setApprovalRight,
} from 'app/slice/treatment.slice';
import { selectMe } from 'app/slice/user.slice';

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

/** COMPONENT */
import IndexOPD from 'features/treatments/MainMenu/OPD/Index'
import IndexDoctorNote from 'features/treatments/MainMenu/DoctorNote/Index'
import IndexImage from 'features/treatments/MainMenu/Image/Index'
import IndexVitalSign from 'features/treatments/MainMenu/VitalSign/Index'
import FragmentFooter from 'features/treatments/FragmentFooter'
import InputTextarea from 'component/Input/InputTextarea'
import IndexSummaryTreatment from 'features/treatments/SummaryTreatment/Index'
import BtnProfile from 'component/Treatments/BtnProfile'
import PopupPatientDetail from 'component/Treatments/PopupPatientDetail'
import SendExamination from 'component/Register/SendExamination'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import MedicalCertificate from 'component/Treatments/MedicalCertificate'
import PopupTreatmentConsent from 'component/Treatments/popupTreatmentConsent'
import { swalCustom, swalWarning } from 'component/Alert/Swal'
import Loading from 'component/Loading'
import PopupSelectPrintProduct from 'component/Treatments/PopupSelectPrintProduct';
import SSO from 'component/Treatments/SSO';

/** CONSTANTS */
import { colors } from 'constants/theme'
import { imgs } from 'constants/images';
import { exportOPD, exportSSO } from 'component/Print/exportPdf'
import { PACKAGE_ADDONS } from 'constants/package'
import { KEY_STORAGE } from 'constants/common';

/** API */
import TreatmentsAPI from 'api/dentists/treatment.api'
import TreatmentSummaryApi from 'api/dentists/treatment-summary.api'
import { fileUrl } from 'api/api'
import HomeApi from 'api/home/home.api'
import ClinicApi from 'api/master/clinic.api'

/** STYLE */
import * as UseStyled from 'features/treatments/UseStyle'
import ReferralEaxmination from 'component/Treatments/Referral-examination'
import { PopupConfirmDF } from 'component/Treatments/PopupConfirmDF';
import { PopupConfirmClose } from 'component/Treatments/PopupConfirmClose';
import MedicalCertificateFiveDisease from 'component/Treatments/PopupMedicalCertificateFiveDisease';
import MedicalCertificateDrivingLicense from 'component/Treatments/MedicalCertificateDrivingLicense';
import MedicalCertificate_A5 from 'component/Treatments/MedicalCertificate_A5';
import ReferralDental from 'component/Treatments/Referral-dental';
import PopupTreatmentFees from 'component/Treatments/PopupTreatmentFees';
import PopTreatmentDocument from 'component/Treatments/PopTreatmentDocument';
import ApprovalApi, { ApprovalStatus } from 'api/approval/approval.api';

export enum TABS_NAME {
  OPD = 'TREATMENTS.MENU_TAB.OPD',
  DOCTOR_NOTE = 'TREATMENTS.MENU_TAB.DOCTOR_NOTE',
  IMAGE = 'TREATMENTS.MENU_TAB.IMAGE',
  VITAL_SIGN = 'TREATMENTS.MENU_TAB.VITAL_SIGN',
  APPOINTMENT = 'HISTORY_TREATMENT.MENU_TAB.APPOINTMENT',
  DOCUMENT = 'HISTORY_TREATMENT.MENU_TAB.DOCUMENT'
}

export enum SUB_TABS_NAME {
  ALL = 'All',
  DX = 'DX',
  TX = 'TX',
  TXP = 'TX.P',
  TXC = 'TX.C',
  MED = 'MED',
  PD = 'PD',
  XRAY = 'X-Ray',
  LAB = 'LAB',
  MAT = 'MAT',
}

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}

export type DoctorsType = {
  userId?: number
  firstname?: string
  lastname?: string
  fullname?: string
}

export default function Treatments(props: any, ref: any) {
  const location = useLocation()
  const history = useHistory()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = useSelector(selectMe)

  const branch = getClinicInfo()
  const isEditTootCharts: any = useSelector(isEditTootChart)
  const modal: boolean = useSelector(handleShow)
  const modal_xray = useSelector((state: any) => state.modal.modal_xray)
  const formXrayPage = useSelector((state: any) => state.modal.xray_page)
  const branchIdServicePoint = getBranch()
  const disabledOpenPopupInfo = localStorage.getItem(KEY_STORAGE.TREATMENT_CLOSE_POPUP)

  const approvalRight = useSelector(checkApprovalRight)

  /** SUMMARY TREATMENTS */
  const treatments: TreatmentStateInterface = useSelector(treatment)

  /** BLOCK PAGE */
  // const { RouterPrompt, setBlockPage } = useBlockPageWarning()

  /** CONTROL */
  const [isShowPopupDetail, setIsShowPopupDetail] = useState<boolean>(false)
  const [tabs, setTabs] = useState<TABS_NAME[]>([TABS_NAME.OPD, TABS_NAME.DOCTOR_NOTE, TABS_NAME.IMAGE, TABS_NAME.VITAL_SIGN])
  const [subTabs, setSubTabs] = useState<SUB_TABS_NAME[]>([SUB_TABS_NAME.DX])
  const footerTabs: string[] = ['TREATMENTS.FOOTER_TAB.SUMMARY_TREATMENT', 'TREATMENTS.FOOTER_TAB.DIAGNOSIS', 'TREATMENTS.FOOTER_TAB.RECOMMEND']
  const [activeTab, setActiveTab] = useState<TABS_NAME>(TABS_NAME.OPD)
  const [activeSubTab, setActiveSubTab] = useState<SUB_TABS_NAME>(SUB_TABS_NAME.TX)
  const [activeFooterTab, setActiveFooterTab] = useState(0)

  const [sendExam, setSendExam] = useState(false)
  const [patientCase, setPatientCase] = useState([])
  const [saveTreatment, setSaveTreatment] = useState(false);

  const [anchorElTabletTabs, setAnchorElTabletTabs] = useState<null | HTMLElement>(null)
  const openTabletTabs = Boolean(anchorElTabletTabs)
  const [tabletWidth, setTabletWidth] = useState(0)

  const [anchorElMobileTabs, setAnchorElMobileTabs] = useState<null | HTMLElement>(null)
  const openMobileTabs = Boolean(anchorElMobileTabs)
  const [mobileWidth, setMobileWidth] = useState(0)

  const [servicePointId, setServicePointId] = useState(0)
  const [patientId, setPatientId] = useState(0)
  const [patient, setPatient]: any[] = useState([])
  const [importantSymptomsDoctor, setImportantSymptomsDoctor] = useState('')
  const [doctors, setDoctors] = useState<DoctorsType[]>([])

  const [exportMedicalCertificate, setExportMedicalCertificate] = useState(false)
  const [treatmentConsent, setTreatmentConsent] = useState(false);
  const [exportReferralExamination, setExportReferralExamination] = useState(false)
  const [exportReferralReferralDental, setExportReferralDental] = useState(false)
  const [exportMedicalCertificate_A5, setExportMedicalCertificate_A5] = useState(false)
  const [exportMedicalServices, setSSO] = useState(false)
  const [exportMedicalCertificateFiveDisease, setExportMedicalCertificateFiveDisease] = useState(false)
  const [exportMedicalCertificateDrivingLisense, setExportMedicalCertificateDrivingLisense] = useState(false)

  const [importantSymptoms, setImportantSymptoms] = useState("")

  const [showPopupSelectStickerProduct, setShowPopupSelectStickerProduct] = useState(false);

  const [loading, setLoading] = useState(false)

  const [confirmDF, setConfirmDF] = useState(false)
  const [confirmClose, setConfirmClose] = useState(false)
  const [save, setSave] = useState(false)

  const [popTreatmetnFees, setPopTreatmentFees] = useState<boolean>(false)
  const [popTreatmentDocument, setPopTreatmentDocument] = useState<boolean>(false)

  const [treatmentCurrent, setTreatmentCurrent] = useState<any>([])

  const [hasChangeTreatmentCost, setHasChangeTreatmentCost] = useState();

  useEffect(() => {
    setHasChangeTreatmentCost(user?.hasChangeTreatmentCost)

    const addonDoctorNote: TABS_NAME[] = checkPackage(PACKAGE_ADDONS.ADDON_DOCTOR_NOTE) ? [TABS_NAME.DOCTOR_NOTE] : []
    setTabs([TABS_NAME.OPD, ...addonDoctorNote, TABS_NAME.IMAGE, TABS_NAME.VITAL_SIGN])

    const addonTxP = checkPackage(PACKAGE_ADDONS.ADDON_TREATMENT_PLAN_MANAGE) ? [SUB_TABS_NAME.TXP] : []
    const addonTxC = checkPackage(PACKAGE_ADDONS.ADDON_TREATMENT_COURSE_MANAGE) ? [SUB_TABS_NAME.TXC] : []
    const addonXray = checkPackage(PACKAGE_ADDONS.ADDON_X_RAY) ? [SUB_TABS_NAME.XRAY] : []
    const addonLab = checkPackage(PACKAGE_ADDONS.ADDON_LAB) ? [SUB_TABS_NAME.LAB] : []
    const operativeTab = [SUB_TABS_NAME.DX, SUB_TABS_NAME.TX]
    const productTab = [SUB_TABS_NAME.MED, SUB_TABS_NAME.PD, SUB_TABS_NAME.MAT]
    setSubTabs([...operativeTab, ...addonTxP, ...addonTxC, ...productTab, ...addonXray, ...addonLab])
  }, [user]);

  useEffect(() => {
    if (treatments.summeryTreatments.length > 0) {
      const index = _.findLastIndex(treatments.summeryTreatments, (item: any) => item.teethType)

      if (treatments.summeryTreatments[index]) {
        dispatch(setTreatmentByKey({ key: 'teethType', value: treatments.summeryTreatments[index].teethType }))
      }
    }

  }, [treatments.summeryTreatments]);

  const loadDoctor = async () => {
    const resp = await ClinicApi.findAllDoctors()
    if (resp.status === 200) setDoctors(resp.data)
  }

  const loadPatient = async (option: { viewPatient: boolean }) => {
    if (patientId) {
      const patientData = await TreatmentsAPI.findPatient(patientId)
      if (patientData.status === 200 && !_.isEmpty(patientData.data)) {
        setPatient(patientData.data)
        setImportantSymptoms(patientData.data.importantSymptoms)
        setImportantSymptomsDoctor(patientData.data.importantSymptomsDoctor || '')
        if (user?.position?.positionId === 2 && user?.autoShowPatientProfile === '1' && option?.viewPatient && ((disabledOpenPopupInfo && !_.includes(JSON.parse(disabledOpenPopupInfo), Number(patientId))) || !disabledOpenPopupInfo)) onViewHistory()
      } else setPatient([])
    }
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(tabs[newValue])
    dispatch(setEditTootChart(null))
    dispatch(setEditTootChartType(''))
  }
  const onConfirmDF = () => {
    dispatch(showModal())
    setConfirmDF(true)
    // setIsCancel(false)
  }

  const onTreatmentClose = () => {
    if (save) {
      handleTreatmentClose()
    } else {
      onConfirmClose()
    }
  }

  const handleTreatmentClose = async (noneGoTo?: boolean) => {
    const patientCaseId = patient.patientCase?.patientCaseId
    if (patientCaseId) {
      await HomeApi.updateStatusService(patientCaseId).then(() => {
        if (!noneGoTo) {
          setTimeout(() => { window.location.href = routeName.treatmentsQueue }, 500)
        }
      })
    }

  }

  const onConfirmClose = () => {
    dispatch(showModal())
    setConfirmClose(true)
    // setIsCancel(false)
  }

  const onCloseMenu = () => {
    setAnchorElMobileTabs(null)
    setAnchorElTabletTabs(null)
  }

  const handleOpenDropDown = () => {
    const dropdownTabletWidth = Array.from(document.getElementsByClassName('btn-dropdown-menu-tablet-tab') as HTMLCollectionOf<HTMLElement>)
    const dropdownMobileWidth = Array.from(document.getElementsByClassName('btn-dropdown-menu-mobile-tab') as HTMLCollectionOf<HTMLElement>)
    setTabletWidth(Number(dropdownTabletWidth[0]?.offsetWidth))
    setMobileWidth(Number(dropdownMobileWidth[0]?.offsetWidth))
  }

  const onHandleSaveToothChart = async () => {
    const toothChartFile = {
      patientFileId: treatments?.toothChart?.patientFileId || null,
      base64: '',
      fileExtension: 'png'
    }
    let node: any = ''
    if (treatments.teethType === 'ADULT') {
      node = document.getElementById('tooth-chart')
    } else {
      node = document.getElementById('tooth-chart-child')
    }
    if (node) {
      let base64 = await domtoimage.toPng(node)
      if (base64) {
        const re = 'data:image/png;base64,'
        base64 = base64.replace(re, "'")
        toothChartFile.base64 = base64
      }
    }

    return toothChartFile
  }

  const onCancelTreatmentInvoice = async (doctor: number[]) => {
    const resp = await TreatmentsAPI.findInvoiceTreatment(patient.patientCase?.patientCaseId)
    if (resp.status === 200) {
      resp?.data?.forEach((item: any) => {
        const invoiceId = item?.invoice?.patientInvoiceId
        if (invoiceId && doctor.includes(item?.doctorId)) {
          TreatmentsAPI.deleteInvoiceTreatment(invoiceId)
        }
      })
    }
  }

  const handleTreatmentCurrentChange = () => {
    const newDoctor: number[] = []
    for (const index in treatments.summeryTreatments) {
      const dataTreatment = treatments.summeryTreatments[index]
      if (!treatmentCurrent.some((item: any) => JSON.stringify(item) === JSON.stringify(dataTreatment))) {
        newDoctor.push(dataTreatment?.doctorId || 0)
      }
    }
    return newDoctor
  }

  useEffect(() => {
    if (treatments?.summeryTreatments && treatments?.summeryTreatments.some((item: any) => item?.isChangeCost === "1")) {
      onSaveSummary('save')
    }
  }, [treatments?.summeryTreatments])

  const onSaveSummary = async (type: 'save' | 'sendExam' | 'exportMedicalCertificate' | 'complete' | 'treatmentConsent' | 'onExportMedicalServices' | 'onExportReferralExamination' | 'onExportReferralDental' | 'onExportMedicalCertificate_A5' | 'saveToLab' | 'selectStickerProduct' | 'exportMedicalCertificateFiveDisease' | 'exportMedicalCertificateDrivingLisense' | 'saveToXray' | 'treatmentFees' | 'treatmentDocument') => {
    setLoading(true)
    setSaveTreatment(false)
    const summeryTreatments: any = []
    const summeryProducts: any = []
    const summeryDiagnosis: any = []
    const summeryRecommends: any = []

    let checkNewTotal = true

    if (branch?.pdfInvoiceTreatmentCondition !== "0" && (treatments?.summeryTreatments?.length !== treatmentCurrent?.length || JSON.stringify(treatments.summeryTreatments) !== JSON.stringify(treatmentCurrent))) {
      checkNewTotal = false
    }

    if (!checkNewTotal) {
      onCancelTreatmentInvoice(handleTreatmentCurrentChange())
    }

    _.map(treatments.summeryTreatments, (i: any, index: number) => {
      if (i.operativeTypeId) {
        summeryTreatments.push({
          tempOrderTreatmentId: i.tempOrderTreatmentId || null,
          teeth: i?.teeth || null,
          teethJson: i?.teethJson || null,
          operativeId: i.operativeId || 0,
          qty: Number(i.qty) || 0,
          price: Number(i.price) || 0,
          doctorId: _.some(doctors, { 'userId': i.doctorId }) ? i.doctorId : 0,
          discount: Number(i.discount) || 0,
          discountUnit: i.discountUnit || '',
          additional: Number(i.additional) || 0,
          additionalUnit: i.additionalUnit,
          dfAmount: (i.dfAmount || i.cfAmount) ? Number(i.dfAmount) : null,
          dfUnit: i.dfUnit || null,
          dfDiscountAmount: Number(i.dfDiscountAmount) || 0,
          dfDiscountUnit: i.dfDiscountUnit || '',
          dfAmountNet: Number(i.dfAmountNet) || 0,
          cfAmount: (i.dfAmount || i.cfAmount) ? Number(i.cfAmount) : null,
          cfDiscountAmount: Number(i.cfDiscountAmount) || 0,
          cfDiscountUnit: i.cfDiscountUnit || '',
          cfAmountNet: Number(i.cfAmountNet) || 0,
          dfRemark: i.dfRemark || '',
          clinicFeeId: Number(i.clinicFeeId) || null,
          total: Number(i.total) || 0,
          note: i.note || '',
          noteNextVisit: i.noteNextVisit || '',
          refType: i.refType || null,
          refId: i.refId || 0,
          nextVisit: i.nextVisit || null,
          commission: i.commission || 0,
          refItemId: i?.refItemId || '',
          isChangeCost: i?.isChangeCost || "0",
          bodyData: i?.bodyData || "",
          newBodyData: i?.newBodyData || "",
          requestReason: i?.requestReason || ""
        })
      }

      if (!i.operativeTypeId) {
        summeryProducts.push({
          tempOrderProductId: i.tempOrderProductId || null,
          inventoryId: i.inventoryId,
          itemId: i.itemId,
          itemName: i.itemName,
          group: i.group,
          qty: Number(i.qty),
          price: Number(i.price),
          doctorId: _.some(doctors, { userId: i.doctorId }) ? i.doctorId : 0,
          discount: Number(i.discount) || 0,
          discountUnit: i.discountUnit || 'BAHT',
          additional: Number(i.additional) || 0,
          additionalUnit: i.additionalUnit || 'BAHT',
          note: i.note || '',
          refType: i.refType || null,
          refId: i.refId || 0,
          total: Number(i.total) || 0,
          commission: i.commission || 0,
          sticker: i.sticker || null
        })
      }
    })

    _.map(treatments.patientDiagnosis, (i: any, index: number) => {
      summeryDiagnosis.push({
        patientDiagnosisId: i.patientDiagnosisId || null,
        diagnosisId: Number(i.diagnosisId) || 0,
        doctorId: _.some(doctors, { userId: Number(i.doctorId) }) ? Number(i.doctorId) : 0,
        note: i.note || '',
        teeth: i?.teeth || null,
        teethJson: i?.teethJson || null,
        teethType: i?.teethType || treatments?.teethType || 'ADULT',
        refItemId: JSON.stringify(i?.refItemId || '[]') || '[]'
      })
    })

    _.map(treatments.patientRecommends, (i: any, index: number) => {
      summeryRecommends.push({
        patientRecommendId: i.patientRecommendId || null,
        recommendId: i.recommendId,
        refItemId: JSON.stringify(i?.refItemId || '[]') || '[]'
      })
    })

    const summary: TreatmentStateType = {
      importantSymptoms: importantSymptoms || "",
      importantSymptomsDoctor: importantSymptomsDoctor || '',
      toothChart: await onHandleSaveToothChart(),
      teethType: treatments?.teethType || 'ADULT',
      servicePointId: servicePointId,
      summeryTreatments: summeryTreatments,
      summeryProducts: summeryProducts,
      patientDiagnosis: summeryDiagnosis,
      patientRecommends: summeryRecommends
    }
    if (_.some(summary.summeryTreatments, { 'qty': 0 }) || _.some(summary.summeryProducts, { 'qty': 0 })) {
      setLoading(false)
      return notiError(t(`TREATMENT_SUMMARY.MESSAGE.${_.some(summary.summeryTreatments, { 'qty': 0 }) ? 'NO_OPERATIVE_QTY' : 'NO_PRODUCT_QTY'}`), '', null, null, 'register-page')
    }

    if (_.some(summary.summeryTreatments, { 'doctorId': 0 })) {
      setLoading(false)
      return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_TREATMENT'), '', null, null, 'register-page')
    }

    // if (_.some(summary.summeryProducts, { group: 'MEDICINE', 'doctorId': 0 })) {
    //   setLoading(false)
    //   return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_MEDICINE'), '', null, null, 'register-page')
    // }

    if (_.some(summary.patientDiagnosis, { 'doctorId': 0 })) {
      setLoading(false)
      return notiError(t('TREATMENT_SUMMARY.MESSAGE.NO_DOCTOR_IN_DIAGNOSIS'), '', null, null, 'register-page')
    }

    if (type === 'treatmentConsent' && (_.isEmpty(summary.patientDiagnosis) && _.isEmpty(summary.summeryProducts) && _.isEmpty(summary.summeryTreatments))) {
      setLoading(false)
      return swalWarning(t('TREATMENT_SUMMARY.MESSAGE.PLS_SELECT_TREATMENT'), '', null)
    }
    try {
      const patientCaseId = patient.patientCase?.patientCaseId
      const resp = await TreatmentSummaryApi.treatmentSummaryStore(patientCaseId, summary).finally(() => setLoading(false))
      const findTry = await TreatmentSummaryApi.findTreatmentSummary(patientCaseId).catch(() => setSaveTreatment(false))

      if (resp.status === 201) {
        // setBlockPage(false)
        const pAlreadyTreatment: string = findTry.data.isFixCode === "1" ? localStorage.getItem('PATIENT_ALREADY_TREATMENT') || '' : ''
        const checkNewP: number[] = pAlreadyTreatment ? JSON.parse(pAlreadyTreatment) : []

        if (type === 'complete') {
          TreatmentsAPI.finishTreatment(patientCaseId)
            .then(() => {
              notiSuccess(t('TREATMENT_SUMMARY.MESSAGE.SUCCESS.SAVE'), '', null, null, 'register-page')
              localStorage.setItem('PATIENT_ALREADY_TREATMENT', JSON.stringify(!_.includes(checkNewP, patientCaseId) ? [...checkNewP, patientCaseId] : checkNewP))
              return setTimeout(() => { window.location.href = routeName.treatmentsQueue }, 500)
            }).catch((e) => {
              const message = e?.response?.data?.message
              if (message === 'NOT_FOUND_SERVICE_POINT_PAYMENT') {
                notiError(t('TREATMENT_SUMMARY.MESSAGE.NOT_FOUND_SERVICE_POINT_PAYMENT'))
              } else if (message === 'WRONG_STATUS_VISIT') {
                swalWarning(t('TREATMENT_SUMMARY.MESSAGE.ERROR.ERROR_PATIENT'), t('TREATMENT_SUMMARY.MESSAGE.ERROR.ERROR_VISIT_SUCCESS'), () => { window.location.href = routeName.treatmentsQueue })
              }
              else notiError(t('TREATMENT_SUMMARY.MESSAGE.ERROR.ERROR_TREATMENT'))
            })
        } else {
          localStorage.setItem('PATIENT_ALREADY_TREATMENT', JSON.stringify(!_.includes(checkNewP, patientCaseId) ? [...checkNewP, patientCaseId] : checkNewP))
        }


        if (findTry.status === 200) {
          const newSummeryProducts: any = []
          if (!_.isEmpty(findTry.data.summeryProducts)) {
            _.map(findTry.data.summeryProducts, (prod, index) => {
              newSummeryProducts.push({ ...prod, total: ((prod.price * prod.qty) - prod.discount) + prod.additional })
            })
          }
          setTreatmentCurrent([...findTry.data.summeryTreatments, ...newSummeryProducts])
          dispatch(setTreatmentByKey({ key: 'summeryTreatments', value: [...findTry.data.summeryTreatments, ...newSummeryProducts] }))
          const newPatientDiagnosis: any = []
          if (!_.isEmpty(findTry.data.patientDiagnosis)) {
            _.map(findTry.data.patientDiagnosis, (prod, index) => {
              newPatientDiagnosis.push({ ...prod, refItemId: JSON.parse(prod.refItemId || []) })
            })
          }
          dispatch(setTreatmentByKey({ key: 'patientDiagnosis', value: [...newPatientDiagnosis] }))
          const newPatientRecommends: any = []
          if (!_.isEmpty(findTry.data.patientRecommends)) {
            _.map(findTry.data.patientRecommends, (prod, index) => {
              newPatientRecommends.push({ ...prod, refItemId: JSON.parse(prod.refItemId || []) })
            })
          }
          dispatch(setTreatmentByKey({ key: 'patientRecommends', value: [...newPatientRecommends] }))
        }
        if (type === 'save') return notiSuccess(t('TREATMENT_SUMMARY.MESSAGE.SUCCESS.SAVE'), '', null, null, 'register-page')
        if (type === 'saveToLab') return setSaveTreatment(true)
        if (type === 'saveToXray') return
        if (type === 'sendExam') return
        if (type === 'exportMedicalCertificate') return onExportMedicalCertificate()
        if (type === 'onExportMedicalServices') return setSSO(true)
        if (type === 'treatmentConsent') return onCheckOpenPopPayment(() => onTreatmentConsent())
        if (type === 'onExportMedicalCertificate_A5') return setExportMedicalCertificate_A5(true)
        if (type === 'onExportReferralExamination') return setExportReferralExamination(true)
        if (type === 'onExportReferralDental') return setExportReferralDental(true)
        if (type === 'selectStickerProduct') return onOpenSelectStickerProduct()
        if (type === 'exportMedicalCertificateFiveDisease') return onExportMedicalCertificateFiveDisease()
        if (type === 'exportMedicalCertificateDrivingLisense') return onExportMedicalCertificateDrivingLisense()
        if (type === 'treatmentFees') return onCheckOpenPopPayment(() => onPopTreatmentFees())
        if (type === 'treatmentDocument') return onPopTreatmentDocument()
      } else {
        return notiError(resp.response.data.message, '', null, null, 'register-page')
      }
    } catch (error: any) {
      if (error?.response?.data?.message === 'IS_CANCEL') {
        swalWarning(t('TREATMENT_SUMMARY.MESSAGE.ERROR.ERROR_TREATMENT'), t('TREATMENT_SUMMARY.MESSAGE.ERROR.ERROR_VISIT_CANCEL'), () => { window.location.href = routeName.treatmentsQueue })
      }
      if (error?.response?.data?.message === 'PRODUCT_NOT_ENOUGH' || error?.response?.data?.message === 'MEDICINE_NOT_ENOUGH') {
        notiError(`${t('TREATMENT_SUMMARY.MESSAGE.ERROR.ERROR_PRODUCT_COUNT')} ${error?.response?.data?.name || ''} ${t('TREATMENT_SUMMARY.MESSAGE.ERROR.ERROR_PRODUCT_COUNT_NOT')}`)
      }

    }

  }

  const handleErrorApprovalPending = () => {
    swalCustom(
      t('APPROVELLIST.FILTER.PENDING'),
      `<div >${t('APPROVELLIST.MESSAGE.SAVE_APPROVAL_TITLE')} ${t('APPROVELLIST.MESSAGE.SAVE_APPROVAL_SUB')}</div>`,
      () => console.log(),
      'warning',
      {
        cancelButton: { cancelButtonText: t('BUTTON.ENTER') },
        confirmButton: { showConfirmButton: false },
        showCloseButton: false,
      }
    )
  }

  const onCheckOpenPopPayment = (func: () => void) => {
    if (treatments.summeryTreatments?.some((item: any) => item?.approvalItems?.status === ApprovalStatus.PENDING) && approvalRight?.hasChangeCost) {
      return handleErrorApprovalPending()
    } else {
      return func()
    }
  }

  const onSendExam = async () => {
    await onSaveSummary('sendExam')
    const resp = await HomeApi.patientCase(patient.patientCase?.patientCaseId)
    if (resp.status === 200 && !_.isEmpty(resp.data)) {
      setPatientCase(resp.data)
      setSendExam(true)
    } else setPatientCase([])
  }

  const onExportMedicalCertificate = () => {
    HomeApi.patientCase(patient.patientCase?.patientCaseId)
      .then(({ data }) => {
        setPatientCase(data)
        setExportMedicalCertificate(true)
        dispatch(showModal())
      })
      .catch((e) => { return })
  }

  const onExportMedicalCertificateFiveDisease = () => {
    HomeApi.patientCase(patient.patientCase?.patientCaseId)
      .then(({ data }) => {
        setPatientCase(data)
        setExportMedicalCertificateFiveDisease(true)
        dispatch(showModal())
      })
      .catch((e) => { return })
  }

  const onExportMedicalCertificateDrivingLisense = () => {
    HomeApi.patientCase(patient.patientCase?.patientCaseId)
      .then(({ data }) => {
        setPatientCase(data)
        setExportMedicalCertificateDrivingLisense(true)
        dispatch(showModal())
      })
      .catch((e) => { return })
  }

  const onOpenSelectStickerProduct = () => {
    setShowPopupSelectStickerProduct(true)
    dispatch(showModal())
  }

  const onExportMedicalServices = async () => {
    await onSaveSummary('onExportMedicalServices')
    const resp = await HomeApi.patientCase(patient.patientCase?.patientCaseId)
    if (resp.status === 200 && !_.isEmpty(resp.data)) {
      setPatientCase(resp.data)
      dispatch(showModal())
    } else setPatientCase([])
  }


  const onExportReferralExamination = async () => {
    await onSaveSummary('onExportReferralExamination')
    const resp = await HomeApi.patientCase(patient.patientCase?.patientCaseId)
    if (resp.status === 200 && !_.isEmpty(resp.data)) {
      setPatientCase(resp.data)
      dispatch(showModal())
    } else setPatientCase([])
  }


  const onExportReferralDental = async () => {
    await onSaveSummary('onExportReferralDental')
    const resp = await HomeApi.patientCase(patient.patientCase?.patientCaseId)
    if (resp.status === 200 && !_.isEmpty(resp.data)) {
      setPatientCase(resp.data)
      dispatch(showModal())
    } else setPatientCase([])
  }

  const onExportMedicalCertificate_A5 = async () => {
    await onSaveSummary('onExportMedicalCertificate_A5')
    const resp = await HomeApi.patientCase(patient.patientCase?.patientCaseId)
    if (resp.status === 200 && !_.isEmpty(resp.data)) {
      setPatientCase(resp.data)
      dispatch(showModal())
    } else setPatientCase([])
  }

  useEffect(() => {
    const patientState: any = location.state
    const valueServicePointId = getServicePoint()
    if (!_.isEmpty(patientState) && patientState.patientId && parseInt(patientState.servicePointId) === parseInt(valueServicePointId || ``)) {
      setPatientId(patientState.patientId)
      setServicePointId(patientState.servicePointId)
    }
    else history.push(routeName.treatmentsQueue)
  }, [])

  useEffect(() => {
    if (!_.isEmpty(user) && patientId) loadPatient({ viewPatient: true })
  }, [patientId, user])

  const onViewHistory = () => {
    dispatch(showModal())
    setIsShowPopupDetail(true)
  }

  const renderImage = (img: any) => {
    if (!_.isEmpty(img) && !_.isEmpty(img.filePath) && !_.isEmpty(img.fileName)) return `${fileUrl}/${img.filePath}/${img.fileName}`
    else return imgs.defaultAvatar
  }

  const onTreatmentConsent = () => {
    dispatch(showModal())
    return setTreatmentConsent(true)
  }

  const onPopTreatmentFees = () => {
    dispatch(showModal())
    return setPopTreatmentFees(true)
  }

  const onPopTreatmentDocument = () => {
    dispatch(showModal())
    return setPopTreatmentDocument(true)
  }

  const exportOpd = async (id: number) => {
    setLoading(true)
    exportOPD(id).catch((e) => { setLoading(false) }).finally(() => { setLoading(false) })
  }

  useEffect(() => {
    if (activeSubTab !== 'TX') {
      dispatch(setEditTootChart(null))
      dispatch(setEditTootChartType(''))
    }
  }, [activeTab, activeSubTab, activeFooterTab])

  useEffect(() => {
    if (isEditTootCharts !== null && activeSubTab !== SUB_TABS_NAME.DX && !modal) {
      setActiveTab(tabs[0])
      setActiveSubTab(SUB_TABS_NAME.TX)
    }
  }, [isEditTootCharts])

  const loadCheckApprovePermiss = async () => {
    await ApprovalApi.checkApprovePermiss().then(({ data }) => {
      if (data) {
        dispatch(setApprovalRight(data))
      }
    })
  }

  useEffect(() => {
    loadDoctor()
    loadCheckApprovePermiss()
    // setBlockPage(true)
  }, [])

  // useEffect(() => {
  //   return () => {
  //     handleTreatmentClose(true)
  //   };
  // }, [patient.patientCase?.patientCaseId]);

  useEffect(() => {
    if (!modal) {
      setExportMedicalCertificate(false)
      setIsShowPopupDetail(false)
      setTreatmentConsent(false)
      setSaveTreatment(false)
      setShowPopupSelectStickerProduct(false)
      setConfirmDF(false)
      setConfirmClose(false)
      setExportMedicalCertificateFiveDisease(false)
      setExportMedicalCertificateDrivingLisense(false)
      setPopTreatmentFees(false)
      setPopTreatmentDocument(false)
      dispatch(resetModal())
    }

  }, [modal]);

  const handlePatientCode = () => {
    if (branch?.patientCodeQueue === '1') {
      return patient.cnNumber ?? ''
    }
    if (branch?.patientCodeQueue === '0') {
      return patient.branchCnNumber ?? ''
    }

    if (branch?.patientCodeQueue === '2') {
      return patient.snCode ?? ''
    }
  }


  return (
    <Box className="px-3 pt-4" sx={{ paddingBottom: '80px' }}>
      <Box>
        <Grid container spacing={2}>
          <Grid item className="w-100">
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4} className="w-100 mb-xl-3">
                <UseStyled.CardProfile className="d-flex px-3" onClick={onViewHistory}>
                  <BtnProfile profile={renderImage(patient.profile)} branchCnNumber={handlePatientCode()} firstName={patient.firstname} lastName={patient.lastname} rank={patient.rank} lastVisit={patient.updatedAt} />
                </UseStyled.CardProfile>
              </Grid>
              <Grid item xs={6} lg={4} className="w-100 mb-sm-3">
                <InputTextarea label={t('REGISTER.FORM.IMPORTANT_SYMPTOMS')} height={80} onchange={(e: any) => setImportantSymptoms(e.target.value)} value={importantSymptoms} />
              </Grid>
              <Grid item xs={6} lg={4} className="w-100 mb-3">
                <InputTextarea label={t('TREATMENTS.FORM.IMPORTANT_SYMPTOMS_DOCTOR')} height={80} onchange={(e: any) => setImportantSymptomsDoctor(e.target.value)} value={importantSymptomsDoctor} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item className="w-100">
            <UseStyled.CustomTabs>
              <Tabs value={activeTab} onChange={handleChangeTab} aria-label="treatment tab" className="d-none d-md-block">
                {_.map(tabs, (title: string, index: number) => {
                  return (
                    (index === 0 && (
                      <>
                        <UseStyled.CustomButton
                          key={`tabChild-${index}`}
                          aria-haspopup="true"
                          aria-expanded={openTabletTabs ? 'true' : undefined}
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            handleChangeTab(event, 0)
                            setAnchorElTabletTabs(event.currentTarget)
                            handleOpenDropDown()
                          }}
                          className={`btn-tab btn-dropdown-menu-tablet-tab ${activeTab === TABS_NAME.OPD ? 'Mui-selected' : ''}`}
                          disableRipple
                          {...a11yProps(index)}
                        >
                          <span>{t(title)}</span>
                          <span className={'d-xl-none pl-1'}>({activeSubTab})</span>
                          <FontAwesomeIcon icon={faCaretDown} className={'icon-selector d-xl-none'} />
                        </UseStyled.CustomButton>
                        <UseStyled.MenuBtnMore
                          id="basic-menu-more"
                          anchorEl={anchorElTabletTabs}
                          open={openTabletTabs}
                          onClose={onCloseMenu}
                          onClick={onCloseMenu}
                          className="d-xl-none"
                          sx={{
                            '.MuiMenu-list': {
                              width: tabletWidth,
                              maxHeight: '270px'
                            }
                          }}
                        >
                          {_.map(subTabs, (subTab: SUB_TABS_NAME, indexSubTab: number) => {
                            return (
                              <MenuItem
                                key={indexSubTab}
                                className={'d-block text-ellipsis'}
                                onClick={() => {
                                  setActiveTab(tabs[0])
                                  setActiveSubTab(subTab)
                                  setSaveTreatment(false)
                                  if (indexSubTab === 0) setActiveFooterTab(1)
                                  else setActiveFooterTab(0)
                                }}>
                                {subTab}
                              </MenuItem>
                            )
                          })}
                        </UseStyled.MenuBtnMore>
                      </>
                    )) || <Tab key={`tab-${index}`} label={t(title)} {...a11yProps(index)} className={`btn-tab ${activeTab === title ? 'Mui-selected' : ''}`} disableRipple />
                  )
                })}
              </Tabs>
            </UseStyled.CustomTabs>

            <UseStyled.CustomTabs className="d-md-none">
              <UseStyled.CustomButton
                id="mobile-tabs"
                aria-controls={openMobileTabs ? 'basic-menu-more' : undefined}
                aria-haspopup="true"
                aria-expanded={openMobileTabs ? 'true' : undefined}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setAnchorElMobileTabs(event.currentTarget)
                  handleOpenDropDown()
                }}
                className={'btn-tab btn-dropdown-menu-mobile-tab'}
                disableRipple
              >
                {activeTab === TABS_NAME.OPD ? `OPD (${activeSubTab})` : t(activeTab)}
                <FontAwesomeIcon icon={faCaretDown} className={'icon-selector'} />
              </UseStyled.CustomButton>

              <UseStyled.MenuBtnMore
                id="basic-menu-more"
                anchorEl={anchorElMobileTabs}
                open={openMobileTabs}
                onClose={onCloseMenu}
                onClick={onCloseMenu}
                className="d-xl-none"
                sx={{
                  '.MuiMenu-list': {
                    width: mobileWidth,
                    maxHeight: '270px'
                  }
                }}>
                <Typography className="pb-1 px-3" sx={{ fontWeight: 600 }}>
                  {t(tabs[0])}
                </Typography>
                {_.map(subTabs, (subTab: SUB_TABS_NAME, indexSubTab: number) => {
                  return (
                    <MenuItem
                      key={indexSubTab}
                      className={'d-block text-ellipsis'}
                      onClick={() => {
                        setActiveTab(tabs[0])
                        setActiveSubTab(subTab)
                        setSaveTreatment(false)
                        if (indexSubTab === 0) setActiveFooterTab(1)
                        else setActiveFooterTab(0)
                      }}
                    >
                      {subTab}
                    </MenuItem>
                  )
                })}
                <Divider className="mx-0" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
                {_.map(tabs, (title: string, indexTitle: number) => {
                  if (indexTitle > 0)
                    return (
                      <MenuItem key={indexTitle} className={'d-block text-ellipsis'} onClick={() => setActiveTab(tabs[indexTitle])}>
                        {t(title)}
                      </MenuItem>
                    )
                })}
              </UseStyled.MenuBtnMore>
            </UseStyled.CustomTabs>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item className="w-100 mt-3">
            <TabPanel value={activeTab} index={TABS_NAME.OPD}>
              <IndexOPD
                type='Treatment'
                userId={user.userId}
                patient={patient}
                tabs={subTabs}
                activeTab={activeSubTab}
                setActiveTab={(tab: SUB_TABS_NAME) => {
                  setActiveSubTab(tab);
                }}
                setFooterActiveTab={(tab: number) => setActiveFooterTab(tab)}
                onSaveTreatment={(status: 'saveToLab' | 'saveToXray') => onSaveSummary(status)}
                saveTreatment={saveTreatment}
                setSaveTreatment={(val: boolean) => {
                  setSaveTreatment(val);
                }}
                hasChangeProductPrice={'PERMISS'}
                hasChangeProductDiscountAdditional={'PERMISS'}
                hasCreateTreatment={'PERMISS'}
                hasCreateTreatmentPlan={'PERMISS'}
                hasCreateTreatmentCourse={'PERMISS'}
                medShowType={branch?.medShowType}
                pdShowType={branch?.pdShowType}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={TABS_NAME.DOCTOR_NOTE}>
              <IndexDoctorNote userId={user.userId} patient={patient} hasDoctorNote={'PERMISS'} hasCreateDoctorNote={'PERMISS'} hasDeleteDoctorNote={'PERMISS'} />
            </TabPanel>
            <TabPanel value={activeTab} index={TABS_NAME.IMAGE}>
              <IndexImage patient={patient} />
            </TabPanel>
            <TabPanel value={activeTab} index={TABS_NAME.VITAL_SIGN}>
              <IndexVitalSign patient={patient} onchange={setPatient} loadPatient={(val) => setPatientId(val)} />
            </TabPanel>
          </Grid>
        </Grid>

        <Box className={`w-100 ${activeTab === TABS_NAME.VITAL_SIGN ? 'd-none opacity-0 pe-none' : ''}`}>
          <IndexSummaryTreatment
            onSave={onSaveSummary}
            userId={user.userId}
            type='Treatment'
            tabs={footerTabs}
            activeTab={activeFooterTab}
            setActiveTab={(tab: number) => {
              setActiveFooterTab(tab);
              dispatch(setEditTootChart(null));
              dispatch(setEditTootChartType(''));
              if (tab === 0) setActiveSubTab(SUB_TABS_NAME.TX);
              else if (tab === 1) setActiveSubTab(SUB_TABS_NAME.DX);
            }}
            hasTreatment={'PERMISS'}
            hasChangeTreatmentCost={hasChangeTreatmentCost || "PERMISS"}
            hasChangeTreatmentNote={'PERMISS'}
            hasChangeProductPrice={'PERMISS'}
            patientCaseId={patient.patientCase?.patientCaseId}
            patientId={patient.patientId}
            patientName={`${patient.firstname} ${patient.lastname}`}
            doctors={doctors}
            hasDiagnosis={'PERMISS'}
            hasChangeProductDiscountAdditional={'PERMISS'}
            hasDeleteTreatment={'PERMISS'}
            patientPhone={patient.phone}
            handleTreatmentCurrent={setTreatmentCurrent}
          />
        </Box>
      </Box>

      {!formXrayPage && !modal_xray && isShowPopupDetail && (
        <PopupPatientDetail
          isNotShownPhone={user.hasShowPhoneNumber === 'UNPERMISS'}
          type='treatment'
          profile={renderImage(patient.profile)}
          patient={patient}
          showPopup={isShowPopupDetail}
          onClose={() => {
            setIsShowPopupDetail(false)
            dispatch(resetModal())
          }}
          reRenderPatient={() => loadPatient({ viewPatient: true })}
        />
      )}

      {activeTab !== TABS_NAME.VITAL_SIGN && (
        <FragmentFooter
          type='Treatment'
          tabActive={activeSubTab}
          onSave={() => onSaveSummary('save').then(() => setSave(true))}
          onCompleteTreatment={() => { user.isViewDfConfirm === '1' ? onSaveSummary('save').then(() => onConfirmDF()) : onSaveSummary('complete') }}
          onSendExam={onSendExam}
          onExportMedicalCertificate={() => onSaveSummary('exportMedicalCertificate')}
          onExportMedicalCertificateFiveDisease={() => onSaveSummary('exportMedicalCertificateFiveDisease')}
          onExportMedicalCertificateDrivingLisense={() => onSaveSummary('exportMedicalCertificateDrivingLisense')}
          onExportMedicalServices={onExportMedicalServices}
          onExportMedicalCertificate_A5={onExportMedicalCertificate_A5}
          onExportReferralExamination={onExportReferralExamination}
          onExportReferralDental={onExportReferralDental}
          onExportSSO={exportSSO}
          onTreatmentClose={() => onTreatmentClose()}
          onExportOPD={() => exportOpd(patient.patientId)}
          onTreatmentConsent={() => onSaveSummary('treatmentConsent')}
          onTreatmentFees={() => onSaveSummary('treatmentFees')}
          onTreatmentDocument={() => onSaveSummary('treatmentDocument')}
          onPopupSelectProduct={() => onSaveSummary('selectStickerProduct')}
          patientName={`${patient.firstname} ${patient.lastname}`}
          showButtonPrintMedicineSticker={_.some(treatments.summeryTreatments, { group: 'MEDICINE' })}
          patientCaseId={patient?.patientCase?.patientCaseId}
        />
      )}

      {sendExam && (
        <SendExamination
          isShow={sendExam}
          isHidePhoneNumber={user.hasShowPhoneNumber === 'UNPERMISS'}
          setIsShow={(show: boolean) => {
            setSendExam(show)
            loadPatient({ viewPatient: false })
          }}
          patient={patientCase} type={'treatment'} />)
      }

      {exportMedicalCertificate && (
        <MedicalCertificate
          isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setExportMedicalCertificate(show)}
          patientId={patient.patientId}
          patientCaseId={patient.patientCase.patientCaseId}
          createdAt={patient.createdAt}
          type={'treatment'}
        />
      )}
      {exportMedicalCertificateFiveDisease && (
        <MedicalCertificateFiveDisease
          isShow={exportMedicalCertificateFiveDisease}
          setIsShow={(show: boolean) => setExportMedicalCertificateFiveDisease(show)}
          patientId={patient.patientId}
          branchId={branchIdServicePoint}
          patient={patient}
          type={'treatment'}
          user={user}
          treatments={treatments}
        />
      )}
      {exportMedicalCertificateDrivingLisense && (
        <MedicalCertificateDrivingLicense
          isShow={exportMedicalCertificateDrivingLisense}
          setIsShow={(show: boolean) => setExportMedicalCertificateDrivingLisense(show)}
          patientId={patient.patientId}
          branchId={branchIdServicePoint}
          patient={patient}
          type={'treatment'}
          user={user}
          treatments={treatments}
        />
      )}

      {exportMedicalServices && (
        <SSO
          hidePhoneNumber={user.hasShowPhoneNumber === 'UNPERMISS'}
          isShow={exportMedicalServices}
          setIsShow={(show: boolean) => setSSO(show)}
          patientId={patient.patientId}
          branchId={branchIdServicePoint}
          patient={patient}
          type={'treatment'}
          user={user}
          treatments={treatments}
        />
      )}
      {exportMedicalCertificate_A5 && (
        <MedicalCertificate_A5 isShow={exportMedicalCertificate}
          setIsShow={(show: boolean) => setExportMedicalCertificate_A5(show)}
          patientId={patient.patientId}
          branchId={branchIdServicePoint}
          patient={patient} />
      )}
      {
        exportReferralExamination && (
          <ReferralEaxmination
            isShow={exportMedicalCertificate}
            setIsShow={(show: boolean) => setExportReferralExamination(show)}
            patientId={patient.patientId}
            branchId={branchIdServicePoint}
            patient={patient}
          />
        )
      }
      {
        exportReferralReferralDental && (
          <ReferralDental
            isShow={exportMedicalCertificate}
            setIsShow={(show: boolean) => setExportReferralDental(show)}
            patientId={patient.patientId}
            branchId={branchIdServicePoint}
          />
        )
      }

      {treatmentConsent && (
        <PopupTreatmentConsent
          type='treatment'
          patientCaseId={patient?.patientCase?.patientCaseId}
          servicePointId={servicePointId}
          setIsShow={(show: boolean) => setTreatmentConsent(show)}
        />
      )}

      {showPopupSelectStickerProduct && (
        <PopupSelectPrintProduct
          isShow={showPopupSelectStickerProduct}
          setIsShow={(show: boolean) => {
            setShowPopupSelectStickerProduct(show)
            dispatch(resetModal())
          }}
          patientCaseId={patient?.patientCase?.patientCaseId}
          doctors={doctors} />
      )}
      {confirmDF && (
        <PopupConfirmDF
          isShow={confirmDF}
          treatments={treatments}
          handleSuccess={() => onSaveSummary('complete')}
        />
      )}

      {confirmClose && (
        <PopupConfirmClose
          isShow={confirmClose}
          treatments={treatments}
          handleSuccess={() => onSaveSummary('save').then(() => handleTreatmentClose())}
          onReset={() => handleTreatmentClose()}
        />
      )}

      {popTreatmetnFees &&
        <PopupTreatmentFees
          type='treatment'
          patientCaseId={patient?.patientCase?.patientCaseId}
          servicePointId={servicePointId}
          setIsShow={(show: boolean) => setPopTreatmentFees(show)}
          handleTreatmentCurrent={setTreatmentCurrent}
        />
      }


      {popTreatmentDocument &&
        <PopTreatmentDocument
          type='treatment'
          patientCaseId={patient?.patientCase?.patientCaseId}
          servicePointId={servicePointId}
          setIsShow={(show: boolean) => setPopTreatmentDocument(show)}
        />}

      {loading && <Loading show={true} type="fullLoading" />}

      {/* <RouterPrompt /> */}
    </Box >
  )
}

function a11yProps(index: number) {
  return {
    id: `treatment-tab-${index}`,
    'aria-controls': `treatment-tabpanel-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tab" hidden={value !== index} id={`treatment-tab-${index}`} aria-labelledby={`treatment-tabpanel-${index}`} {...other}>
      {value === index && <Box className={`${index === TABS_NAME.VITAL_SIGN ? 'pt-3' : ''}`}>{children}</Box>}
    </div>
  )
}
