import { Box } from '@mui/material'
import StarActive from 'assets/icon/starActive'
import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { CardManageWeb } from '../useStyles'
import { fileUrl } from 'api/api'
import BtnEdit from 'assets/button/BtnEdit'
import BtnDelFilled from 'assets/button/BtnDelFilled'
import DrageMove from 'assets/icon/appointment/card/drag-move'
import { colors } from 'constants/theme'
import ManageWebApi from 'api/application/manageWeb'
import { ReviewProps, ReviewSortProps } from 'api/application/manageWeb-interface'
import { useTranslation } from 'react-i18next'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { ManegeWebAction, ManegeWebStatus } from '../ManageWeb'
import { swalActive, swalDelete } from 'component/Alert/Swal'

type ReviewListProps = {
    dataList: any
    onEdit: (status: boolean, item?: any) => void
    onDel: (id: number, name: string) => void
    statusDel: boolean
    reLoad: () => void
}

const ReviewList = (props: ReviewListProps) => {
    const { t } = useTranslation()
    const t_REVIEW = "MANAGE_WEB.REVIEW"

    const [isDragging, setIsDragging] = useState<boolean>(false)

    const renderStarScore = (score: number) => {
        const newEmptyArray = Array(5).fill(null);
        const renderStar = newEmptyArray.map((item: any, index: number) => (
            <StarActive key={index} stroke='#F6E331' fill={score < index + 1 ? 'transparent' : '#F6E331'} />
        ))
        return renderStar
    }

    const onDragStart = (result: any) => {
        setIsDragging(true)
    }

    const onDragEnd = async (result: any) => {
        if (!result.destination) {
            setIsDragging(false)
            return;
        }
        const { source, destination } = result;
        const data = props?.dataList
        const [removed] = data.splice(source.index, 1);
        data.splice(destination.index, 0, removed);

        const newData: ReviewSortProps[] = data.map((item: any, index: number) => {
            return { reviewId: item.reviewId, sorting: index + 1 }
        })
        await ManageWebApi.sortReview(newData).finally(() => {
            setIsDragging(false)
            props.reLoad()
        })
    }

    const handleActive = async (status: 'ACTIVE' | 'INACTIVE', data: any) => {
        const id = data?.reviewId || 0
        const newPayload: ReviewProps = { ...data, status: status }
        const onSaveStatus = async () => {
            if (id !== 0) {
                const res = await ManageWebApi.updateReview(id, newPayload)
                if (res === "pass") {
                    props.reLoad()
                    notiSuccess(t(`${t_REVIEW}.SAVE_STATUS`))
                } else {
                    notiError(t(`${t_REVIEW}.SAVE_ERROR`))
                }
            } else {
                notiError(t(`${t_REVIEW}.SAVE_ERROR`))
            }
        }
        swalActive(
            `<p class="mb-0 mx-auto" style="max-width: 475px">${t(t_REVIEW + '.NOTI_CHANGE_STATUS')}</p>`,
            `<p class="mb-0 mx-auto" style="max-width: 450px">${t(`${t_REVIEW}.ALERT.CONFIRM_STATUS_`, {
                subject: data.topic || '-',
                status: t(`STATUS.${status}`)
            })}</p>`,
            async (res: any) => {
                if (res) {
                    await onSaveStatus()
                }
            },
            'question',
            t('BUTTON.SUBMIT'),
            t('BUTTON.CANCEL')
        )
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} >
                <Box sx={{ paddingRight: '16px', paddingBottom: isDragging ? '200px' : '' }}>
                    <Droppable droppableId={`${'Review'}`}>
                        {(dropProvided, dropSnapshot) => (
                            <Box ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}>
                                {props.dataList && props.dataList.map((item: any, index: number) => {
                                    return (
                                        <Draggable
                                            key={index}
                                            draggableId={`${item.reviewId}-${index}`}
                                            index={index}
                                        >
                                            {(dragProvided, dragSnapshot) => {
                                                return (
                                                    <CardManageWeb
                                                        {...dragProvided.draggableProps}
                                                        // {...dragProvided.dragHandleProps}
                                                        ref={dragProvided.innerRef}
                                                    >
                                                        <Box className={'content-card'} sx={{ width: 'calc(100%)' }}>
                                                            <Box className={'container-img'} >
                                                                <img src={`${fileUrl}/${item?.filePath}/${item?.fileName}`} />
                                                            </Box>
                                                            <Box className={'container-des'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <Box sx={{ width: 'calc(100% - 82px)' }}>
                                                                        <Box className={'text-ellipsis'} sx={{ fontSize: '18px', fontWeight: '500', minHeight: '27px' }}>
                                                                            {item?.topic}
                                                                        </Box>
                                                                    </Box>
                                                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                                        <Box
                                                                            sx={{ cursor: 'pointer' }}
                                                                            {...dragProvided.dragHandleProps}
                                                                        >
                                                                            <DrageMove
                                                                                fill={dragSnapshot?.isDragging ? colors.themeSecondColor : undefined}
                                                                                width={'20px'}
                                                                                height={'20px'}
                                                                            />
                                                                        </Box>
                                                                        <Box sx={{ cursor: 'pointer', marginLeft: '8px' }}>
                                                                            <ManegeWebAction
                                                                                onActive={() => handleActive("ACTIVE", item)}
                                                                                onInActive={() => handleActive("INACTIVE", item)}
                                                                                disabledActive={item.status === "ACTIVE"}
                                                                                onEdit={() => props.onEdit(true, item)}
                                                                                onDelete={() => props.onDel(item.reviewId, item.topic)}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ marginTop: '8px', paddingBottom: '8px', width: '100%', height: '100%' }}>
                                                                    <Box sx={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        textAlign: 'left',
                                                                        display: '-webkit-box',
                                                                        WebkitLineClamp: 3,
                                                                        WebkitBoxOrient: 'vertical'
                                                                    }} >
                                                                        {item?.subTopic}
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ paddingBottom: '16px', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                                                                    <Box>
                                                                        {renderStarScore(item?.score || 0)}
                                                                    </Box>
                                                                    <Box sx={{ width: 'fit-content', marginTop: '10px' }}>
                                                                        <ManegeWebStatus status={item?.status || 'INACTIVE'} />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </CardManageWeb>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                            </Box>
                        )}
                    </Droppable>
                </Box>
            </DragDropContext>
        </>
    )
}

export default ReviewList