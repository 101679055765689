import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'

export interface ChangePassword {
    password?: string
    confirmPassword?: string
}

export interface tagProps {
    tagId?: number
    tagName: string
    tagNameEn: string
    color: string
    status?: "ACTIVE" | "INACTIVE"
    operativeTypeId?: number | null
    operativeTypeName?: string
    operativeTypeNameEn?: string
}

const path = 'tags'

export default class TagsApi extends BaseAPI {
    static findAll(props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } }).then((res) => res)
    }
    static findById(props: FindAllInterface): Promise<any> {
        return this.api.get(`${path}`, { params: { ...props } }).then((res) => res)
    }
    static createTag(props: tagProps): Promise<any> {
        return this.api.post(`${path}`, props).then((res) => res)
    }
    static updateTag(id: number, props: tagProps): Promise<any> {
        return this.api.patch(`${path}/${id}`, props).then((res) => res)
    }
    static deleteTag(id: number): Promise<any> {
        return this.api.delete(`${path}/${id}`).then((res) => res)
    }
    static updateStatusTag(id: number, status: string): Promise<any> {
        return this.api.patch(`${path}/${id}/status`, { status: status }).then((res) => res)
    }
}