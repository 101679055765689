import { useState, useEffect } from 'react'
import { Grid, Typography, Box, FormControl, RadioGroup, FormControlLabel, MenuItem } from '@mui/material'
import 'react-multi-carousel/lib/styles.css'
import _ from 'lodash'
import moment from 'moment'
import { useDispatch } from 'react-redux'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import TreatmentHistoryApi from 'api/dentists/treatment-history.api'
import ClinicApi from 'api/master/clinic.api'

/** UTILS || SLICE */
import { closeModal, resetModal } from 'app/slice/modal.slice'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import InputCheckbox from 'component/Input/InputCheckbox'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import InputSecondNewDatePicker from 'component/Input/InputSecondNewDatePicker'
import Loading from 'component/Loading'
import InputTextarea from 'component/Input/InputTextarea'
import InputRadio from 'component/Input/InputRadio'
import InputTextField from 'component/Input/InputTextField'
import { width } from 'pdfkit/js/page'
import { Row } from 'react-bootstrap'
import { Style } from '@mui/icons-material';

type ModalCustomProps = {
    isShow: boolean | false
    setIsShow: (show: boolean) => void
    onClose?: () => void
    patientId: number
    branchId: number
}

type Doctors = {
    userId: number
    firstname: string
    lastname: string
    fullname: string
}

export default function ReferralDental(props: ModalCustomProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [doctors, setDoctors] = useState<Doctors[]>([])
    const [errorDoctorSelect, setErrorDoctorSelect] = useState('')

    const [loading, setLoading] = useState(false)

    /** FILTER */
    const [doctor, setDoctor] = useState<number>(0)
    const [date, setDate] = useState<string>(moment().format('YYYY-MM-DD'))
    const [disableDoctor, setDisableDoctor] = useState(false)
    const [xray, setXray] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [film, setFilm] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [latCaph, setLatCaph] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [other, setOther] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [scrapeSwayTartar, setScrapeSwayTartar] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [toothFilling, setToothFilling] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [toothExtraction, setToothExtraction] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [wisdomTooth, setWisdomTooth] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [fillingQuantity, setFillingQuantity] = useState('')
    const [extractionQuantity, setExtractionQuantity] = useState('')
    const [wisdomQuantity, setWisdomQuantity] = useState('')
    const [other_1, setOther_1] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [continueTreatment, setContinueTreatment] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [consult, setConsult] = useState<'CHECK' | 'UNCHECK'>('UNCHECK')
    const [detailOther, setDetailOther] = useState('')
    const [detailOther_1, setDetailOther_1] = useState('')
    const [dentalHistory, setDentalHistory] = useState('') //Quantity
    const [textLength, setTextLength] = useState<number>(0)
    const [errorMaxLength, setErrorMaxLength] = useState<boolean>(false)

    const loadDoctor = async () => {
        const resp = await ClinicApi.findAllDoctors()
        if (resp.status === 200) setDoctors(resp.data)
    }

    useEffect(() => {
        loadDoctor()
    }, [])

    useEffect(() => {
        if (!xray) {
            setFilm('UNCHECK')
            setLatCaph('UNCHECK')
            setOther('UNCHECK')
            setDetailOther('')
        }
    }, [xray])

    const onPrint = async () => {
        if (!doctor && !disableDoctor) {
            setLoading(false)
            return setErrorDoctorSelect(t('MODAL_MED_CERTIFICATE.NOT_SELECT_DOCTOR'))
        }
        setLoading(true)
        const print = await TreatmentHistoryApi.printReferralExamination(props.patientId, {
            date,
            branchId: props.branchId,
            doctorId: doctor,
            xray: xray,
            film: film,
            latCaph: latCaph,
            scrapeSwayTartar: scrapeSwayTartar,
            toothFilling: toothFilling,
            fillingQuantity: fillingQuantity,
            toothExtraction: toothExtraction,
            extractionQuantity: extractionQuantity,
            wisdomTooth: wisdomTooth,
            wisdomQuantity: wisdomQuantity,
            other: other,
            other_1: other_1,
            detailOther: detailOther,
            detailOther_1: detailOther_1,
            continueTreatment: continueTreatment,
            consult: consult,
            dentalHistory: dentalHistory
        }).finally(() => setLoading(false))

        if (print.status === 200) {
            //dark
            window.open(`${fileUrl}/${print.data}`, '_blank')
            clearData()
            props.setIsShow(false)
            dispatch(closeModal())
        }
    }

    const clearData = () => {
        setDoctor(0)
        setDate(moment().format('YYYY-MM-DD'))
        setDisableDoctor(false)
        setXray('UNCHECK')
        setFilm('UNCHECK')
        setLatCaph('UNCHECK')
        setScrapeSwayTartar('UNCHECK')
        setToothFilling('UNCHECK')
        setFillingQuantity('')
        setToothExtraction('UNCHECK')
        setExtractionQuantity('')
        setWisdomTooth('UNCHECK')
        setWisdomQuantity('')
        setOther('UNCHECK')
        setOther_1('UNCHECK')
        setContinueTreatment('UNCHECK')
        setConsult('UNCHECK')
        setDetailOther('')
        setDetailOther_1('')
        setDentalHistory('')
    }

    const handleChangeName = (e: any) => {
        setDentalHistory(e)
        const contentLength = e.length

        if (contentLength > 180) {
            setErrorMaxLength(true)
        } else {
            setTextLength(contentLength)
            setErrorMaxLength(false)
        }

    }

    const sendOutClinic = () => {
        setDisableDoctor(true)
        setDoctor(0)
    }

    const checkOther = (e: any, other: string) => {
        if (e) {
            other === '0' ? setOther('CHECK') : setOther_1('CHECK')
        }
        else {
            if (other === '0') {
                setOther('UNCHECK')
                setDetailOther('')
            } else {
                setOther_1('UNCHECK')
                setDetailOther_1('')
            }

        }
    }

    return (
        <ModalCustom
            title={t('MODAL_REFERRAL_EXAMINATION.TITLE')}
            alignFooter={'end'}
            size={'lg'}
            footerInline
            onClose={props.onClose}
            onSubmit={onPrint}
            onReset={() => {
                dispatch(resetModal())
                props.setIsShow(false)
            }}
            textBtnCancel={t('BUTTON.CANCEL')}
            textBtnConfirm={t('BUTTON.PRINT')}
            modalStyle={'modal-referral-examination'}
            component={
                <>
                    <Grid container spacing={2} className="pt-2">
                        <Grid item className='w-100'>
                            <Box className="d-flex align-items-center">
                                <Typography className='mr-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.SEND_PATIENT')}</Typography>
                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                        <FormControlLabel value="false" control={<InputRadio checked={disableDoctor === false} className="radio" onClick={(e: any) => setDisableDoctor(false)} />} label={t('MODAL_REFERRAL_EXAMINATION.SEND_IN')} />
                                        <FormControlLabel value="true" control={<InputRadio checked={disableDoctor === true} onClick={(e: any) => sendOutClinic()} />} label={t('MODAL_REFERRAL_EXAMINATION.SEND_OUT')} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6} className='w-100'>
                            <InputSecondNewDatePicker
                                required
                                key={'date'}
                                label={t('MODAL_REFERRAL_EXAMINATION.DATE')}
                                value={date}
                                onchange={(e: any) => setDate(moment(e).format('YYYY-MM-DD'))} />
                        </Grid>
                        <Grid item xs={6} className='w-100'>
                            <BasicSelect
                                required
                                name="doctorId"
                                labelId="lb-dentist"
                                selectId="sl-dentist"
                                label={t('HISTORY_APPOINTMENT.DOCTOR')}
                                value={doctor || null}
                                options={[
                                    _.map(doctors, (doctorData: any, i: number) => (
                                        <MenuItem key={i} value={doctorData.userId}>
                                            {doctorData.fullname}
                                        </MenuItem>
                                    ))
                                ]}
                                onchange={(event) => {
                                    setDoctor(event.target.value)
                                    setErrorDoctorSelect('')
                                }}
                                classesOption="style-select-doctors-mb"
                                disabled={disableDoctor}
                                helperText={errorDoctorSelect || ''}
                            />
                        </Grid>
                    </Grid>
                    <div className='pt-2 pl-1'>
                        <div>{t('MODAL_REFERRAL_EXAMINATION.DETAIL_TREATMENT')}</div>
                        <div className='d-flex' style={{ width: '100%' }}>
                            <div style={{ whiteSpace: 'nowrap' }}>
                                <InputCheckbox label={t('X-Ray')} className="hover-none-bg" checked={xray === 'CHECK'}
                                    onChange={(e) => setXray(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(xray)} />
                            </div>
                            <div style={{ width: '100%' }}>
                                <div>
                                    <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.PANORAMIC_FILM')} className="hover-none-bg" checked={film === 'CHECK'}
                                        onChange={(e) => setFilm(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(film)} disabled={!xray} />
                                </div>
                                <div>
                                    <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.LATERAL_CEPHALOGRAM')} className="hover-none-bg" checked={latCaph === 'CHECK'}
                                        onChange={(e) => setLatCaph(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(latCaph)} disabled={!xray} />
                                </div>
                                <div>
                                    <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_OTHER')} className="hover-none-bg" checked={other === 'CHECK'}
                                        onChange={(e) => checkOther(e.target.checked, '0')} value={String(other)} disabled={!xray} />
                                    <InputTextField
                                        placeholder={t('MODAL_REFERRAL_EXAMINATION.DETAIL')}
                                        onchange={(e: any) => {
                                            setDetailOther(e.target.value)
                                        }}
                                        value={detailOther}
                                        disabled={other === "UNCHECK"} />
                                </div>
                            </div>
                        </div>

                        <div >
                            <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.SCALING')} className="hover-none-bg" checked={scrapeSwayTartar === 'CHECK'}
                                onChange={(e) => setScrapeSwayTartar(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(scrapeSwayTartar)} />
                        </div>
                        <div className='d-flex' style={{ width: '100%' }}>
                            <div className='d-flex' style={{ whiteSpace: 'nowrap' }}>
                                <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FILLING')} className="hover-none-bg" checked={toothFilling === 'CHECK'}
                                    onChange={(e) => setToothFilling(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(toothFilling)} />
                                {toothFilling === 'CHECK' && <div className='d-flex' style={{ whiteSpace: 'nowrap', padding: '10px 30px 10px 40px' }}>
                                    <Typography className='mr-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.MODAL.QUANTITY')}</Typography>
                                    <InputTextField
                                        onchange={(e: any) => { setFillingQuantity(e.target.value) }}
                                        value={fillingQuantity}
                                        style={{ width: 60, paddingTop: '5px' }}
                                        inputHeight={20}
                                    />
                                    <Typography className='ml-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.MODAL.TEETH')}</Typography>
                                </div>}
                            </div>
                        </div>
                        <div className='d-flex' style={{ width: '100%' }}>
                            <div className='d-flex' style={{ whiteSpace: 'nowrap' }}>
                                <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.TOOTH_EXTRACTION')} className="hover-none-bg" checked={toothExtraction === 'CHECK'}
                                    onChange={(e) => setToothExtraction(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(toothExtraction)} />
                                {toothExtraction === 'CHECK' && <div className='d-flex' style={{ whiteSpace: 'nowrap', padding: '10px 30px 10px 30px' }}>
                                    <Typography className='mr-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.MODAL.QUANTITY')}</Typography>
                                    <InputTextField
                                        onchange={(e: any) => { setExtractionQuantity(e.target.value) }}
                                        value={extractionQuantity}
                                        style={{ width: 60, paddingTop: '5px' }}
                                        inputHeight={20}
                                    />
                                    <Typography className='ml-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.MODAL.TEETH')}</Typography>
                                </div>}
                            </div>
                        </div>
                        <div className='d-flex' style={{ width: '100%' }}>
                            <div className='d-flex' style={{ whiteSpace: 'nowrap' }}>
                                <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.WISDOM_TOOTH_EXTRACTION')} className="hover-none-bg" checked={wisdomTooth === 'CHECK'}
                                    onChange={(e) => setWisdomTooth(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(wisdomTooth)} />
                                {wisdomTooth === 'CHECK' && <div className='d-flex' style={{ whiteSpace: 'nowrap', padding: '10px 30px 10px 23px' }}>
                                    <Typography className='mr-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.MODAL.QUANTITY')}</Typography>
                                    <InputTextField
                                        onchange={(e: any) => { setWisdomQuantity(e.target.value) }}
                                        value={wisdomQuantity}
                                        style={{ width: 60, paddingTop: '5px' }}
                                        inputHeight={20}
                                    />
                                    <Typography className='ml-3' sx={{ fontWeight: 500 }} style={{ whiteSpace: 'nowrap' }}>{t('MODAL_REFERRAL_EXAMINATION.MODAL.TEETH')}</Typography>
                                </div>}
                            </div>
                        </div>
                        <div style={{ paddingBottom: '10px' }}>
                            <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.MODAL.FORM_OTHER')} className="hover-none-bg" checked={other_1 === 'CHECK'}
                                onChange={(e) => checkOther(e.target.checked, '1')} value={String(other_1)} />
                            <InputTextField
                                placeholder={t('MODAL_REFERRAL_EXAMINATION.DETAIL')}
                                onchange={(e: any) => {
                                    setDetailOther_1(e.target.value)
                                }}
                                value={detailOther_1}
                                disabled={other_1 === "UNCHECK"} />
                        </div>

                        <div>
                            <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.EXAMINATION')} className="hover-none-bg" checked={continueTreatment === 'CHECK'}
                                onChange={(e) => setContinueTreatment(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(continueTreatment)} />
                        </div>
                        <div>
                            <InputCheckbox label={t('MODAL_REFERRAL_EXAMINATION.CONCERN')} className="hover-none-bg" checked={consult === 'CHECK'}
                                onChange={(e) => setConsult(e.target.checked ? 'CHECK' : 'UNCHECK')} value={String(consult)} />
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div style={{ justifyContent: 'space-between' }}>
                            <div>Dental history</div>
                            <div style={{ fontWeight: 400, fontSize: '10px', color: '#808080', textAlign: 'end', marginLeft: '10px' }}>{`${textLength}/${180}`}</div>
                        </div>
                        <InputTextarea
                            placeholder={t('MODAL_REFERRAL_EXAMINATION.DETAIL')}
                            value={dentalHistory}
                            onchange={(e: any) => {
                                if (e.target.value.length <= 180) handleChangeName(e.target.value)
                            }}
                        />
                    </div>

                    {loading && <Loading show={true} type="fullLoading" />}
                </>
            }
        />
    )
}

