import { useEffect, useState } from 'react'
import { Divider, Box, Typography, Tooltip } from '@mui/material'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import CryptoJS from 'crypto-js'

/** SLICK */
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** CONSTANTS */
import { colors, templateImage, PickRoom } from 'constants/theme'
import { logout, getClinicInfo } from 'utils/app.utils'
import { KEY_STORAGE, ServicePointsType, PermissionMenusType, reloadClinicInfoAsMinute } from 'constants/common'
import { editBranchInfo } from 'app/slice/user.slice'
import { PackageItem } from 'constants/package.js'

/** API */
import { routeName } from 'routes/routes-name'
import LoginApi, { PropsShortcutPermission } from 'api/login-api'
import AboutUs from "api/admin/clinic-manage/about.api"

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import Loading from 'component/Loading'

/** STYLE */
import * as UsedStyle from 'features/pick/usedStyle'
import 'react-multi-carousel/lib/styles.css'
import 'styles/MultiCarousel.css'

const initStateErrorMessage = {
  VALIDATE_BRANCH_ID: '',
  VALIDATE_SERVICE_POINT_ID: ''
}

const thai_vowel_marks = [
  "์",
  "่",
  "้",
  "๊",
  "๋",
  "็",
  "์",
  "ิ",
  "ี",
  "ึ",
  "ื",
  "ุ",
  "ู",
  "ั",
]

export default function Pick() {
  const { t, i18n } = useTranslation()
  const lang = i18n?.language || 'th'

  const dispatch = useDispatch()
  const clinicInfo = getClinicInfo()

  const [branchesAndServicePoints, setBranchesAndServicePoint] = useState([])
  const [servicePoints, setServicePoints] = useState([])
  const [branchSelected, setBranchSelected] = useState('')
  const [servicePointSelected, setServicePointSelected] = useState('')

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)
  const [loading, setLoading] = useState(false);

  const settingsSlick = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  const loadData = () => {
    LoginApi.roles()
      .then(({ data }) => {
        if (data !== undefined && data.length) {
          setBranchesAndServicePoint(data)
          setBranchSelected(data[0]?.branchId)
          if (!_.isEmpty(data[0]?.servicePoints)) {
            setServicePoints(data[0]?.servicePoints)
            setServicePointSelected(data[0]?.servicePoints[0]?.servicePointId || 0)
          }
        }
        else {
          logout()
          window.location.reload()
        }
      }).catch((e) => {
        logout()
        window.location.reload()
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  const trans = (condition: "branch" | "servicePoint") => {
    if (condition === "branch") {
      const branch: any = _.find(branchesAndServicePoints, { branchId: branchSelected })
      if (lang === 'en' && branch && branch?.branchNameEn) return branch.branchNameEn
      else return branch.branchName
    } else if (condition === "servicePoint") {
      const servicePoint: any = _.find(servicePoints, { servicePointId: servicePointSelected })
      if (lang === 'en' && servicePoint && servicePoint?.servicePointNameEn) return servicePoint.servicePointNameEn
      else return servicePoint.servicePointName
    }
  }

  const submit = () => {
    if (!branchSelected) return setErrorMessage({ ...errorMessage, ...{ VALIDATE_BRANCH_ID: t('PICK.MESSAGE.VALIDATE_BRANCH_ID') } })
    if (!servicePointSelected) return setErrorMessage({ ...errorMessage, ...{ VALIDATE_SERVICE_POINT_ID: t('PICK.MESSAGE.VALIDATE_SERVICE_POINT_ID') } })

    LoginApi.pick(branchSelected, servicePointSelected)
      .then(async (token) => {
        setLoading(true)
        const servicePoint: ServicePointsType = {
          branch: {
            id: Number(branchSelected),
            name: trans('branch')
          },
          servicePoint: {
            id: Number(servicePointSelected),
            name: trans('servicePoint')
          }
        }

        const permissionMenus: PermissionMenusType = {}
        const params: PropsShortcutPermission = {
          servicePointId: Number(servicePointSelected),
          branchId: Number(branchSelected)
        }

        /** permission shortcuts */
        /** permission menus */
        await LoginApi.shortcutPermission(params).then(({ data }) => permissionMenus.shortcuts = data).catch((e) => { return })
        const resAboutUs = await AboutUs.findAboutUs()
        let checkApp = false
        if (
          resAboutUs?.status === 200 &&
          resAboutUs?.data?.enableApp === '1' &&
          resAboutUs?.data?.branchDefaultRegisterApps?.length &&
          resAboutUs?.data?.branchDefaultRegisterApps?.some((item: any) => item?.branchId === servicePoint.branch?.id && item?.isDefaultRegisApp === '1')) {
          const encryptApp: any = CryptoJS.AES.encrypt('PERMISS', "cliniter dental") || ''
          localStorage.setItem(KEY_STORAGE.APP, encryptApp)
          checkApp = true
        } else {
          const encryptApp: any = CryptoJS.AES.encrypt('UNPERMISS', "cliniter dental") || ''
          localStorage.setItem(KEY_STORAGE.APP, encryptApp)
        }

        await LoginApi.rolesPermission(params).then(({ data }) => {
          let newMenu: any = []
          if (resAboutUs?.status === 200 && (resAboutUs?.data?.useWeb === '0' || !checkApp)) {
            data.forEach((item: any) => {
              if (item?.menuFirstKey === 'EXTRA') {
                newMenu.push({
                  ...item, menuSeconds: item?.menuSeconds.filter((info: any) => {
                    const isApp = !checkApp ? info?.menuSecondKey !== 'APPLICATION' : true
                    const isWebManage = resAboutUs?.data?.useWeb === '0' ? info?.menuSecondKey !== 'WEB_MANAGE' : true
                    return isWebManage && isApp
                  })
                })
              } else {
                newMenu.push(item)
              }
            })
          } else {
            newMenu = data
          }
          permissionMenus.menus = newMenu
        }).catch((e) => { return })

        /** detail clinic */
        const lastUpdate = _.get(clinicInfo, 'lastUpdate') ? moment(clinicInfo.lastUpdate, 'YYYY-MM-DD HH:mm') : ''
        const duration = lastUpdate ? moment.duration(moment().diff(lastUpdate)).asMinutes() : 0
        if (!clinicInfo || !Number(clinicInfo?.branchId) || Number(clinicInfo?.branchId !== Number(branchSelected)) || duration >= reloadClinicInfoAsMinute) {
          let newDetail = null
          await AboutUs.detailClinic(Number(branchSelected))
            .then(({ data }) => {
              newDetail = data

              dispatch(editBranchInfo({ value: { newDetail } }))
              localStorage.setItem(KEY_STORAGE.CLINIC_INFO, JSON.stringify({ ...newDetail, lastUpdate: moment().format('YYYY-MM-DD HH:mm') }))

              /** package */
              const item: PackageItem = {
                time: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                package: newDetail.package.toUpperCase(),
                packageExpired: newDetail.dateExpired,
                countBranch: newDetail.countBranch,
                enabledApp: Number(newDetail?.enableApp) || 0
              }

              const encrypt: any = CryptoJS.AES.encrypt(JSON.stringify(item), "cliniter dental") || ''
              localStorage.setItem(KEY_STORAGE.PACKAGE, encrypt)
            })
        }

        setLoading(false)

        localStorage.setItem(KEY_STORAGE.PERMISSION, JSON.stringify(permissionMenus))
        localStorage.setItem(KEY_STORAGE.SERVICE_POINT, JSON.stringify(servicePoint))
        window.location.href = routeName.welcome
      })
  }

  const selectBranch = (branchId: any) => {
    const service = _.find(branchesAndServicePoints, { branchId: branchId })
    const servicePoint = _.get(service, 'servicePoints')
    setBranchSelected(branchId)
    setServicePoints(servicePoint)
    setServicePointSelected('')
  }

  const selectServicePoint = (servicePintId: any) => {
    setServicePointSelected(servicePintId)
  }

  const renderTextName = (textName: string, color: string) => {
    const vowelMarksRegex = new RegExp("[" + thai_vowel_marks.join("") + "]", "g");

    const newTextNoneVowel = (text: string) => {
      const newText = text.replace(vowelMarksRegex, '');
      const fontSize = newText?.length > 15 ? 12 : 14
      if (newText.length > 15) {
        let i = 0
        let idx = 0
        text.split('').forEach((item, index: number) => {
          if (!thai_vowel_marks.includes(item)) {
            i += 1
          }
          if (i === 15) {
            idx = index
          }
        })

        const firstLine = text.substring(0, idx);
        const secondLine = text.substring(idx);

        return { textJSX: `${firstLine}\n${secondLine}`, font: fontSize, hover: newText?.length > 30 }
      } else {
        return { textJSX: `${text}`, font: fontSize, hover: newText?.length > 30 }
      }

    }

    const renderTextNameJSX = (textJSX: string, font: number, hover?: boolean) => {
      return (
        <Tooltip placement={'right'} disableHoverListener={!hover} title={<p style={{ color: 'white', fontSize: '14px', margin: '0' }}>{textName}</p>} >
          <UsedStyle.TextBranchName
            className='w-100 text-center'
            font={font}
            color={color}
          >
            {textJSX}
          </UsedStyle.TextBranchName>
        </Tooltip >
      )
    }

    if (textName?.length) {
      const textNoneVowel = newTextNoneVowel(`${textName}`)
      return renderTextNameJSX(textNoneVowel.textJSX, textNoneVowel.font, textNoneVowel.hover)
    } else {
      return renderTextNameJSX(textName, 14)
    }

  }



  return (
    <UsedStyle.CardContainer className='position-relative h-100'>
      <UsedStyle.Logo src={templateImage.logo} alt="Logo" />
      <Box className='d-flex align-items-center h-100'>
        <Box className='p-3 overflow-auto custom-scroll w-100' sx={{ maxHeight: '100%' }}>
          <UsedStyle.CardForm className='m-auto w-100'>
            <Typography className='text-center' sx={{ fontSize: '20px', fontWeight: 500 }}>{t('PICK.LABEL.BRANCH')}</Typography>
            <UsedStyle.CustomSlider className='pt-4'>
              {!_.isEmpty(branchesAndServicePoints) && (
                <Slider {...settingsSlick} className="slick-pick-branch">
                  {_.map(branchesAndServicePoints, (branch: any, index) => (

                    <UsedStyle.CustomSliderItem
                      key={index}
                      active={branchSelected === branch.branchId}
                      onClick={() => selectBranch(branch.branchId)} data-value={branch.branchId}>
                      <Box className={`p-3 cursor-pointer w-100 h-100 d-flex align-items-center justify-content-center flex-column`}>
                        <Box sx={{ 'svg': { width: '65px' } }}>
                          <PickRoom.Branch color={branchSelected === branch.branchId ? colors.themeSecondColor : colors.disabledGray} />
                        </Box>
                        {renderTextName
                          (
                            lang === 'en' && branch?.branchNameEn ? branch.branchNameEn : branch?.branchName || '',
                            branchSelected === branch.branchId ? colors.themeSecondColor : colors.disabledGray
                          )
                        }
                      </Box>
                    </UsedStyle.CustomSliderItem>

                  ))}
                </Slider>
              )}
            </UsedStyle.CustomSlider>
            <Divider sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important`, margin: '36px 0 16px' }} />
            <Typography className='text-center' sx={{ fontSize: '20px', fontWeight: 500 }}>{t('PICK.LABEL.SERVICE_POINT')}</Typography>
            <UsedStyle.CustomSlider className='pt-4'>
              {!_.isEmpty(servicePoints) && (
                <Slider {...settingsSlick} className="slick-pick-branch">
                  {_.map(servicePoints, (servicePoint: any, index) => (

                    <UsedStyle.CustomSliderItem
                      key={index}
                      active={servicePointSelected === servicePoint.servicePointId ? true : false}
                      onClick={() => selectServicePoint(servicePoint.servicePointId)}
                      data-value={servicePoint.servicePointId}>
                      <Box className={`p-3 cursor-pointer w-100 h-100 d-flex align-items-center justify-content-center flex-column service-${servicePoint.pointOfService}`}>
                        {servicePoint.pointOfService === 'COUNTER' ? (
                          <PickRoom.Counter color={servicePointSelected === servicePoint.servicePointId ? colors.themeSecondColor : colors.disabledGray} />
                        ) : servicePoint.pointOfService === 'ROOMSERVICES' ? (
                          <PickRoom.Service color={servicePointSelected === servicePoint.servicePointId ? colors.themeSecondColor : colors.disabledGray} />
                        ) : servicePoint.pointOfService === 'PAYMENT' ? (
                          <PickRoom.Payment color={servicePointSelected === servicePoint.servicePointId ? colors.themeSecondColor : colors.disabledGray} />
                        ) : (
                          <PickRoom.OtherService color={servicePointSelected === servicePoint.servicePointId ? colors.themeSecondColor : colors.disabledGray} />
                        )}
                        {renderTextName
                          (
                            lang === 'en' && servicePoint.servicePointNameEn ? servicePoint.servicePointNameEn : servicePoint.servicePointName || '',
                            servicePointSelected === servicePoint.servicePointId ? colors.themeSecondColor : colors.disabledGray
                          )
                        }
                        {/* <Tooltip placement={'right'} disableHoverListener={servicePoint.servicePointName.length > 12 ? false : true} arrow title={<p style={{ color: 'white', fontSize: '14px' }}>{lang === 'en' && servicePoint.servicePointNameEn ? servicePoint.servicePointNameEn : servicePoint.servicePointName || ''}</p>} >
                          <Typography
                            className='w-100 text-center'
                            sx={{
                              fontWeight: 600,
                              color: servicePointSelected === servicePoint.servicePointId ? colors.themeSecondColor : colors.disabledGray
                            }}>
                            {lang === 'en' && servicePoint.servicePointNameEn ? servicePoint.servicePointNameEn : servicePoint.servicePointName || ''}
                          </Typography>
                        </Tooltip> */}
                      </Box>
                    </UsedStyle.CustomSliderItem>

                  ))}
                </Slider>
              )}
            </UsedStyle.CustomSlider>
            <ButtonCustom
              onClick={submit}
              textButton={!branchSelected || !servicePointSelected ? t('PICK.BUTTON.EMPTY') : t('PICK.BUTTON.SUBMIT')}
              className="w-100"
              disabled={!branchSelected || !servicePointSelected}
              btnStyle={{ height: '60px', marginTop: '44px', fontSize: '18px !important' }} />
          </UsedStyle.CardForm>
        </Box>
        <UsedStyle.ImageBackgroundLeft src={templateImage.pick.left} alt="bgPickLeft" />
        <UsedStyle.ImageBackgroundRight src={templateImage.pick.right} alt="bgPickRight" />
      </Box>
      <Loading show={loading} type='fullLoading' />
    </UsedStyle.CardContainer >
  )
}