import { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Tooltip, Fade } from '@mui/material';

/** CONSTANTS */
import { colors } from 'constants/theme'
import { scale } from 'constants/scale'

/** STYLE */
import { styled } from '@mui/material/styles'

const Layout = styled('div')<{ isShowTime?: boolean }>(({ theme, isShowTime }) => ({
  paddingLeft: isShowTime === false ? 0 : '3.05rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.disabledGray
  },
  '&:hover': {
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.disabledGray
    }
  }
}))

const Container = styled('div')<{ width?: number | string }>(({ theme, width }) => ({
  width: width ? width : 'auto',
  position: 'relative',
  padding: '1.5rem 0 1rem',
  borderLeft: `1px solid ${colors.borderInput}`,
  marginRight: '.35rem',
  [theme.breakpoints.up('lg')]: {
    borderRight: `1px solid ${colors.borderInput}`
  }
}))

const LineMoment = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  backgroundColor: colors.themeThirdColor,
  display: 'block',
  height: 2,
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    background: colors.themeThirdColor
  },
  '&::before': {
    left: -5,
    width: 9,
    height: 9,
    borderRadius: 50,
    top: '50%',
    transform: 'translateY(-50%)'
  },
  '&::after': {
    right: -20,
    width: 20,
    height: 2
  },
  '&.hide-dot': {
    '&::before': {
      opacity: 0
    }
  }
}))

const Graph = styled('div')<{ showMinute?: boolean }>(({ theme, showMinute }) => ({
  height: showMinute ? scale.rangeOfMinute * 15 : scale.rangeOfMinute * 60,
  borderTop: `1px solid ${colors.borderInput}`,
  marginLeft: '-.5rem',
  position: 'relative',
  zIndex: "-1",
  '&:nth-child(odd)': {
    backgroundColor: showMinute ? "#F7F7F7" : "",
  }, '&:nth-child(even)': {
    backgroundColor: showMinute ? "#ffffff" : "",
  },
  '&:before': {
    content: 'attr(data-title)',
    fontSize: 14,
    color: colors.textExtraLightGray,
    position: 'absolute',
    top: '-0.75rem',
    left: '-2.25rem',
    zIndex: 2
  },
  '&.graph-schedule:last-of-type': {
    height: 10
  },
  [theme.breakpoints.down('lg')]: {
    marginLeft: '-0.125rem'
  },
}))

type ScheduleType = {
  branchId?: number
  width?: any
  isShowTime?: boolean
  card?: any
  popup?: any
  break?: any
  timeRange?: string[]
  hideDot?: boolean
  enableTimeTooltip?: boolean
  key?: number
  showMinute?: boolean
}
const defaultTimes = ['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00']

export default function Schedule(props: ScheduleType) {
  const handleTime = () => {
    if (props.showMinute) {
      const defaultRange_Time = ['00', '15', '30', '45']
      const dataTime: any = []
      props.timeRange?.forEach((item: any) => {
        defaultRange_Time.forEach((element: any) => {
          dataTime.push(`${String(item).slice(0, -3)}:${element}`)
        })
      })
      return dataTime.slice(0, -3)
    } else {
      return props.timeRange
    }

  }

  const [nowPoint, setNowPoint] = useState(0)
  const [time, setTime] = useState<string[]>(handleTime() || defaultTimes)
  const unit = scale.rangeOfMinute

  const CustomContainer = styled(Container)<{ width?: number | string }>(({ theme, width }) => ({
    width: width ? width : 'auto'
  }))

  const handleNowPoint = () => {

    const diff = moment.duration(moment().diff(moment(time[0], 'HH:00'))).asMinutes()
    const now = moment();
    const seconds = now.seconds();
    if (0 <= diff && diff <= ((time.length + 1) * 60)) {
      setNowPoint(diff * unit)
    } else {
      setNowPoint(0)
    }
    setTimeout(() => {
      handleNowPoint()
    }, (60 - seconds) * 1000)
  }

  useEffect(() => {
    if (props.timeRange) setTime(handleTime())
    handleNowPoint()
  }, [props.timeRange])

  useEffect(() => {
    handleNowPoint()
  }, [time])

  useEffect(() => {
    handleNowPoint()
  }, [])


  return (
    <Layout isShowTime={props.isShowTime} id='layout-schedule' className={'layout-schedule h-100 custom-scroll'}>
      {(props.popup && (
        <CustomContainer width={props.width} className={'container-schedule'} key={props.key ?? 1}>
          <LineMoment className={`line-moment ${props.hideDot ? 'hide-dot' : ''}`} style={{ transform: `translate(0, ${nowPoint}px)`, ...(props.width === 284 ? { zIndex: 10 } : {}) }}></LineMoment>
          <section >
            {_.map(time, (hour: string, index: number) => {
              return (
                <Tooltip
                  title={hour === '24:00' ? '00:00' : hour}
                  TransitionComponent={Fade}
                  placement="top"
                  disableHoverListener={!props.enableTimeTooltip}
                  classes={{ popper: 'tooltip-graph-appointment' }}>
                  <Graph
                    key={index}
                    data-title={hour === '24:00' ? '00:00' : hour}
                    className={'graph-schedule'}
                    showMinute={props.showMinute}
                  />
                </Tooltip>
              )

            })}

          </section>
          {props.card}
          {props.popup}
          {props?.break || <></>}
        </CustomContainer>
      )) || (
          <Container width={props.width} className={'container-schedule'} >
            <LineMoment className={`line-moment ${props.hideDot ? 'hide-dot' : ''}`} style={{ transform: `translate(0, ${nowPoint}px)`, ...(props.width === 284 ? { zIndex: 10 } : {}) }}></LineMoment>
            <section>
              {_.map(time, (hour: string, index: number) => {
                return (
                  <Tooltip
                    title={hour === '24:00' ? '00:00' : hour}
                    TransitionComponent={Fade}
                    placement="top"
                    disableHoverListener={!props.enableTimeTooltip}
                    classes={{ popper: 'tooltip-graph-appointment' }}>
                    <Graph
                      key={index + `${props.key}`}
                      data-title={hour === '24:00' ? '00:00' : hour}
                      className={'graph-schedule'}
                      showMinute={props.showMinute}
                    />
                  </Tooltip>
                )
              })}
              {props?.break || <></>}

            </section>
            {props.card}

          </Container>
        )}
    </Layout>
  )
}
