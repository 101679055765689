import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Divider, TableSortLabel, Typography } from '@mui/material'
import { ReportsProps, ExportProps } from 'features/report/report-constants'
import { isBuddhistYear, numberFormat, numberToCurrencyFormat, toBuddhistYear } from 'utils/app.utils'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

import TableRowExport from 'component/Pdf/TableRowExport'
import TableRowCommon from 'component/Table/TableRowCommon'
import TableCustom from 'component/Table/TableCustom'
import EmptyData from 'features/report/component/emptyData'

/** STYLE */
import * as UseStyled from 'features/report/useStyled'
import { colors } from 'constants/theme'

import { UseSnCode } from 'utils/useSnCode'
import { SORT_TYPE } from 'constants/common'
import { dateTimeToView, dateToView, timeToView } from 'utils/date.utils'

export interface AdvanceComponent {
  page: number
  pageLimit: number
  rowCount: number
  data: any
  summary: any
  dataExport: any
  handleChangeRowsPerPage: (val: any) => void
  handleChangePage: (val: any) => void
  onRequestSort: (sortBy: string, sortType: string) => void
  setSortType: (val: SORT_TYPE) => void
  setSortBy: (val: string) => void
  sortType: string
  sortBy: string
  componentRef: any
  branchName: string
  rangeDate: string[]
  channel: string
  billStatus: string
}

interface AdvanceIncreaseIF {
  branchName: string
  createdAt: string
  advanceNumber: string
  statusOrder: string
  paymentChannel: string
  amountTotal: number
  createdBy: string
  remark: string
  cancelRemark: string
  cancelBy: string
  branchCnNumber: string
  cnNumber: string
  snCode: string
  patientFullname: string
}
interface AdvanceIncreaseSummaryIF {
  paymentChannelId: number
  amountTotal: number
  amountCancel: number
}
export default function AdvanceIncreaseList(props: AdvanceComponent) {
  const { t } = useTranslation()

  const [snType, setSnType] = useState(false)

  const handleSnCode = useCallback(() => {
    const fetchData = async () => {
      const sn = await UseSnCode()
      setSnType(sn)
    }

    fetchData()
  }, [snType])

  useEffect(() => {
    handleSnCode()
  }, [])

  const onRequestSort = (sortByVal: string, sortTypeVal: SORT_TYPE) => {
    props.setSortType(sortTypeVal)
    props.setSortBy(sortByVal)
    props.handleChangePage(1)
  }

  const headCells = [
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.DF.#NO', align: 'center', class: 'text-nowrap', width: '30px', style: { width: '30px' } },
    { id: '3', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.BRANCH', align: 'center', class: 'text-nowrap', width: '150px', style: { width: '80px' } },
    { id: '4', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.DATE_INCREASE', align: 'center', class: 'text-nowrap', width: '150px', style: { width: '60px' } },
    { id: '5', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.BILL', align: 'center', class: 'text-nowrap', width: '150px', style: { width: '50px' } },
    { id: '6', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.STATUS', align: 'center', class: 'text-nowrap', width: '250px', style: { width: '40px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.PATIENT_CN_NUMBER', align: 'center', class: 'text-nowrap', width: '350px' },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.BRANCH_CN_NUMBER', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '60px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.PATIENT_SN_CODE', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '50px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.DECREASE.PATIENT_NAME', align: 'left', class: 'text-nowrap', width: '350px', style: { width: '50px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.CHANNEL_INCREASE', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '60px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.TOTAL_INCREASE', align: 'right', class: 'text-nowrap', width: '350px', style: { width: '40px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.UPDATED_BY', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '60px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.DETAIL', align: 'left', class: 'text-nowrap', width: '350px', style: { width: '40px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.REMARK_CANCEL', align: 'left', class: 'text-nowrap', width: '350px', style: { width: '60px' } },
    { id: '7', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.CANCELER', align: 'center', class: 'text-nowrap', width: '350px', style: { width: '50px' } }
  ]
  const headTotalCells = [
    { id: '1', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.CAP_PAY', align: 'left', class: 'text-nowrap', width: '30px' },
    { id: '3', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.SUM_INCREASE', align: 'center', class: 'text-nowrap', width: '150px' },
    { id: '4', disablePadding: false, labelKey: 'REPORT.TABLE.INCREASE.SUM_CANCEL', align: 'center', class: 'text-nowrap', width: '150px' }
  ]

  if (!snType) {
    headCells.splice(3, 1)
  }
  const renderData = (data: any, no: number, exportData: boolean, render: 'data' | 'sum') => {
    const fontExportData = exportData ? 8 : 16
    if (render === 'data') {
      const advance: AdvanceIncreaseIF = data
      const num = exportData === true ? no + 1 : props.page * props.pageLimit - props.pageLimit + no + 1
      const objRenderData = {
        key: num.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: num, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.branchName, class: 'text-nowrap' },
          {
            option: 'TEXT',
            align: 'center',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData },
            label: (
              <>
                {dateToView(advance.createdAt)} <br /> {timeToView(advance.createdAt)} น.
              </>
            ),
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.advanceNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: t(`REPORT.BILL_STATUS.${advance.statusOrder}`), class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.cnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.branchCnNumber, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.snCode || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.patientFullname, class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.paymentChannel, class: 'text-nowrap' },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(advance.amountTotal, 2), class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.createdBy, class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.remark || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'left', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.cancelRemark || '-', class: 'text-nowrap' },
          { option: 'TEXT', align: 'center', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: advance.cancelBy || '-', class: 'text-nowrap' }
        ]
      }
      if (!snType) {
        objRenderData.columns.splice(7, 1)
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    } else {
      const objRenderData = {
        key: no.toString(),
        id: no.toString(),
        obj: data,
        columns: [
          { option: 'MERGE', align: 'center', colSpan: !snType ? 8 : 9 },
          {
            option: 'TEXT',
            align: 'right',
            style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData },
            label: <Typography sx={{ fontWeight: 500, fontSize: fontExportData }}>{t('REPORT.TITLE.SUM')}</Typography>,
            class: 'text-nowrap'
          },
          { option: 'TEXT', align: 'right', style: { border: exportData ? `0px solid ${colors.black}` : '', fontSize: fontExportData }, label: numberFormat(_.sumBy(data, 'amountTotal') || 0), class: 'text-nowrap' },
          { option: 'MERGE', align: 'center', colSpan: 4 },

        ],
        classes: 'row-summary'
      }
      return <>{(exportData === true && <TableRowExport {...objRenderData} />) || <TableRowCommon {...objRenderData} />}</>
    }
  }
  const renderSummary = () => {
    const summary = props.summary
    const sumChannel1 = _.find(props.summary, { paymentChannelId: 1 })
    const sumChannel2 = _.find(props.summary, { paymentChannelId: 2 })
    const sumChannel3 = _.find(props.summary, { paymentChannelId: 3 })
    const sumChannel4 = _.find(props.summary, { paymentChannelId: 4 })
    const sumChanel = {
      text: "TOTAL",
      amountTotal: (sumChannel1?.amountTotal || 0) + (sumChannel2?.amountTotal || 0) + (sumChannel3?.amountTotal || 0) + (sumChannel4?.amountTotal || 0),
      amountCancel: (sumChannel1?.amountCancel || 0) + (sumChannel2?.amountCancel || 0) + (sumChannel3?.amountCancel || 0) + (sumChannel4?.amountCancel || 0)
    }

    const objRenderData1 = {
      key: 'chanel-1',
      id: 'chanel-1',
      obj: sumChannel1,
      columns: [
        { option: 'TEXT', align: 'left', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.1`), class: 'text-nowrap cap-pay' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel1?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel1?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }
    const objRenderData2 = {
      key: 'chanel-2',
      id: 'chanel-2',
      obj: sumChannel2,
      columns: [
        { option: 'TEXT', align: 'left', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.2`), class: 'text-nowrap cap-pay' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel2?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel2?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }
    const objRenderData3 = {
      key: 'chanel-3',
      id: 'chanel-3',
      obj: sumChannel3,
      columns: [
        { option: 'TEXT', align: 'left', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.3`), class: 'text-nowrap cap-pay' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel3?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel3?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }
    const objRenderData4 = {
      key: 'chanel-4',
      id: 'chanel-4',
      obj: sumChannel4,
      columns: [
        { option: 'TEXT', align: 'left', style: { fontSize: 16 }, label: t(`REPORT.PAYMENT_CHANNEL.4`), class: 'text-nowrap cap-pay' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel4?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChannel4?.amountCancel || 0), class: 'text-nowrap' }
      ]
    }

    const sumObjRenderData = {
      key: 'chanel-sum',
      id: 'chanel-sum',
      obj: sumChanel,
      columns: [
        { option: 'TEXT', align: 'left', style: { fontSize: 16 }, label: t(`REPORT.TABLE.INCREASE.SUM_TOTAL`), class: 'text-nowrap cap-pay' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChanel?.amountTotal || 0), class: 'text-nowrap' },
        { option: 'TEXT', align: 'right', style: { fontSize: 16 }, label: numberToCurrencyFormat(sumChanel?.amountCancel || 0), class: 'text-nowrap' }
      ],
      classes: 'row-summary'
    }

    return [
      <>{<TableRowCommon {...objRenderData1} />}</>,
      <>{<TableRowCommon {...objRenderData2} />}</>,
      <>{<TableRowCommon {...objRenderData3} />}</>,
      <>{<TableRowCommon {...objRenderData4} />}</>,
      <>{<TableRowCommon {...sumObjRenderData} />}</>,
    ]

    return <></>

  }

  return (
    <>
      <Box>
        <UseStyled.ResponsiveReportTable>
          <TableCustom
            rowHeadCell={2}
            // tableMinWidth={992}
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headCells}
            rowsData={[
              _.map(props.data, (d: any, index: number) => {
                return renderData(d, index, false, 'data')
              }),
              renderData(props.dataExport, 0, false, 'sum')
            ]}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <Box className="mt-3" sx={{ maxWidth: '480px' }}>
        <UseStyled.ResponsiveReportTable className="mt-2">
          <TableCustom
            hidePagination
            page={props.page}
            pageLimit={props.pageLimit}
            sortType={props.sortType}
            sortBy={props.sortBy}
            rowCount={props.rowCount}
            onSort={props.onRequestSort}
            setPageLimit={props.handleChangeRowsPerPage}
            setPage={props.handleChangePage}
            headCells={headTotalCells}
            rowsData={[renderSummary()]}
            tableMinWidth={480}
            customScroll
          />
        </UseStyled.ResponsiveReportTable>
      </Box>
      <div className="print-show" ref={props.componentRef}>
        <ExportData
          headCells={t(`REPORT.REPORT_NAME.ADVANCE_INCREASE`)}
          branchName={props.branchName}
          rangeDate={props.rangeDate}
          channel={props.channel}
          billStatus={props.billStatus}
          componant={
            <Box sx={{
              'tbody tr:last-child td': {
                paddingBottom: '6px'
              }
            }}>
              <UseStyled.Styles className="pt-3">
                <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', borderBottom: '1px solid' }}>
                  <thead>
                    <tr>
                      {_.map(headCells, (val: any, index: number) => {
                        return <td style={{ textAlign: val.align, fontSize: 8, fontWeight: 500, padding: '4px 0', ...val?.style }}> {t(val.labelKey)}</td>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      _.map(props.dataExport, (d: any, index: number) => {
                        return renderData(d, index, true, 'data')
                      }),
                      renderData(props.data, 0, true, 'sum')
                    ]}
                  </tbody>
                </table>

                <Box
                  sx={{
                    width: '240px',
                    marginTop: '16px',
                    '.cap-pay': {
                      textAlign: 'left'
                    },
                    'tbody tr td': {
                      fontSize: '8px !important',
                      border: '0px solid'
                    }
                  }}
                >
                  <table style={{ tableLayout: 'fixed', borderCollapse: 'collapse', minWidth: '100%', maxWidth: '100%', borderBottom: '1px solid' }}>
                    <thead>
                      <tr>
                        {_.map(headTotalCells, (val: any, index: number) => {
                          return <td style={{ textAlign: val.align, fontSize: 8, fontWeight: 500, padding: '4px 0', ...val?.style }}> {t(val.labelKey)}</td>
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {renderSummary()}
                    </tbody>
                  </table>
                </Box>
              </UseStyled.Styles>
            </Box>
          }
        />
      </div>
    </>
  )
}

type IncreaseExportDataProps = {
  headCells: string
  branchName: string
  componant: any
  rangeDate: string[]
  channel: string
  billStatus: string
}

export function ExportData(props: IncreaseExportDataProps) {
  const { rangeDate, channel, billStatus } = props
  const { t } = useTranslation()
  return (
    <>
      <div style={{ fontSize: '14px', fontWeight: '500' }}>{props.headCells}</div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <div style={{ fontSize: '10px', fontWeight: '500' }}>{t('REPORT.TABLE.DECREASE.BRANCH')}: {props.branchName}</div>
        <div style={{ fontSize: '10px', fontWeight: '500' }}>{t('REPORT.TABLE.DECREASE.DATE_START')}: {toBuddhistYear(moment(rangeDate[0]))}</div>
        {rangeDate[1] !== 'Invalid date' && <div style={{ fontSize: '10px', fontWeight: '500' }}>{t('REPORT.TABLE.DECREASE.DATE_END')}: {toBuddhistYear(moment(rangeDate[1]))}</div> || <></>}
        <div style={{ fontSize: '10px', fontWeight: '500' }}>{t('REPORT.FILTER.RECEIPT_STATUS')}: {`${billStatus === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.FILTER.BILL.${billStatus}`)}`}</div>
        <div style={{ fontSize: '10px', fontWeight: '500' }}>{t('REPORT.TABLE.INCREASE.CHANNEL_INCREASE')}: {`${channel === 'ALL' ? t(`REPORT.FILTER.ALL`) : t(`REPORT.PAYMENT_CHANNEL.${channel}`)}`}</div>
      </div>
      {props.componant}
    </>
  )
}
