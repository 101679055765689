import Calendar from 'component/Calendar/Calendar'
import { useCallback, useEffect, useState } from 'react'
import { Card, Dropdown, Image } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import _ from 'lodash'
import { Avatar, Box, Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import { fileUrl } from 'api/api'
import DoctorScheduleApi, { DoctorScheduleMonthItemInterface, DoctorScheduleMonthListInterface, DoctorScheduleTags } from 'api/counter/doctor-schedule.api'
import ClinicApi from 'api/master/clinic.api'
import PersonnelApi from 'api/setting/personnel-managements/personnel.api'

/** UTILS || SLICE */
import { showModal } from 'app/slice/modal.slice'
import { DateInterface, HeadSchedule } from './head-schedule'
import { getBranch, numberToCurrencyFormat } from 'utils/app.utils'
import UseWindowSize from 'utils/useWindowSize'
import DoctorScheduleShare from './doctor-schedule-share'
import { CustomCalenderDoctorSchedule, MenuDoctorListOverall } from '../appointment/CalendarStyle'

/** CONSTANTS */
import { colors } from 'constants/theme'
import { icons, imgs } from 'constants/images'
import iconEllipsis from 'assets/icon/iconEllipsis.svg'

/** COMPONENT */
import InputNewRangeTimePicker from 'component/Input/InputNewRangeTimePicker'
import InputCheckbox from 'component/Input/InputCheckbox'
import CalendarDay, { CalendarItem } from 'component/Calendar/CalendarMonth/CalendarMonth'
import { ButtonDeleteSimple } from 'component/Button/ButtonDelete'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { swalWarning } from 'component/Alert/Swal'
import BasicSelect from 'component/Select/BasicSelect'
import AppointmentApi from 'api/counter/appointment.api'
import { selectMe, selectUser } from 'app/slice/user.slice'
import { BoxTag } from '../appointment/style'

/** STYLE */
const DivRenderDay = styled('div')(({ theme }) => ({
  '& li:hover': { backgroundColor: colors.white },
  '& li:nth-child(n+3)': { display: 'none' },
  '& #basic-button': {
    '& .show-slim': { display: 'none' },
    ':hover': { '&:hover': { backgroundColor: colors.white } }
  },

  [theme.breakpoints.down(1200)]: {
    display: 'flex',
    padding: '0.25rem',
    gap: '4px 4px',
    flexWrap: 'wrap',
    alignContent: 'start',
    '&:after': { flex: 'auto' },
    '& li': {
      display: 'inline-flex',
      margin: '0.125rem',
      padding: '0px',
      minHeight: '0px',
      '&:nth-child(n)': { display: 'inline-flex' },
      '&:nth-child(n+9)': { display: 'none' }
    },
    '& .MuiAvatar-root': {
      height: '30px',
      width: '30px',
      fontSize: '0.875rem'
    },
    '& #basic-button': {
      display: 'inline-flex',
      height: '30px',
      width: '30px',
      maxWidth: '30px',
      minWidth: '30px',
      border: '.5px solid',
      borderRadius: '50%',
      backgroundColor: colors.themeSecondColor06,
      margin: '0.125rem',
      '& .show-slim': { display: 'block' },
      '& .show-full': { display: 'none' },
      '& .MuiButton-endIcon': { display: 'none' }
    }
  },
  [theme.breakpoints.down(769)]: {
    gap: '2px 2px',
    '& .MuiAvatar-root': {
      height: '35px',
      width: '35px',
      fontSize: '1rem'
    },
    '& #basic-button': {
      display: 'inline-flex',
      height: '35px',
      width: '35px',
      maxWidth: '35px',
      minWidth: '35px',
      fontSize: '1rem'
    },
    '& li': {
      '&:nth-child(n)': { display: 'inline-flex' },
      '&:nth-child(n+6)': { display: 'none' }
    }
  }
}))

const MenuDropdownItem = styled(MenuItem)(({ theme }) => ({
  padding: '0rem .25rem',
  marginTop: '4px',
  cursor: 'default',
  '&:hover': {
    background: colors.themeSecondColor06,
    color: colors.themeSecondColor
  },
  '& .title': {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.4
  },
  '& .subtitle': {
    lineHeight: 1.4,
    [theme.breakpoints.up('lg')]: {
      fontSize: 16
    }
  },
  '& .name-doctor': {
    fontSize: '1rem'
  },
  '& .time-doctor': {
    marginTop: '-2px!important',
    fontSize: '.875rem'
  },
  [theme.breakpoints.down('lg')]: {
    display: 'inline-flex',
    '& .MuiMenuItem-root': {
      padding: '0px'
    },
    '& .name-doctor': { display: 'none' }
  }
}))

const DivDayNumber = styled('div')(({ theme }) => ({
  paddingTop: '1px',
  marginLeft: '.5rem',
  '&.now': { color: colors.white, backgroundColor: colors.themeMainColor }
}))

const CustomTimePicker = styled('div')(({ theme }) => ({
  '.range-time-picker': {
    '.MuiFormControl-root': {
      '.MuiOutlinedInput-input': {
        fontSize: '14px',
        paddingLeft: '6px'
      }
    },
    '.ant-picker': {
      '.MuiSvgIcon-root': {
        width: '18px !important',
        height: '18px !important',
        marginRight: '-6px'
      }
    },
    '&.last-range-picker': {
      '.MuiFormControl-root': {
        '.MuiOutlinedInput-input': {
          paddingRight: '24px !important'
        }
      }
    }
  }
}))

const InputText = styled('input')(({ theme }) => ({
  height: 32,
  ':focus': { borderColor: colors.themeSecondColor }
}))

interface CalendarDayObjectInterface {
  dateString: string
  dayOfMonth: number
  isCurrentMonth: boolean
  isPreviousMonth?: boolean
  isNextMonth?: boolean
}

interface TimeInterface {
  timeStart: string
  timeEnd: string
  breakTimeStart: string
  breakTimeEnd: string
}

interface Item {
  permission: boolean
  text: string
  time: TimeInterface
}

interface DayOfWeekObjectInterface {
  [key: number]: Item
}

interface DoctorScheduleAssistants {
  staffId: number
}
interface DoctorScheduleItem {
  date: string
  doctorId: number
  timeStart: string
  timeEnd: string
  breakTimeStart?: string
  breakTimeEnd?: string
  handInsurance: number
  countAppoints?: number
  servicePointId?: number
  isUpdate?: number
  statusDoctorSchedule: 'NORMAL' | 'LEAVE_WORK' | 'MISSING'
  doctorScheduleAssistants?: DoctorScheduleAssistants[]
}
interface AssistantListInterface {
  staffId: number
  staffFullname: string
}
interface DateRangeInMonthInterface {
  date: string
  dayOfWeek: string
  dayOfWeekNumber: number
}

export const getColorStatus = (status: string, type: 'COLOR' | 'BACKGROUND') => {
  if (type === 'BACKGROUND') {
    let bgColorSchedule: any = colors.white
    if (status === 'LEAVE_WORK') bgColorSchedule = colors.doctorScheduleStatus.leave
    else if (status === 'MISSING') bgColorSchedule = colors.doctorScheduleStatus.missing
    return bgColorSchedule
  }

  if (type === 'COLOR') {
    let txtColorSchedule: any = colors.black
    if (status === 'LEAVE_WORK') txtColorSchedule = colors.doctorScheduleStatus.solid.leave
    else if (status === 'MISSING') txtColorSchedule = colors.doctorScheduleStatus.solid.missing
    else if (status === 'NORMAL') txtColorSchedule = colors.white
    return txtColorSchedule
  }
}

export default function DoctorSchedule() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [widthScreen, heightScreen] = UseWindowSize()

  const [bodyHeight, setBodyHeight] = useState(Array.from(document.getElementsByClassName('container-content') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0)
  const headHeight = Array.from(document.getElementsByClassName('head-schedule') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0

  /** LOAD DATA */
  const [dataSchedule, setDataSchedule] = useState<DoctorScheduleMonthListInterface[]>([])
  const [assistants, setAssistants] = useState<AssistantListInterface[]>([])
  const [listTemplate, setlistTemplate] = useState<any[]>([])
  const [dataModal, setDataModal]: any = useState({})
  const [serviceRooms, setServiceRooms] = useState<any[]>([])
  const [handInsurances, setHnadInsurances] = useState<any[]>([])

  const [isInsure, setIsInsure] = useState<boolean>(false)
  const [listAppointmentMonth, setListAppointmentMonth] = useState<any[]>([])

  const [filterBranch, setFilterBranch] = useState<number>(getBranch() || 1)

  const userSetup = useSelector(selectUser)

  const dateNow = moment().format('YYYY-MM-DD').split('-')
  const [filterDate, setFilterDate] = useState<DateInterface>({ year: Number(dateNow[0]), month: Number(dateNow[1]), date: 1 })
  const [filterDoctor, setfilterDoctor] = useState<string>('')
  const onlyDentist: boolean = filterDoctor.split(',').length > 1 && filterDoctor !== '' ? false : true

  const [maDentist, setMaDentist] = useState<number>(0)

  const [dayExpane, setDayExpane] = useState<string>()
  const [width, setWidth] = useState<number>(0)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()
  const open = Boolean(anchorEl)

  const [edit, setEdit] = useState<boolean>(false)
  const [daysOfWeekObject, setDaysOfWeekObject] = useState<DayOfWeekObjectInterface>({
    0: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
    1: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
    2: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
    3: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
    4: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
    5: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
    6: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } }
  })

  /** DATA CREATE */
  const [saveSchedule, setSaveSchedule] = useState<DoctorScheduleItem[]>([])

  const [dataTag, setDataTag] = useState<any>([
    { tagId: 1, tagName: 'test1', color: '#a12345' },
    { tagId: 2, tagName: 'test22', color: '#e5e553' },
    { tagId: 3, tagName: 'test3333', color: '#5334d1' },
  ])

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonWidth = Array.from(document.getElementsByClassName('btn-doctor-over') as HTMLCollectionOf<HTMLElement>)
    setWidth(Number(buttonWidth[0]?.offsetWidth))
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  const share = () => {
    if (!dataSchedule.length)
      return swalWarning(`${t('DOCTOR_SCHEDULE.MESSAGE.WARNING.POST_SCHEDULE')}${t('USER_DOCTOR')}${t('DOCTOR_SCHEDULE.MESSAGE.WARNING.BEFORE_SCHEDULE')}${t('USER_DOCTOR')}${t('DOCTOR_SCHEDULE.MESSAGE.WARNING.SHARE_SCHEDULE')}`)
    const { month, year }: any = filterDate
    dispatch(showModal())
    setDataModal({
      showPopupSheare: true,
      month,
      year,
      branchId: dataSchedule[0]?.schedule[0]?.branchId || '-',
      doctorId: [dataSchedule[0]?.schedule[0]?.doctorId] || [],
      dataSchedule,
      email: dataSchedule[0]?.schedule[0]?.email || '-',
      doctorFullname: dataSchedule[0]?.schedule[0]?.doctorFullname || '-'
    })
  }

  const loadData = useCallback(() => {
    setDataSchedule([])
    let condition: any = {}
    const { date, month, year }: any = filterDate
    condition = { ...condition, branchId: filterBranch }
    condition = { ...condition, date: `${year}-${String(month).padStart(2, '0')}-${String(date).padStart(2, '0')}` }
    condition = { ...condition, doctorId: filterDoctor !== '' ? filterDoctor : '' }
    DoctorScheduleApi.findAll(condition).then(({ data }) => setDataSchedule(data))
  }, [filterBranch, filterDate, filterDoctor])

  const loadDataAssistant = useCallback(() => {
    setAssistants([])
    DoctorScheduleApi.findAllAssistants({ branchId: filterBranch }).then(({ data }) => {
      const newAssistants: AssistantListInterface[] = _.map(data, (d: any) => ({ staffId: d.userId, staffFullname: d.fullname }))
      setAssistants(newAssistants)
    })
  }, [filterBranch])

  const loadAppointment = useCallback(() => {
    const { date, month, year }: any = filterDate
    let condition: any = {}
    condition = { ...condition, branchId: filterBranch }
    condition = { ...condition, date: `${year}-${String(month).padStart(2, '0')}-${String(date).padStart(2, '0')}` }
    condition = { ...condition, doctorId: filterDoctor !== '' ? filterDoctor : '0' }
    AppointmentApi.displayMonth(condition).then(({ data }) => data && setListAppointmentMonth(data))
  }, [filterBranch, filterDate, filterDoctor])

  const getServiceRoom = useCallback(() => {
    ClinicApi.serviceRoom().then(({ data }) => setServiceRooms(data || []))
  }, [])

  useEffect(() => {
    DoctorScheduleApi.findAllHandInsurances().then(({ data }) => setHnadInsurances(data.sort((a: any, b: any) => a.hour - b.hour) || []))
  }, [])

  useEffect(() => {
    getServiceRoom()
  }, [getServiceRoom])

  useEffect(() => {
    loadDataAssistant()
  }, [loadDataAssistant])

  useEffect(() => {
    console.log(serviceRooms)
  }, [serviceRooms])

  useEffect(() => {
    loadData()
    loadAppointment()
    getServiceRoom()
    if (edit) {
      selectDentistManagement(maDentist)
    }
  }, [loadData])

  useEffect(() => {
    setBodyHeight(Array.from(document.getElementsByClassName('container-content') as HTMLCollectionOf<HTMLElement>)[0]?.offsetHeight || 0)
  }, [heightScreen])

  const renderDay = (schedule: DoctorScheduleMonthItemInterface[], dateRender: string) => {
    const renderDoctor = (doctor: DoctorScheduleMonthItemInterface, index: number, displayType?: string) => {
      return (
        <MenuDropdownItem className={`px-xl-2 text-ellipsis doctor-list-item`} key={index}>
          <div className="p-0 d-flex overflow-hidden flex-center">
            <Avatar alt="Remy Sharp" src={`${fileUrl}/${doctor.avatar}`} sx={{ backgroundColor: colors.white }}>
              <img src={imgs.defaultAvatar} alt="" className={'w-100 h-100'} />
            </Avatar>
            <div className="pl-2 name-doctor text-nowrap-ellipsis -overflow-hidden ">
              <p className="mb-0 fc-main  text-nowrap-ellipsis">
                <strong>{String(doctor?.doctorFullname || '')}</strong>
              </p>
              {doctor?.userTags?.length ?
                <BoxTag className={'text-ellipsis'} sx={{ flexWrap: 'wrap' }}>
                  {doctor?.userTags?.map((item: DoctorScheduleTags, index: number) => {
                    return (
                      <Box
                        key={index}
                        title={item.tagName}
                        className={`items-tag`}
                        sx={{ backgroundColor: item.color, }}
                      />
                    )
                  })}
                </BoxTag>
                : <></>
              }
              <p className="mb-0 text-muted time-doctor text-nowrap-ellipsis">
                {doctor.statusDoctorSchedule === 'NORMAL' ? (
                  <>
                    {`${moment(String(doctor.timeStart || '00:00'), 'HH:mm').format('HH.mm น.')} - ${moment(String(doctor.timeEnd || '00:00'), 'HH:mm').format('HH.mm น.')}`} ({doctor.countAppoints} {t('DOCTOR_SCHEDULE.MEETUP')})
                  </>
                ) : (
                  <div style={{ color: getColorStatus(doctor.statusDoctorSchedule, 'COLOR'), fontWeight: 'bold' }}>{doctor.statusDoctorSchedule ? `(${t(`DOCTOR_SCHEDULE.STATUS.${doctor.statusDoctorSchedule}`)})` : '-'}</div>
                )}
              </p>
            </div>
          </div>
        </MenuDropdownItem>
      )
    }

    const renderBtn = (label: any) => {
      return (
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(e) => {
            handleOpen(e)
            setDayExpane(dateRender)
          }}
          endIcon={<FontAwesomeIcon icon={faCaretDown} />}
          disableRipple
          className={'btn-doctor-over py-0 px-2 w-100 text-nowrap'}
          style={{ color: colors.themeSecondColor }}
          sx={{ ':hover': { '&:hover': { backgroundColor: colors.white } } }}
        >
          <>{label}</>
        </Button>
      )
    }

    if (schedule.length) {
      return (
        <DivRenderDay className="doctor-list">
          {_.map(schedule, (doctor: DoctorScheduleMonthItemInterface, index: number) => renderDoctor(doctor, index))}
          {schedule.length > 2 && (
            <>
              {(widthScreen < 1200 && (
                <>
                  {(widthScreen < 769 &&
                    schedule.length > 5 &&
                    renderBtn(
                      <div className="show-slim">
                        <span className="d-flex align-items-center justify-content-center">{`+${schedule.length - 5}`}</span>
                      </div>
                    )) ||
                    (widthScreen < 1200 &&
                      schedule.length > 9 &&
                      renderBtn(
                        <div className="show-slim">
                          <span className="d-flex align-items-center justify-content-center">{`+${schedule.length - 9}`}</span>
                        </div>
                      )) || <></>}
                </>
              )) ||
                renderBtn(<span className="show-full text-ellipsis">{t('DOCTOR_SCHEDULE.MORE', { MORE: schedule.length - 2 })} </span>)}
              <MenuDoctorListOverall
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && dayExpane === dateRender}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                classes={{ list: 'custom-scroll' }}
                sx={{ '& .MuiMenu-list': { minWidth: width } }}
              >
                {_.map(schedule, (doctor: DoctorScheduleMonthItemInterface, index: number) => renderDoctor(doctor, index, 'in'))}
              </MenuDoctorListOverall>
            </>
          )}
        </DivRenderDay>
      )
    }
  }

  /** MA SCHEDULE */
  const initSaveSchedule: DoctorScheduleItem = {
    date: '',
    doctorId: maDentist,
    timeStart: '00:00',
    timeEnd: '00:00',
    breakTimeStart: '00:00',
    breakTimeEnd: '00:00',
    handInsurance: 0,
    servicePointId: 0,
    statusDoctorSchedule: 'NORMAL',
    doctorScheduleAssistants: []
  }
  const getDateInMonth = async (year: number, month: number) => {
    const range: any[] = []

    const start = String(
      moment([year, month - 1])
        .startOf('month')
        .format('YYYY-MM-DD')
    )
    const end = String(
      moment([year, month - 1])
        .endOf('month')
        .add(1, 'day')
        .format('YYYY-MM-DD')
    )
    const currentDay = moment(start).subtract(1, 'day')
    let count = 0
    while (count <= 31 && String(currentDay.add(1, 'day').format('YYYY-MM-DD')) !== end) {
      range.push({ date: currentDay.format('YYYY-MM-DD'), dayOfWeek: moment(currentDay).locale('en').format('ddd'), dayOfWeekNumber: moment(currentDay).day() })
      count++
    }
    return range
  }
  const checkWeekDays = async (data: any) => {
    // data.find((d: any) => d.permission === true) ? true : false
    const arraySelect: any[] = []
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const dayOfWeek: any = data[key]
        if (dayOfWeek.permission) arraySelect.push(key)
      }
    }
    return arraySelect
  }
  const handleClickDayOfWeek = async (val: number) => {
    if (await validateDentist()) return false
    const temp = { ...daysOfWeekObject }
    const dayOfWeekNumber: number = val
    temp[dayOfWeekNumber] = { ...temp[dayOfWeekNumber], permission: !temp[dayOfWeekNumber].permission }
    if (temp[dayOfWeekNumber].permission === true) onSetSameDay(dayOfWeekNumber)
    else {
      const allDays: any = await getDateInMonth(filterDate.year, filterDate.month)
      const arrDaySelect = _.filter(allDays, (day: any) => day.dayOfWeekNumber === dayOfWeekNumber)?.map((d: any) => d.date)
      const firstSave: DoctorScheduleItem | undefined = saveSchedule.find((schedule: DoctorScheduleItem) => schedule.date === arrDaySelect[0])
      if (firstSave?.timeStart === '00:00') {
        setSaveSchedule([...saveSchedule.filter((schedule: any) => !arrDaySelect.includes(schedule.date))])
      }
    }
    setDaysOfWeekObject(temp)
  }
  const onSetSameDay = async (dayOfWeekNumber: number, objSaveSchedule?: DoctorScheduleItem) => {
    const allDays: any = await getDateInMonth(filterDate.year, filterDate.month)
    const arrDaySelect = _.filter(allDays, (day: any) => day.dayOfWeekNumber === dayOfWeekNumber)?.map((d: any) => d.date)
    const oldSchedule: DoctorScheduleItem[] = saveSchedule.filter((schedule: DoctorScheduleItem) => arrDaySelect.includes(schedule.date))
    if (oldSchedule.length && objSaveSchedule === undefined) objSaveSchedule = oldSchedule[0]
    setSaveSchedule([
      ...saveSchedule.filter((schedule: DoctorScheduleItem) => !arrDaySelect.includes(schedule.date)),
      ...arrDaySelect.map((d: string) => ({
        ...(objSaveSchedule ? objSaveSchedule : initSaveSchedule),
        date: d
      }))
    ])
  }
  const addSchedule = async (date: string) => {
    if (await validateDentist()) return false

    const dayOfWeekNumber: number = moment(date).day()
    const arraySelect: any = await checkWeekDays(daysOfWeekObject)
    if (arraySelect.includes(String(dayOfWeekNumber))) await onSetSameDay(dayOfWeekNumber)
    else setSaveSchedule([...saveSchedule, { ...initSaveSchedule, date }])
  }

  const onRemoveSchedule = (date: string) => {
    const data = _.filter(listAppointmentMonth, (i: any) => {
      return i.date === date
    })
    const check = _.filter(data[0]?.doctors, (i: any) => {
      return i.doctorId === maDentist
    })
    const result = _.some(check, (i: any) => {
      return !_.isEmpty(i.groupAppointments)
    })
    return result
  }
  const removeSchedule = async (date: string) => {
    const dayOfWeekNumber: number = moment(date).day()
    const arraySelect: any = await checkWeekDays(daysOfWeekObject)
    if (moment(date).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
      return swalWarning(
        t('DOCTOR_SCHEDULE.ALERT.CONFIRM_DELETE_HEAD_NOW'),
        t('DOCTOR_SCHEDULE.MESSAGE.WARNING.DELETE_SCHEDULE'),
        async (res: any) => {
          if (arraySelect.includes(String(dayOfWeekNumber))) {
            const allDays: any = await getDateInMonth(filterDate.year, filterDate.month)
            const arrDaySelect = _.filter(allDays, (day: any) => day.dayOfWeekNumber === dayOfWeekNumber)?.map((d: any) => d.date)
            setSaveSchedule([...saveSchedule.filter((schedule: any) => !arrDaySelect.includes(schedule.date))])
          } else {
            setSaveSchedule([...saveSchedule.filter((schedule: any) => schedule.date !== date)])
          }
        },
        {
          showCancelButton: true,
          allowOutsideClick: true,
          confirmButtonText: t('DOCTOR_SCHEDULE.BUTTON.CONFIRM')
        }
      )
    } else if (onRemoveSchedule(date)) {
      return swalWarning(
        t('DOCTOR_SCHEDULE.ALERT.CONFIRM_DELETE_HEAD'),
        t('DOCTOR_SCHEDULE.ALERT.CONFIRM_DELETE'),
        async (res: any) => {
          if (arraySelect.includes(String(dayOfWeekNumber))) {
            const allDays: any = await getDateInMonth(filterDate.year, filterDate.month)
            const arrDaySelect = _.filter(allDays, (day: any) => day.dayOfWeekNumber === dayOfWeekNumber)?.map((d: any) => d.date)
            setSaveSchedule([...saveSchedule.filter((schedule: any) => !arrDaySelect.includes(schedule.date))])
          } else {
            setSaveSchedule([...saveSchedule.filter((schedule: any) => schedule.date !== date)])
          }
        },
        {
          showCancelButton: true,
          allowOutsideClick: true,
          confirmButtonText: t('DOCTOR_SCHEDULE.BUTTON.CONFIRM')
        }
      )
    } else {
      if (arraySelect.includes(String(dayOfWeekNumber))) {
        const allDays: any = await getDateInMonth(filterDate.year, filterDate.month)
        const arrDaySelect = _.filter(allDays, (day: any) => day.dayOfWeekNumber === dayOfWeekNumber)?.map((d: any) => d.date)
        setSaveSchedule([...saveSchedule.filter((schedule: any) => !arrDaySelect.includes(schedule.date))])
      } else {
        setSaveSchedule([...saveSchedule.filter((schedule: any) => schedule.date !== date)])
      }
    }
  }
  const onSetSchedule = async (date: string, key: any, value: any) => {
    const currentSchedule: any = saveSchedule.find((schedule: any) => schedule.date === date)
    currentSchedule[key] = value
    const dayOfWeekNumber: number = moment(date).day()
    const arraySelect: any = await checkWeekDays(daysOfWeekObject)
    if (arraySelect.includes(String(dayOfWeekNumber))) await onSetSameDay(dayOfWeekNumber, currentSchedule)
    else setSaveSchedule([...saveSchedule.filter((schedule: any) => schedule.date !== date), currentSchedule])
  }
  const initDaysOfWeekObject = async () =>
    setDaysOfWeekObject({
      0: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
      1: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
      2: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
      3: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
      4: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
      5: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } },
      6: { permission: false, text: '1', time: { timeStart: '0:00', timeEnd: '0:00', breakTimeStart: '0.00', breakTimeEnd: '0.00' } }
    })
  const resetManage = () => {
    setEdit(false)
    loadData()
    setMaDentist(0)
    setSaveSchedule([])
    setlistTemplate([])
    initDaysOfWeekObject()
  }

  const validateDentist = async () => {
    if (!maDentist) {
      notiError(t('DOCTOR_SCHEDULE.FORM.SELECT_MANAGE_DOCTOR'))
      return true
    }
    return false
  }
  const submit = async () => {
    if (await validateDentist()) return false
    const payload: any = {
      branchId: filterBranch,
      doctorId: maDentist,
      year: filterDate.year,
      month: filterDate.month,
      doctorSchedules: saveSchedule.map((item) => {
        return { ...item, isUpdate: undefined }
      })
    }
    await DoctorScheduleApi.create(payload)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          resetManage()
          notiSuccess(t(`DOCTOR_SCHEDULE.MESSAGE.SUCCESS.CREATE`), '', null)
        }
      })
      .catch((e) => {
        const err = e.response.data
        notiError(t(`DOCTOR_SCHEDULE.MESSAGE.${err.message}`))
      })
  }
  const selectDentistManagement = (dentistId: number) => {
    setMaDentist(dentistId)
    initDaysOfWeekObject()
    if (edit) {
      let condition: any = {}
      const { date, month, year }: any = filterDate

      condition = { ...condition, branchId: filterBranch }
      condition = { ...condition, date: `${year}-${String(month).padStart(2, '0')}-${String(date).padStart(2, '0')}` }
      condition = { ...condition, doctorId: dentistId }

      DoctorScheduleApi.findAll(condition).then(({ data }) => {
        const temp: DoctorScheduleItem[] = data.map((d: any) => {
          const schedule: any = d?.schedule[0] || {}

          if (schedule) {
            return {
              date: d.date,
              doctorId: schedule?.doctorId || 0,
              timeStart: schedule?.timeStart || '',
              timeEnd: schedule?.timeEnd || '',
              breakTimeStart: schedule?.breakTimeStart || null,
              breakTimeEnd: schedule?.breakTimeEnd || null,
              handInsurance: schedule?.handInsurance === 0 ? 0 : typeof schedule?.handInsurance === 'number' ? schedule?.handInsurance : getHandInsurance(d.date, schedule),
              statusDoctorSchedule: schedule?.statusDoctorSchedule || '',
              servicePointId: schedule?.servicePointId || 0,
              doctorScheduleAssistants: schedule?.doctorScheduleAssistants?.map((as: any) => ({ staffId: as.staffId })) || []
            }
          }
        })

        setSaveSchedule(temp)
      })
      DoctorScheduleApi.findAllMonthSchedule({ branchId: condition.branchId, doctorId: condition.doctorId }).then(({ data }) => data && setlistTemplate(data))
      PersonnelApi.findPermissionById(dentistId, { attribute: 'isInsure' }).then(({ data }) => {
        setIsInsure(data?.isInsure === '1' ? true : false)
      })
    }
  }

  const handleInsurePermissionById = (dentistId: number) => {
    PersonnelApi.findPermissionById(dentistId, { attribute: 'isInsure' }).then(({ data }) => {
      setIsInsure(data?.isInsure === '1' ? true : false)
    })
  }

  const getHandInsurance = (date: any, daySchdule?: any) => {
    const currentSchedule = daySchdule ? daySchdule : saveSchedule.find((schedule: any) => schedule.date === date)
    if (currentSchedule) {
      try {
        const splitStart = currentSchedule.timeStart.split(':')
        const splitEnd = currentSchedule.timeEnd.split(':')
        const h = moment()
          .set('hour', Number(splitEnd[0]))
          .set('minute', Number(splitEnd[1]))
          .diff(moment().set('hour', Number(splitStart[0])).set('minute', Number(splitStart[1])), 'h')
        return handInsurances.reduce((p, current) => {
          if (h >= current.hour) return current.price
          return p
        }, 0)
      } catch (error) {
        return 0
      }
    }
    return 0
  }
  const copySchedule = async (yearMonth: string, dentistId: number) => {
    const arrYearMonth: any[] = yearMonth.split('-')
    const year: any = Number(arrYearMonth[0])
    const month: any = Number(arrYearMonth[1])
    let condition: any = {}

    condition = { ...condition, branchId: filterBranch }
    condition = { ...condition, year: year }
    condition = { ...condition, month: month }
    condition = { ...condition, doctorId: dentistId }
    const currentRangDate: any[] = await getDateInMonth(filterDate.year, filterDate.month)
    const groupCurrent: any = _.groupBy(currentRangDate, 'dayOfWeekNumber')
    const templateRangDate: any[] = await getDateInMonth(year, month)
    const groupTemplate: any = _.groupBy(templateRangDate, 'dayOfWeekNumber')

    DoctorScheduleApi.getCopySchedule(condition).then(({ data }) => {
      const temp: DoctorScheduleItem[] = []
      const dataTemplate: DoctorScheduleMonthItemInterface[] = data
      _.map(groupCurrent, (week: any, dateOfWeekNumber: number) => {
        _.map(week, (w: DateRangeInMonthInterface, i: number) => {
          const dateTemplate: string = groupTemplate[w.dayOfWeekNumber][i]?.date
          const newCopySchedule: any = dataTemplate.find((d: any) => d.date === dateTemplate)
          if (newCopySchedule)
            temp.push({
              date: w.date,
              doctorId: newCopySchedule?.doctorId || 0,
              timeStart: newCopySchedule?.timeStart || '',
              timeEnd: newCopySchedule?.timeEnd || '',
              breakTimeStart: newCopySchedule?.breakTimeStart || null,
              breakTimeEnd: newCopySchedule?.breakTimeEnd || null,
              handInsurance: newCopySchedule?.handInsurance === 0 ? 0 : typeof newCopySchedule?.handInsurance !== 'number' ? getHandInsurance(w.date, newCopySchedule) : newCopySchedule?.handInsurance,
              statusDoctorSchedule: newCopySchedule?.statusDoctorSchedule || '',
              servicePointId: newCopySchedule?.servicePointId || 0,
              doctorScheduleAssistants: newCopySchedule?.doctorScheduleAssistants?.map((as: any) => ({ staffId: as.staffId })) || []
            })
        })
      })
      setSaveSchedule(temp)
    })
  }

  /** MA SCHEDULE */

  return (
    <div>
      <Card className="border-0 h-100">
        <HeadSchedule
          branchId={1}
          viewDisplay={'MONTH'}
          date={filterDate}
          doctor={''}
          handleViewDisplay={(val) => {
            return
          }}
          handleBranchId={setFilterBranch}
          handleDate={setFilterDate}
          handleDoctor={(doctorId: string) => {
            if (!doctorId?.includes(',') && !isNaN(Number(doctorId))) {
              handleInsurePermissionById(Number(doctorId))
            }
            setfilterDoctor(doctorId)
          }}
          handleClickAdd={(val) => setEdit(true)}
          handleClickView={(val) => {
            return
          }}
          maDentist={maDentist}
          handleDentist={selectDentistManagement}
          onUseCopyMonth={copySchedule}
          share={share}
          onSave={submit}
          onCancel={() => resetManage()}
          edit={edit}
          listTemplateDoctor={listTemplate}
        />
        <Card.Body className="p-0">
          <CustomCalenderDoctorSchedule className={`px-0 col overflow-auto custom-scroll mt-3 mx-3 ${edit ? 'calender-edit' : ''}`} style={{ maxHeight: `calc(${bodyHeight - headHeight}px - 35px)` }}>
            {(edit && (
              <Calendar
                yearAndMonth={[filterDate.year, filterDate.month]} // default
                onYearAndMonthChange={(val) => {
                  return
                }} // default
                onEditDaysOfWeek={handleClickDayOfWeek}
                daysOfWeekObject={daysOfWeekObject}
                headerDayOfWeek={edit} //display header days of week
                onGetFirstMessage={() => {
                  return
                }}
                renderDay={(calendarDayObject: CalendarDayObjectInterface) => {
                  const daySchdule: DoctorScheduleItem | undefined = saveSchedule.find((schedule: DoctorScheduleItem) => schedule.date === calendarDayObject.dateString)
                  const currentDate = calendarDayObject.dateString

                  return (
                    (daySchdule && (
                      <div style={{ backgroundColor: getColorStatus(daySchdule?.statusDoctorSchedule, 'BACKGROUND'), height: '100%' }}>
                        <div className="container-item p-0">
                          <div className="d-flex flex-center" style={{ height: '34px' }}>
                            <DivDayNumber style={{ display: 'inline-block' }} className={`day-grid-item-header mr-auto ${currentDate === moment().format('YYYY-MM-DD') ? 'now' : ''}`}>
                              {calendarDayObject.dayOfMonth}
                            </DivDayNumber>
                            {<ButtonDeleteSimple onClick={() => removeSchedule(currentDate)} />}
                            <Dropdown className="d-inline-flex" drop={'down'} align={'start'}>
                              <Dropdown.Toggle variant="link" bsPrefix="p-0" className="text-black-50">
                                <Image src={iconEllipsis} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu align={'end'} style={{ fontSize: '16px' }}>
                                <Dropdown.Item onClick={() => onSetSchedule(currentDate, 'statusDoctorSchedule', 'LEAVE_WORK')}>{t('DOCTOR_SCHEDULE.STATUS.LEAVE_WORK')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => onSetSchedule(currentDate, 'statusDoctorSchedule', 'MISSING')}>{t('DOCTOR_SCHEDULE.STATUS.MISSING')}</Dropdown.Item>
                                <Dropdown.Item onClick={() => onSetSchedule(currentDate, 'statusDoctorSchedule', 'NORMAL')}>{t('DOCTOR_SCHEDULE.STATUS.MANAGE')}</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {(daySchdule.statusDoctorSchedule === 'NORMAL' && (
                            <div className="p-2 overflow-auto">
                              <div className="mb-2">
                                <CustomTimePicker>
                                  <InputNewRangeTimePicker
                                    value={[moment(daySchdule.timeStart, 'HH:mm').format('HH:mm'), moment(daySchdule.timeEnd, 'HH:mm').format('HH:mm')]}
                                    onChange={(times: any) => {
                                      const time1 = times[0]
                                      const time2 = times[1]
                                      // onSetSchedule(currentDate, 'timeStart', time1 === '00:00' ? time2 : time1)
                                      // onSetSchedule(currentDate, 'timeEnd', time1 === '00:00' ? time1 : time2)
                                      onSetSchedule(currentDate, 'timeStart', time1)
                                      onSetSchedule(currentDate, 'timeEnd', time2)
                                      onSetSchedule(currentDate, 'handInsurance', getHandInsurance(currentDate))
                                      onSetSchedule(currentDate, 'isUpdate', 1)
                                    }}
                                    size={'small'}
                                    minmax={moment(daySchdule.timeEnd, 'HH:mm').format('HH:mm') !== '00:00' || moment(daySchdule.timeStart, 'HH:mm').format('HH:mm') !== '00:00' ? true : false}
                                  />
                                </CustomTimePicker>
                              </div>
                              <div className="mb-2">
                                <CustomTimePicker>
                                  <InputNewRangeTimePicker
                                    value={[moment(daySchdule.breakTimeStart, 'HH:mm').format('HH:mm'), moment(daySchdule.breakTimeEnd, 'HH:mm').format('HH:mm')]}
                                    onChange={(times: any) => {
                                      const time1 = times[0]
                                      const time2 = times[1]
                                      // onSetSchedule(currentDate, 'breakTimeStart', time1 === '00:00' ? time2 : time1)
                                      // onSetSchedule(currentDate, 'breakTimeEnd', time1 === '00:00' ? time1 : time2)
                                      onSetSchedule(currentDate, 'breakTimeStart', time1)
                                      onSetSchedule(currentDate, 'breakTimeEnd', time2)
                                    }}
                                    suffixIcon={<Image src={icons.iconCoffeeTime} width={18} height={18} style={{ marginRight: -6 }} />}
                                    size={'small'}
                                    minmax={moment(daySchdule.breakTimeEnd, 'HH:mm').format('HH:mm') !== '00:00' || moment(daySchdule.breakTimeStart, 'HH:mm').format('HH:mm') !== '00:00' ? true : false}
                                  />
                                </CustomTimePicker>
                              </div>

                              <div className="mb-2">
                                <BasicSelect
                                  formControlClass={'form bg-white'}
                                  style={{ fontSize: '14px' }}
                                  inputHeight={32}
                                  onchange={(e) => {
                                    const val: number = e.target.value
                                    onSetSchedule(currentDate, 'servicePointId', val ? val : 0)
                                  }}
                                  label={t('DOCTOR_SCHEDULE.FORM.SERVICE_POINT')}
                                  selectId="select-service-point"
                                  value={daySchdule?.servicePointId}
                                  labelId="label--service-point"
                                  options={_.map(serviceRooms, (room: any, index: number) => (
                                    <MenuItem className="py-1" key={index} value={room.servicePointId}>
                                      {room.servicePointName}
                                    </MenuItem>
                                  ))}
                                />
                              </div>
                              <div className="mb-2">
                                <BasicSelect
                                  multiple
                                  style={{ fontSize: '14px' }}
                                  inputHeight={32}
                                  formControlClass={'form bg-white fz-14'}
                                  fullWidth={true}
                                  onchange={(e) => {
                                    const val: any[] = e.target.value
                                    onSetSchedule(currentDate, 'doctorScheduleAssistants', val.length ? val.map((v) => ({ staffId: v })) : [])
                                  }}
                                  renderValue={(val: any[]) =>
                                    `${_.filter(assistants, (as: any) => val.includes(as.staffId))
                                      ?.map((as: any) => as.staffFullname)
                                      ?.join(', ') || t('USER_MEDICINE_ASSISTANT')
                                    }`
                                  }
                                  label={t('USER_MEDICINE_ASSISTANT')}
                                  selectId="select-dentist"
                                  value={daySchdule?.doctorScheduleAssistants?.map((d) => d.staffId) || []}
                                  labelId="label-dentist"
                                  options={_.map(assistants, (assistant: AssistantListInterface, i: number) => (
                                    <MenuItem className="py-1" key={i} value={assistant.staffId}>
                                      <InputCheckbox className="mr-2 p-0" checked={daySchdule.doctorScheduleAssistants?.map((d) => d.staffId).includes(assistant.staffId) ? true : false} label={assistant.staffFullname} />
                                    </MenuItem>
                                  ))}
                                />
                              </div>
                              <div className={`mb-2 ${isInsure ? '' : 'd-none'}`}>
                                <InputText
                                  type="text"
                                  disabled={userSetup.me.hasInsuranceCost === 'UNPERMISS'}
                                  className="form-control"
                                  placeholder="ค่าประกันลงเวร"
                                  onChange={(e) => {
                                    onSetSchedule(currentDate, 'handInsurance', Number(e.target.value.replace(/[^0-9]/g, '')))
                                    onSetSchedule(currentDate, 'isUpdate', 0)
                                  }}
                                  value={daySchdule.isUpdate ? getHandInsurance(currentDate) : daySchdule?.handInsurance || 0}
                                />
                              </div>
                            </div>
                          )) || (
                              <div className="d-flex flex-center" style={{ height: 'calc(100% - 32px)' }}>
                                {daySchdule.statusDoctorSchedule ? t(`DOCTOR_SCHEDULE.STATUS.${daySchdule.statusDoctorSchedule}`) : '-'}
                              </div>
                            )}
                        </div>
                      </div>
                    )) || (
                      <div style={{ backgroundColor: colors.white, height: '100%' }} onClick={() => calendarDayObject.isCurrentMonth && addSchedule(currentDate)}>
                        <div className="container-item p-0">
                          <div className="d-flex flex-center" style={{ height: '34px' }}>
                            <DivDayNumber style={{ display: 'inline-block' }} className={`day-grid-item-header mr-auto ${currentDate === moment().format('YYYY-MM-DD') ? 'now' : ''}`}>
                              {calendarDayObject.dayOfMonth}
                            </DivDayNumber>
                          </div>
                        </div>
                      </div>
                    )
                  )
                }}
              />
            )) || (
                <CalendarDay
                  yearAndMonth={[filterDate.year, filterDate.month]} // default
                  renderDay={(calendarDayObject: any) => {
                    const dataDay: DoctorScheduleMonthListInterface | undefined = dataSchedule.find((schedule: DoctorScheduleMonthListInterface) => schedule.date === calendarDayObject.dateString)
                    const currentDate = calendarDayObject.dateString
                    const daySchdule: DoctorScheduleMonthItemInterface | undefined = dataDay?.schedule[0]
                    return (
                      (onlyDentist && (
                        <>
                          {(daySchdule && (
                            <div style={{ backgroundColor: getColorStatus(daySchdule?.statusDoctorSchedule, 'BACKGROUND'), height: '100%' }}>
                              <div className="container-item p-0">
                                <div className="d-flex flex-center" style={{ height: '34px' }}>
                                  <DivDayNumber style={{ display: 'inline-block' }} className={`day-grid-item-header mr-auto ${currentDate === moment().format('YYYY-MM-DD') ? 'now' : ''}`}>
                                    {calendarDayObject.dayOfMonth}
                                  </DivDayNumber>
                                </div>
                                {daySchdule.statusDoctorSchedule === 'NORMAL' ? (
                                  <div className=" px-1 d-flex overflow-hidden">
                                    <Avatar className="mt-1" src={`${fileUrl}/${daySchdule.avatar}`} alt={daySchdule.doctorFullname || ''} sx={{ backgroundColor: colors.white }}>
                                      <img src={imgs.defaultAvatar} alt="" className="w-100 h-100" />
                                    </Avatar>
                                    <div className="pl-2 name-doctor text-nowrap-ellipsis -overflow-hidden ">
                                      <p className="mb-0 fc-main  text-nowrap-ellipsis">
                                        <strong>{String(daySchdule.doctorFullname || '')}</strong>
                                      </p>
                                      {daySchdule?.userTags?.length ?
                                        <BoxTag sx={{ flexWrap: 'wrap' }}>
                                          {daySchdule?.userTags?.map((item: DoctorScheduleTags, index: number) => {
                                            return (
                                              <Box
                                                key={index}
                                                title={item.tagName}
                                                className={`items-tag`}
                                                sx={{ backgroundColor: item.color, }}
                                              />
                                            )
                                          })}
                                        </BoxTag>
                                        : <></>
                                      }
                                      <p className="mb-0 text-muted time-doctor text-nowrap-ellipsis">
                                        {daySchdule.statusDoctorSchedule === 'NORMAL' ? (
                                          <div className="fz-14">
                                            {`${moment(String(daySchdule.timeStart || '00:00'), 'HH:mm').format('HH.mm น.')} - ${moment(String(daySchdule.timeEnd || '00:00'), 'HH:mm').format('HH.mm น.')}`} ({daySchdule.countAppoints}{' '}
                                            {t('DOCTOR_SCHEDULE.MEETUP')})
                                          </div>
                                        ) : (
                                          <div style={{ color: getColorStatus(daySchdule.statusDoctorSchedule, 'COLOR'), fontWeight: 'bold' }}>-</div>
                                        )}
                                      </p>
                                      <p className="mb-0 fz-14 text-wrap  w-100">
                                        <strong>{t('DOCTOR_SCHEDULE.ASSISTANT')}</strong>: {daySchdule?.doctorScheduleAssistants?.map((as: any) => as.staffFullname).join(', ') || '-'}
                                      </p>
                                      {isInsure && (
                                        <p className="mb-0 fz-14 text-nowrap-ellipsis ">
                                          <strong>{t('DOCTOR_SCHEDULE.GUARANTEE')}</strong>:{' '}
                                          {daySchdule?.handInsurance || daySchdule?.handInsurance === 0 ? numberToCurrencyFormat(daySchdule?.handInsurance || 0) : getHandInsurance(currentDate, daySchdule)} {t('DOCTOR_SCHEDULE.CURRENCY')}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex flex-center" style={{ height: 'calc(100% - 32px)', marginTop: '-17px' }}>
                                    {daySchdule.statusDoctorSchedule ? t(`DOCTOR_SCHEDULE.STATUS.${daySchdule.statusDoctorSchedule}`) : '-'}
                                  </div>
                                )}
                              </div>
                            </div>
                          )) || (
                              <div style={{ backgroundColor: colors.white, height: '100%' }} onClick={() => calendarDayObject.isCurrentMonth && addSchedule(currentDate)}>
                                <div className="container-item p-0">
                                  <div className="d-flex flex-center" style={{ height: '34px' }}>
                                    <DivDayNumber style={{ display: 'inline-block' }} className={`day-grid-item-header mr-auto ${currentDate === moment().format('YYYY-MM-DD') ? 'now' : ''}`}>
                                      {calendarDayObject.dayOfMonth}
                                    </DivDayNumber>
                                  </div>
                                </div>
                              </div>
                            )}
                        </>
                      )) || (
                        <CalendarItem
                          calendarDayObject={calendarDayObject} // defaultValue
                          items={renderDay(dataDay?.schedule || [], calendarDayObject.dateString)}
                        />
                      )
                    )
                  }}
                />
              )}
          </CustomCalenderDoctorSchedule>
        </Card.Body>
      </Card>

      {dataModal?.showPopupSheare && <DoctorScheduleShare data={dataModal} />}
    </div>
  )
}
