import { useState, useEffect, useCallback } from 'react'
import { Box, IconButton, TableRow, TableCell, Collapse, Grid } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** API */
import InventoryApi from 'api/warehouses/inventory.api'

/** UTILS */
import { colors } from 'constants/theme'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'

/** COMPONENT */
import { InventoryTabs } from 'features/warehouses/inventory/form-inventory'
import TableCustom from 'component/Table/TableCustom'
import Loading from 'component/Loading'

/** STYLE */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import * as UseStyled from 'features/warehouses/inventory/styled'

export default function HistoryOrderTab(props: InventoryTabs) {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState([0]);

    /** PAGINATION  */
    const [page, setPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(10)
    const [rowCount, setRowCount] = useState(0)

    const loadData = useCallback((id: number) => {
        setLoading(true)
        let condition: any = { itemId: id }
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }

        InventoryApi.findOrderHistoryById(id, condition)
            .then(({ data, headers }) => {
                setOrders(data)
                setRowCount(headers['x-total'])
            })
            .catch(() => { return })
            .finally(() => setLoading(false))
    }, [page, pageLimit])

    useEffect(() => {
        if (props.inventory?.itemId) loadData(props.inventory?.itemId)
    }, [loadData]);

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
        setPage(1)
    }

    const handleChangePage = (page: number) => {
        setPage(page)
    }

    const headCells = [
        { id: 'no', disablePadding: false, label: '#', width: '60px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.DOCUMENT_DATE'), width: '135px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.LOT_NO'), width: '200px' },
        { id: 'message', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.QTY') },
        { id: 'price', disablePadding: false, label: t('INVENTORY.TABLE.CELLS.TOTAL'), width: '200px' },
        { id: 'message', disablePadding: false, label: t('Action'), align: 'center', width: '120px' }
    ]

    return (
        <>
            <UseStyled.ResponsiveTable>
                <TableCustom
                    page={page}
                    pageLimit={pageLimit}
                    sortType={'ASC'}
                    sortBy={''}
                    rowCount={rowCount}
                    textEmptyData={t('REGISTER.NO_ITEM')}
                    onSort={() => { return }}
                    setPageLimit={handleChangeRowsPerPage}
                    setPage={handleChangePage}
                    headCells={headCells}
                    rowsData={
                        [
                            !loading ?
                                (
                                    _.isEmpty(orders) ? (
                                        <TableRow>
                                            <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                                {t('REGISTER.NO_ITEM')}
                                            </TableCell>
                                        </TableRow>
                                    ) :
                                        _.map(orders, (order, index: number) => {
                                            return (
                                                <RenderData
                                                    no={index}
                                                    page={page}
                                                    pageLimit={pageLimit}
                                                    headCells={headCells}
                                                    objData={order}
                                                />
                                            )
                                        })
                                ) : (
                                    <TableRow>
                                        <TableCell align="center" scope="row" colSpan={_.size(headCells)}>
                                            <Box className='mx-auto' sx={{ maxWidth: 'max-content' }}>
                                                <Loading show={true} type='softLoading' />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                        ]
                    }
                    tableFixedWidth
                    tableMinWidth={1024}
                />
            </UseStyled.ResponsiveTable>
        </>
    )
}

type RenderOrderDataType = {
    no: number
    page: number
    pageLimit: number
    headCells: any
    objData: any
}

function RenderData(props: RenderOrderDataType) {
    const { t } = useTranslation()
    const { no, page, pageLimit, objData, headCells } = props
    const { date, lotNo, qty, total, orders } = objData
    const [open, setOpen] = useState(false);

    const number = page * pageLimit - pageLimit + no + 1

    return (
        <>
            <TableRow>
                <TableCell align='center'>
                    {number}
                </TableCell>
                <TableCell align='left'>
                    {date && date !== '0000-00-00' ? toBuddhistYear(moment(date), 'DD/MM/YYYY') : '-'}
                </TableCell>
                <TableCell align='left'>
                    {lotNo || '-'}
                </TableCell>
                <TableCell align='left'>
                    {numberFormat(qty || 0, 0, 0)}
                </TableCell>
                <TableCell align='right'>
                    {numberFormat(total || 0)}
                </TableCell>
                <TableCell align='center'>
                    <IconButton onClick={() => setOpen(!open)} className={`p-0 align-middle`} sx={{ backgroundColor: 'transparent !important' }}>
                        <ExpandMoreIcon
                            sx={{
                                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                backgroundColor: colors.white,
                                border: `0.5px solid ${colors.textLightGray}`,
                                borderRadius: '50px',
                                color: colors.themeMainColor,
                                width: '22px !important',
                                height: '22px !important'
                            }} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow className='section-collapse' sx={{ borderBottom: open ? `1px solid ${colors.lightGray}` : 'none' }}>
                <TableCell colSpan={_.size(headCells)} className="p-0 border-0">
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {
                            _.map(orders, (order, indexOrder: number) => {
                                return (
                                    <Box key={indexOrder} className={`px-3 box-wrap`}>
                                        <Box className={'py-3'} sx={{ paddingLeft: '40px', borderTop: indexOrder !== 0 ? `1px solid ${colors.lightGray}` : 'none' }}>
                                            <Grid container spacing={1}>
                                                <Grid item lg={2.4} xl={2.05} className="d-flex">
                                                    <UseStyled.TypoTitleOrder type={'title'}>{t('INVENTORY.TABLE.CELLS.BILL_NO')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{order?.paymentNumber || '-'}</UseStyled.Typo>
                                                </Grid>
                                                <Grid item lg={1} xl={1.3} className="d-flex">
                                                    <UseStyled.TypoTitleOrder type={'title'}>{t('INVENTORY.TABLE.CELLS.QTY')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{numberFormat(order?.qty || 0, 0, 0)}</UseStyled.Typo>
                                                </Grid>
                                                <Grid item lg={2} xl={2} className="d-flex" >
                                                    <UseStyled.TypoTitleOrder type={'title'} >{t('INVENTORY.TABLE.CELLS.PRICE_UNIT')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{numberFormat(order?.price || 0)} {t('CURRENCY_CHANGE.TH')}</UseStyled.Typo>
                                                </Grid>
                                                <Grid item lg={1.5} xl={1.6} className="d-flex" >
                                                    <UseStyled.TypoTitleOrder type={'title'}>{t('INVENTORY.TABLE.CELLS.DISCOUNT')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{numberFormat(order?.discount || 0)} {order?.discountUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}</UseStyled.Typo>
                                                </Grid>
                                                <Grid item lg={1.5} xl={1.6} className="d-flex" >
                                                    <UseStyled.TypoTitleOrder type={'title'}>{t('INVENTORY.TABLE.CELLS.ADDON')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{numberFormat(order?.additional || 0)} {order?.additionalUnit === 'BAHT' ? t('CURRENCY_CHANGE.TH') : '%'}</UseStyled.Typo>
                                                </Grid>
                                                <Grid item lg={1.7} xl={1.8} className="d-flex" >
                                                    <UseStyled.TypoTitleOrder type={'title'} >{t('INVENTORY.TABLE.CELLS.TOTAL_PRICE')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{numberFormat(order?.total || 0)} {t('CURRENCY_CHANGE.TH')}</UseStyled.Typo>
                                                </Grid>
                                                <Grid item lg={1.7} xl={1.5} className="d-flex" >
                                                    <UseStyled.TypoTitleOrder type={'title'} >{t('INVENTORY.TABLE.CELLS.PAYMENT_AMOUNT')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{numberFormat(order?.amountPaid || 0)} {t('CURRENCY_CHANGE.TH')}</UseStyled.Typo>
                                                </Grid>
                                                <Grid item lg={3} xl={2} className="d-flex" >
                                                    <UseStyled.TypoTitleOrder type={'title'} >{t('INVENTORY.TABLE.CELLS.PATIENT_NAME')}</UseStyled.TypoTitleOrder>
                                                    <UseStyled.Typo>{order?.patientFullname || ''}</UseStyled.Typo>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}