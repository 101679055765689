import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import ButtonCustom from 'component/Button/ButtonCustom'
import UseWindowSize from 'utils/useWindowSize'
import { ResponsiveTable, IconTable } from 'features/treatments/MainMenu/OPD/lab/style'
import TableCustom from 'component/Table/TableCustom'
import { Box, Typography, TableRow, TableCell } from '@mui/material'
import LabOrderApi, { LabOrderInterface, AllLabByPatientType } from 'api/lab-order.api'
import { dateToView } from 'utils/date.utils';
import { swalDelete } from 'component/Alert/Swal'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import moment from 'moment'
import { closeModal, showModal, handleShow } from 'app/slice/modal.slice'
import { icons, Icons } from 'constants/images'
import FormLabOrder from 'features/lab-order/component/FormLabOrder'
import { treatment, TreatmentStateInterface } from 'app/slice/treatment.slice';
import TableRowCommon from 'component/Table/TableRowCommon'
import ViewLabOrder from 'features/lab-order/component/ViewLabOrder'
import { useLocation } from 'react-router-dom';

interface LabProps {
  type?: 'Treatment' | 'Payment' | 'HistoryTreatment' | 'RetrospectiveEdit'
  userId?: number
  patient?: any
  onSaveTreatment?: () => void
  setSaveTreatment?: (val: boolean) => void
  saveTreatment?: boolean | false
}
export default function Lab(props: LabProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [width] = UseWindowSize()
  const location = useLocation()
  const modal = useSelector(handleShow)

  const treatments: TreatmentStateInterface = useSelector(treatment)
  const [isViewDetail, setIsViewDetail] = useState<boolean>(false)

  const [patientId, setPatientId] = useState<number>(props.patient?.patientId || 0)
  const [patientCaseId, setPatientCaseId] = useState<number>(props.patient?.patientCase?.patientCaseId || 0)

  const [showForm, setShowForm] = useState<boolean>(false)
  const [labOrderId, setLabOrderId] = useState<number>(0)
  const [labOrders, setLabOrders] = useState<LabOrderInterface[]>([])
  const [popShow, setPopShow] = useState<boolean>(false)

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(5)
  const [rowCount, setRowCount] = useState(0)

  useEffect(() => {
    if (location.pathname === '/retrospective-edit') {
      setPopShow(true)
    }
  }, [location])

  useEffect(() => {
    if (props.saveTreatment) {
      dispatch(showModal())
      setShowForm(true)
    } else {
      dispatch(closeModal())
      setShowForm(false)
    }
  }, [props.saveTreatment]);

  useEffect(() => {
    if (!modal) {
      dispatch(closeModal())
      setShowForm(false)
      setIsViewDetail(false)
    }
  }, [modal]);

  useEffect(() => {
    const { patientId: pId, patientCase } = props.patient
    setPatientId(pId)
    if (props.type !== 'HistoryTreatment' && props.type !== 'RetrospectiveEdit') {
      const { patientCaseId: pcId } = patientCase
      setPatientCaseId(pcId)
    } else {
      setPatientCaseId(0)
    }
  }, [props.patient, props.userId])

  const loadLabOrderList = useCallback(async (pcId?: number) => {
    if (props.type === 'HistoryTreatment' || props.type === 'RetrospectiveEdit') {
      const condition: AllLabByPatientType = {
        patientId: patientId,
        page: page,
        pageLimit: pageLimit,
        sortType: 'ASC',
        sortBy: 'labOrderId'
      }
      const res = await LabOrderApi.loadAllLabByPatient(patientId, condition)
      if (res?.status === 200) {
        setRowCount(res.headers['x-total'])
        setLabOrders(res.data)
      }
    } else {
      const res = await LabOrderApi.getByPatientCase(pcId || patientCaseId, { patientCaseId, page, pageLimit })
      if (res?.status === 200) {
        setRowCount(res.headers['x-total'])
        setLabOrders(res.data)
      }
    }
  }, [page, pageLimit, patientId])

  useEffect(() => {
    loadLabOrderList()
  }, [page, pageLimit, patientId])

  const verifyTreatment = (method: 'CREATE' | 'UPDATE') => {
    if (props.type !== 'HistoryTreatment' && props.type !== 'RetrospectiveEdit')
      if (treatments?.summeryTreatments.length === 0) {
        notiError(method === 'CREATE' ? t('LAB_ORDER.ALERT.NO_TREATEMNT_CREATE') : t('LAB_ORDER.ALERT.NO_TREATEMNT_UPDATE'))
        return false
      }
    return true
  }

  const formAdd = () => {
    if (!verifyTreatment('CREATE')) return false
    else if (props?.onSaveTreatment) props.onSaveTreatment()
    dispatch(showModal())
    setShowForm(true)
  }

  const formEdit = async (objData: LabOrderInterface) => {
    if (!verifyTreatment('UPDATE')) return false
    else if (props?.onSaveTreatment && props.type !== 'RetrospectiveEdit') props.onSaveTreatment()
    const { labOrderId: id } = objData
    setLabOrderId(id)
    dispatch(showModal())
    setShowForm(true)
  }

  const onDelete = (id: number, obj: any) => {
    const { labOrderNumber } = obj
    swalDelete(
      `<p class="mb-0 mx-auto" style="max-width: 325px">${t('LAB_ORDER.ALERT.CONFIRM_DELETE')}</p>`,
      `<p class="text-center mb-0 mx-auto" style="max-width: 306px">${t('LAB_ORDER.MESSAGE.DEL_SUB')} ${labOrderNumber} <br /> ${t('LAB_ORDER.MESSAGE.YESNO')}</p>`,

      (res: any) => {
        if (res) {
          LabOrderApi.remove(id)
            .then((resp) => (notiSuccess(t('LAB_ORDER.MESSAGE.SUCCESS.DELETE'), '', null, () => loadLabOrderList(), 'register-page')))
            .catch((e) => (notiError(t('LAB_ORDER.MESSAGE.ERROR'))))
        }
      },
      { confirmButtonText: t('LAB_ORDER.ALERT.BTN_CONFIRM_DELETE'), width: '450px', cancelButtonText: t('BUTTON.CANCEL') }
    )
  }

  const desktopHeadCells = [{ id: 'no', disablePadding: false, label: '#', width: '80px !important' }]
  const actionHeadCells = [{ id: 'action', disablePadding: false, label: t('Action'), align: 'center' }]
  const tableHeadCells = [
    { id: 'vn', disablePadding: false, label: width >= 1200 ? t('VN') : '#', align: 'left' },
    { id: 'dateOrder', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_TIME_ORDER'), align: 'left' },
    { id: 'labCompanyName', disablePadding: false, label: t('LAB_ORDER.TABLE.COMPANY'), align: 'left' },
    { id: 'dateExport', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_EXPORT'), align: 'left' },
    { id: 'dateImport', disablePadding: false, label: t('LAB_ORDER.TABLE.DATE_RECEIVE'), align: 'left' },
    { id: 'status', disablePadding: false, label: t('LAB_ORDER.TABLE.STATUS'), align: 'center' },
  ]
  let headCells = width >= 1440 ? [...desktopHeadCells, ...tableHeadCells] : [...tableHeadCells]
  headCells = props.type !== 'HistoryTreatment' ? [...headCells, ...actionHeadCells] : [...headCells]

  const onView = (e: any, objVal: LabOrderInterface) => {
    setLabOrderId(objVal.labOrderId)
    setIsViewDetail(true)
    dispatch(showModal())
  }

  const [hoverEdit, setHoverEdit] = useState<any[]>([])

  const [hoverDelete, setHoverDelete] = useState<any[]>([])

  const renderData = (objData: LabOrderInterface, no: number) => {

    no = page * pageLimit - pageLimit + no + 1
    const { vnNumber, labOrderId: lbOrderId, dateOrder, timeOrder, labCompanyName, dateExport, dateImport, labOrderStatus } = objData
    const getTextLabOrderStatus = (status: string) => {
      const resultTxt = t(`LAB_ORDER.LAB_STATUS.${status}`)
      return resultTxt
    }

    function hoverEditProps(editNo: any) {
      return {
        onMouseOver: () => setHoverEdit([...hoverEdit, editNo]),
        onMouseOut: () => setHoverEdit(hoverEdit.filter((h: any) => h !== editNo)),
        onClick: () => formEdit(objData)
      }
    }

    function hoverDeleteProps(editNo: any) {
      return {
        onMouseOver: () => setHoverDelete([...hoverDelete, editNo]),
        onMouseOut: () => setHoverDelete(hoverDelete.filter((h: any) => h !== editNo)),
        onClick: () => onDelete(lbOrderId, objData)
      }
    }

    const objRenderData = {
      key: String(no),
      id: String(lbOrderId),
      obj: objData,
      columns: [
        width >= 1440 ? { option: 'TEXT', align: 'center', label: no } : {},
        { option: 'TEXT', align: 'left', label: vnNumber || '-' },
        { option: 'TEXT', align: 'center', label: `${dateOrder ? dateToView(dateOrder) : '-'} ${timeOrder ? moment(timeOrder, 'HH:mm').format('HH.mm น.') : ''}` },
        { option: 'TEXT', align: 'left', label: labCompanyName || '-' },
        { option: 'TEXT', align: 'left', label: dateExport ? dateToView(dateExport) : '-' },
        { option: 'TEXT', align: 'left', label: dateImport ? dateToView(dateImport) : '-' },
        { option: 'TEXT', align: 'center', label: getTextLabOrderStatus(labOrderStatus) },
        props.type !== 'HistoryTreatment' && {
          option: 'COMPONENT', component: (
            <div className='text-center'>
              <IconTable className="align-baseline edit" {...hoverEditProps(no)}>
                <Icons.DropdownEditActive />
              </IconTable>
              <IconTable className="align-baseline trash" {...hoverDeleteProps(no)}>
                <Icons.DropdownTrashActive />
              </IconTable>
            </div>
          )
        } || {},
      ],

    }

    return width >= 769 ? (
      <TableRowCommon rowLink={true} {...objRenderData} onClick={(e) => onView(e, objData)} />
    ) : (
      <>
        <TableRow key={lbOrderId}>
          <TableCell align="left" scope="row" colSpan={6}>
            <Box className="d-flex justify-content-between">
              <Box>
                <Typography className="d-inline-block mr-3" sx={{ width: '125px', fontWeight: 600 }}>
                  {t('VN')}
                </Typography >
                <Typography className="d-inline">{vnNumber || '-'}</Typography>
              </Box >
              <Box>
              </Box>
            </Box >
            <Box>
              <Typography className="d-inline-block mr-3" sx={{ width: '125px', fontWeight: 600 }}>
                {t('LAB_ORDER.TABLE.DATE_TIME_ORDER')}
              </Typography>
              <Typography className="d-inline">{dateOrder ? dateToView(dateOrder) : '-'} {timeOrder ? moment(timeOrder, 'HH:mm').format('HH.mm น.') : ''}</Typography>
            </Box>
            <Box>
              <Typography className="d-inline-block mr-3" sx={{ width: '125px', fontWeight: 600 }}>
                {t('LAB_ORDER.TABLE.COMPANY')}
              </Typography>
              <Typography className="d-inline">{labCompanyName}</Typography>
            </Box>
            <Box>
              <Typography className="d-inline-block mr-3" sx={{ width: '125px', fontWeight: 600 }}>
                {t('LAB_ORDER.TABLE.DATE_EXPORT')}
              </Typography>
              <Typography className="d-inline">{dateExport ? dateToView(dateExport) : '-'}</Typography>
            </Box>
            <Box>
              <Typography className="d-inline-block mr-3" sx={{ width: '125px', fontWeight: 600 }}>
                {t('LAB_ORDER.TABLE.DATE_RECEIVE')}
              </Typography>
              <Typography className="d-inline">{dateImport ? dateToView(dateImport) : '-'}</Typography>
            </Box>
            <Box>
              <Typography className="d-inline-block mr-3" sx={{ width: '125px', fontWeight: 600 }}>
                {t('LAB_ORDER.TABLE.STATUS')}
              </Typography>
              <Typography className="d-inline">{getTextLabOrderStatus(labOrderStatus)}</Typography>
            </Box>
          </TableCell>
          {props.type !== 'HistoryTreatment' && (
            <TableCell align="left" scope="row">
              <IconTable className="align-baseline edit" {...hoverEditProps(no)}>
                <Icons.DropdownEditActive />
              </IconTable>
              <IconTable className="align-baseline trash" {...hoverDeleteProps(no)}>
                <Icons.DropdownTrashActive />
              </IconTable>
            </TableCell>
          ) || <></>}
        </TableRow>
      </>
    )
  }

  const handleShowForm = () => {
    if (popShow) {
      return showForm
    } else {
      return props.saveTreatment
    }
  }

  return (
    <div className="w-100 h-100">
      <div className={'w-100'}>
        <div className="d-flex justify-content-between align-items-center pb-2">
          <Row className="mx-0 w-100">
            <Col className="col-auto px-1 flex-center">
              <Typography sx={{ fontSize: 18, fontWeight: 500, flex: 'none' }}>{t('LAB_ORDER.HEADER')}</Typography>
            </Col>
            {props.type !== 'HistoryTreatment' && (
              <Col className="col-auto px-1 ml-auto">
                <ButtonCustom mode="add" btnStyle={{ height: 42 }} textButton={t('LAB_ORDER.CREATE')} onClick={() => formAdd()} />
              </Col>
            ) || <></>}
          </Row>
        </div>
        <div className="px-1">
          <ResponsiveTable className="mt-2">
            <TableCustom
              page={page}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              setPage={setPage}
              sortType={'ASC'}
              onSort={() => { return }}
              sortBy={''}
              textEmptyData={t('LAB_ORDER.EMPTYDATA')}
              rowCount={rowCount}
              headCells={headCells}
              rowsData={labOrders.map((val, i) => renderData(val, i))}
            />
          </ResponsiveTable>
          <ViewLabOrder
            labOrderId={labOrderId}
            showPopup={isViewDetail}
            onClose={() => {
              setLabOrderId(0)
              setIsViewDetail(false)
            }} />
          {handleShowForm() && <FormLabOrder
            showForm={showForm}
            labOrderId={labOrderId}
            patientId={patientId}
            patientCaseId={patientCaseId}
            patient={props.patient}
            onReset={() => {
              setShowForm(false)
              setLabOrderId(0)
              if (props.setSaveTreatment) props.setSaveTreatment(false)
            }}
            handleSuccess={loadLabOrderList} />}
        </div>
      </div>
    </div>
  )
}
