import { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Box, Typography, Tabs, MenuItem } from '@mui/material';

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import BasicSelect from 'component/Select/BasicSelect'
import FormUploadTreatment from 'component/Register/FormUploadTreatment'
import ButtonCustom from 'component/Button/ButtonCustom'

/** CONSTANTS */
import { Icons, onImgError } from 'constants/images'
import { colors } from 'constants/theme'

/** API */
import { fileUrl } from 'api/api'
import UploadTreatmentApi from 'api/master/uploadTreatment.api';

/** UTILS */
import { toBuddhistYear } from 'utils/app.utils';

/** STYLE */
import * as UseStyled from 'features/treatments/MainMenu/Image/UseStyled'
import { useLocation } from 'react-router-dom';

type IndexImageType = {
  patient?: any
}

type VisitType = {
  patientCaseId: number
  branchId: number
  vnNumber: string | number
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  previewImage?: boolean
}

type ComponentGalleryProps = {
  type?: number
  vnNumber?: string | number
  gallery?: any
  loading?: boolean
  onPreviewImage?: (image: any) => void
}

enum TreatmentType {
  'ALL',
  'VN_NUMBER',
  'NOT_VN_NUMBER'
}

export default function IndexImage(props: IndexImageType) {
  const { t } = useTranslation()
  const location = useLocation();

  const [softLoading, setSoftLoading] = useState(false)
  const [patient, setPatient] = useState<string>('')
  const [visits, setVisits] = useState([])
  const [treatmentTypes, setTreatmentTypes] = useState([])
  const [treatmentActive, setTreatmentActive] = useState<string | number>(TreatmentType.ALL)
  const [typeActive, setTypeActive] = useState(0)
  const [treatmentImages, setTreatmentImages] = useState([])
  const [treatmentImage, setTreatmentImage] = useState([])
  const [uploadTreatment, setUploadTreatment] = useState(false)
  const [previewImage, setPreviewImage] = useState({})

  const [showManageImg, setShowManageImg] = useState<boolean>(true)

  useEffect(() => {
    if (location?.pathname.includes('history')) {
      setShowManageImg(false)
    }
  }, [location]);

  const loadVisit = async (patientId: number) => {
    const resVisit = await UploadTreatmentApi.findTreatmentHistory(patientId)
    if (resVisit.status === 200 && !_.isEmpty(resVisit.data)) setVisits(resVisit.data)
    else setVisits([])
  }

  const loadTreatmentType = async () => {
    const resTreatmentType = await UploadTreatmentApi.findAllImageTypes()
    if (resTreatmentType.status === 200 && !_.isEmpty(resTreatmentType.data)) setTreatmentTypes(resTreatmentType.data)
    else setTreatmentTypes([])
  }

  const loadGallery = async () => {
    let condition: any = {}
    condition = { ...condition, condition: treatmentActive === TreatmentType.ALL || treatmentActive === TreatmentType.VN_NUMBER || treatmentActive === TreatmentType.NOT_VN_NUMBER ? TreatmentType[treatmentActive] : '--' }
    condition = { ...condition, vnNumber: condition.condition === '--' ? treatmentActive : '' }
    const resGallery = await UploadTreatmentApi.getTreatmentImage(props.patient.cnNumber, condition)
    if (resGallery.status === 200 && !_.isEmpty(resGallery.data)) setTreatmentImages(resGallery.data)
    else setTreatmentImages([])
  }

  const handleLoadGallery = () => {
    setSoftLoading(true)
    if (typeActive === 0) {
      const gallery: any = []
      _.map(treatmentImages, (img: any, index: number) => {
        _.map(img.treatmentImages, (image: any, indexImage: number) => {
          gallery.push(image)
        })
      })
      setTreatmentImage(gallery)
      setSoftLoading(false)
    } else {
      const gallery: any = []
      _.map(treatmentImages, (img: any, index: number) => {
        if (img.imageTypeId === typeActive) {
          _.map(img.treatmentImages, (image: any, indexImage: number) => {
            gallery.push(image)
          })
        }
      })
      setTreatmentImage(gallery)
      setSoftLoading(false)
    }
  }

  const handleChangeTreatment = (treatment: any) => {
    setTreatmentActive(treatment)
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTypeActive(newValue)
  }

  const handlePreviewImage = (image: any) => {
    setUploadTreatment(true)
    setPreviewImage(image)
  }

  useEffect(() => {
    setPatient(props.patient || '')
    loadTreatmentType()
    if (!_.isEmpty(props.patient)) loadVisit(props.patient?.patientId)
  }, [props.patient])

  useEffect(() => {
    if (!_.isEmpty(props.patient.cnNumber)) loadGallery()
  }, [props.patient.cnNumber, treatmentActive, typeActive])

  useEffect(() => {
    handleLoadGallery()
  }, [treatmentImages, treatmentActive, typeActive])

  return (
    <>
      <UseStyled.SectionImage className='pl-xl-2 w-100 d-flex flex-column flex-xl-row overflow-hidden'>
        <UseStyled.FragmentLeft className='w-100 d-xl-flex' sx={{ borderRight: { lg: `1px solid ${colors.lightGray}` } }}>
          <UseStyled.TreatmentList className={`w-100 overflow-hidden d-xl-flex`}>
            <UseStyled.TreatmentListItem className='d-xl-flex flex-column overflow-hidden'>
              <UseStyled.TypographyTitle className={'pb-2'}>
                {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST')} ({visits.length + 3})
              </UseStyled.TypographyTitle>
              <div className={'custom-scroll'} style={{ overflowY: 'auto' }}>
                <UseStyled.Item className={`${treatmentActive === TreatmentType.ALL ? 'active' : ''}`} onClick={() => handleChangeTreatment(TreatmentType.ALL)}>
                  <span className={'text-ellipsis'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL')}</span>
                </UseStyled.Item>
                <UseStyled.Item className={`${treatmentActive === TreatmentType.VN_NUMBER ? 'active' : ''}`} onClick={() => handleChangeTreatment(TreatmentType.VN_NUMBER)}>
                  <span className={'text-ellipsis'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL_VISIT')}</span>
                </UseStyled.Item>
                <UseStyled.Item className={`${treatmentActive === TreatmentType.NOT_VN_NUMBER ? 'active' : ''}`} onClick={() => handleChangeTreatment(TreatmentType.NOT_VN_NUMBER)}>
                  <span className={'text-ellipsis'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.NO_VISIT')}</span>
                </UseStyled.Item>
                {_.map(visits, (visit: VisitType, index: number) => {
                  return (
                    <UseStyled.Item key={visit.patientCaseId} className={`${treatmentActive === visit.vnNumber ? 'active' : ''}`} onClick={() => handleChangeTreatment(visit.vnNumber)}>
                      <span className={'text-ellipsis'}>VN {visit.vnNumber}</span>
                    </UseStyled.Item>
                  )
                })}
              </div>
            </UseStyled.TreatmentListItem>
            <UseStyled.TreatmentListSelect className='d-xl-none mb-2 mb-md-3'>
              <BasicSelect
                labelId="lb-select-treatment"
                selectId="sl-select-treatment"
                label=""
                onchange={(e: any) => handleChangeTreatment(e.target.value)}
                value={treatmentActive}
                options={[
                  <MenuItem key={TreatmentType.ALL} value={TreatmentType.ALL}>
                    {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL')}
                  </MenuItem>,
                  <MenuItem key={TreatmentType.VN_NUMBER} value={TreatmentType.VN_NUMBER}>
                    {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.ALL_VISIT')}
                  </MenuItem>,
                  <MenuItem key={TreatmentType.NOT_VN_NUMBER} value={TreatmentType.NOT_VN_NUMBER}>
                    {t('REGISTER.UPLOAD_TREATMENT.IMAGE_LIST_OPTION.NO_VISIT')}
                  </MenuItem>,
                  _.map(visits, (visit: VisitType, index: number) => {
                    return (
                      <MenuItem key={visit.patientCaseId} value={visit.vnNumber}>
                        {visit.vnNumber}
                      </MenuItem>
                    )
                  })
                ]}
              />
            </UseStyled.TreatmentListSelect>
          </UseStyled.TreatmentList>
        </UseStyled.FragmentLeft>
        <UseStyled.FragmentRight className='w-100 d-flex flex-column overflow-hidden'>
          <UseStyled.TreatmentType className={'overflow-hidden h-100'}>
            <Box className='d-flex align-items-center justify-content-between pb-1'>
              <UseStyled.TypographyTitle className={'text-nowrap mr-2'}>{t('REGISTER.UPLOAD_TREATMENT.IMAGE_TYPE')}</UseStyled.TypographyTitle>
              {showManageImg && <ButtonCustom variant="outlined" onClick={() => handlePreviewImage({})} textButton={t('TREATMENTS.BUTTON.MANAGE_TREATMENT_IMAGE')} style={{ margin: 0 }} btnStyle={{ height: 44, fontSize: 16 }} />}
            </Box>
            <UseStyled.TreatmentTypeTabs className={`custom-scroll`}>
              <Tabs classes={{ indicator: 'd-none' }} value={typeActive} onChange={handleChangeTab}>
                <UseStyled.ButtonType
                  key={0}
                  value={0}
                  label={
                    <span className={'text-ellipsis'} style={{ maxWidth: '96%' }}>
                      {t('REGISTER.UPLOAD_TREATMENT.ALL_TYPE')}
                    </span>
                  }
                  {...a11yProps(0)}
                />
                {_.map(treatmentTypes, (type: any, index: number) => {
                  return (
                    <UseStyled.ButtonType
                      key={type.imageTypeId}
                      value={type.imageTypeId}
                      label={
                        <span className={'text-ellipsis'} style={{ maxWidth: '96%' }}>
                          {type.imageTypeName}
                        </span>
                      }
                      {...a11yProps(type.imageTypeId)}
                    />
                  )
                })}
              </Tabs>
            </UseStyled.TreatmentTypeTabs>

            <TabPanel key={0} value={typeActive} index={0}>
              <ComponentGallery
                type={0}
                vnNumber={treatmentActive}
                gallery={treatmentImage}
                loading={softLoading}
                onPreviewImage={(image: any) => handlePreviewImage(image)}
              />
            </TabPanel>
            {_.map(treatmentTypes, (panel: any, index: number) => {
              return (
                <TabPanel key={panel.imageTypeId} value={typeActive} index={panel.imageTypeId}>
                  <ComponentGallery
                    type={panel.imageTypeId}
                    vnNumber={treatmentActive}
                    gallery={treatmentImage}
                    loading={softLoading}
                    onPreviewImage={(image: any) => handlePreviewImage(image)}
                  />
                </TabPanel>
              )
            })}
          </UseStyled.TreatmentType>
        </UseStyled.FragmentRight>
      </UseStyled.SectionImage>
      {uploadTreatment &&
        <FormUploadTreatment
          isShow={uploadTreatment}
          setIsShow={(show: boolean) => {
            setUploadTreatment(show)
            setPreviewImage({})
          }}
          patient={patient}
          previewImage={previewImage}
          typeActive={typeActive}
          treatmentActive={treatmentActive}
          handleSubmit={(val: 'uploadSuccess' | '') => {
            if (val === 'uploadSuccess') loadGallery()
          }}
        />
      }
    </>
  )
}

function ComponentGallery(props: ComponentGalleryProps) {
  const { t } = useTranslation()
  const { gallery, loading } = props

  const renderImage = (img: any) => {
    return `${fileUrl}/${img.fullPath}`
  }

  return (
    <>
      {_.isEmpty(gallery) && (
        <UseStyled.EmptyItem className={'d-flex align-items-center justify-content-center flex-column'}>
          <Icons.Gallery />
          <Typography className={'mt-2'} sx={{ color: colors.black60 }}>
            {t('REGISTER.TITLE.EMPTY_GALLERY')}
          </Typography>
        </UseStyled.EmptyItem>
      )}

      <UseStyled.RowPanel className={`${loading ? 'd-none' : 'd-flex'} align-items-center flex-wrap`}>
        {_.map(gallery, (i: any, index: number) => {
          return (
            <UseStyled.ColumnPanel
              onClick={() => {
                if (props.onPreviewImage) props.onPreviewImage(i)
              }}>
              <UseStyled.CardImg>
                <UseStyled.DivImg>
                  <img src={renderImage(i)} alt={i.fileName} onError={onImgError} />
                </UseStyled.DivImg>
              </UseStyled.CardImg>
              <UseStyled.CardTime className='time-label'>
                <Typography>{i.createdAt ? `${toBuddhistYear(moment(i.createdAt), 'DD/MM/YYYY HH:mm')} ${t('TIME_UNIT')}` : ''}</Typography>
              </UseStyled.CardTime>
            </UseStyled.ColumnPanel>
          )
        })}
      </UseStyled.RowPanel>
    </>
  )
}

function a11yProps(index: number) {
  return {
    id: `type-tab-${index}`,
    'aria-controls': `tabpanel-type-${index}`
  }
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      className={'image-panel custom-scroll p-0 mt-2 mt-md-3 mb-0 mx-0'}
      style={{ minHeight: props.previewImage ? 'unset' : 200, overflowY: 'auto', height: props.previewImage ? '30%' : '100%', maxHeight: props.previewImage ? 'unset' : '100%' }}
      hidden={value !== index}
      id={`tabpanel-type-${index}`}
      aria-labelledby={`type-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0, margin: '0 -0.25rem', width: '100%', height: '100%' }}>{children}</Box>}
    </div>
  )
}
