import { useState, useCallback, useEffect } from 'react'
import { Card, Col } from 'react-bootstrap'
import { Grid, MenuItem } from '@mui/material'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'

/** API */
// import ManageDoctorFeeApi, { FindManageDoctorAllInterface, DoctorFeeInterface } from 'api/counter/manage-doctor-fee.api'
import ManageDoctorLabFeeApi, { FindManageDoctorLabFeeAllInterface, LabOrderFeeInterface } from 'api/counter/manage-doctor-lab-fee.api'
/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** UTILS || SLICE */
import { isCreate, isDelete, isUpdate, isView, isExport } from 'utils/verification.utils'
import { numberFormat, toBuddhistYear } from 'utils/app.utils'
import { resetModal, showModal } from 'app/slice/modal.slice'

/** COMPONENT */
import HeaderCard from 'component/CardCustom/HeaderCard'
import InputTextSearch from 'component/Input/InputTextSearch'
import InputSecondNewRangePicker, { emptyRangePicker } from 'component/Input/InputSecondNewRangePicker'
import TableCustom from 'component/Table/TableCustom'
import TableRowCommon from 'component/Table/TableRowCommon'
import Loading from 'component/Loading'

/** STYLE */
import * as UseStyled from 'features/counter/recall/UseStyled'
import FilterSelect from 'component/Select/FilterSelect'
import ButtonCustom from 'component/Button/ButtonCustom'
import ManageDoctorLabFee from './from-doctor-lad-fee'

const permissions = {
    isCreate: isCreate(),
    isDelete: isDelete(),
    isUpdate: isUpdate(),
    isView: isView(),
    isExport: isExport()
}
const arrayLabOrderStatus: string[] = ['ORDER_LAB', 'SEND_LAB', 'RECEIVE_LAB', 'RECEIVE_BILL', 'REPLY_LAB', 'CANCEL_LAB', 'CLOSE_LAB']


export default function ManageDoctorFee() {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [labDoctorFees, setLabDoctorFees] = useState<LabOrderFeeInterface[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [dataModal, setDataModal] = useState<{ isShow: boolean, data: any }>({ isShow: false, data: {} })


    /** pagination */
    const [page, setPage] = useState<number>(1)
    const [pageLimit, setPageLimit] = useState<number>(10)
    const [rowCount, setRowCount] = useState<number>(0)
    const [status, setStatus] = useState<string>('ALL')
    const [search, setSearch] = useState<string>('')
    const [rangeDate, setRangeDate] = useState<string[]>([moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])

    const loadDoctorFees = useCallback(() => {
        setLoading(true)
        let condition: FindManageDoctorLabFeeAllInterface = {}
        if (page) condition = { ...condition, page: page }
        if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
        if (status) condition = { ...condition, status: status }
        if (search) condition = { ...condition, search: search }

        if ((rangeDate[0] !== null && rangeDate[0] !== '') && (rangeDate[1] !== null && rangeDate[1] !== '')) {
            condition = { ...condition, dateStart: rangeDate[0], dateEnd: rangeDate[1] }
        }
        ManageDoctorLabFeeApi.findAll(condition)
            .then(({ data, headers }) => {
                setLabDoctorFees(data)
                setRowCount(Number(headers['x-total']))
            })
            .catch(() => setLabDoctorFees([]))
            .finally(() => setLoading(false))
    }, [search, status, page, pageLimit, rangeDate])

    useEffect(() => {
        loadDoctorFees()
    }, [])


    const headCells = [
        { id: 'id', disablePadding: false, label: t('#'), align: 'center', width: '70px' },
        { id: 'date', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.DATE_ORDER'), width: '100px' },
        { id: 'doctorFullname', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.DOCTOR'), width: '200px' },
        { id: 'branchCnNumber', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.PATIENT_CNCODE'), width: '150px' },
        { id: 'patientsFullname', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.PATIENT_NAME'), width: '200px' },
        { id: 'labAndTx', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.LAB_TX'), width: '200px' },
        { id: 'labFee', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.LABFEE'), width: '100px' },
        { id: 'perDf', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.PER_DF'), width: '100px' },
        { id: 'DFLabFee', disablePadding: false, label: t('MANAGE_DOCTOR_LAB_FEE.TABLE.CELLS.DFLABFEE'), width: '100px' },
        { id: 'action', disablePadding: false, label: t('Action'), width: '100px' }
    ]
    const onEdit = (objData: any) => {
        setDataModal({ isShow: true, data: objData })
        dispatch(showModal())
    }

    const renderData = (objData: LabOrderFeeInterface, no: number, exportDataValue: boolean) => {
        const {

            labOrderId,
            dateOrder,
            doctorFullname,
            cnNumber,
            patientsFullname,
            listOfProcedures,
            labOrderNumber,
            labOrderPrice,
            labDoctorAmount,
            dfNet,
        } = objData


        no = page * pageLimit - pageLimit + no + 1

        const objRenderData = {
            key: String(Math.random()),
            id: String(labOrderId),
            obj: objData,
            columns: [
                { option: 'TEXT', align: 'center', label: no, class: 'px-1' },
                // { option: 'TEXT', align: 'left', label: branchCode || '-' },
                // { option: 'TEXT', align: 'left', label: labOrderId },
                { option: 'TEXT', align: 'left', label: toBuddhistYear(moment(dateOrder)) },
                { option: 'TEXT', align: 'left', label: doctorFullname },
                { option: 'TEXT', align: 'left', label: cnNumber },
                { option: 'TEXT', align: 'left', label: patientsFullname },
                { option: 'TEXT', align: 'left', label: listOfProcedures },
                { option: 'TEXT', align: 'left', label: numberFormat(labOrderPrice) },
                { option: 'TEXT', align: 'left', label: labDoctorAmount + "%" },
                { option: 'TEXT', align: 'left', label: numberFormat(dfNet) },
                {
                    option: 'ACTION',
                    align: 'center',
                    label: 'action',
                    values: [
                        { option: 'EDIT', label: t(`BUTTON.EDIT`), disabled: permissions.isUpdate.disabled },
                    ]
                }
            ]
        }

        return (
            <TableRowCommon {...objRenderData}
                onedit={() => onEdit(objData)}
            />
        )
    }

    const onRequestSort = () => {
        setPage(1)
    }

    const handleChangeRowsPerPage = (limit: number) => {
        setPageLimit(limit)
        setPage(1)
    }

    const handleChangePage = (val: number) => {
        setPage(val)
    }
    const getLabelLabOrderStatus = (status: string) => {
        let label: any = ''
        if (status === 'ORDER_LAB') label = t('LAB_ORDER.LAB_STATUS.ORDER_LAB')
        else if (status === 'SEND_LAB') label = t('LAB_ORDER.LAB_STATUS.SEND_LAB')
        else if (status === 'RECEIVE_LAB') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_LAB')
        else if (status === 'RECEIVE_BILL') label = t('LAB_ORDER.LAB_STATUS.RECEIVE_BILL')
        else if (status === 'REPLY_LAB') label = t('LAB_ORDER.LAB_STATUS.REPLY_LAB')
        else if (status === 'CANCEL_LAB') label = t('LAB_ORDER.LAB_STATUS.CANCEL_LAB')
        else if (status === 'CLOSE_LAB') label = t('LAB_ORDER.LAB_STATUS.CLOSE_LAB')
        return label
    }

    return (
        <div className='h-100'>
            <Card className={'border-0 h-100 overflow-auto'}>
                <HeaderCard text={t('MANAGE_DOCTOR_LAB_FEE.HEADER')} />
                <Card.Body>
                    <Card.Title className={'mb-0 pl-xl-3'}>
                        <UseStyled.RowFilter>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={3} lg={3} xl={3} className='w-100'>
                                    <InputTextSearch
                                        keyInput={'search'}
                                        label={t('INCOME_EXPENSES.INPUT.MAIN_SEARCH')}
                                        value={search}
                                        onchange={(event) => {
                                            setSearch(event.target.value)
                                            setPage(1)
                                        }}
                                        onClear={() => {
                                            setPage(1)
                                            setSearch('')
                                        }}
                                    />

                                </Grid>
                                {/* <Grid item xs={6} md={3} lg={3} xl={3} className='w-100'>
                                    <FilterSelect
                                        fullWidth-
                                        renderValue={() => `${t('MANAGE_DOCTOR_LAB_FEE.FILTER.STATUS')}: ${getLabelLabOrderStatus(_.find(arrayLabOrderStatus, (statusLab: any) => statusLab === status) || '') || t('ALL')}`}
                                        label={t('')}
                                        selectId="select-status"
                                        value={status}
                                        onchange={(event) => {
                                            setStatus(event.target.value)
                                            setPage(1)
                                        }}
                                        labelId="label-status"
                                        options={[
                                            <MenuItem key={'all'} value={0}>
                                                {t('ALL')}
                                            </MenuItem>,
                                            _.map(arrayLabOrderStatus, (statusLab: any, i: number) => (
                                                <MenuItem key={i} value={statusLab}>
                                                    {getLabelLabOrderStatus(statusLab)}
                                                </MenuItem>
                                            ))
                                        ]}
                                        formControlStyle={{ maxWidth: 'unset !important' }}
                                    />
                                </Grid> */}
                                <Grid item xs={6} md={3} lg={3} xl={3} className='w-100'>
                                    <InputSecondNewRangePicker
                                        inputHeight={32}
                                        value={rangeDate || []}
                                        onchange={(val) => {
                                            setPage(1)
                                            if (_.isEmpty(val)) setRangeDate(emptyRangePicker)
                                            else setRangeDate([moment(val[0]).format('YYYY-MM-DD'), moment(val[1]).format('YYYY-MM-DD')])
                                        }}
                                        allowClear
                                        onClear={() => {
                                            setPage(1)
                                            setRangeDate(emptyRangePicker)
                                        }}
                                    />
                                </Grid>
                                <Col xs={6} md={3} lg={3} xl={3} className="px-1 mb-2">
                                    <ButtonCustom style={{ height: '30px', marginTop: '4px' }} onClick={loadDoctorFees} textButton={t('INPUT.SEARCH')} />
                                </Col>
                            </Grid>
                        </UseStyled.RowFilter>
                        <UseStyled.ResponsiveTable className='mt-3'>
                            <TableCustom
                                tableFixedWidth
                                tableMinWidth={2500}
                                page={page}
                                pageLimit={pageLimit}
                                sortType={'ASC'}
                                sortBy={'updatedAt'}
                                rowCount={rowCount}
                                onSort={onRequestSort}
                                setPageLimit={handleChangeRowsPerPage}
                                setPage={handleChangePage}
                                headCells={headCells}
                                rowsData={_.map(labDoctorFees, renderData)}
                            />
                        </UseStyled.ResponsiveTable>
                    </Card.Title>
                </Card.Body>

                {dataModal.isShow && <ManageDoctorLabFee type='CLINIC_FEE' data={dataModal.data} onReset={() => {
                    setDataModal({
                        isShow: false,
                        data: []
                    })
                    dispatch(resetModal())
                    loadDoctorFees()
                }} />}

            </Card>

            <Loading show={loading} type={'fullLoading'} />

        </div>
    )
}
