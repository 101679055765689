import { useEffect, useState } from 'react'
import _ from 'lodash'
import { styled } from '@mui/material/styles'

/** UTILS */
import UseScroll from 'utils/useScroll'
import { colors } from 'constants/theme'

/** INTERFACE & COMPONANT */
import { AppointmentCardItemDayInterface, AppointmentDisplayDayInterface, AppointmentDisplayWeekInterface } from 'features/counter/appointment/appointment-interface'
import SectionAppointment from 'component/Appointment/GraphAppointment'
import CardAppointment from 'component/Appointment/CardAppointment'
import CardAppointmentDetail from 'component/Appointment/CardAppointmentDetail'
import SectionFilterDoctor, { DataPropsType } from 'features/counter/appointment/dentist-filter'

/** ICONS */
import IconButton from '@mui/material/IconButton'
import ArrowForward from '@mui/icons-material/ChevronRight'
import ArrowBack from '@mui/icons-material/ChevronLeft'
import { getNameMonthByNo, getNameDateOfWeekByShort } from 'constants/date-time'
import { fileUrl } from 'api/api'
import { ScrollSync, ScrollSyncNode } from 'component/syncScroll'
import CardBreak from 'component/Appointment/CardBreak'
import moment from 'moment'
import { getBranch, isBuddhistYear } from 'utils/app.utils'
import AboutUs from 'api/admin/clinic-manage/about.api'
import { a } from 'component/Register/style/styleTreatmentHistory'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useFormatSizeAppointment } from './appointment'
// import { sizeSectionAppointment } from './appointment'

const DivDisplayWeek = styled('div')<{ listAppointment: AppointmentDisplayWeekInterface[], width: number }>(({ theme, listAppointment, width }) => ({
  '& .service-slider-container': {
    overflow: 'auto',
    maxHeight: `100%`
  },
  '& .service-slider-wrap': {
    minWidth: `calc(${listAppointment.length * width}px + 4.05rem)`
  },
  '& .section-head-doctor': {
    zIndex: 300,
    paddingTop: '1rem',
    // paddingLeft: '3.05rem'
  },
  '& .section-body-appointment': {
    // paddingLeft: '3rem',
    width: `calc(${listAppointment.length * width}px + 4.05rem)`,
    overflow: 'auto',
    '.layout-schedule': {
      overflow: 'hidden'
    },
  },
  '& .div-time': {
    width: 48,
    display: 'inline-block',
    position: 'sticky',
    marginTop: 88,
    // overflow: 'auto',
    maxHeight: '100%'
  },
  '& .graph-appointment': {
    width: `${width}px`,
    display: 'inline-block',
    // margin: '0 0.05rem 0 0.05rem'
  }
}))

const DivSectionFilterDoctor = styled('div')<{ width: number }>(({ theme, width }) => ({
  display: 'inline-block',
  width: `${width}px`
}))

const AreaArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  marginTop: 56,
  width: 88,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '&.arrow-next': {
    right: 0
  },
  '&:hover .MuiIconButton-root': {
    background: `${colors.disabledGray} !important`
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
}))

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: colors.lightGray,
  border: `1px solid ${colors.lightGray}`,
  color: colors.white,
  padding: 0,
  width: 34,
  height: 34,
  transition: 'opacity .3s ease',
  '& svg': { fontSize: 26 }
}))

export interface DisplayWeekProps {
  branchId: number
  branch: string
  listAppointment: AppointmentDisplayWeekInterface[]
  timeRange: any
  onEdit?: (id: number) => void
  clinicDayClose: string[]
  onLoading?: (event: boolean) => void
  onEditSpecialInfo?: (id: number) => void
  doctorWeek?: any
}
export type MarkDoctorDayType = {
  SUN: number
  MON: number
  TUE: number
  WED: number
  THU: number
  FRI: number
  SAT: number
}

export default function DisplayWeek(props: DisplayWeekProps) {
  const { t, i18n } = useTranslation()
  const [branch, setBranch] = useState<any>()
  // useFormatSizeAppointment();
  const sizeSectionAppointment = 500

  useEffect(() => {
    setBranch(props.branch)
  }, [props.branch])


  const { hideArrow, scrollBehavior, onPressArrow } = UseScroll('.service-slider-container', '.service-slider-wrap', sizeSectionAppointment)

  const [viewDetails, setViewDetails] = useState([])
  const [markDoctor, setMarkDoctor] = useState<number>(0)
  const [markDoctorDay, setMarkDoctorDay] = useState<MarkDoctorDayType>({ SUN: 0, MON: 0, TUE: 0, WED: 0, THU: 0, FRI: 0, SAT: 0 })

  const handleViewDetail = (val: any) => {
    const temp: any = []
    if (!temp.find((t: any) => t === val)) temp.push(val)
    setViewDetails(temp)
  }

  const handleCloseDetail = (val: any) => {
    const temp: any = [...viewDetails].filter((t: any) => t !== val)
    setViewDetails(temp)
  }

  useEffect(() => {
    const arr: any = []
    for (const key in markDoctorDay) {
      const element: any = _.get(markDoctorDay, key)
      arr.push(element)
    }
    const unique = _.filter(arr, (value, index, self) => {
      return self.indexOf(value) === index
    })
    if (unique.length === 1) setMarkDoctor(unique[0])
    else setMarkDoctor(0)
  }, [markDoctorDay])

  const getDoctorActive = (id: number, dataDoctor: any, dateOfWeek: any) => setMarkDoctorDay({ ...markDoctorDay, [dateOfWeek]: id })

  const handleMarkDoctor = (id: number) => {
    const tempMarkDoctorDay: any = { ...markDoctorDay }
    Object.keys(tempMarkDoctorDay).forEach((v) => (tempMarkDoctorDay[v] = id))
    setTimeout(() => setMarkDoctorDay(tempMarkDoctorDay), 500)
  }

  const renderHead = (la: AppointmentDisplayWeekInterface) => {
    if (la.doctors.length > 0 && _.get(markDoctorDay, la.dateOfWeek) === 0) {
      const doctorWeek = props?.doctorWeek || {}
      const doctorId = doctorWeek?.date === la.date ? doctorWeek.doctorId : la.doctors[0].doctorId
      getDoctorActive(doctorId, la.doctors, la.dateOfWeek)
    }
    const dataRender: DataPropsType[] =
      la.doctors.map((doctor: AppointmentDisplayDayInterface) => {
        const tm: any = doctor.doctorSchedule.split(' - ')
        return {
          doctorId: doctor.doctorId,
          doctorFullName: doctor.doctorFullname || 'Doctor1 Cliniter',
          servicePointName: doctor.servicePointName,
          startWork: tm[0] || '9:00',
          getOffWork: tm[1] || '18:00',
          avatar: `${fileUrl}/${doctor.doctorProfile}` || '',
          status: doctor.statusDoctorSchedule || ''
        }
      }) || []
    const headText = `${t('APPOINTMENT.HEADER.DATE_WEEK_AT', { week: getNameDateOfWeekByShort(la.dateOfWeek, i18n.language) })} ${parseInt(la.date.split('-')[2])} ${getNameMonthByNo(parseInt(la.date.split('-')[1]), i18n.language)} ${parseInt(la.date.split('-')[0]) + isBuddhistYear()}`
    return (
      <SectionFilterDoctor
        headText={headText}
        data={dataRender}
        selectDoctor={dataRender.find((dr: DataPropsType) => dr.doctorId === _.get(markDoctorDay, la.dateOfWeek))}
        onClick={(id) => getDoctorActive(id, la.doctors, la.dateOfWeek)}
        markDoctor={markDoctor}
        onMarkDoctor={handleMarkDoctor}
        clinicIsClose={_.includes(props.clinicDayClose, la.dateOfWeek)}
      />
    )
  }

  const scrollTabDoctor = (e: any) => {
    e.preventDefault();
    const tabDoctor: any = document.getElementById("service-slider-container");
    document.getElementById("layout-schedule")?.scrollTo(0, tabDoctor?.scrollTop)
    document.getElementById("service-slider-container")?.scrollTo(tabDoctor?.scrollLeft, tabDoctor?.scrollTop)
  }

  return (
    <DivDisplayWeek listAppointment={props.listAppointment} width={sizeSectionAppointment} className="display-weeks position-relative h-100">
      <AreaArrow className={`arrow-prev ${hideArrow === 'prev' || hideArrow === 'both' ? 'd-none' : ''}`} onClick={() => onPressArrow('onPrev')}>
        <CustomIconButton>
          <ArrowBack />
        </CustomIconButton>
      </AreaArrow>
      <AreaArrow className={`arrow-next ${hideArrow === 'next' || hideArrow === 'both' ? 'd-none' : ''}`} onClick={() => onPressArrow('onNext')}>
        <CustomIconButton>
          <ArrowForward />
        </CustomIconButton>
      </AreaArrow>
      <ScrollSync proportional={false}>
        <Box style={{ display: 'flex', position: 'relative', height: '100%' }}
          sx={{
            "#layout-schedule::-webkit-scrollbar": {
              width: '0px',
              height: '0px',
            },
            '.div-time': {
              marginBottom: '28px'
            },
            '.layout-schedule': {
              overflowY: 'hidden !important'
            }
          }}>
          <ScrollSyncNode group="week" onScroll={scrollTabDoctor}>
            <div id='div-time' className={'div-time none-scroll'} style={{ paddingTop: '28px' }}>
              {props.listAppointment.length > 0 && (
                <SectionAppointment enableTimeTooltip branchId={props.branchId} timeRange={props.timeRange} isShowTime={true} showMinute />
              )}
            </div>
          </ScrollSyncNode>
          <ScrollSyncNode group="week" onScroll={scrollTabDoctor}>
            {/* style={{ scrollBehavior: scrollBehavior }} */}
            <div id="service-slider-container" className={'service-slider-container custom-scroll'} >
              <div className="service-slider-wrap" style={{}}>
                <Box
                  className="section-head-doctor sticky-top bg-white"
                  sx={{
                    '.btn-filterDoctor': {
                      width: '100%'
                    },
                    '.doctor-bg:nth-child(even)': {
                      background: colors.appointmentDoctor.colorEven,
                    },
                    '.doctor-bg:nth-child(odd)': {
                      background: colors.appointmentDoctor.colorOdd,
                    }
                  }}
                >
                  {props.listAppointment.map((la: AppointmentDisplayWeekInterface, i: number) => (
                    <DivSectionFilterDoctor key={i} width={sizeSectionAppointment} className='doctor-bg'>{renderHead(la)}</DivSectionFilterDoctor>
                  ))}
                </Box>
                <div className="section-body-appointment" style={{}}>

                  {props.listAppointment.map((la: AppointmentDisplayWeekInterface, i: number) => {
                    let countAp = 0
                    let checkTimeStart = '';
                    return (
                      <div key={i} className={'graph-appointment'} style={{}}>
                        <SectionAppointment
                          enableTimeTooltip
                          branchId={props.branchId}
                          width={sizeSectionAppointment}
                          isShowTime={false}
                          showMinute
                          timeRange={props.timeRange}
                          hideDot={i > 0}
                          card={la.doctors
                            .find((d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, la.dateOfWeek))
                            ?.appointments?.map((ap: AppointmentCardItemDayInterface, j: number) => {
                              const appointments = la.doctors.find((d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, la.dateOfWeek))?.appointments
                              const count = _.filter(appointments, (list: AppointmentCardItemDayInterface, iList: number) => j + 1 > iList && list.doctorId === ap.doctorId && list.start === ap.start).length
                              const allRow = _.filter(appointments, (list: AppointmentCardItemDayInterface) => list.doctorId === ap.doctorId && list.start === ap.start).length

                              const handlePatientCode = () => {
                                if (branch?.patientCodeAppointment === '1') {
                                  return ap.cnNumber
                                }
                                if (branch?.patientCodeAppointment === '0') {
                                  return ap.branchCnNumber
                                }
                                if (branch?.patientCodeAppointment === '2') {
                                  return ap.snCode
                                }
                              }

                              if (checkTimeStart !== ap.start) {
                                checkTimeStart = ap.start
                                countAp = 0
                              } else {
                                countAp = countAp + 1
                              } return (
                                <CardAppointment
                                  key={j}
                                  appointmentId={ap.appointmentId}
                                  status={ap.statusAppointment}
                                  branchCnNumber={handlePatientCode() ? handlePatientCode() : ''}
                                  colorBackground={ap.color}
                                  serviceText={ap.serviceText}
                                  patientFullName={ap.patientFullname}
                                  rangeTimeText={ap.rangeTimeText}
                                  start={ap.start || ap.rangeTimeText.split(' - ')[0].replace('.', ':')}
                                  end={ap.end || ap.rangeTimeText.split(' - ')[1].replace('.', ':')}
                                  hasLab={ap.hasLab === 'HAS'}
                                  hasXray={ap.hasXray === 'HAS'}
                                  onViewDetail={handleViewDetail}
                                  timeStart={props.timeRange[0]}
                                  width={(sizeSectionAppointment / allRow)}
                                  countThis={count}
                                  countThisTime={allRow}
                                  translateX={(sizeSectionAppointment / allRow) * countAp}
                                  specialInfo={ap?.specialInfo}
                                  phone={ap?.phone}
                                  allRow={allRow >= 4}
                                />
                              )
                            })}
                          popup={la.doctors
                            .find((d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, la.dateOfWeek))
                            ?.appointments
                            ?.map((ap: AppointmentCardItemDayInterface, j: number) => (
                              <CardAppointmentDetail
                                key={j}
                                timeStart={props.timeRange[0]}
                                timeEnd={props.timeRange[props.timeRange.length - 1]}
                                appointmentId={ap.appointmentId}
                                showPopup={viewDetails.find((v) => v === ap.appointmentId) ? true : false}
                                onClose={handleCloseDetail}
                                onEdit={props?.onEdit ? props?.onEdit : undefined}
                                onEditSpecialInfo={props.onEditSpecialInfo ? props.onEditSpecialInfo : undefined}
                                onLoading={(event: boolean) => props?.onLoading ? props.onLoading(event) : undefined}
                                status={ap.statusAppointment}
                                branch={props.branch}
                                popAutoScroll
                              />
                            )) || <></>}
                          break={
                            <CardBreak
                              start={moment(la.doctors.find((d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, la.dateOfWeek))?.breakTimeStart, 'HH:mm').format('HH:mm')}
                              end={la.doctors.find((d: AppointmentDisplayDayInterface) => d.doctorId === _.get(markDoctorDay, la.dateOfWeek))?.breakTimeEnd}
                              timeStart={props.timeRange[0]}
                              width={sizeSectionAppointment}
                              translateX={sizeSectionAppointment}
                            />
                          }
                        />
                      </div>
                    )
                  }
                  )}
                </div>
              </div>
            </div>
          </ScrollSyncNode>
        </Box>
      </ScrollSync>

    </DivDisplayWeek>
  )
}
