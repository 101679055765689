import { useState, useEffect, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { Typography, Grid, Box, MenuItem } from '@mui/material'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

/** TRANSLATION */
import { useTranslation } from 'react-i18next'

/** COMPONENT */
import ButtonCustom from 'component/Button/ButtonCustom'
import BasicSelect from 'component/Select/BasicSelect'
import InputTextarea from 'component/Input/InputTextarea'
import Info from 'features/finance/payment/component/popupPayment/Info'
import { swalCustom } from 'component/Alert/Swal'


import PaymentChannels from 'features/finance/payment/component/popupPayment/PaymentChannels'
import SummaryReceipt from 'features/finance/payment/component/popupPayment/SummaryReceipt'
import { notiSuccess, notiError } from 'component/notifications/notifications'


/** API */
import PaymentApi from 'api/counter/payment/payment.api'
import RegisterApi from 'api/counter/register.api'
import ClinicApi from 'api/master/clinic.api'
import PaymentChannelsApi from 'api/setting/finances/payment-channels.api'
import { PaymentInterface } from 'api/counter/payment/payment-interface'
import AdvanceApi from 'api/finances/advance.api'

/** CONSTANTS */
import { colors } from 'constants/theme'

/** UTILS || SLICE */
import {
  orderPaymentChannels,
  resetStatePayment,
  setPaymentByKey,
  setSummeryByKey,
  setSummery,
  setSummerySubByKey,
  summery,
  summerySub,
  summerySubTreatmentRight,
  summeryTreatmentRight,
  valBankCredit,
  valBankDebit,
  valBankTransfer,
  valPayment,
  resetPaymentChannelByKey,
  setSummerySubTreatmentRight,
  setSelectChannel
} from 'app/slice/payment.slice'
import { treatment, TreatmentStateInterface } from 'app/slice/treatment.slice'
import { getBranch, numberFormat, numberToCurrencyFormat } from 'utils/app.utils'

/** STYLE */
import * as UseStyled from 'features/finance/payment/component/popupPayment/UseStyledFormPayment'
import { fileUrl } from 'api/api'
import Loading from 'component/Loading'
import OverdueApi from 'api/finances/overdue.api'
import { dateTimeToView } from 'utils/date.utils'
import TreatmentSummaryApi from 'api/dentists/treatment-summary.api'
import { useHistory } from 'react-router-dom'
import { routeName } from 'routes/routes-name'
import { cal_sum } from 'features/treatments/SummaryTreatment/SummaryTreatment'



type ModalCustomProps = {
  type: 'PAYMENT' | 'OVERDUE' | 'RETROSPECTIVE' | 'EDIT_PAYMENT'
  isShow: boolean | false
  patient: any
  detail?: any
  histories?: any[]
  orderPaymentId?: number
  initBalance?: any
  setIsShow: (show: boolean) => void
  onSuccess?: (val?: any) => void
  date?: any
}

export interface PatientFinanceInterface {
  advanceBalance: number
  outstandingBalance: number
  treatmentRights: any[]
}

export default function PopupPayment(props: ModalCustomProps) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [disabledButton, setDisabledButton] = useState<boolean>(false)
  const treatments: TreatmentStateInterface = useSelector(treatment)
  const objPayment: PaymentInterface = useSelector(valPayment)
  const valSummery = useSelector(summery)
  const valSummerySub = useSelector(summerySub)
  const valSummeryTreatmentRight = useSelector(summeryTreatmentRight)
  const valSummerySubTreatmentRight = useSelector(summerySubTreatmentRight)
  const bankCredit: any = useSelector(valBankCredit)
  const bankTransfer: any = useSelector(valBankTransfer)
  const bankDebit: any = useSelector(valBankDebit)
  const paymentChannelsSelected: any = useSelector(orderPaymentChannels)
  const branchId = getBranch()
  const [activePage, setActivePage] = useState(0)
  const [patientFinance, setPatientFinance] = useState<PatientFinanceInterface>({ advanceBalance: 0, outstandingBalance: 0, treatmentRights: [] })
  const [banks, setBanks] = useState<any[]>([])
  const [billPrefixes, setBillPrefixes] = useState([])
  const [defaultPaymentChannels, setDefaultPaymentChannels] = useState([])
  const [editAdvance, setEditAdvance] = useState<number>(props.detail?.orderPaymentChannelAdvance || 0)
  const [editTreatmentRight, setEditTreatmentRight] = useState<number>(props.detail?.orderPaymentChannelTreatmentRight || 0)
  const [editAmountReceipt, setEditAmountReceipt] = useState<number>(props.detail?.orderPaymentChannel || 0)
  const arrNoPc = [0, 5, 6, 1, 2, 3, 4, 7]
  const arrChanel = [0, 1, 2, 3, 4, 5, 6, 7]
  const [allItem, setAllItem] = useState<any>([])
  const [overdueCal, setOverdueCal] = useState<number>(0)
  const [treatmentRight, setTreatmentRight] = useState<boolean>(false)
  const [disabledNext, setDisabledNext] = useState<boolean>(false)

  const history = useHistory()

  const priceOutStanding = treatments?.outStandingPayment?.overdueTotal || 0

  useEffect(() => {
    setAllItem([])
    if (props.detail && props.type === "EDIT_PAYMENT") {
      _.map(props.detail.order?.orderTreatments, (item: any) => {
        allItem.push(item)
      })
      _.map(props.detail.order?.orderProducts, (item: any) => {
        allItem.push(item)
      })
    }
  }, [props.detail])

  const loadData = useCallback(() => {

    if (props.isShow) {
      setLoading(true)
      RegisterApi.findPatientFinanceByPatientId(props.patient.patientId || props.patient.order.patientId).then((resPatientFinance: any) => {
        if (resPatientFinance.status === 200) {
          const treatmentRights = resPatientFinance.data.patientTreatmentRights
          let num = 0;
          treatmentRights.map((e: any) => { num += e.balance })
          num === 0 ? setTreatmentRight(true) : setTreatmentRight(false)
          let advanceBalance = parseFloat(resPatientFinance.data.advanceBalance)
          if (props?.detail?.orderPaymentChannels) {
            for (const paymentChannel of props.detail.orderPaymentChannels) {
              if (paymentChannel.paymentChannelId === 5) {
                const treatmentRight = _.find(treatmentRights, { treatmentRightId: parseInt(paymentChannel.treatmentRightId) })
                const treatmentRightIndex = _.findIndex(treatmentRights, { treatmentRightId: parseInt(paymentChannel.treatmentRightId) })
                if (treatmentRight) {
                  if (treatmentRight.isDivide === '0') {
                    treatmentRight.balance = treatmentRight.balance + 1
                    treatmentRight.amountUsed = treatmentRight.amountUsed - 1
                  }
                  else {
                    treatmentRight.balance = treatmentRight.balance + paymentChannel.amountPaid
                    treatmentRight.amountUsed = treatmentRight.amountUsed - paymentChannel.amountPaid
                  }
                  treatmentRight.amountPaid = paymentChannel.amountPaid
                  treatmentRights[treatmentRightIndex] = treatmentRight
                }
              }
              if (paymentChannel.paymentChannelId === 6) {
                advanceBalance += parseFloat(paymentChannel.amountPaid)
              }
            }
          }
          setPatientFinance({ advanceBalance: advanceBalance, outstandingBalance: resPatientFinance.data.outstandingBalance, treatmentRights: treatmentRights })
        }
      })
      ClinicApi.findAllBanks(branchId).then((resBanks) => {
        if (resBanks.status === 200) setBanks(resBanks.data)
      })

      ClinicApi.allBillPrefix().then((resBillPrefixes) => {
        setBillPrefixes(resBillPrefixes.data)
        dispatch(setPaymentByKey({ key: 'prefix', value: resBillPrefixes?.data[0].prefix || '' }))
      })
      PaymentChannelsApi.findAll({}).then((resp) => setDefaultPaymentChannels(resp.data))

      dispatch(setSummery({}))
      let initBalance: any = 0
      if (!_.isEmpty(props?.initBalance)) {
        initBalance = Object.values(props?.initBalance).reduce((a: any, b: any) => Number(a || 0) + Number(b || 0))
        dispatch(setSummeryByKey({ key: 0, value: initBalance }))
      }
      const isEditPayment = props.type === 'EDIT_PAYMENT' ? true : false
      dispatch(setPaymentByKey({ key: 'totalPrice', value: isEditPayment ? props.detail.totalPrice : _.sumBy(treatments.summeryTreatments, 'total') - Number(initBalance) - priceOutStanding }))

      for (const keyPcId of arrNoPc) {
        let newPriceOutStanding = priceOutStanding
        for (const index in isEditPayment ? allItem : treatments.summeryTreatments) {

          if (Object.prototype.hasOwnProperty.call(isEditPayment ? allItem : treatments.summeryTreatments, index)) {
            const summaryTreatment = isEditPayment ? allItem[index] : treatments.summeryTreatments[index];
            let type = ''
            let id = null
            if (summaryTreatment.tempOrderTreatmentId || summaryTreatment.orderTreatmentId) id = summaryTreatment.tempOrderTreatmentId || summaryTreatment.orderTreatmentId
            else if (summaryTreatment.tempOrderProductId || summaryTreatment.orderProductId) id = summaryTreatment.tempOrderProductId || summaryTreatment.orderProductId
            else id = parseInt(index) + 1

            const isTreatment = summaryTreatment.tempOrderTreatmentId || summaryTreatment.orderTreatmentId || summaryTreatment.operativeId ? true : false
            if (props.type === 'RETROSPECTIVE') type = isTreatment ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
            else if (props.type === 'OVERDUE') type = isTreatment ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
            else if (props.type === 'EDIT_PAYMENT') type = isTreatment ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'
            else type = isTreatment ? 'TEMP_ORDER_TREATMENT' : 'TEMP_ORDER_PRODUCT'

            let pay = 0
            let payEdit = 0
            if ((props?.type === 'OVERDUE' || isEditPayment) && props?.histories) {
              pay = _.sum(
                _.chain(props.histories)
                  .map((his, id) => his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'] ? his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'][summaryTreatment?.orderTreatmentId || summaryTreatment?.orderProductId] : 0)
                  .value()
              ) || 0
              if (isEditPayment) {
                const newEdit = [props.histories[props.histories.length - 1]]
                payEdit = _.sum(
                  _.chain(newEdit)
                    .map((his, id) => his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'] ? his?.itemPaids[summaryTreatment?.orderTreatmentId ? 'ORDER_TREATMENT' : 'ORDER_PRODUCT'][summaryTreatment?.orderTreatmentId || summaryTreatment?.orderProductId] : 0)
                    .value()
                )
              }
            }
            const newPriceOut = summaryTreatment.total < newPriceOutStanding ? summaryTreatment.total : newPriceOutStanding
            const newTotal = (summaryTreatment.total - newPriceOutStanding) <= 0 ? 0 : summaryTreatment.total - newPriceOutStanding
            newPriceOutStanding = (summaryTreatment.total - newPriceOutStanding) < 0 ? newPriceOutStanding - summaryTreatment.total : 0
            dispatch(setSummerySubByKey({ key: keyPcId, keySub: index, value: { isCheck: false, value: 0, treatmentPrice: newTotal - (pay || 0) + (payEdit || 0), paidBalance: newTotal - (pay || 0), type: type, id: id, isDisabled: true, paymentOut: newPriceOut } }))

          }
        }

      }
      setLoading(false)
    }
  }, [props.isShow])

  useEffect(() => {
    loadData()
  }, [loadData, props.isShow])


  useEffect(() => {
    const amountReceipt = Object.keys(valSummery).reduce((sum, key) => sum + parseFloat(parseInt(key) !== 5 && parseInt(key) !== 6 ? valSummery[key] : 0), 0) || editAmountReceipt || 0
    if (Object.keys(valSummery).reduce((sum, key) => sum + parseFloat(parseInt(key) !== 5 && parseInt(key) !== 6 ? valSummery[key] : 0), 0)) setEditAmountReceipt(0)
    const totalPrice = objPayment.totalPrice
    const discountTreatmentRight = _.sumBy(valSummeryTreatmentRight[5], 'value') || 0
    // editTreatmentRight ||
    const advance = valSummery[6] || editAdvance || 0
    if (valSummery[6]) setEditAdvance(0)
    const subTotal = totalPrice - discountTreatmentRight - advance
    let amountChange = amountReceipt - subTotal
    let overdue = subTotal - amountReceipt
    setOverdueCal(overdue)
    if (amountChange < 0) amountChange = 0
    if (overdue < 0) overdue = 0

    dispatch(setPaymentByKey({ key: 'discountTreatmentRight', value: discountTreatmentRight }))
    dispatch(setPaymentByKey({ key: 'advance', value: advance }))
    dispatch(setPaymentByKey({ key: 'subTotal', value: subTotal }))
    dispatch(setPaymentByKey({ key: 'amountReceipt', value: amountReceipt }))
    dispatch(setPaymentByKey({ key: 'amountChange', value: amountChange }))
    dispatch(setPaymentByKey({ key: 'overdue', value: overdue }))
  }, [valSummery, valSummerySub, valSummeryTreatmentRight, valSummerySubTreatmentRight])


  const payment = async () => {
    if (_.every(paymentChannelsSelected, (v: any) => v === false)) return notiError(t('PAYMENT.FORM.PLS_SELECT_PAYMENT_CHANNEL'), undefined, undefined, () => {
      setDisabledButton(false)
      setDisabledNext(false)
    })
    else if (overdueCal === 0 && treatmentRight && paymentChannelsSelected[5] && props.type !== 'EDIT_PAYMENT') return notiError(t('PAYMENT.MESSAGE.SUCCESS.CHANGE_PAYMENT_METHOD'), undefined, undefined, () => {
      setDisabledButton(false)
      setDisabledNext(false)
    })
    else if (overdueCal + priceOutStanding > 0 && props.type !== 'EDIT_PAYMENT') {
      const optionButton = {
        confirmButton: {
          confirmButtonText: t('PAYMENT.FORM_PAYMENT.OVERDUE_CONFIRM.YES')
        },
        cancelButton: {
          cancelButtonText: t('PAYMENT.FORM_PAYMENT.OVERDUE_CONFIRM.NO'),
          showCancelButton: true
        },
      }
      swalCustom(
        t('PAYMENT.FORM_PAYMENT.OVERDUE_CONFIRM.TITLE'),
        t('PAYMENT.FORM_PAYMENT.OVERDUE_CONFIRM.CAPTION'), (res: any) => {
          if (res) {
            setDisabledButton(true)
            setDisabledNext(true)
            if (_.every(paymentChannelsSelected, (v: any) => v === false)) return notiError(t('PAYMENT.FORM.PLS_SELECT_PAYMENT_CHANNEL'), undefined, undefined, () => {
              setDisabledButton(false)
              setDisabledNext(false)
            })
            else finishPayment()
          } else {
            setDisabledButton(false)
            setDisabledNext(false)
          }
        },
        'question',
        optionButton
      )
    } else finishPayment()
  }

  const alertTreantRights = async () => {

    // if (overdueCal > 0 && paymentChannelsSelected[5]) {
    //   return notiError(t('PAYMENT.MESSAGE.SUCCESS.CHANGE_PAYMENT_METHOD'), undefined, undefined, () => {
    //     setDisabledButton(false)
    //     setDisabledNext(false)
    //   })
    // } else 
    if (_.every(paymentChannelsSelected, (v: any) => v === false)) return notiError(t('PAYMENT.FORM.PLS_SELECT_PAYMENT_CHANNEL'), undefined, undefined, () => {
      setDisabledButton(false)
      setDisabledNext(false)
    })
    else setActivePage(1)
  }

  const finishPayment = async () => {
    const newOrderPaymentChannels: any = []
    const objPay = {
      prefix: objPayment.prefix,
      totalPrice: (objPayment.totalPrice + priceOutStanding),
      amountPaid: objPayment.amountPaid,
      amountReceipt: objPayment.amountReceipt,
      amountChange: objPayment.amountChange,
      overdue: (objPayment.overdue + priceOutStanding),
      remark: objPayment.remark,
      orderPaymentChannels: newOrderPaymentChannels
    }

    if (props.type === 'EDIT_PAYMENT' ? !allItem : !treatments.summeryTreatments.length) return notiError(t('TREATMENT_SUMMARY.MESSAGE.PLS_SELECT_TREATMENT'), undefined, undefined, () => { setDisabledButton(false) })
    // if (props.type === 'EDIT_PAYMENT' && summaryValue() !== summaryOriginalValue()) return notiError(`${t('PAYMENT.MESSAGE.ERROR.PAY_ORIGINAL_VALUE')} ${numberFormat(summaryOriginalValue())} ${t('CURRENCY_CHANGE.TH')}`)
    /** CASH */
    if (paymentChannelsSelected[1]) {
      const paymentChannelId = 1
      const orderPaymentItems: any = []
      const payWithCash = valSummerySub[paymentChannelId] || {}
      let amountPaid = 0
      let checkSelect = paymentChannelsSelected[1] || false
      if (!_.isEmpty(payWithCash)) {

        _.map(payWithCash, (i: any) => {
          if (i.value >= 0 && i.value !== undefined) {
            orderPaymentItems.push({ refItemId: i.id, refItemType: i.type, amountPaid: i.value })
            amountPaid += i.value
            checkSelect = true
          }
        })
      }

      if (checkSelect) newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: '', cardTypeId: '', patientTreatmentRightId: '', orderPaymentItems: orderPaymentItems })
    }

    /** TRANSFER */
    if (paymentChannelsSelected[2]) {
      if (!bankTransfer?.bankId) return notiError(t('PAYMENT.FORM.PLS_SELECT_BANK'), undefined, undefined, () => { setDisabledButton(false) })
      const paymentChannelId = 2
      const orderPaymentItems: any = []
      let amountPaid = 0
      let checkSelect = paymentChannelsSelected[2] || false
      const payWithTransfer = valSummerySub[paymentChannelId] || null
      if (!_.isEmpty(payWithTransfer)) {
        _.map(payWithTransfer, (i: any) => {
          if (i.value >= 0 && i.value !== undefined) {
            orderPaymentItems.push({ refItemId: i.id, refItemType: i.type, amountPaid: i.value })
            amountPaid += i.value
            checkSelect = true
          }
        })
      }
      if (checkSelect) newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: bankTransfer.bankId, cardTypeId: '', patientTreatmentRightId: '', orderPaymentItems: orderPaymentItems })
    }

    /** CREDIT */
    if (paymentChannelsSelected[3]) {
      if (!bankCredit?.bankId) return notiError(t('PAYMENT.FORM.PLS_SELECT_BANK'), undefined, undefined, () => { setDisabledButton(false) })
      if (!bankCredit?.cardTypeId) return notiError(t('PAYMENT.FORM.PLS_SELECT_CARD_TYPE'), undefined, undefined, () => { setDisabledButton(false) })
      const paymentChannelId = 3
      const orderPaymentItems: any = []
      let amountPaid = 0
      let checkSelect = paymentChannelsSelected[3] || false

      const payWithCredit = valSummerySub[paymentChannelId] || null
      if (!_.isEmpty(payWithCredit)) {
        _.map(payWithCredit, (i: any) => {
          if (i.value >= 0 && i.value !== undefined) {
            orderPaymentItems.push({ refItemId: i.id, refItemType: i.type, amountPaid: i.value })
            amountPaid += i.value
            checkSelect = true
          }
        })
      }

      if (checkSelect)
        newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: bankCredit.bankId, cardTypeId: bankCredit.cardTypeId, patientTreatmentRightId: '', orderPaymentItems: orderPaymentItems })
    }

    /** DEBIT */
    if (paymentChannelsSelected[4]) {
      if (!bankDebit?.bankId) return notiError(t('PAYMENT.FORM.PLS_SELECT_BANK'), undefined, undefined, () => { setDisabledButton(false) })
      if (!bankDebit?.cardTypeId) return notiError(t('PAYMENT.FORM.PLS_SELECT_CARD_TYPE'), undefined, undefined, () => { setDisabledButton(false) })
      const paymentChannelId = 4
      const orderPaymentItems: any = []
      let amountPaid = 0
      let checkSelect = paymentChannelsSelected[4] || false
      const payWithDebit = valSummerySub[paymentChannelId] || null
      if (!_.isEmpty(payWithDebit)) {
        _.map(payWithDebit, (i: any) => {
          if (i.value >= 0 && i.value !== undefined) {
            orderPaymentItems.push({ refItemId: i.id, refItemType: i.type, amountPaid: i.value })
            amountPaid += i.value
            checkSelect = true
          }
        })
      }
      if (checkSelect)
        newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: bankDebit.bankId, cardTypeId: bankDebit.cardTypeId, patientTreatmentRightId: '', orderPaymentItems: orderPaymentItems })
    }


    if (props.type === 'PAYMENT' || props.type === 'RETROSPECTIVE' || props.type === 'EDIT_PAYMENT' || props.type === 'OVERDUE') {
      /** TREATMENT_RIGHT */
      if (valSummeryTreatmentRight[5] && paymentChannelsSelected[5]) {
        const paymentChannelId = 5
        const payWithTreatmentRight = valSummeryTreatmentRight[paymentChannelId] || null
        if (!_.isEmpty(payWithTreatmentRight)) {
          if (payWithTreatmentRight.some((item: any) => item?.isCheck)) {
            for (const index in payWithTreatmentRight) {
              if (payWithTreatmentRight[index].isCheck) {
                const payTreatmentRightSub = valSummerySubTreatmentRight[paymentChannelId][index] || null
                let amountPaid = 0
                let checkSelect = false
                const orderPaymentItems: any = []
                _.map(payTreatmentRightSub, (j: any) => {
                  if (j.isCheck) {
                    orderPaymentItems.push({ refItemId: j.id, refItemType: j.type, amountPaid: j.value })
                    amountPaid += j.value
                    checkSelect = true
                  }
                })
                newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: '', cardTypeId: '', patientTreatmentRightId: index, orderPaymentItems: orderPaymentItems })
              }
            }
          } else {
            const paymentChannelId = 5
            const orderPaymentItems: any = []
            const payWithCash = valSummerySub[paymentChannelId] || {}
            let amountPaid = 0
            let checkSelect = paymentChannelsSelected[5] || false
            if (!_.isEmpty(payWithCash)) {
              _.map(payWithCash, (i: any) => {
                if (i.value >= 0 && i.value !== undefined) {
                  orderPaymentItems.push({ refItemId: i.id, refItemType: i.type, amountPaid: i.value })
                  amountPaid += i.value
                  checkSelect = true
                }
              })
            }
            if (checkSelect) newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: '', cardTypeId: '', patientTreatmentRightId: '', orderPaymentItems: orderPaymentItems })
          }
        }
      }
    }

    if (paymentChannelsSelected[7]) {
      const paymentChannelId = 7
      const orderPaymentItems: any = []
      const payWithVoucher = valSummerySub[paymentChannelId] || {}
      let amountPaid = 0
      let checkSelect = paymentChannelsSelected[7] || false
      if (!_.isEmpty(payWithVoucher)) {

        _.map(payWithVoucher, (i: any) => {
          if (i.value >= 0 && i.value !== undefined) {
            orderPaymentItems.push({ refItemId: i.id, refItemType: i.type, amountPaid: i.value })
            amountPaid += i.value
            checkSelect = true
          }
        })
      }

      if (checkSelect) newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: '', cardTypeId: '', patientTreatmentRightId: '', orderPaymentItems: orderPaymentItems })
    }

    /** ADVANCE */
    if (valSummerySub[6] && paymentChannelsSelected[6]) {
      const paymentChannelId = 6
      const orderPaymentItems: any = []
      let amountPaid = 0
      let checkSelect = paymentChannelsSelected[6] || false
      const payWithAdvance = valSummerySub[paymentChannelId] || null
      if (!_.isEmpty(payWithAdvance)) {
        _.map(payWithAdvance, (i: any) => {
          orderPaymentItems.push({ refItemId: i.id, refItemType: i.type, amountPaid: i.value })
          amountPaid += i.value
          checkSelect = true
        })
      }
      if (checkSelect) newOrderPaymentChannels.push({ paymentChannelId: paymentChannelId, amountPaid: amountPaid, bankId: '', cardTypeId: '', patientTreatmentRightId: '', orderPaymentItems: orderPaymentItems })
    }

    const val = valSummery[6]
    if (val > patientFinance.advanceBalance || (val === objPayment.totalPrice && val > patientFinance.advanceBalance)) return notiError(t('PAYMENT.FORM.PLS_RENEW_ADVANCE'), undefined, undefined, () => { setDisabledButton(false) })
    // PAY
    if (props.type === 'PAYMENT') {
      const findTry = await TreatmentSummaryApi.findTreatmentSummary(props.patient.patientCase.patientCaseId).catch((res) => {
        return
      })

      setLoading(true)

      await PaymentApi.payment(props.patient.patientCase.patientCaseId, objPay).then((resPay) => {
        if (props?.onSuccess) props.onSuccess(objPay)
        if (resPay.status === 201) {

          const pAlreadyTreatment: string = findTry.data.isFixCode === "1" ? localStorage.getItem('PATIENT_ALREADY_TREATMENT') || '' : ''
          const checkNewP: number[] = pAlreadyTreatment ? JSON.parse(pAlreadyTreatment) : []
          localStorage.setItem('PATIENT_ALREADY_TREATMENT', JSON.stringify(_.filter(checkNewP, (id) => id !== props.patient.patientCase.patientCaseId)))

          window.open(`${fileUrl}/${resPay.data}`, '_blank')
          notiSuccess(t('PAYMENT.MESSAGE.SUCCESS.PAY_SUCCESS'), 'reset', null, null, 'register-page')
        }
        history.push(routeName.payment)
        setLoading(false)

      }).catch(async (res) => {
        const resMessage = res?.response?.data?.message || ''
        if (resMessage === "PRODUCT_NOT_ENOUGH") {
          notiError(t(`PAYMENT.MESSAGE.ERROR.${resMessage}`))
          setLoading(false)
          return
        }
        const resError = await handelCheckQueue()
        if (!resError) {
          notiError(t('PAYMENT.MESSAGE.ERROR.VISIT_DONE'))
        } else {
          notiError(t('PAYMENT.MESSAGE.ERROR.ERROR'))
        }
        history.push(routeName.payment)
        setLoading(false)
        return
      }).finally(() => {
        setDisabledButton(false)
      })

    } else if (props.type === 'RETROSPECTIVE') {
      setDisabledButton(false)
      return props.onSuccess && props.onSuccess(objPay)
    } else if (props.type === 'EDIT_PAYMENT') {
      setLoading(true)
      PaymentApi.updateDetail(props.detail.orderPaymentId, objPay).then((resPay) => {
        setLoading(false)
        if (resPay.status === 200) {
          window.open(`${fileUrl}/${resPay.data}`, '_blank')
          notiSuccess(t('PAYMENT.MESSAGE.SUCCESS.PAY_SUCCESS'), 'reset', null, null, 'register-page')
        }
        if (props?.onSuccess) props.onSuccess()
        dispatch(resetStatePayment())
      }).finally(() => {
        setDisabledButton(false)
      })
    } else {
      if (props?.orderPaymentId) {
        if (!newOrderPaymentChannels.length) return notiError(t('PAYMENT.FORM.PLS_INPUT_PAYMENT'))
        setLoading(true)
        OverdueApi.paidOverdue(props?.orderPaymentId, objPay).then((resPay) => {
          setDisabledButton(true)

          if (resPay.status === 201)
            notiSuccess(t(`PAYMENT.MESSAGE.SUCCESS.PAY_OVERDUE_SUCCESS`), '', null, () => {
              if (props?.onSuccess) props.onSuccess()
              window.open(`${fileUrl}/${resPay.data}`, '_blank')
              notiSuccess(t('PAYMENT.MESSAGE.SUCCESS.PAY_SUCCESS'), 'reset', null, null, 'register-page')
              dispatch(resetPaymentChannelByKey())
              dispatch(resetStatePayment())
              setDisabledButton(false)
              setLoading(false)
            })

        }).catch(async (res) => {
          setLoading(false)
          setDisabledButton(false)
          return
        }).finally(() => {
          return
        })
      }
    }
  }

  const handelCheckQueue = async () => {
    let check = false
    await PaymentApi.listQueue({ branchId: branchId }).then(({ data }) => {
      check = data.queue.some((item: any) => item.patientCaseId === props.patient.patientCase.patientCaseId)
    })

    return check
  }

  useEffect(() => {
    if (allItem) {
      const objBalance: any = {}
      const sumPaid: any = {}
      _.map(arrChanel, (pcId: any) => {
        _.map(valSummerySub[pcId], (sub: any, index: any) => {
          const { value } = sub

          if (sumPaid[index] === undefined) sumPaid[index] = value
          if (index === 0 && !_.isEmpty(props?.initBalance)) {
            objBalance[index] -= props?.initBalance[index] || 0
          }
          sumPaid[index] += value


        })
      })

      _.map(arrChanel, (pcId: any) => {
        _.map(valSummerySub[pcId], (sub: any, index: any) => {
          const { treatmentPrice } = sub
          dispatch(setSummerySubByKey({ key: pcId, keySub: index, value: { ...sub, paidBalance: treatmentPrice - sumPaid[index] } }))
        })
      })
    }
  }, [valSummery, allItem])

  useEffect(() => {
    const lengthPaymentSelect = _.filter(paymentChannelsSelected, (fs) => {
      return fs === true
    }).length
    if (lengthPaymentSelect === 1) {
      const keyPaymentSelect = _.findKey(paymentChannelsSelected, (fs) => fs === true)
      const defaultPrefix = _.get(_.find(defaultPaymentChannels, { paymentChannelId: Number(keyPaymentSelect) }), 'defaultPrefix')
      if (defaultPrefix) dispatch(setPaymentByKey({ key: 'prefix', value: defaultPrefix }))
    } else return
  }, [paymentChannelsSelected])

  return (
    <UseStyled.ModalStyle size={'xl'} show={props.isShow} centered fullscreen>
      <Modal.Body className="py-0 overflow-hidden">
        <Loading show={loading} type="fullLoading" />
        <Grid container spacing={2} className="my-0 h-100 pb-4">
          <Grid lg={6} item className={`w-100 h-100 ${activePage === 0 ? 'd-block' : 'd-none'} d-xl-block`}>
            <Box className="pr-xl-3 h-100 d-flex flex-column">
              <Typography className="pb-4 text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                {t('PAYMENT.FORM_PAYMENT.TITLE.PAYMENT_DETAIL')}
              </Typography>
              <Box className="custom-scroll" sx={{ overflowX: 'auto' }}>
                <Info patient={props.patient} finance={{ advanceBalance: patientFinance.advanceBalance, outstandingBalance: patientFinance.outstandingBalance }} treatmentRights={patientFinance.treatmentRights} />
                <PaymentChannels menu={props.type} treatments={props.type === "EDIT_PAYMENT" ? props.detail : treatments} banks={banks} balancePaid={objPayment.totalPrice} overdue={objPayment.overdue + priceOutStanding} patientFinance={patientFinance.treatmentRights} patientFinanceFull={patientFinance} />
              </Box>
              <UseStyled.FooterStepButton className="d-flex d-xl-none justify-content-md-end mt-2 mr-md-2">
                <ButtonCustom textButton={t('BUTTON.CANCEL')} variant="outlined" onClick={() => props.setIsShow(false)} />
                <ButtonCustom textButton={t('BUTTON.NEXT')} disabled={loading || disabledNext}
                  onClick={() => {
                    setDisabledNext(true)
                    alertTreantRights()
                  }}
                />
              </UseStyled.FooterStepButton>
            </Box>
          </Grid>
          <Grid lg={6} item className={`w-100 h-100 ${activePage === 1 ? 'd-block' : 'd-none'} d-xl-block`} sx={{ borderLeft: { lg: `1px solid ${colors.lightGray}` } }}>
            <Box className="h-100 d-flex flex-column">
              <Typography className="pb-4 text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                {t('PAYMENT.FORM_PAYMENT.TITLE.PAYMENT_SUMMARY')}
              </Typography>
              <Box className="custom-scroll mb-3" sx={{ overflowX: 'auto' }}>
                <UseStyled.SummaryNetPrice className="d-flex align-items-center mx-md-3 mx-xl-4 mb-3">
                  <Box className="d-flex align-items-center justify-content-center flex-column h-100">
                    <Typography className="text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                      {props.type === "EDIT_PAYMENT" ? numberFormat(props.detail.totalPrice) : numberFormat(objPayment.totalPrice + priceOutStanding)} {t('CURRENCY_CHANGE.TH')}
                    </Typography>
                    <Typography className="text-center" sx={{ fontSize: 14, color: colors.themeMainColor }}>
                      {t('PAYMENT.FORM_PAYMENT.NET_PRICE')}
                    </Typography>
                  </Box>
                  <Box className="d-flex align-items-center justify-content-center flex-column h-100" sx={{ borderLeft: `1px solid ${colors.lightGray}` }}>
                    <Typography className="text-center" sx={{ fontSize: 20, color: colors.themeMainColor, fontWeight: 600 }}>
                      {numberFormat(objPayment.overdue + priceOutStanding)} {t('CURRENCY_CHANGE.TH')}
                    </Typography>
                    <Typography className="text-center" sx={{ fontSize: 14, color: colors.themeMainColor }}>
                      {t('PAYMENT.FORM_PAYMENT.OUT_STANDING_PRICE')}
                    </Typography>
                  </Box>
                </UseStyled.SummaryNetPrice>
                <Box className="d-flex align-items-center px-md-2 px-xl-3 mb-3">
                  <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{t('PAYMENT.FORM_PAYMENT.RECEIPT_TYPE')}</Typography>
                  <Box className="d-flex align-items-center justify-content-end pl-2" sx={{ flex: 1 }}>
                    <Box className="w-100" sx={{ maxWidth: '112px' }}>
                      <BasicSelect
                        selectId=""
                        label=""
                        labelId="billPrefixId"
                        onchange={(e: any) => dispatch(setPaymentByKey({ key: 'prefix', value: e.target.value }))}
                        value={objPayment.prefix}
                        options={_.map(billPrefixes, (i: any) => (
                          <MenuItem key={i.billPrefixId || i.paymentChannelId} value={i.prefix || i.defaultPrefix}>
                            {i.prefix || i.defaultPrefix}
                          </MenuItem>
                        ))}
                      />
                    </Box>
                    <Typography className="pl-3 d-none d-sm-block" sx={{ flex: 'none' }}>
                      {props.type !== 'RETROSPECTIVE' ? '-xxx-xx-xx-xxxxx' : '-xx-xx-xxxxx'}
                    </Typography>
                  </Box>
                </Box>
                <SummaryReceipt treatments={props.type === "EDIT_PAYMENT" ? props.detail : treatments} initBalance={props.initBalance} patient={props.patient} date={props.date} />
                <Box className="px-md-2 px-xl-3 mb-md-3 pt-md-2">
                  <InputTextarea
                    key={'important-symptoms'}
                    onchange={(e: any) => {
                      dispatch(setPaymentByKey({ key: 'remark', value: e.target.value }))
                    }}
                    value={objPayment.remark}
                    label={t('PAYMENT.FORM_PAYMENT.REMARK')}
                  />
                </Box>
                <Box className="px-md-2 px-xl-3 mb-md-3 pt-md-2">
                  {props.histories?.length && (
                    <>
                      {_.map(props.histories, (his: any, iHis: number) => {
                        return <p className="mb-1">{`${t('PAYMENT.PAY')} #${iHis + 1} ${dateTimeToView(his.createdAt)} (${numberToCurrencyFormat(his.amountItemPaid)})`}</p>
                      })}
                    </>
                  )}
                </Box>
              </Box>
              <UseStyled.FooterPaymentButton className="d-flex flex-column mt-auto">
                <ButtonCustom textButton={t('PAYMENT.FORM_PAYMENT.BUTTON_BACK_TO_DETAIL')} variant="outlined" onClick={() => {
                  setActivePage(0)
                  setDisabledNext(false)
                }} className="d-xl-none" />
                <ButtonCustom
                  textButton={t('BUTTON.CANCEL')}
                  variant="outlined"
                  onClick={() => {
                    props.setIsShow(false)
                    dispatch(resetStatePayment())
                  }}
                  className="d-none d-xl-block"
                />
                <ButtonCustom
                  textButton={t('PAYMENT.FORM_PAYMENT.BUTTON_PAYMENT')}
                  disabled={loading || disabledButton}
                  onClick={() => {
                    setDisabledButton(true)
                    payment()
                  }}
                  className="mt-2 mt-md-3"
                />
              </UseStyled.FooterPaymentButton>
            </Box>
          </Grid>
        </Grid>
      </Modal.Body>
    </UseStyled.ModalStyle>
  )
}
