import BaseAPI from 'api/api'
import { FindAllInterface } from 'api/api-interface'
import { getBranch } from 'utils/app.utils'

export interface OperativeInterface {
  operativeTypeId: number
  operativeCode: string
  operativeName: string
  operativeNameEn?: string
  costPrice: string
  salePrice: string
  operativeUnitId: number
  operativeUnit?: any
  operativeDiagnoses?: any[]
  operativeClinicFees?: any[]
  status: string
  branchPrice?: '0' | '1',
  operativePrice?: { branchId: number, price: number }[],
  // {productId:number,qty:number}[]
  operativeProducts?: { productId: number, qty: number }[],
}

export interface OperativeObjInterface {
  costPrice: number
  createdAt: string
  createdBy: string
  operativeClinicFees: {
    clinicFeeId: number
    clinicFeeText: string
    createdAt: string
    createdBy: string
    operativeClinicFeeId: number
    operativeId: number
    price: number
    updatedAt: string
    updatedBy: string
  }[]
  operativeCode: string
  operativeDiagnoses: {
    createdAt: string
    createdBy: string
    diagnosisId: number
    diagnosisText: string
    operativeDiagnosisId: number
    operativeId: number
    updatedAt: string
    updatedBy: string
  }[]
  operativeId: number
  operativeName: string
  operativeNameEn: string
  operativeTypeId: number
  operativeUnitId: number
  operative_type: {
    color: string
    createdAt: string
    createdBy: string
    operativeTypeId: number
    operativeTypeName: string
    operativeTypeNameEn: string
    status: string
    updatedAt: string
    updatedBy: string
  }
  operative_unit: {
    createdAt: string
    createdBy: string
    operativeUnitId: number
    operativeUnitName: string
    operativeUnitNameEn: null | string
    status: string
    updatedAt: string
    updatedBy: string
  }
  salePrice: number
  status: string
  updatedAt: string
  updatedBy: string
}

export default class OperativeApi extends BaseAPI {
  static findAll(props: any): Promise<any> {
    return this.api.get('operatives', { params: { ...props, branchId: getBranch() } }).then((res) => res)
  }

  static findAllActive(props: FindAllInterface): Promise<any> {
    return this.api.get('operatives/all-active', { params: { ...props } }).then((res) => res)
  }

  static findAllFixCode(): Promise<any> {
    return this.api.get('operatives/fix-code').then((res) => res)
  }

  static create(playload: any) {
    return this.api.post('operatives', playload)
  }

  static findById(id: number): Promise<OperativeInterface> {
    return this.api.get(`operatives/${id}/${getBranch()}`)
  }

  static update(id: number, body: OperativeInterface) {
    return this.api.patch(`operatives/${id}`, body)
  }

  static updateStatus(id: number, status: string): Promise<any> {
    return this.api.patch(`operatives/${id}/status`, { id, status })
  }

  static remove(id: number) {
    return this.api.delete(`operatives/${id}`)
  }
}
