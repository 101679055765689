import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PaymentApi from 'api/counter/payment/payment.api';
import { resetModal } from 'app/slice/modal.slice';
import InputTextarea from 'component/Input/InputTextarea';
import InputTextField from 'component/Input/InputTextField';
import { notiError, notiSuccess } from 'component/notifications/notifications';
import { ModalCancel } from 'features/finance/payment/style/payment-style';
import { getBranch } from 'utils/app.utils';
import { swalCustom } from 'component/Alert/Swal';
import ApprovalApi from 'api/approval/approval.api';

export interface ICancelPaymentProps {
    isShow: boolean
    id: number
    paymentNumber: string
    onReset?: () => void
    resetSuccess?: (status: boolean) => void
    checkApprovalPermiss?: boolean
}
const initStateErrorMessage = {
    USER_NAME_STRING_EMPTY: '',
    PASSWORD_STRING_EMPTY: '',
    CANCEL_REMARK_STRING_EMPTY: '',
    NOT_FOUND_USER: '',
}
export default function CancelPayment(props: ICancelPaymentProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [username, setUserCode] = useState<string>('')
    const [password, setPassword] = useState<string>('');
    const [cancelRemark, setCancelRemark] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<any>({ ...initStateErrorMessage })

    const [disabledDel, setDisabledDel] = useState<boolean>(false)

    const clearErrorMessage = async () => setErrorMessage(initStateErrorMessage)
    const resetForm = async () => {
        clearErrorMessage()
        if (props.onReset) props.onReset()
        dispatch(resetModal())
        setUserCode('')
        setPassword('')
        setCancelRemark('')
    }

    useEffect(() => {
        clearErrorMessage()
    }, [username, password, cancelRemark]);

    const onDelete = (id: number, obj: any) => {
        if (disabledDel) {
            return
        }
        setDisabledDel(true)
        if (!username || !password || !cancelRemark) {
            setErrorMessage({
                USER_NAME_STRING_EMPTY: !username ? t('PAYMENT.MESSAGE.ERROR.USER_NAME_STRING_EMPTY') : '',
                PASSWORD_STRING_EMPTY: !password ? t('PAYMENT.MESSAGE.ERROR.PASSWORD_STRING_EMPTY') : '',
                CANCEL_REMARK_STRING_EMPTY: !cancelRemark ? t('PAYMENT.MESSAGE.ERROR.CANCEL_REMARK_STRING_EMPTY') : '',
            })
            setDisabledDel(false)
            return false
        }

        if (!props?.checkApprovalPermiss) {
            PaymentApi.cancel(id, { username, password, cancelRemark })
                .then((res) => {
                    notiSuccess(t(`PAYMENT.MESSAGE.SUCCESS.CANCEL`))
                    dispatch(resetModal())
                    props.resetSuccess && props.resetSuccess(true)
                })
                .catch((e) => {
                    const message = e?.response?.data?.message
                    if (message === 'NOT_FOUND_USER' || message === 'INVALID_PASSWORD') {
                        setErrorMessage({
                            ...initStateErrorMessage,
                            NOT_FOUND_USER: message === 'NOT_FOUND_USER' ? t('PAYMENT.MESSAGE.ERROR.NOT_FOUND_USER') : '',
                            INVALID_PASSWORD: message === 'INVALID_PASSWORD' ? t('PAYMENT.MESSAGE.ERROR.INVALID_PASSWORD') : ''
                        })
                    } else notiError(t('PAYMENT.MESSAGE.ERROR.NOT_CANCEL'))
                }).finally(() => setDisabledDel(false))
        } else {
            PaymentApi.cancelNew(id, { username, password, requestReason: cancelRemark, branchId: getBranch() })
                .then((res) => {
                    notiSuccess(t(`PAYMENT.MESSAGE.SUCCESS.CANCEL`))
                    dispatch(resetModal())
                    swalCustom(
                        t('APPROVELLIST.FILTER.PENDING'),
                        `<div class="test-t" style="width: 350px">${t('APPROVELLIST.MESSAGE.PENDING_RECEIPT')}</div>`,
                        (res: any) => props.resetSuccess && props.resetSuccess(true),
                        'warning',
                        {
                            confirmButton: { showConfirmButton: false },
                            cancelButton: { cancelButtonText: t('BUTTON.ENTER') },
                            showCloseButton: false
                        }
                    )
                })
                .catch((e) => {
                    const message = e?.response?.data?.message
                    if (message === 'NOT_FOUND_USER' || message === 'INVALID_PASSWORD') {
                        setErrorMessage({
                            ...initStateErrorMessage,
                            NOT_FOUND_USER: message === 'NOT_FOUND_USER' ? t('PAYMENT.MESSAGE.ERROR.NOT_FOUND_USER') : '',
                            INVALID_PASSWORD: message === 'INVALID_PASSWORD' ? t('PAYMENT.MESSAGE.ERROR.INVALID_PASSWORD') : ''
                        })
                    } else notiError(t('PAYMENT.MESSAGE.ERROR.NOT_CANCEL'))
                }).finally(() => setDisabledDel(false))
        }
    }

    return props.isShow ? (
        <ModalCancel
            size={'lg'}
            modalStyle={'modal-cancel'}
            // modalTheme={'danger'}
            title={t('PAYMENT.FORM.TITLE')}

            alignFooter={'center'}
            component={
                <>
                    <span className="fz-14 text-muted">{t('PAYMENT.FORM.CAPTION')} {props.paymentNumber}</span>
                    <Row className="mx-0 mt-2">
                        <Col xs={12} sm={6} className="px-2 mb-3">
                            <InputTextField
                                required
                                label={t('PAYMENT.FORM.USER_NAME')}
                                type="text"
                                value={username}
                                inputProps={{ shrink: true }}
                                helperText={errorMessage?.USER_NAME_STRING_EMPTY || errorMessage?.NOT_FOUND_USER || ''}
                                onchange={(e) => setUserCode(e.target.value)}
                                disabledAutoComplete
                            />
                        </Col>
                        <Col xs={12} sm={6} className="px-2 mb-3">
                            <InputTextField
                                required
                                label={t('PAYMENT.FORM.PASSWORD')}
                                type="password"
                                value={password}
                                inputProps={{ shrink: true }}
                                helperText={errorMessage?.PASSWORD_STRING_EMPTY || errorMessage?.INVALID_PASSWORD || ''}
                                onchange={(e) => setPassword(e.target.value)}
                            />
                        </Col>
                        <Col xs={12} sm={12} className="px-2 mb-3">
                            <InputTextarea required value={cancelRemark} onchange={(event) => setCancelRemark(event.target.value)} label={t('PAYMENT.FORM.CANCEL_REMARK')} helperText={errorMessage?.CANCEL_REMARK_STRING_EMPTY || ''} />
                        </Col>
                    </Row>
                </>
            }
            id={props.id}
            textBtnDelete={t('PAYMENT.FORM.BTN_DEL')}
            onDelete={onDelete}
            autoResetModal={false}
            onReset={() => (resetForm())}
            textBtnCancel={t('APPOINTMENT.FORM.CANCEL')}
        />
    ) : <></>
}
