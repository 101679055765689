import { makeStyles } from '@material-ui/core'
import { Box, styled } from '@mui/material'
import { colors } from 'constants/theme'

export const DivNoneData = styled('div')(({ theme }) => ({ fontSize: '1rem', color: colors.textExtraLightGray, textAlign: 'center', padding: '2rem' }))

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  bodyCalendar: {
    '& .card-body': {
      padding: '1px'
    }
  },
  formMutiSelect: {
    '& .ant-select': {
      fontSize: '20px !important'
    }
  },
  btn: {
    width: 'fit-content',
    height: '40px',
    borderRadius: '4px',
    border: `solid 1px ${colors.themeSecondColor}`,
    backgroundColor: colors.white,
    fontSize: '16px',
    color: colors.themeSecondColor
  },
  btnDisable: {
    width: 'fit-content',
    height: '40px',
    borderRadius: '4px',
    border: `solid 1px ${colors.disabledGray}`,
    backgroundColor: colors.white,
    fontSize: '16px',
    color: colors.disabledGray
  },
  containerRadio: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    fontSize: '16px'
  },
  radio: {
    '& .css-ahj2mt-MuiTypography-root': {
      fontSize: '16px !important'
    }
  },
  formLabel: {
    marginRight: '21px',
    fontWeight: 700
  },
  newPatient: {
    padding: '5px 0px 5px 16px',
    fontSize: '18px',
    fontWeight: 700,
    marginLeft: '30px',
    borderRadius: '4px',
    width: '100%',
    backgroundColor: colors.themeSecondColor10
  },
  dropdownColor: {
    '& .btn-primary': {
      backgroundColor: (props: any) => props.backgroundColor,
      color: colors.black
    },
    '& .btn-primary.dropdown-toggle': {
      display: 'flex',
      backgroundColor: (props: any) => props.backgroundColor,
      color: colors.black,
      '&:after': {
        marginTop: '15px'
      }
    }
  }
}))

export default useStyles

export const ModalSlice = styled("div")(({ theme }) => ({
  overflow: "auto",
  position: "absolute",
  zIndex: "1500",
  top: "0",
  right: "0",
  width: "35%",
  minWidth: '422px',
  maxWidth: '552px',
  height: "100%",
  backgroundColor: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  ".MuiOutlinedInput-root.Mui-disabled": {
    backgroundColor: "#E0E0E0 !important",
  },
  [theme.breakpoints.down('lg')]: {
    width: "50%",
  },
  [theme.breakpoints.down('xs')]: {
    width: "100%",
    minWidth: '300px',
  }
}))

export const ModalSlice_Title = styled("div")(({ theme }) => ({
  margin: "0",
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
  color: "#2F4269",
  fontSize: "16px",
  fontWeight: "600",
  textAlign: "center",
}))

export const ModalSlice_Footer = styled("div")(({ theme }) => ({
  position: "sticky",
  zIndex: "2",
  bottom: "0px",
  right: "0px",
  backgroundColor: "#fff",
  padding: "8px 0px",
  // marginLeft: "-16px",
  borderTop: "1px solid #E5E5E5"
}))

export const AppointmentAppContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: '1500',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '10px 10px 10px 6px',
  width: '100%',
  maxWidth: '370px',
  ".appointment-app-content": {
    height: `calc(100% - 0px)`,
    '.slider-area': {
      height: '100%',
      width: '100%',
      maxWidth: '100%'
    }
  },
}))

export const AppointmentAppCard = styled(Box)(({ theme }) => ({
  margin: '1rem 1rem 0',
  ".am-app-card": {
    height: '110px',
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer',
    ".am-app-number": {
      position: 'absolute',
      top: '0',
      backgroundColor: '#2F4269',
      color: '#FFF',
      padding: '0px 5px 5px 5px',
      borderRadius: '4px 0 20px'
    }
  }
}))

export const AppointmentAppStatus = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: '8px 10px',
  padding: '2px 5px',
  background: 'linear-gradient(0deg, rgba(242,75,7,1) 0%, rgba(247,153,15,1) 100%)',
  borderRadius: '4px',
  fontSize: '13px',
  color: '#fff'
}))

export const BoxTag = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  '.items-tag': {
    minWidth: '16px',
    minHeight: '16px',
    borderRadius: '50%',
    border: '1px solid #fff'
  }
}))