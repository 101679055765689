import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

/** STYLE */
import { styled } from '@mui/material/styles';

/** CONSTANTS */
import { colors } from 'constants/theme'

export const HeaderUpdatePage = styled('div')(({ theme }) => ({
    width: '100%',
    height: 80,
    border: `1px solid ${colors.borderHeaderCard}`,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',

    'p': {
        margin: '24px 0px 24px 24px',
        fontSize: 20,
        fontWeight: 600,
    },

    [theme.breakpoints.down('md')]: {
        height: 152,
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'start'
    },
    [theme.breakpoints.down(382)]: {
        height: 120,
        'p': {
            margin: '8px 0px 8px 24px'
        }

    }
}))

export const ButtonGroup = styled('div')(({ theme }) => ({
    width: 'fit-content',
    padding: '0 4px',
    // width: 368,

    height: 48,
    border: `1px solid ${colors.themeSecondColor}`,
    borderRadius: 4,
    backgroundColor: colors.themeSecondColor,
    marginRight: 32,
    marginTop: 16,
    marginBottom: 16,

    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
        alignSelf: 'end',
        height: 56,
        width: '376px',
        marginTop: 0,
        marginRight: 8,
        padding: '8px 0',
    },
    [theme.breakpoints.down(382)]: {
        flexDirection: 'row',
        width: '100%',
        padding: '8px 0 8px 0',
        marginRight: 0
    }
}))

export const UpdateListButton = styled('button')(({ theme }) => ({
    width: 176,
    height: 40,
    border: `1px solid ${colors.themeSecondColor}`,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 400,

    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    '&:hover': {
        backgroundColor: colors.themeSecondColor06
    },

    [theme.breakpoints.down(382)]: {
        fontSize: 14
    }
}))

export const ManualButton = styled('button')(({ theme }) => ({
    width: 176,
    height: 40,
    borderRadius: 4,
    border: 'none',
    fontSize: 16,
    fontWeight: 400,

    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',

    '&:hover': {
        opacity: 0.9
    },
    [theme.breakpoints.down(382)]: {
        fontSize: 14
    }
}))

export const BodyUpdatePage = styled('div')({
    width: '100%',
    height: '100%'
})

export const ShowContent = styled('div')({
    margin: '24px 0px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
})


export const TextHeader = styled('div')(({ theme }) => ({
    color: colors.textPrimary,
    padding: '16px 24px',
    fontSize: 18,
    fontWeight: 400,
    border: 'none',
}))

export const SlideDataContainer = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: 'none',
    padding: '0 1rem',
    fontSize: 16,
    fontWeight: 400,

}))

export const SlideData = styled((props: any) => (
    <MuiAccordionSummary
        expandIcon={
            <ExpandMoreIcon
                fontSize='small'
                className=''
                sx={{ backgroundColor: colors.themeSecondColor, border: `1px solid ${colors.themeSecondColor}`, borderRadius: '50px', color: colors.white, borderTop: 'none' }} />
        } {...props} />
))(({ theme }) => ({
    height: 40,
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.lightGray}`,

}))

export const ListUpdate = styled(MuiAccordionDetails)(({ theme }) => ({

}))

export const HeaderDetail = styled('p')(({ theme }) => ({
    fontSize: 20,
    fontWeight: 700,

    [theme.breakpoints.down(576)]: {
        fontSize: 16
    }
}))

export const SubDetail = styled('p')(({ theme }) => ({
    fontSize: 20,
    fontWeight: 400,

    [theme.breakpoints.down(576)]: {
        fontSize: 16
    }
}))

export const ManualLiast = styled('ul')(() => ({
    listStyle: 'none',
    fontSize: '14px',
    fontWeight: 400,

    'li': {
        padding: '8px 0',
    },

}))