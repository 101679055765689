import { Box, ClickAwayListener, MenuItem } from '@mui/material'
import InputTextField from 'component/Input/InputTextField'
import ModalCustom from 'component/ModalCustom/ModalCustom'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import FormManageColor from './form-manage-color'
import { ColorTag } from './style'
import HelperText from 'component/Input/HelperText'
import TagsApi, { tagProps } from 'api/setting/personnel-managements/tags.api'
import { notiError, notiSuccess } from 'component/notifications/notifications'
import { colors } from 'constants/theme'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'

type FormManageTagProps = {
    onClosePopTag: () => void
    isEdit: boolean
    data: tagProps
    reLoadData: () => void
}

const FormManageTag = (props: FormManageTagProps) => {
    const { onClosePopTag, isEdit, data, reLoadData } = props
    const { t } = useTranslation()

    const [tagId, setTagId] = useState<number>(0)
    const [tagName, setTagName] = useState<string>('')
    const [tagColor, setTagColor] = useState<string>('')
    const [status, setStaus] = useState<"ACTIVE" | "INACTIVE">("ACTIVE")

    const [errorTag, setErrorTag] = useState<boolean>(false)
    const [popSelectColor, setPopSelectColor] = useState<boolean>(false)

    const [operativeTypes, setOperativeTypes] = useState<any>([])
    const [operativeType, setOperativeType] = useState<any>({})

    const onLoadOperativeType = async (search = '') => {
        const respOperativeTypes = await OperativeTypeApi.findAll({ pageLimit: 100, search: search, status: 'ACTIVE' })
        if (respOperativeTypes.status === 200) {
            const options = respOperativeTypes?.data || []
            setOperativeTypes(options)
        }
    }

    useEffect(() => {
        onLoadOperativeType()
    }, [])

    useEffect(() => {
        if (data) {
            setTagId(data?.tagId || 0)
            setTagName(data.tagName)
            setTagColor(data.color)
            setStaus(data?.status || "ACTIVE")
            if (data?.operativeTypeId) {
                setOperativeType({
                    operativeTypeId: data.operativeTypeId,
                    operativeTypeName: data?.operativeTypeName,
                    operativeTypeNameEn: data?.operativeTypeNameEn
                })
            }
        }

    }, [data])

    const onClosePop = () => {
        setTimeout(() => {
            onClosePopTag()
        }, 300)
    }

    const onSubmit = async () => {
        if ((!tagName || tagName === '') ||
            (!tagColor || tagColor === '' || tagColor === '#')
        ) {
            setErrorTag(true)
            return
        }

        const newData: tagProps = {
            tagName: tagName,
            tagNameEn: tagName,
            color: tagColor,
            operativeTypeId: operativeType?.operativeTypeId || null
        }

        if (!isEdit) {
            await TagsApi.createTag((newData))
                .then((res) => {
                    onClosePop()
                    reLoadData()
                    notiSuccess(t('TAGS.MESSAGE.ADD_SUCCESSFUL'))
                }).catch((err) => {
                    const errorMessage = err?.response?.data
                    if (errorMessage.message === 'IS_DUPLICATE_TAG_NAME') {
                        notiError(t(`TAGS.MESSAGE.${errorMessage.message}`))
                    } else {
                        notiError(t('TAGS.MESSAGE.ERROR'))
                    }
                })
            return
        }

        if (isEdit && tagId) {
            await TagsApi.updateTag(tagId, { ...newData, status: status })
                .then((res) => {
                    onClosePop()
                    reLoadData()
                    notiSuccess(t('TAGS.MESSAGE.UPDATE_SUCCESSFUL'))
                }).catch((err) => {
                    const errorMessage = err?.response?.data
                    if (errorMessage.message === 'IS_DUPLICATE_TAG_NAME') {
                        notiError(t(`TAGS.MESSAGE.${errorMessage.message}`))
                    } else {
                        notiError(t('TAGS.MESSAGE.ERROR'))
                    }
                })
            return
        }
    }

    const handleChangeColor = ({ hex }: { hex: string }) => {
        setErrorTag(false)
        setTagColor(hex)
        setOperativeType({})
    }

    const handleChangeOperative = (val: any) => {
        setOperativeType(val)
    }

    return (
        <>
            <ModalCustom
                title={t(`TAGS.TITLE_${!isEdit ? 'CREATE' : 'UPDATE'}`)}
                closeButton
                onClose={() => onClosePop()}
                onReset={() => onClosePop()}
                textBtnCancel={t('TAGS.BUTTON.CANCEL')}
                textBtnConfirm={t('TAGS.BUTTON.SAVE')}
                onSubmit={() => {
                    onSubmit()
                }}
                component={(
                    <Box>
                        {isEdit &&
                            <Box>
                                <Form className="mx-0 mt-0 mb-0 p-0  d-inline-flex w-100">
                                    <Form.Label className=" mr-auto mb-0" >{t('INPUT.LABEL_STATUS')}</Form.Label>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        onChange={() => setStaus(status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
                                        checked={status === 'ACTIVE' ? true : false}
                                        style={{ marginLeft: 'auto' }}
                                    />
                                </Form>
                            </Box>
                        }
                        <Box>
                            <Box sx={{ textAlign: 'right', fontSize: '10px' }}>
                                {tagName.length}/20
                            </Box>
                            <InputTextField
                                label={t('TAGS.FORM.TAGNAME')}
                                value={tagName}
                                onchange={(event) => {
                                    setErrorTag(false)
                                    const newValue = event.target.value
                                    if (newValue?.length <= 20) {
                                        setTagName(newValue)
                                    }
                                }}
                                required
                                helperText={errorTag && (!tagName || tagName === '') ? t("TAGS.FORM.TAGNAME_PLEASE") : ''}
                            />
                        </Box>
                        <Box className='mt-3'>
                            <Box>
                                {t('TAGS.FORM.TAGCOLOR')}<span style={{ color: colors.statusInactive, marginLeft: '3px' }}>*</span>
                            </Box>
                            <ClickAwayListener onClickAway={() => setPopSelectColor(false)}>
                                <Box sx={{ position: 'relative' }}>
                                    <ColorTag
                                        color={tagColor || 'transparent'}
                                        error={errorTag && (!tagColor || tagColor === '' || tagColor === '#')}
                                        onClick={() => setPopSelectColor(!popSelectColor)}
                                    />
                                    {errorTag && (!tagColor || tagColor === '' || tagColor === '#') &&
                                        <Box>
                                            <HelperText label={t('TAGS.FORM.TAGCOLOR_PLEASE')} />
                                        </Box>
                                    }
                                    {popSelectColor &&
                                        <FormManageColor
                                            color={tagColor}
                                            setColor={setTagColor}
                                            handleChangeColor={handleChangeColor}
                                            onLoadOperativeType={onLoadOperativeType}
                                            operativeTypes={operativeTypes}
                                            operativeType={operativeType}
                                            handleChangeOperative={handleChangeOperative}
                                        />
                                    }
                                </Box>
                            </ClickAwayListener>
                        </Box>
                    </Box>
                )}
            />
        </>
    )
}

export default FormManageTag