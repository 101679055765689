import branchAndServicePoint from 'locales/plus/en/setting/branch-service-point.json'
import defaultLab from 'locales/plus/en/setting/default-labs.json'
import defaultProduct from 'locales/plus/en/setting/default-products.json'
import defaultUsers from 'locales/plus/en/setting/default-users.json'
import defaultSetting from 'locales/plus/en/setting/defaults.json'
import dfManagement from 'locales/plus/en/setting/doctor-fee-managements.json'
import cfManagement from 'locales/plus/en/setting/credit-fee-managements.json'
import finance from 'locales/plus/en/setting/finances.json'
import personalManagement from 'locales/plus/en/setting/personnel-managements.json'
import treatmentCourse from 'locales/plus/en/setting/treatment-courses.json'
import treatmentPlan from 'locales/plus/en/setting/treatment-plans.json'
import treatmentRight from 'locales/plus/en/setting/treatment-rights.json'
import treatment from 'locales/plus/en/setting/treatments.json'
import doctorNote from 'locales/plus/en/setting/doctor-note.json'
import formatForm from 'locales/plus/en/setting/branch-format-form.json'

export const SettingEN = {
  ...branchAndServicePoint,
  ...defaultLab,
  ...defaultProduct,
  ...defaultUsers,
  ...defaultUsers,
  ...defaultSetting,
  ...dfManagement,
  ...finance,
  ...personalManagement,
  ...treatmentCourse,
  ...treatmentPlan,
  ...treatmentRight,
  ...treatment,
  ...doctorNote,
  ...cfManagement,
  ...formatForm
}
