import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { colors } from 'constants/theme'
import { KEY_STORAGE } from 'constants/common'

export const RowFilter = styled(Box)(({ theme }) => ({
  '.MuiAutocomplete-root': {
    '.MuiInputBase-formControl': {
      paddingTop: '2.5px !important',
      paddingRight: '28px !important',
      '.MuiOutlinedInput-input': {
        lineHeight: 2
      },
      '.MuiAutocomplete-clearIndicator': {
        right: 7
      }
    }
  },
  '.btn-export': {
    button: {
      marginLeft: 'auto'
    },
    '&.disabled': {
      button: {
        border: `1px solid ${colors.disabledGray} !important`,
        color: `${colors.disabledGray} !important`
      }
    }
  }
}))

export const AutoCompleteFilter = styled(Box)<{ type?: 'branch' | 'patient' | 'operativeType' | 'operative' }>(({ theme, type }) => ({
  '.title': {
    position: 'absolute',
    top: '50%',
    left: 16,
    transform: 'translateY(-50%)',
    zIndex: 1
  },
  '.MuiAutocomplete-root': {
    '.MuiInputBase-formControl': {
      paddingLeft: `${(type === 'branch' && '81px') || (type === 'patient' && '95px') || (type === 'operativeType' && '127px') || (!type && '0px') || '74px'} !important`
    }
  }
}))

export const ResponsiveTable = styled(Box)(({ theme }) => ({
  '.MuiTable-root': {
    '.MuiTableBody-root': {
      '.MuiTableCell-root': {
        verticalAlign: 'top !important',
        paddingBottom: '16px',
        '&.action': {
          paddingTop: '13px'
        }
      }
    }
  }
}))

export const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: colors.themeMainColor,
  fontSize: 16
}))