import { useCallback, useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import MenuItem from '@mui/material/MenuItem'
import { Box, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { useLocation } from 'react-router-dom';

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE */
import * as Styled from 'features/setting/treatments/recalls/style'

/** API */
import RecallApi from 'api/setting/treatments/recall.api'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import { routeName } from 'routes/routes-name'
/** UTILS || SLICE */
import { submitModal, unSubmitModal, showModal, resetModal } from 'app/slice/modal.slice'
import { isCreate, isDelete, isUpdate, isView } from 'utils/verification.utils'
import { colors } from 'constants/theme'

/** COMPONENT */
import HeaderLabel from 'component/CardCustom/HeaderLabel'
import InputTextField from 'component/Input/InputTextField'
import BasicSelect from 'component/Select/BasicSelect'
import AutocompleteSelect from 'component/Select/AutocompleteSelect'
import Noti from 'assets/update/noti'
import { icons, Icons } from 'constants/images'
import InputTextarea from 'component/Input/InputTextarea'
import { getBranch } from 'utils/app.utils'
import TreatmentApi from 'api/dentists/treatment.api'
import { notiError, notiSuccess } from 'component/notifications/notifications'

interface IFTreatmentListCreate {
    itemTypes: [{
        itemTypeId: number
        itemTypeName: string
    }]
    items: [{
        group: string
        itemCode: string
        itemId: number
        itemName: string
        itemNameEn: string
        itemTypeId: number
        itemTypeName: string
        itemTypeNameEn: string
        itemUnitName?: string
        itemUnitNameEn?: string
        price: number
    }]
    disabled: boolean
    refId?: number
    id?: any
    price?: any,
    dateUse?: any,
    doctorId?: number,
    duration?: any,
    durationUnit?: string
    status?: string
    type?: any,
    itemId: any,
    itemTypeId: any,
    qty?: any,
    itemCode?: any,
    balance?: number
}

const initStateErrorMessage = {
    RECALL_NOT_FOUND: ``,
    RECALL_ALREADY_IN_SYSTEM: ``,
    CREATE_RECALL_WAS_UNSUCCESSFU: ``,
    UPDATE_RECALL_UNSUCCESSFUL: ``,
    IS_DUPLICATE_RECALL_NAME: ``,
    IS_DUPLICATE_RECALL_NAME_EN: ``,
    OPERATIVE_TYPE: ``,
    OPERATIVE_TYPE_NUMBER_BASE: ``,
    OPERATIVE_TYPE_ANY_REQUIRED: ``,
    RECALL_AMOUNT: ``,
    RECALL_AMOUNT_NUMBER_BASE: ``,
    RECALL_AMOUNT_ANY_REQUIRED: ``,
    MESSAGE: ``,
    MESSAGE_STRING_VALID: ``,
    MESSAGE_ANY_REQUIRED: ``,
    TREATMENT_ID: ``
}

export default function FormRecalls() {
    const { t } = useTranslation()
    const branchId = getBranch()
    const location = useLocation()
    const [recalls, setRecalls] = useState<any>(location?.state)
    const [recallId, setRecallId] = useState(Number)
    const [recallAmount, setRecallAmount] = useState<string>('')
    const [recallMessage, setRecallMessage] = useState<string>(t('RECALL.INPUT.DEFAULT'))
    const [recallStatus, setRecallStatus] = useState('ACTIVE')

    const [errorMessage, setErrorMessage] = useState<any>(initStateErrorMessage)

    const [recallUnit, setRecallUnit] = useState<string>('DAY')

    const [operativeTypes, setOperativeTypes] = useState<any>([])
    const [operativeType, setOperativeType] = useState<any>({ type: "text", name: "", value: 0 })
    const [operativeTypeId, setOperativeTypeId] = useState<number>(0)

    const [treatmentItems, setTreatmentItems] = useState<any[]>([])
    const [treatmentItemId, setTreatmentItemId] = useState<number>(0)
    const [treatmentItem, setTreatmentItem]: any = useState({ itemName: ``, itemId: 0 })

    const [hover, setHover] = useState<boolean>(false)


    const clearErrorMessage = () => setErrorMessage(initStateErrorMessage)
    const UsageType = [
        { text: ':PATIENT_FULLNAME', show: t('RECALL.MESSAGE.PATIENT_NAME') },
        { text: ':OPERATIVE_NAME', show: t('RECALL.MESSAGE.SERVICE_NAME') },
        { text: ':CLINIC_NAME', show: t('RECALL.MESSAGE.CLINIC_NAME') },
        { text: ':BRANCH_NAME', show: t('RECALL.MESSAGE.BRANCH_NAME') },
        { text: ':DATE_RECALL', show: t('RECALL.MESSAGE.RECALL_DATE') },
    ]

    const loadOperativeTypes = useCallback(() => {
        OperativeTypeApi.findAllActive({}).then((respOperativeTypes) => {
            if (respOperativeTypes.status === 200) {
                const newOperativeTypes: any[] = []
                for (const index in respOperativeTypes.data) {
                    const operative = respOperativeTypes.data[index]
                    newOperativeTypes.push({ type: 'text', name: operative.operativeTypeName, value: operative.operativeTypeId })
                }
                setOperativeTypes(newOperativeTypes)
            }
        })
    }, [])
    const loadTreatmentItem = useCallback((group?: string, typeId?: number, search?: string) => {
        let condition: any = {}
        condition = { ...condition, userId: 121 }
        condition = { ...condition, page: 1 }
        condition = { ...condition, pageLimit: 100 }
        condition = { ...condition, group }
        condition = { ...condition, branchId: branchId }
        if (search) condition = { ...condition, search: search }
        if (typeId) condition = { ...condition, typeId: typeId }
        TreatmentApi.getItems(condition).then((res) => {
            if (res.status === 200) setTreatmentItems(res.data)
        })
        RecallApi.findAll(condition).then((res) => {
            if (res.status === 200) setRecalls(res.data)
        })

    }, [])

    useEffect(() => {
        loadOperativeTypes()
        loadTreatmentItem()
    }, [loadOperativeTypes, loadTreatmentItem])

    useEffect(() => {
        const { state }: any = location
        if (state) {
            setRecallId(state.recallId)
            setRecallAmount(state.recallAmount)
            setRecallMessage(state.message)
            setRecallStatus(state.status)
            setRecallUnit(state.recallUnit)
            setOperativeTypeId(state.operativeTypeId)
            setTreatmentItemId(state.operativeId)

            setTreatmentItem({ itemName: state.operativeName, itemId: state.operativeId })
            setOperativeType({ type: "text", name: state.operativeTypeName || '', value: state.operativeTypeId })

        }
    }, [location])

    const submit = () => {
        if (!recallAmount || !operativeTypeId || !recallMessage || !treatmentItemId) {
            return setErrorMessage({
                ...errorMessage,
                ...{
                    RECALL_AMOUNT: !recallAmount ? t('RECALL.MESSAGE.RECALL_AMOUNT') : '', OPERATIVE_TYPE: !operativeTypeId ? t('RECALL.MESSAGE.OPERATIVE_TYPE') : '', MESSAGE: !recallMessage ? t('RECALL.MESSAGE.MESSAGE') : '',
                    TREATMENT_ID: !treatmentItemId ? t('RECALL.MESSAGE.TREATMENT_ID') : ''
                }
            })
        }

        RecallApi.create({ operativeTypeId: operativeTypeId, recallAmount: recallAmount, recallUnit: recallUnit, message: recallMessage, operativeId: treatmentItemId })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 201) {
                    notiSuccess(t('RECALL.MESSAGE.SUCCESS.CREATE'), 'go', routeName.recalls,)
                    resetForm()
                } else {
                    const err = res.response.data
                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECALL.MESSAGE.${err.message}`) } })
                }
            })
            .catch((e) => {
                const err = e.response.data
                notiError(t('RECALL.MESSAGE.RECALL_ALREADY_IN_SYSTEM'))
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECALL.MESSAGE.${err.message}`) } })
            })
    }

    const submitEdit = () => {
        if (!recallAmount || !operativeTypeId || !recallMessage || !treatmentItem.itemId) {
            return setErrorMessage({
                ...errorMessage,
                ...{
                    RECALL_AMOUNT: !recallAmount ? t('RECALL.MESSAGE.RECALL_AMOUNT') : '', OPERATIVE_TYPE: !operativeTypeId ? t('RECALL.MESSAGE.OPERATIVE_TYPE') : '', MESSAGE: !recallMessage ? t('RECALL.MESSAGE.MESSAGE') : '',
                    TREATMENT_ID: !treatmentItem.itemId ? t('RECALL.MESSAGE.TREATMENT_ID') : ''
                }
            })
        }

        RecallApi.update(recallId, {
            operativeTypeId: operativeTypeId,
            recallAmount: recallAmount,
            recallUnit: recallUnit,
            message: recallMessage,
            operativeId: treatmentItem.itemId,
            status: recallStatus
        })
            .then((res: any) => {
                if (res.status !== undefined && res.status === 200) {
                    notiSuccess(t('RECALL.MESSAGE.SUCCESS.UPDATE'), 'go', routeName.recalls,)
                    resetForm()
                } else {
                    const err = res.response.data

                    setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECALL.MESSAGE.${err.message}`) } })
                }
            })
            .catch((e) => {
                const err = e.response.data
                notiError(t('RECALL.MESSAGE.RECALL_ALREADY_IN_SYSTEM'))
                setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`RECALL.MESSAGE.${err.message}`) } })
            })
    }

    const resetForm = () => {
        setOperativeTypeId(0)
        setRecallAmount('')
        setRecallUnit('DAY')
        setRecallMessage(t('RECALL.INPUT.DEFAULT'))
        clearErrorMessage()
        setOperativeType({ type: "text", name: "", value: 0 })
        setTreatmentItem({ itemName: ``, itemId: 0 })
        setRecallStatus('')
    }

    return (
        <div className='overflow-hidden px-4 pb-4'>
            <HeaderLabel
                onSubmit={recallId ? submitEdit : submit}
                text={recallId ? t('RECALL.TITLE_UPDATE') : t('RECALL.TITLE_CREATE')}
                navigator={{ previousTo: routeName.recalls, previousTitle: t('RECALL.TITLE'), currentTitle: recallId ? t('RECALL.TITLE_UPDATE') : t('RECALL.TITLE_CREATE') }}
                goBack={routeName.recalls}
            />

            <Card style={{ border: 'none' }} >
                <Card.Header style={{ backgroundColor: colors.white }} className='px-4'>
                    <Row className='pb-3'>
                        <Col sm={2} className="mb-2 mb-lg-0">
                            <InputTextField
                                onchange={(event) => {
                                    const amount = String(event.target.value).replace(/[^0-9.]/g, '')
                                    setRecallAmount(String(amount))
                                    clearErrorMessage()
                                }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                                value={recallAmount}
                                label={t('RECALL.INPUT.RECALL_TIME')}
                                helperText={errorMessage.RECALL_AMOUNT || errorMessage.RECALL_AMOUNT_NUMBER_BASE || errorMessage.RECALL_AMOUNT_ANY_REQUIRED}
                                required={true}
                                hideControl
                            />
                        </Col>
                        <Col sm={2} className="mb-2 mb-lg-0">
                            <BasicSelect
                                onchange={(event) => {
                                    const value = event.target.value
                                    if (value) {
                                        setRecallUnit(value)
                                        clearErrorMessage()
                                    }
                                }}
                                label={t('RECALL.INPUT.RECALL_UNIT')}
                                selectId="select-recall-unit"
                                value={recallUnit}
                                labelId="label-recall-unit"
                                required
                                options={[
                                    <MenuItem key="1" value="DAY">
                                        {t('TIME_DURATION_TYPE.DAY')}
                                    </MenuItem>,
                                    <MenuItem key="2" value="WEEK">
                                        {t('TIME_DURATION_TYPE.WEEK')}
                                    </MenuItem>,
                                    <MenuItem key="3" value="MONTH">
                                        {t('TIME_DURATION_TYPE.MONTH')}
                                    </MenuItem>,
                                    <MenuItem key="4" value="YEAR">
                                        {t('TIME_DURATION_TYPE.YEAR')}
                                    </MenuItem>
                                ]}
                            />
                        </Col>
                        <Col sm={4} className="mb-2 mb-lg-0">
                            <AutocompleteSelect
                                labelId="label-operative-type"
                                inputLabel={t('RECALL.INPUT.TREATMENT')}
                                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                options={operativeTypes}
                                required
                                getOptionLabel={(option: any) => option.name}
                                renderOption={(props, option: any) => (
                                    <Box key={option} component="li" {...props}>
                                        {option.name}
                                    </Box>
                                )}
                                onchange={(e, value: any) => {
                                    setOperativeTypeId(value.value)
                                    setOperativeType(value)
                                    clearErrorMessage()
                                    setTreatmentItems([])
                                    setTreatmentItemId(0)
                                    setTreatmentItem({ itemName: ``, itemId: 0 })
                                    loadTreatmentItem('OPERATIVE', value.value, '')
                                }}
                                value={operativeType}
                                key={'label-operative-type'}
                                errMessage={errorMessage.OPERATIVE_TYPE}
                                disableClearable
                            />
                        </Col>
                        <Col sm={4} className="mb-2 mb-lg-0">
                            <AutocompleteSelect
                                disableClearable
                                required
                                labelId={`lb-item`}
                                inputLabel={t('RECALL.INPUT.OPERATIVE')}
                                noOptionsText={t('REGISTER.FORM.NOT_FOUND')}
                                options={treatmentItems}
                                getOptionLabel={(option: any) => option.itemName}
                                renderOption={(props, option: any) => <Box key={option} component="li" {...props}>   {option.itemName} </Box>}
                                disabled={operativeTypeId === 0 ? true : false}
                                getOptionDisabled={(option: any) => recalls.some((e: any) => e.operativeId === option.itemId) ? true : false}
                                onchange={(e, value: any) => {
                                    setTreatmentItemId(value.itemId)
                                    clearErrorMessage()
                                    setTreatmentItem(value)
                                }}
                                onInputChange={(e, value: any) => {
                                    clearErrorMessage()
                                    setTreatmentItems([])
                                    setTreatmentItemId(0)
                                    loadTreatmentItem('OPERATIVE', operativeTypeId, value,)
                                }}
                                onOpen={() => {
                                    clearErrorMessage()
                                    setTreatmentItems([])
                                    setTreatmentItem({ itemName: ``, itemId: 0 })
                                    setTreatmentItemId(0)
                                    loadTreatmentItem('OPERATIVE', operativeTypeId, '',)
                                }}
                                value={treatmentItem}
                                errMessage={errorMessage.TREATMENT_ID}
                            />
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Grid container >
                        <Grid item lg={8} sm={12} className='py-3 pl-4 pr-4'>
                            <Box className='pb-3 d-flex flex-row'>
                                <Typography style={{ fontSize: 18, fontWeight: 500, color: colors.black }}>{t('RECALL.LABEL.SETTING_MSG')}</Typography>
                                <img src={icons.exclamation_mark} alt="exclamation_mark" className='mx-1'
                                    onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => setHover(!hover)}
                                />
                                <Styled.AutoNotiButton className={`${hover ? '' : 'd-none'}`}>{t('RECALL.LABEL.AUTO_NOTIFICATION')}</Styled.AutoNotiButton>
                            </Box>
                            <Col>
                                <InputTextarea
                                    required
                                    inputProps={{ maxLength: 255 }}
                                    label={t('RECALL.LABEL.MESSAGE')}
                                    key={`textarea-message`}
                                    height={176}
                                    value={recallMessage}
                                    onchange={(event) => {
                                        setRecallMessage(event.target.value)
                                        clearErrorMessage()
                                    }}
                                    helperText={errorMessage.MESSAGE || errorMessage.MESSAGE_ANY_REQUIRED || errorMessage.MESSAGE_STRING_VALID}
                                />
                            </Col>

                        </Grid>
                        <Grid item lg={4} sm={12} className='py-lg-3 px-lg-4 py-0 px-0 w-100' >
                            <Box className='pb-0 d-flex flex-row pb-lg-3'>
                                <Typography style={{ fontSize: 18, fontWeight: 500, color: colors.black }}>{t('RECALL.LABEL.RECALL_TYPE')}</Typography>
                            </Box>
                            <div className='d-flex justify-content-between w-lg-75 pb-3 w-100'>
                                <span style={{ fontSize: 16, fontWeight: 500, color: colors.black }}>{t('RECALL.MESSAGE.CALLING_FORMAT')}</span>
                                <span style={{ fontSize: 16, fontWeight: 500, color: colors.black }}>{t('RECALL.MESSAGE.DISPLAYED_DATA')}</span>
                            </div>
                            {_.map(UsageType, (item: any) => {
                                return (
                                    <div className='d-flex justify-content-between w-lg-75 pb-1 w-100'>
                                        <span>{item.text}</span> <Styled.ShowingTag>{item.show}</Styled.ShowingTag>
                                    </div>
                                )
                            })}
                        </Grid>
                    </Grid>

                </Card.Body>
            </Card>
        </div>
    )
}