import { useEffect, useState } from 'react'
import { Button, Menu, Tabs, Tab, Box } from '@mui/material'
import _ from 'lodash'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'

/** STYLE / CONSTANTS */
import { styled } from '@mui/material/styles'
import { colors } from 'constants/theme'

/** API */
import ComponentApi from 'api/components-api'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const InputAddress = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: 0,
  backgroundColor: 'transparent !important',
  position: 'relative',
  '.MuiButton-endIcon': {
    position: 'absolute',
    right: 14,
    svg: {
      fontSize: 16,
      color: colors.black
    }
  },
  '.MuiOutlinedInput-input': {
    textOverflow: 'ellipsis',
    marginRight: 10,
  },
  '&[aria-expanded="true"]': {
    '.MuiButton-endIcon': {
      transform: 'rotate(180deg)'
    }
  }
}))

const MenuDropdown = styled(Menu)(({ theme }) => ({
  '.MuiPaper-root': {
    overflow: 'hidden',
    boxShadow: `0 8px 10px 0 ${colors.black12}`,
    borderRadius: 4
  },
  '.MuiList-root': {
    maxHeight: 312,
    marginTop: 4,
    padding: 0,
    paddingBottom: 16
  }
}))

const HeaderTabsRow = styled(Tabs)(({ theme }) => ({
  position: 'relative',
  '&:before': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    height: 2,
    width: '100%',
    backgroundColor: colors.extraLightBlue
  },
  '.MuiTabs-indicator': {
    backgroundColor: colors.themeSecondColor
  }
}))

const HeaderTab = styled(Tab)(({ theme }) => ({
  width: '33.33%',
  paddingLeft: 0,
  paddingRight: 0,
  fontSize: 16,
  color: colors.textPrimary,
  '&.Mui-selected': {
    color: colors.themeSecondColor
  }
}))

const BodyTabPanel = styled('div')(({ theme }) => ({
  maxHeight: 'calc(312px - 48px - 16px)',
  overflowX: 'hidden',
  overflowY: 'auto',
  marginTop: 6
}))

const ListItem = styled('div')(({ theme }) => ({
  fontSize: 14,
  color: colors.textPrimary,
  padding: '0.375rem 1.5rem',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: colors.themeSecondColor06,
    color: colors.themeSecondColor
  }
}))

type AddressProps = {
  patientId: number | string
  countryId?: number | string
  provinceId?: number
  districtId?: number
  subDistrictId?: number
  postcode?: string
  disabled?: boolean
  label?: string,
  provinceText?: string,
  districtText?: string,
  subDistrictText?: string,
  callbackAddress?: (val: any) => void
  required?: boolean
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const addressValue = {
  provinceId: 0,
  districtId: 0,
  subDistrictId: 0,
  postcode: ''
}

export default function SelectAddress(props: AddressProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [dropdownWidth, setDropdownWidth] = useState(0)
  const [tab, setTab] = useState(0)
  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [subDistricts, setSubDistricts] = useState([])
  const [address, setAddress] = useState(addressValue)
  const [disabledField, setDisabledField] = useState(false)

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    const width = Array.from(document.getElementsByClassName('select-address') as HTMLCollectionOf<HTMLElement>)
    setDropdownWidth(Number(width[0]?.offsetWidth))
    setAnchorEl(event.currentTarget)
    setTab(0)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const loadProvinces = async () => {
    if (sessionStorage.getItem('provinces')) {
      const items: any = sessionStorage.getItem('provinces')
      setProvinces(JSON.parse(items))
    } else {
      const resProvinces = await ComponentApi.findAllProvinces()
      if (resProvinces.status === 200) {
        const items: any = _.orderBy(resProvinces.data, ['provinceNameTh', 'provinceNameEn'], ['asc', 'desc'])
        setProvinces(items)
        sessionStorage.setItem('provinces', JSON.stringify(items))
      }
    }
  }

  const loadDistricts = async (provinceId: string) => {
    const resDistricts = await ComponentApi.findAllDistricts(provinceId)
    if (resDistricts.status === 200) {
      const items: any = _.orderBy(resDistricts.data, ['districtNameTh', 'districtNameEn'], ['asc', 'desc'])
      setDistricts(items)
      if (props?.districtText) {
        const district: any = _.find(items, (val: any) => val.districtNameTh.search(props.districtText) !== -1)
        if (district) {
          setAddress({ ...address, ...{ districtId: district.districtId, subDistrictId: 0, postcode: '' } })
          loadSubDistricts(district.districtId.toString(), provinceId.toString())
          setTab(2)
        }
      } else if (props.districtId) loadSubDistricts(props.districtId.toString())
    }
  }

  const loadSubDistricts = async (districtId: string, provinceId = ``) => {
    const resSubDistricts = await ComponentApi.findAllSubDistricts(districtId)
    if (resSubDistricts.status === 200) {
      const items: any = _.orderBy(resSubDistricts.data, ['subDistrictNameTh', 'subDistrictNameEn'], ['asc', 'desc'])
      setSubDistricts(items)

      if (props?.subDistrictText) {

        const subDistrict: any = _.find(items, (val: any) => val.subDistrictNameTh.search(props.subDistrictText) !== -1)
        if (subDistrict) {
          setAddress({ ...address, ...{ provinceId: parseInt(provinceId), districtId: parseInt(districtId), subDistrictId: subDistrict.subDistrictId, postcode: subDistrict.postcode } })
          handleCloseMenu()
        }
      }

    }
  }

  const renderProvince = () => {

    const province: any = _.find(provinces, (val: any) => val.provinceNameTh.search(props.provinceText) !== -1)
    if (props.provinceText && props.districtText && props.subDistrictText && province) {
      setAddress({ ...address, ...{ provinceId: province.provinceId, districtId: 0, subDistrictId: 0, postcode: '' } })
      setDistricts([])
      loadDistricts(province.provinceId.toString())
      setTab(1)
    }
  }

  const getItemName = (keyName: string, id: number | string) => {
    if (keyName === 'province' && provinces && address.provinceId) return _.find(provinces, { provinceId: id }) ? _.get(_.find(provinces, { provinceId: id }), 'provinceNameTh') : ''
    else if (keyName === 'district' && districts && address.districtId) return _.find(districts, { districtId: id }) ? ', ' + _.get(_.find(districts, { districtId: id }), 'districtNameTh') : ''
    else if (keyName === 'subDistrict' && subDistricts && address.subDistrictId) return _.find(subDistricts, { subDistrictId: id }) ? ', ' + _.get(_.find(subDistricts, { subDistrictId: id }), 'subDistrictNameTh') : ''
    else if (keyName === 'postcode' && address.postcode) return ', ' + address.postcode
    else return ''
  }
  useEffect(() => {
    renderProvince()
  }, [props.provinceText, props.districtText, props.subDistrictText])

  useEffect(() => {
    if (props?.callbackAddress) props?.callbackAddress(address)
  }, [address.subDistrictId])

  useEffect(() => {
    loadProvinces()
    if (props.provinceId && !props.provinceText && !props.districtText && !props.subDistrictText) {
      setAddress({ provinceId: props.provinceId || 0, districtId: props.districtId || 0, subDistrictId: props.subDistrictId || 0, postcode: props.postcode || '' })
      loadDistricts(props.provinceId.toString())
    } else {
      setAddress(addressValue)
    }
  }, [props.patientId, props.provinceId])

  useEffect(() => {
    if (props.countryId === 219 || props.countryId === "Thailand" || props.countryId === "TH") {
      setDisabledField(false)
    } else {
      setAddress(addressValue)
      setDisabledField(true)
    }
  }, [props.countryId])

  return (
    <div>
      <InputAddress
        id="basic-button"
        className={'select-address'}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenu}
        endIcon={!open ? <FontAwesomeIcon icon={faCaretDown} /> : <></>}
        disabled={props.disabled || disabledField}
        disableFocusRipple
        disableRipple>
        <div className="pe-none w-100">
          <InputTextField
            disabled={props.disabled || disabledField}
            label={props.label || t('SELECT_ADDRESS')}
            value={
              address.provinceId ? getItemName('province', Number(address.provinceId)) + getItemName('district', Number(address.districtId)) + getItemName('subDistrict', Number(address.subDistrictId)) + getItemName('postcode', address.postcode) : ''
            }
            required={props?.required}
          />
        </div>
      </InputAddress>
      <MenuDropdown
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        sx={{ '.MuiMenu-list': { width: dropdownWidth } }}
      >
        <Box sx={{ width: '100%' }}>
          <HeaderTabsRow value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
            <HeaderTab label={t('PROVINCE')} {...tabListProps(0)} />
            <HeaderTab className={`${!address.provinceId ? 'pe-none' : ''}`} label={t('DISTRICT')} {...tabListProps(1)} />
            <HeaderTab className={`${!address.districtId ? 'pe-none' : ''}`} label={t('SUBDISTRICT')} {...tabListProps(2)} />
          </HeaderTabsRow>

          <TabPanel value={tab} index={0}>
            {_.map(provinces, (province: any, index: number) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => {
                    setAddress({ ...address, ...{ provinceId: province.provinceId, districtId: 0, subDistrictId: 0, postcode: '' } })
                    setDistricts([])
                    loadDistricts(province.provinceId.toString())
                    setTab(1)
                  }}
                >
                  {province.provinceNameTh}
                </ListItem>
              )
            })}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            {_.map(districts, (district: any, index: number) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => {
                    setAddress({ ...address, ...{ districtId: district.districtId, subDistrictId: 0, postcode: '' } })
                    loadSubDistricts(district.districtId.toString())
                    setTab(2)
                  }}
                >
                  {district.districtNameTh}
                </ListItem>
              )
            })}
          </TabPanel>
          <TabPanel value={tab} index={2}>
            {_.map(subDistricts, (subDistrict: any, index: number) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => {
                    setAddress({ ...address, ...{ subDistrictId: subDistrict.subDistrictId, postcode: subDistrict.postcode } })
                    handleCloseMenu()
                  }}
                >
                  {subDistrict.subDistrictNameTh}
                </ListItem>
              )
            })}
          </TabPanel>
        </Box>
      </MenuDropdown>
    </div>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <BodyTabPanel className={'custom-scroll'} role="tabpanel" hidden={value !== index} id={`address-tabpanel-${index}`} aria-labelledby={`address-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </BodyTabPanel>
  )
}

function tabListProps(index: number) {
  return {
    id: `address-tab-${index}`,
    'aria-controls': `address-tabpanel-${index}`
  }
}
