import { useState, useEffect, useCallback } from 'react'
import { Card } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom'
import 'features/setting/treatment-courses/treatment-course/treatment-course.css'
import { Grid, Divider, Tab, Box, MenuItem, Typography, IconButton, TableRow, TableCell } from '@mui/material';
import _ from 'lodash'
import $ from 'jquery'

/** TRANSLATION*/
import { useTranslation } from 'react-i18next'
import HeaderLabel from 'component/CardCustom/HeaderLabel'

/** STYLE */
import * as UseStyled from 'features/setting/treatment-courses/treatment-course/style'
import * as UsedTxPlanStyled from 'features/setting/treatment-plans/treatment-plans/style'
import { TypeTabs, ButtonType, ProductItem, ProductBox, ProductCard, InventoryChip } from 'features/treatments/MainMenu/OPD/style/ProductUseStyle';
import * as SummaryTreatmentStyle from 'features/treatments/SummaryTreatment/UseStyle'
import { colors } from 'constants/theme'

/** API */
import { fileUrl } from 'api/api'

/** COMPONENT */
import InputTextField from 'component/Input/InputTextField'
import TableCustom from 'component/Table/TableCustom'
import BasicSelect from 'component/Select/BasicSelect'
import TableRowNonIcon from 'component/Table/TableRowNonIcon'
import OperativeTypeApi from 'api/setting/treatments/operative-type.api'
import OperativeApi from 'api/setting/treatments/operative.api'
import ProductTypesApi from 'api/setting/default-products/product-type.api'
import ProductsApi from 'api/warehouses/product.api'
import TreatmentCourseTypeApi, { TreatmentCourseTypeInterface } from 'api/setting/treatment-courses/treatment-course-type.api'
import TreatmentCourseApi, { TreatmentCourseInterface, TreatmentCoursePaymentInterface } from 'api/setting/treatment-courses/treatment-course.api'
import { notiSuccess, notiError } from 'component/notifications/notifications'
import { routeName } from 'routes/routes-name'
import { icons, onImgError } from 'constants/images';
import { getBranch, numberFormat, numberOnly } from 'utils/app.utils'

/** ICON */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import AddIcon from '@mui/icons-material/Add'
import TableRowCommon from 'component/Table/TableRowCommon'
import MedicinesApi from 'api/warehouses/medicine.api';
import FormTrementCoursePayment, { DataPayment, InterfaceTreatmentCoursePayment, dataPaymentProps } from './form-trement-course-payment';
import { selectMe } from 'app/slice/user.slice';
import { useSelector } from 'react-redux';
import TreatmentProductApi from 'api/dentists/treatmentProduct.api';
import InputTextSearch from 'component/Input/InputTextSearch';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import IconMoveTableRow from 'assets/icon/iconMoveTableRow';

interface ValueInterface {
  group?: 'TX' | 'MED' | 'PD'
  itemId: any
  type: string
  itemCode: string
  itemName: string
  unit: string
  price: number
  img?: string
}

interface SummaryTableCourseInterface {
  group?: 'TX' | 'MED' | 'PD'
  itemId: any
  itemCode: string
  itemName: string
  priceUnit: any
  amount: any
  itemSort?: number
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number | string
  value: number | string
  className?: string
}

const initStateErrorMessage = {
  TREATMENT_COURSE_TYPE_ANY_REQUIRED: '',
  TREATMENT_COURSE_TYPE_NUMBER: '',

  TREATMENT_COURSE_NAME_STRING_BASE: '',
  IS_DUPLICATE_TREATMENT_COURSE_NAME: '',
  TREATMENT_COURSE_NAME_MAXIMUM_LENGTH: '',
  TREATMENT_COURSE_NAME_STRING_EMPTY: '',
  TREATMENT_COURSE_NAME_ANY_REQUIRED: '',

  TREATMENT_COURSE_NAME_EN_STRING_BASE: '',
  IS_DUPLICATE_TREATMENT_COURSE_NAME_EN: '',
  TREATMENT_COURSE_NAME_EN_MAXIMUM_LENGTH: '',
  TREATMENT_COURSE_NAME_EN_STRING_EMPTY: '',
  TREATMENT_COURSE_NAME_EN_ANY_REQUIRED: '',

  EMPTY_COURSE_TYPE: '',
  EMPTY_COURSE_NAME: '',
  EMPTY_COURSE_NAME_EN: '',
  EMPTY_COURSE_SUMMARY: '',
}

export default function FromTreatmentCourse() {
  const { t } = useTranslation()
  const history = useHistory()
  const location: any = useLocation()
  const [initLocation, setInitLocation] = useState()
  const [editMenu, setEditMenu] = useState<TreatmentCourseInterface>()
  const [treatmentCourseId, setTreatmentCourseId] = useState<number | null>(null)
  const user = useSelector(selectMe)
  const branchId = Number(getBranch())

  const [errorMessage, setErrorMessage] = useState(initStateErrorMessage)

  const [courseTypes, setCourseTypes] = useState<TreatmentCourseTypeInterface[]>([])
  const [courseName, setCourseName] = useState('')
  const [courseNameEn, setCourseNameEn] = useState('')
  const [courseStatus, setCourseStatus] = useState<"ACTIVE" | "ISACTIVE">('ACTIVE')
  const [courseCode, setCourseCode] = useState('')
  const [selectCourseType, setSelectCourseType] = useState('')

  const tabs: string[] = ['TX', 'MED', 'PD']
  const [activeTab, setActiveTab] = useState(0)

  const [selectFilterOperative, setSelectFilterOperative] = useState('ALL')
  const [operativeTypes, setOperativeTypes] = useState([])
  const [operatives, setOperatives] = useState([])

  const [productTypes, setProductTypes] = useState([])
  const [allProduct, setAllProduct] = useState<any>([]);
  const [products, setProducts] = useState([])
  const [medicines, setMedicines] = useState([])
  const [typeSelected, setTypeSelected] = useState<number | string>('ALL');

  const [summaryTableCourses, setSummaryTableCourses] = useState<SummaryTableCourseInterface[]>([])

  /** PAGINATION  */
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(5)
  const [rowCount, setRowCount] = useState(0)
  const [sortBy, setSortBy] = useState('createAt')
  const [sortType, setSortType] = useState('ASC')
  const [search, setSearch] = useState('')

  const [anchorElMobileTabs, setAnchorElMobileTabs] = useState<null | HTMLElement>(null)
  const openMobileTabs = Boolean(anchorElMobileTabs)
  const [mobileWidth, setMobileWidth] = useState(0)

  const [paymentChanel, setPaymentChannel] = useState<TreatmentCoursePaymentInterface>(InterfaceTreatmentCoursePayment)
  const [errorPaymentChanel, setErrorPaymentChanel] = useState<boolean>(false)

  const [isDraging, setIsDraging] = useState<boolean>(false)
  const [isHegihtTableDrag, setIsHegihtTableDrag] = useState<string>('100%')
  const droppableId = 'droppable-courses-1'

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
    setSelectFilterOperative('ALL')
    setTypeSelected('ALL')
  }

  const onCloseMenu = () => setAnchorElMobileTabs(null)

  const handleOpenDropDown = () => {
    const dropdownMobileWidth = Array.from(document.getElementsByClassName('btn-dropdown-menu-mobile-tab') as HTMLCollectionOf<HTMLElement>)
    setMobileWidth(Number(dropdownMobileWidth[0]?.offsetWidth))
  }

  const loadTreatmentPlans = useCallback(() => {
    if (!initLocation) setInitLocation(location)
    if (initLocation !== undefined) {
      const { state }: any = initLocation
      setEditMenu(state)
      if (editMenu?.treatmentCourseId) onEdit()
    }
    if (!courseTypes.length) TreatmentCourseTypeApi.findAll({ status: 'ACTIVE', pageLimit: 100 }).then(({ data }) => data && setCourseTypes(data))
  }, [location, initLocation, editMenu])

  const loadOperative = useCallback(async () => {
    if (!operativeTypes.length) await OperativeTypeApi.findAll({ status: 'ACTIVE', pageLimit: 50 }).then(({ data }) => data && setOperativeTypes(data))

    let condition: any = {}
    condition = { ...condition, status: 'ACTIVE' }
    if (page) condition = { ...condition, page: page }
    if (search) condition = { ...condition, search: search }
    if (pageLimit) condition = { ...condition, pageLimit: pageLimit }
    if (selectFilterOperative !== 'ALL') condition = { ...condition, operativeTypeId: selectFilterOperative }
    await OperativeApi.findAllActive(condition).then(({ headers, data }) => {
      const rawOperatives: any = []
      _.map(data, (opt, index) => {
        rawOperatives.push({
          group: 'TX',
          itemId: opt.operativeId,
          type: _.get(_.find(operativeTypes, { operativeTypeId: opt.operativeTypeId }), 'operativeTypeName') || '-',
          itemName: opt.operativeName || opt.operativeNameEn,
          itemCode: opt.operativeCode,
          unit: opt.operativeUnitName || '-',
          price: opt.salePrice
        })
      })
      setOperatives(rawOperatives)
      setRowCount(headers['x-total'] || 0)
    })
  }, [page, pageLimit, selectFilterOperative, operativeTypes, search])

  // const loadProduct = async () => {
  const loadProduct = useCallback(async () => {


    if (!productTypes.length) {
      const resTypes = await ProductTypesApi.findAll({ status: 'ACTIVE', pageLimit: 100 })
      setProductTypes(resTypes?.data || [])
    }
    // const conditionProduct: any = { status: 'ACTIVE', group: 'PRODUCT', pageLimit: 100 }
    // const conditionMedicine: any = { status: 'ACTIVE', group: 'MEDICINE', pageLimit: 100 }
    let condition_P: any = { page: 1, pageLimit: 1000, branchId: branchId, userId: user.userId, group: 'PRODUCT' }
    if (typeSelected !== "ALL") condition_P = { ...condition_P, itemTypeId: typeSelected }
    let resProducts: any = [];
    const resPds = await TreatmentProductApi.findAllProduct(condition_P)
    resProducts = resPds.data || []
    // const resMds = await MedicinesApi.findAll(conditionMedicine)
    let condition_M: any = { page: 1, pageLimit: 1000, branchId: branchId, userId: user.userId, group: 'MEDICINE' }
    // user.userId
    if (typeSelected !== "ALL") condition_M = { ...condition_M, itemTypeId: typeSelected }
    const resMds = await TreatmentProductApi.findAllProduct(condition_M)
    resProducts = [...resProducts, ...(resMds.data || [])]

    setAllProduct(resProducts)


  }, [typeSelected])


  const handleFilterProduct = (data: any, valueTypeSelected: number | string) => {
    const med: any = []
    const pd: any = []

    if (!_.isEmpty(allProduct) && valueTypeSelected === 'ALL') {
      _.map(data, (item, index) => {

        if (item.group === 'MEDICINE') med.push({
          group: item.group === 'PRODUCT' ? 'PD' : 'MED',
          itemId: item.itemId,
          type: item?.itemTypeName || '-',
          itemName: item.itemName,
          itemCode: item.itemCode,
          unit: item?.itemUnitName,
          price: item.price,
          img: item?.image || '',
          qty: item.qty,
          minStock: item.minStock
        })
        else if (item.group === 'PRODUCT') pd.push({
          group: item.group === 'PRODUCT' ? 'PD' : 'MED',
          itemId: item.itemId,
          type: item?.itemTypeName || '-',
          itemName: item.itemName,
          itemCode: item.itemCode,
          unit: item?.itemUnitName,
          price: item.price,
          img: item?.image || '',
          qty: item.qty,
          minStock: item.minStock
        })
      })
    } else if (!_.isEmpty(allProduct)) {
      _.map(data, (item, index) => {
        if (item.itemTypeId === valueTypeSelected) {
          if (item.group === 'MEDICINE') med.push({
            group: item.group === 'PRODUCT' ? 'PD' : 'MED',
            itemId: item.itemId,
            type: item?.itemTypeName || '-',
            itemName: item.itemName,
            itemCode: item.itemCode,
            unit: item?.itemUnitName,
            price: item.price,
            img: item?.image || '',
            qty: item.qty,
            minStock: item.minStock
          })
          else if (item.group === 'PRODUCT') pd.push({
            group: item.group === 'PRODUCT' ? 'PD' : 'MED',
            itemId: item.itemId,
            type: item?.itemTypeName || '-',
            itemName: item.itemName,
            itemCode: item.itemCode,
            unit: item?.itemUnitName,
            price: item.price,
            img: item?.image || '',
            qty: item.qty,
            minStock: item.minStock
          })
        }
      })
    }
    setMedicines(med)
    setProducts(pd)
  }

  const onEdit = async () => {
    if (editMenu?.treatmentCourseId) {
      if (editMenu?.treatmentCourseId) setTreatmentCourseId(editMenu?.treatmentCourseId)
      setCourseName(editMenu.treatmentCourseName)
      setCourseNameEn(editMenu.treatmentCourseNameEn)
      setCourseStatus(editMenu?.status || "ACTIVE")
      TreatmentCourseTypeApi.findById(editMenu.treatmentCourseTypeId).then((res) => Number(res.status) === 200 && setSelectCourseType(String(editMenu.treatmentCourseTypeId)))
      const tempSummaryTableCourses: SummaryTableCourseInterface[] = editMenu.treatmentCourseItems?.map((tmi: any) => {
        let group: any = ''
        if (tmi.type === 'OPERATIVE') group = 'TX'
        if (tmi.type === 'MEDICINE') group = 'MED'
        if (tmi.type === 'PRODUCT') group = 'PD'
        return {
          group: group,
          itemId: tmi.itemId,
          itemCode: tmi.itemCode,
          itemName: tmi.itemName,
          priceUnit: tmi.price,
          amount: tmi.qty,
          itemSort: tmi?.itemSort || 0
        }
      })
      setSummaryTableCourses(tempSummaryTableCourses)
    }
  }

  useEffect(() => {
    handleFilterProduct(allProduct, typeSelected)
  }, [allProduct]);

  const submit = () => {
    clearErrorMessage()
    if (!selectCourseType || !courseName || !courseNameEn) {
      return setErrorMessage({
        ...errorMessage,
        EMPTY_COURSE_TYPE: !selectCourseType ? t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_COURSE_TYPE') : '',
        EMPTY_COURSE_NAME: !courseName ? t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_COURSE_NAME') : '',
        EMPTY_COURSE_NAME_EN: !courseNameEn ? t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_COURSE_NAME_EN') : '',
      })
    }

    if (_.isEmpty(summaryTableCourses)) {
      return notiError(t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_COURSE_SUMMARY'))
    }


    const tempTreatmentCourse: TreatmentCourseInterface = {
      treatmentCourseTypeId: parseInt(selectCourseType),
      treatmentCourseName: courseName,
      treatmentCourseNameEn: courseNameEn,
      // paymentType: paymentChanel.payType,
      // paymentDetail: paymentChanel.detail,
      treatmentCourseItems: summaryTableCourses.map((stc: SummaryTableCourseInterface) => {
        let type: any = ''
        if (stc.group === 'TX') type = 'OPERATIVE'
        if (stc.group === 'PD') type = 'PRODUCT'
        if (stc.group === 'MED') type = 'MEDICINE'
        return {
          type: type,
          itemId: stc.itemId,
          qty: stc.amount,
          price: parseFloat(String(stc.priceUnit)),
          itemSort: stc?.itemSort || 0
        }
      })
    }

    // if (handleCheckPayDetail(tempTreatmentCourse)) {
    if (!treatmentCourseId) create(tempTreatmentCourse)
    else {
      tempTreatmentCourse.status = courseStatus
      update(tempTreatmentCourse)
    }
    // }
  }


  const handleCheckPayDetail = (tempTreatmentCourse: TreatmentCourseInterface) => {
    if (tempTreatmentCourse?.paymentType === "") {
      notiError(t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_PAYMENT_TYPE'))
      return false
    }
    if (tempTreatmentCourse?.paymentType === "SPLITPAY") {
      if (tempTreatmentCourse?.paymentDetail === "[]") {
        notiError(t('TREATMENT_COURSE.MESSAGE.ERROR.EMPTY_SPLITPAY'))
        return false
      } else {
        const data: any = tempTreatmentCourse.paymentDetail
        const dataDetail = JSON.parse(data)
        if (dataDetail[dataDetail.length - 1].balance !== 0) {
          const divElement = document.querySelector('.container-card');
          if (divElement) {
            const divHeight = divElement.clientHeight;
            const divBottom = divHeight;
            $('.div-component').delay(300).animate({ scrollTop: divBottom }, 500)
          }

          setErrorPaymentChanel(true)
          return false
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }

  const create = (treatmentCourse: TreatmentCourseInterface) => {
    clearErrorMessage()
    TreatmentCourseApi.create(treatmentCourse)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 201) {
          notiSuccess(t(`TREATMENT_COURSE.MESSAGE.SUCCESS.CREATE`), '', null, null)
          history.push(routeName.treatmentCourse)
        }
        else {
          const err = res.response.data
          setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_COURSE.MESSAGE.${err.message}`) } })
        }
      })
      .catch((e) => {
        const err = e.response.data
        setErrorMessage({ ...errorMessage, ...{ [err.message]: t(`TREATMENT_COURSE.MESSAGE.${err.message}`) } })
      })
  }

  const update = (treatmentCourse: TreatmentCourseInterface) => {
    TreatmentCourseApi.update(Number(treatmentCourseId), treatmentCourse)
      .then((res: any) => {
        if (res.status !== undefined && res.status === 200) {
          notiSuccess(t(`TREATMENT_COURSE.MESSAGE.SUCCESS.UPDATE`), '', null, null)
          history.push(routeName.treatmentCourse)
        }
        else {
          const err = res.response?.data

          setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`TREATMENT_COURSE.MESSAGE.${err?.message}`) } })
        }
      })
      .catch((e) => {
        const err = e.response?.data
        setErrorMessage({ ...errorMessage, ...{ [err?.message]: t(`TREATMENT_COURSE.MESSAGE.${err?.message}`) } })
      })
  }

  const clearErrorMessage = () => {
    setErrorMessage(initStateErrorMessage)
  }

  const onRequestSort = (sortByVal: string, sortTypeVal: string) => {
    setSortType(sortTypeVal)
    setSortBy(sortByVal)
  }

  const handleChangePage = (val: number) => {
    setPage(val)
  }

  const handleChangeRowsPerPage = (limit: number) => {
    setPageLimit(limit)
  }

  const onAdd = (objData: ValueInterface) => {
    const find = summaryTableCourses.findIndex(({ itemId, group }: any) => Number(itemId) === Number(objData.itemId) && group === objData.group)
    if (find >= 0) {
      const summaryTableCoursesTemp: SummaryTableCourseInterface[] = [...summaryTableCourses]
      summaryTableCoursesTemp[find].amount = Number(summaryTableCoursesTemp[find].amount) + 1
      setSummaryTableCourses(summaryTableCoursesTemp)
    } else {
      setSummaryTableCourses([...summaryTableCourses, { group: objData.group, itemId: parseInt(objData.itemId), itemCode: objData.itemCode, itemName: objData.itemName, amount: 1, priceUnit: objData.price }])
    }
  }

  const onDelete = (objData: any) => {
    const items = summaryTableCourses.filter(({ itemId }: any) => itemId !== objData.itemId)
    setSummaryTableCourses(items)
  }

  const handleChangeAmount = (count: string, index: number) => {
    const summaryTableCoursesTemp: SummaryTableCourseInterface[] = [...summaryTableCourses]
    summaryTableCoursesTemp[index].amount = count
    setSummaryTableCourses(summaryTableCoursesTemp)
  }

  const handleChangePrice = (price: string, index: number) => {
    const summaryTableCoursesTemp: SummaryTableCourseInterface[] = [...summaryTableCourses]
    summaryTableCoursesTemp[index].priceUnit = price
    setSummaryTableCourses(summaryTableCoursesTemp)
  }

  const handleChangeSort = (index: number) => {
    const summaryTableCoursesTemp: SummaryTableCourseInterface[] = [...summaryTableCourses]
    if (summaryTableCoursesTemp?.length && !summaryTableCoursesTemp.some((item) => item?.itemSort)) {
      summaryTableCoursesTemp[index].itemSort = 1
    } else if (summaryTableCoursesTemp.some((item, num: number) => item?.itemSort && num === index)) {
      const sortCurrent = summaryTableCoursesTemp[index].itemSort || 0
      summaryTableCoursesTemp[index].itemSort = 0
      const minSortCurrent: { item: SummaryTableCourseInterface, index: number, itemSort: number }[] = []
      summaryTableCoursesTemp.forEach((item, num: number) => {
        if (item?.itemSort && item.itemSort > sortCurrent) {
          minSortCurrent.push({ item: { ...item }, index: num, itemSort: item.itemSort })
        }
      })
      minSortCurrent.sort((a, b) => a.itemSort - b.itemSort).forEach((item, num: number) => {
        summaryTableCoursesTemp[item.index].itemSort = sortCurrent + num
      })
    } else {
      const maxSort = Math.max(...summaryTableCoursesTemp.map(item => (item?.itemSort || 0)));
      summaryTableCoursesTemp[index].itemSort = maxSort + 1
    }
    setSummaryTableCourses(summaryTableCoursesTemp)
  }

  const handleProductBorder = (id: any) => {
    let stausStyle = false
    for (const index in summaryTableCourses) {
      const item = summaryTableCourses[index]
      if (item?.itemId === id) {
        stausStyle = true
        break
      }
    }
    return stausStyle
  }

  const headCells = [
    {
      id: 'no',
      disablePadding: false,
      label: t('TREATMENT_PLAN.FORM.LIST.TABLE.NO')
    },
    {
      id: 'itemCode',
      disablePadding: false,
      label: t('TREATMENT_PLAN.FORM.LIST.TABLE.CODE')
    },
    {
      id: 'type',
      disablePadding: false,
      label: t('TREATMENT_PLAN.FORM.LIST.TABLE.TYPE')
    },
    {
      id: 'productService',
      disablePadding: false,
      label: t('TREATMENT_PLAN.FORM.LIST.TABLE.ITEM')
    },
    {
      id: 'unit',
      disablePadding: false,
      label: t('TREATMENT_PLAN.FORM.LIST.TABLE.UNIT')
    },
    {
      id: 'price',
      disablePadding: false,
      label: t('TREATMENT_PLAN.FORM.LIST.TABLE.PRICE')
    },
    {
      id: 'action',
      disablePadding: false,
      label: t('TREATMENT_PLAN.FORM.LIST.TABLE.ACOINT')
    }
  ]

  const renderData = (objData: any, no: number) => {
    no = page * pageLimit - pageLimit + no + 1
    const { type, itemCode, itemName, unit, price, itemId, status } = objData

    const statusBtnActive = status === 'ACTIVE' ? true : false
    const statusBtnInActive = status === 'INACTIVE' ? true : false
    const objRenderData = {
      key: itemId,
      id: itemId,
      obj: objData,
      columns: [
        { option: 'TEXT', align: 'center', label: no },
        { option: 'TEXT', align: 'left', label: itemCode },
        { option: 'TEXT', align: 'left', label: type },
        { option: 'TEXT', align: 'left', label: itemName },
        { option: 'TEXT', align: 'left', label: unit },
        { option: 'TEXT', align: 'right', label: numberFormat(price) },
        {
          option: 'ADD',
          align: 'center',
          label: 'action',
          values: [
            { option: 'STATUS_ACTIVE', label: t(`+`), disabled: statusBtnActive },
            { option: 'STATUS_INACTIVE', label: t(`+`), disabled: statusBtnInActive }
          ]
        }
      ]
    }
    return <TableRowNonIcon onadd={() => onAdd(objData)} {...objRenderData} />
  }

  const handleChangeProductType = (id: number | string) => {
    setTypeSelected(id)
  }

  const renderImage = (image: any) => {
    if (image?.filePath && image?.fileName) return `${fileUrl}/${image.filePath}/${image.fileName}`
    else return activeTab === 1 ? icons.medDefault : icons.prodDefault
  }

  const headTableSummary = [
    {
      id: 'drag',
      disablePadding: false,
      label: t(``),
      width: '40px'
    },
    {
      id: 'no',
      disablePadding: false,
      label: t(`TREATMENT_COURSE.FORM.SUMMARY.TABLE.NO`),
      width: '80px'
    },
    {
      id: 'text',
      disablePadding: false,
      label: t(`TREATMENT_COURSE.FORM.SUMMARY.TABLE.CODE`),
      width: '200px'
    },
    {
      id: 'text',
      disablePadding: false,
      label: t(`TREATMENT_COURSE.FORM.SUMMARY.TABLE.ITEM`),
    },
    {
      id: 'sort',
      disablePadding: false,
      label: t('TREATMENT_COURSE.FORM.SUMMARY.TABLE.ORDER'),
      align: 'center',
      width: '150px'
    },
    {
      id: 'text',
      disablePadding: false,
      label: t(`TREATMENT_COURSE.FORM.SUMMARY.TABLE.QTY`),
      align: 'center',
      width: '165px'
    },
    {
      id: 'price',
      disablePadding: false,
      label: t(`TREATMENT_COURSE.FORM.SUMMARY.TABLE.PRICE`),
      width: '165px'
    },
    {
      id: 'price',
      disablePadding: false,
      label: t(`TREATMENT_COURSE.FORM.SUMMARY.TABLE.TOTEL`),
      width: '165px'
    },
    {
      id: 'action',
      disablePadding: false,
      label: t(`TREATMENT_COURSE.FORM.SUMMARY.TABLE.ACTION`),
      width: '80px'
    }
  ]

  const renderSummary = (objData: any, index: number) => {
    const { amount, itemCode, itemId, itemName, priceUnit, itemSort } = objData
    const newIndex = index
    const objRenderData = {
      key: index,
      id: index,
      obj: objData,
      // classes: 'bg-white',
      columns: [
        { option: 'TEXT', align: 'center', label: <IconMoveTableRow />, isDrag: true, class: 'bg-white' },
        { option: 'TEXT', align: 'center', label: newIndex + 1, class: 'bg-white' },
        { option: 'TEXT', align: 'left', label: itemCode || '-', class: 'bg-white' },
        { option: 'TEXT', align: 'left', label: itemName || '-', class: 'bg-white' },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <Box
              sx={{ width: '50px', cursor: 'pointer', margin: '0 auto' }}
              onClick={() => handleChangeSort(newIndex)}>
              <InputTextField
                key={`sort${newIndex}`}
                inputTextAlign={'right'}
                value={itemSort || ''}
                style={{ pointerEvents: 'none' }}
              />
            </Box>
          )
          , class: 'bg-white'
        },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <InputTextField key={`amount${newIndex}`} inputTextAlign={'right'} value={amount} onchange={(e) => handleChangeAmount(numberOnly(e), newIndex)} />
          )
          , class: 'bg-white'
        },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <InputTextField key={`priceUnit${newIndex}`} inputTextAlign={'right'} value={priceUnit} onchange={(e) => handleChangePrice(numberOnly(e), newIndex)} />
          )
          , class: 'bg-white'
        },
        { option: 'TEXT', align: 'right', label: numberFormat(priceUnit * amount || 0), class: 'bg-white' },
        {
          option: 'COMPONENT',
          align: 'center',
          component: (
            <IconButton disableFocusRipple disableRipple onClick={() => onDelete({ itemId, itemName, amount, priceUnit })} className="p-0" sx={{ backgroundColor: 'transparent' }}>
              <img src={icons.btnMinus} alt="discard" />
            </IconButton>
          )
          , class: 'bg-white'
        }
      ]
    }

    return (
      <Draggable draggableId={`drag-id-${index}`} index={index}>
        {(provider) => (
          <TableRowCommon providerDraggable={provider} {...objRenderData} />
        )}
      </Draggable>
    )
  }

  useEffect(() => {
    loadTreatmentPlans()
  }, [loadTreatmentPlans]);

  useEffect(() => {
    loadOperative()
  }, [loadOperative]);

  useEffect(() => {
    loadProduct()
  }, [loadProduct]);

  const handlePaymentChannel = (val: TreatmentCoursePaymentInterface) => {
    setPaymentChannel(val)
  }

  const handleDragEnd = (e: any) => {
    if (!e?.destination) {
      setIsDraging(false)
      return
    };
    const tempData = Array.from(summaryTableCourses);
    const [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setSummaryTableCourses(tempData);
    setIsDraging(false)
  };

  const handleHeightTableDrag = () => {
    const [divTable]: any = document.querySelectorAll('.table-drag')
    if (divTable) {
      return setIsHegihtTableDrag(`${divTable?.clientHeight}px !important`)
    }
    return setIsHegihtTableDrag('100% !important')
  }

  return (
    <div className={`container-card`}>
      <div className="d-flex flex-column">
        <HeaderLabel
          text={`${treatmentCourseId ? t('TREATMENT_COURSE.FORM.FORM_EDIT') : t('TREATMENT_COURSE.FORM.FORM_ADD')}`}
          navigator={{ previousTo: routeName.treatmentCourse, previousTitle: t('TREATMENT_COURSE.TITLE'), currentTitle: treatmentCourseId ? t('TREATMENT_COURSE.FORM.FORM_EDIT') : t('TREATMENT_COURSE.FORM.FORM_ADD') }}
          goBack={routeName.treatmentCourse}
          onSubmit={submit}
        />
        <Card className="border shadow-sm mx-4 mb-4 h-auto card">
          <Card.Body className="h-100 p-3">
            <Grid container spacing={2}>
              <Grid item sm={6} lg={4} className='w-100 d-none'>
                <InputTextField label={t('TREATMENT_COURSE.ID_COURSE')} required={true} value={courseCode} onchange={(e) => setCourseCode(e.target.value)} />
              </Grid>
              <Grid item sm={6} lg={4} className='w-100'>
                <BasicSelect
                  required
                  onchange={(event) => {
                    const value = event.target.value
                    if (value) {
                      setSelectCourseType(value)
                      clearErrorMessage()
                    }
                  }}
                  label={t('TREATMENT_COURSE.FORM.TREATMENT_COURSE_TYPE')}
                  selectId="courseType"
                  value={selectCourseType}
                  labelId="courseType"
                  options={courseTypes.map((obj: TreatmentCourseTypeInterface) => (
                    <MenuItem key={obj?.treatmentCourseTypeId} value={obj?.treatmentCourseTypeId}>
                      {obj.treatmentCourseTypeName}
                    </MenuItem>
                  ))}
                  helperText={errorMessage.EMPTY_COURSE_TYPE || errorMessage.TREATMENT_COURSE_TYPE_ANY_REQUIRED || errorMessage.TREATMENT_COURSE_TYPE_NUMBER}
                />
              </Grid>
              <Grid item sm={6} lg={4} className='w-100 d-none d-md-block d-xl-none'></Grid>
              <Grid item sm={6} lg={4} className='w-100'>
                <InputTextField
                  label={t('TREATMENT_COURSE.FORM.TREATMENT_COURSE_NAME')}
                  required={true}
                  value={courseName}
                  onchange={(e) => {
                    setCourseName(e.target.value)
                    clearErrorMessage()
                  }}
                  helperText={
                    errorMessage.EMPTY_COURSE_NAME
                    || errorMessage.TREATMENT_COURSE_NAME_STRING_BASE
                    || errorMessage.IS_DUPLICATE_TREATMENT_COURSE_NAME
                    || errorMessage.TREATMENT_COURSE_NAME_MAXIMUM_LENGTH || errorMessage.TREATMENT_COURSE_NAME_STRING_EMPTY || errorMessage.TREATMENT_COURSE_NAME_ANY_REQUIRED}
                />
              </Grid>
              <Grid item sm={6} lg={4} className='w-100'>
                <InputTextField
                  label={t('TREATMENT_COURSE.FORM.TREATMENT_COURSE_NAME_EN')}
                  required={true}
                  value={courseNameEn}
                  onchange={(e) => {
                    setCourseNameEn(e.target.value)
                    clearErrorMessage()
                  }}
                  helperText={errorMessage.EMPTY_COURSE_NAME_EN
                    || errorMessage.TREATMENT_COURSE_NAME_EN_STRING_BASE
                    || errorMessage.IS_DUPLICATE_TREATMENT_COURSE_NAME_EN
                    || errorMessage.TREATMENT_COURSE_NAME_EN_MAXIMUM_LENGTH
                    || errorMessage.TREATMENT_COURSE_NAME_EN_STRING_EMPTY || errorMessage.TREATMENT_COURSE_NAME_EN_ANY_REQUIRED}
                />
              </Grid>
            </Grid>
            <Divider className="mx-0 mt-4 mb-3" sx={{ backgroundColor: 'transparent', opacity: 1, borderColor: `${colors.lightGray} !important` }} />
            <Box className='d-md-flex' sx={{ flexGrow: 1, bgcolor: 'background.paper' }}>
              <Box>
                <UseStyled.CustomTabs orientation="vertical" variant="scrollable" value={activeTab} onChange={handleChangeTab} className="d-none d-md-flex" sx={{ flex: 'none' }}>
                  {_.map(tabs, (tab: string, index: number) => {
                    return <Tab key={index} label={tab} {...a11yProps(tab)} />
                  })}
                </UseStyled.CustomTabs>
                <UseStyled.CustomTabs className="d-md-none">
                  <UseStyled.CustomButton
                    id="mobile-tabs"
                    aria-controls={openMobileTabs ? 'basic-menu-more' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMobileTabs ? 'true' : undefined}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      setAnchorElMobileTabs(event.currentTarget)
                      handleOpenDropDown()
                    }}
                    className={'btn-tab btn-dropdown-menu-mobile-tab w-100'}
                    disableRipple
                  >
                    {tabs[activeTab]}
                    <FontAwesomeIcon icon={faCaretDown} className={'icon-selector'} style={{ transform: openMobileTabs ? 'rotate(180deg)' : '' }} />
                  </UseStyled.CustomButton>

                  <UseStyled.MenuBtnMore
                    id="basic-menu-more"
                    anchorEl={anchorElMobileTabs}
                    open={openMobileTabs}
                    onClose={onCloseMenu}
                    onClick={onCloseMenu}
                    className="d-xl-none"
                    sx={{
                      '.MuiMenu-list': {
                        width: mobileWidth,
                        maxHeight: '270px'
                      }
                    }}
                  >
                    {_.map(tabs, (tab: string, indexTab: number) => {
                      return (
                        <MenuItem
                          key={indexTab}
                          className={'d-block text-ellipsis'}
                          onClick={(e) => handleChangeTab(e, indexTab)}
                        >
                          {tab}
                        </MenuItem>
                      )
                    })}
                  </UseStyled.MenuBtnMore>
                </UseStyled.CustomTabs>
              </Box>

              <TabPanel value={activeTab} index={0}>
                <Box className='mb-3 d-flex justify-content-between align-items-center ' >
                  <Typography sx={{ color: colors.themeMainColor, fontWeight: 600 }}>{t('TREATMENT_COURSE.FORM.TYPE_OPERATIVE')}</Typography>
                  <Box className="w-100 pl-3" sx={{ maxWidth: 379 }}>
                    <InputTextSearch
                      keyInput={'search'}
                      label={t('INPUT.SEARCH')}
                      value={search}
                      onchange={(event) => {
                        setSearch(event.target.value)
                        setPage(1)
                      }}
                      onClear={(event) => {
                        setSearch('')
                        setPage(1)
                      }}
                    />
                  </Box>
                </Box>

                <UsedTxPlanStyled.TypeTabs className="d-flex flex-wrap mr-0">
                  <Box className="px-1 pb-2">
                    <UsedTxPlanStyled.ButtonType variant="outlined" title={t('All')} className={`${selectFilterOperative === 'ALL' ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={(e) => {
                      setSelectFilterOperative('ALL')
                      setPage(1)

                    }}>
                      <span className="text-ellipsis">{t('All')}</span>
                    </UsedTxPlanStyled.ButtonType>
                  </Box>
                  {operativeTypes.map(({ operativeTypeName, operativeTypeNameEn, operativeTypeId, value }: any) => {
                    return (
                      <Box key={`col-operative-type-${operativeTypeId}`} className="px-1 pb-2">
                        <UsedTxPlanStyled.ButtonType variant="outlined" title={operativeTypeName || operativeTypeNameEn} key={operativeTypeId} data-value={operativeTypeId} onClick={(e) => {
                          setSelectFilterOperative(operativeTypeId)
                          setPage(1)

                        }} className={`${selectFilterOperative === operativeTypeId ? 'active' : ''} text-ellipsis`}>
                          <span className="text-ellipsis">{operativeTypeName || operativeTypeNameEn}</span>
                        </UsedTxPlanStyled.ButtonType>
                      </Box>
                    )
                  })}
                </UsedTxPlanStyled.TypeTabs>
                <Box className='mt-2'>
                  <TableCustom
                    page={page}
                    pageLimit={pageLimit}
                    sortType={sortType}
                    sortBy={sortBy}
                    onSort={onRequestSort}
                    setPageLimit={handleChangeRowsPerPage}
                    setPage={handleChangePage}
                    rowCount={rowCount}
                    headCells={headCells}
                    rowsData={operatives.map((val: any, i: any) => {
                      return renderData(val, i)
                    })}
                    tableMinWidth={1280}
                  />
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Typography className='mb-3' sx={{ color: colors.themeMainColor, fontWeight: 600 }}>{t('TREATMENT_COURSE.FORM.TYPE_MEDICINE')}</Typography>
                <TypeTabs className="d-flex flex-wrap custom-scroll">
                  <Box className="px-1 pt-2">
                    <ButtonType variant="outlined" title={t('All')} className={`${typeSelected === 'ALL' || typeSelected === 0 ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={() => handleChangeProductType('ALL')} key={`type-all`}>
                      <span className="text-ellipsis">{t('All')}</span>
                    </ButtonType>
                  </Box>
                  {_.map(productTypes, (type: any, index: number) => {
                    if (type.group === 'MEDICINE') {
                      return (
                        <Box key={`med-type-${index}`} className="px-1 pt-2">
                          <ButtonType variant="outlined" key={type.productTypeId} title={type.productTypeName || type.productTypeNameEn} onClick={() => handleChangeProductType(type.productTypeId)} className={`${Number(typeSelected) === Number(type.productTypeId) ? 'active' : ''} text-ellipsis`}>
                            <span className="text-ellipsis">{type.productTypeName || type.productTypeNameEn}</span>
                          </ButtonType>
                        </Box>
                      )
                    }
                  })}
                </TypeTabs>
                <ProductItem className='d-flex align-items-start flex-wrap custom-scroll mt-3 mt-xl-4'>
                  {!_.isEmpty(medicines) && (
                    _.map(medicines, (med: any, index: number) => {
                      return (
                        <ProductBox key={`med-${index}`} className="px-1 px-xl-2 mb-2 mb-xl-3">
                          <ProductCard onClick={() => onAdd(med)} style={{ border: handleProductBorder(med.itemId) ? `1px solid ${colors.themeSecondColor}` : '' }}>
                            <Box className='card-image'>
                              <Box className='position-relative'>
                                <img src={renderImage(med?.img)} alt={med.itemName} className={`${_.isEmpty(med?.img) || !med?.img?.filePath || !med?.img?.fileName ? 'no-prod-image' : ''}`} onError={onImgError} />
                              </Box>
                            </Box>
                            <Box className='card-detail'>
                              <Box className='d-flex align-items-start justify-content-between'>
                                <Box className='overflow-hidden'>
                                  <Typography className='text-ellipsis' sx={{ flex: 1 }}>{med.itemName || '-'}</Typography>
                                  <Typography className='text-ellipsis'>{numberFormat(med.price || 0)} {t('CURRENCY_CHANGE.TH')}</Typography>
                                </Box>
                                <Box className='mt-1' sx={{ display: 'block', textAlign: 'right' }}>
                                  <Box><AddIcon style={{ height: '24px', width: '24px', color: colors.white, backgroundColor: colors.themeSecondColor, cursor: 'pointer', borderRadius: '4px' }} /></Box>
                                  <Box>
                                    <InventoryChip label={`${t('TREATMENT_PRODUCT.CARD.QUANTITY')}: ${numberFormat(med.qty, 0, 0)}`} limit={med.qty <= med.minStock} className="ml-2 p-0" />
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </ProductCard>
                        </ProductBox>
                      )
                    })
                  ) || (
                      <Typography className='w-100 text-center' sx={{ color: colors.disabledGray, paddingTop: '48px' }}>{t('TREATMENT_PRODUCT.TITLE.EMPTY_MEDICINE')}</Typography>
                    )}
                </ProductItem>
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Typography className='mb-3' sx={{ color: colors.themeMainColor, fontWeight: 600 }}>{t('TREATMENT_COURSE.FORM.TYPE_PRODUCT')}</Typography>
                <TypeTabs className="d-flex flex-wrap custom-scroll">
                  <Box className="px-1 pt-2">
                    <ButtonType variant="outlined" title={t('All')} className={`${typeSelected === 'ALL' || typeSelected === 0 ? 'active' : ''} overflow-hidden`} data-value={'All'} onClick={() => handleChangeProductType('ALL')} key={`type-all`}>
                      <span className="text-ellipsis">{t('All')}</span>
                    </ButtonType>
                  </Box>
                  {_.map(productTypes, (type: any, index: number) => {
                    if (type.group === 'PRODUCT') {
                      return (
                        <Box key={`med-type-${index}`} className="px-1 pt-2">
                          <ButtonType variant="outlined" key={type.productTypeId} title={type.productTypeName || type.productTypeNameEn} onClick={() => handleChangeProductType(type.productTypeId)} className={`${Number(typeSelected) === Number(type.productTypeId) ? 'active' : ''} text-ellipsis`}>
                            <span className="text-ellipsis">{type.productTypeName || type.productTypeNameEn}</span>
                          </ButtonType>
                        </Box>
                      )
                    }
                  })}
                </TypeTabs>
                <ProductItem className='d-flex align-items-start flex-wrap custom-scroll mt-3 mt-xl-4'>
                  {!_.isEmpty(products) && (
                    _.map(products, (prod: any, index: number) => {
                      return (
                        <ProductBox key={`prod-${index}`} className="px-1 px-xl-2 mb-2 mb-xl-3">
                          <ProductCard onClick={() => onAdd(prod)} style={{ border: handleProductBorder(prod.itemId) ? `1px solid ${colors.themeSecondColor}` : '' }}>
                            <Box className='card-image'>
                              <Box className='position-relative'>
                                <img src={renderImage(prod?.img)} alt={prod.itemName} className={`${_.isEmpty(prod?.img) || !prod?.img?.filePath || !prod?.img?.fileName ? 'no-prod-image' : ''}`} onError={onImgError} />
                              </Box>
                            </Box>
                            <Box className='card-detail'>
                              <Box className='d-flex align-items-start justify-content-between'>
                                <Box className='overflow-hidden'>
                                  <Typography className='text-ellipsis' sx={{ flex: 1 }}>{prod.itemName || '-'}</Typography>
                                  <Typography className='text-ellipsis'>{numberFormat(prod.price || 0)} {t('CURRENCY_CHANGE.TH')}</Typography>
                                </Box>
                                <Box className='mt-1' sx={{ display: 'block', textAlign: 'right' }}>
                                  <Box>
                                    <AddIcon style={{ height: '24px', width: '24px', color: colors.white, backgroundColor: colors.themeSecondColor, cursor: 'pointer', borderRadius: '4px' }} />
                                  </Box>
                                  <Box><InventoryChip label={`${t('TREATMENT_PRODUCT.CARD.QUANTITY')}: ${numberFormat(prod.qty, 0, 0)}`} limit={prod.qty <= prod.minStock} className="ml-2 p-0" /></Box>
                                </Box>
                              </Box>
                            </Box>
                          </ProductCard>
                        </ProductBox>
                      )
                    })
                  ) || (
                      <Typography className='w-100 text-center' sx={{ color: colors.disabledGray, paddingTop: '48px' }}>{t('TREATMENT_PRODUCT.TITLE.EMPTY_MEDICINE')}</Typography>
                    )}
                </ProductItem>
              </TabPanel>
            </Box>
            <Box className='pl-md-3' sx={{ marginLeft: { sm: '56px' }, marginTop: '32px' }}>
              <Typography className='mb-3' sx={{ color: colors.themeMainColor, fontWeight: 600 }}>
                {t('TREATMENT_COURSE.FORM.SUMMARY_COURSE')}{` `}
                <span style={{ color: colors.statusInactive }}>*</span>
              </Typography>
              <UseStyled.SummaryCourse className={'table-drag'}
                onMouseEnter={() => handleHeightTableDrag()}
                onMouseLeave={() => setIsHegihtTableDrag('100%')}
                sx={{ transition: 'all 0.3s ease-in-out', height: isHegihtTableDrag, '.MuiPaper-root': { paddingBottom: isDraging ? '67.5px' : '' } }}
              >
                <DragDropContext
                  onDragEnd={handleDragEnd}
                  onDragStart={(e) => setIsDraging(true)}
                >
                  <TableCustom
                    page={1}
                    pageLimit={0}
                    sortType={''}
                    sortBy={''}
                    onSort={() => { return }}
                    setPageLimit={() => { return }}
                    setPage={() => { return }}
                    rowCount={0}
                    headCells={headTableSummary}
                    rowsData={_.map(summaryTableCourses, (val: any, i: any) => {
                      return renderSummary(val, i)
                    })}
                    // .reverse()
                    tableFixedWidth
                    tableMinWidth={1280}
                    hidePagination
                    isDrag={true}
                    droppableId={droppableId}
                  />
                </DragDropContext>
              </UseStyled.SummaryCourse>
            </Box>
            <SummaryTreatmentStyle.NetPrice className={`d-flex align-items-center justify-content-between justify-content-md-end mt-2 px-3 px-md-4`} sx={{ marginLeft: { sm: '72px' } }}>
              {t('TREATMENT_SUMMARY.TITLE.SUM_TOTAL')} <span className="pl-2 pl-md-5 text-right">{numberFormat(summaryTableCourses.map(({ amount, priceUnit }: any) => Number(amount) * Number(priceUnit)).reduce((a: any, b: any) => a + b, 0) || 0)} {t('CURRENCY_CHANGE.TH')}</span>
            </SummaryTreatmentStyle.NetPrice>

            {/* <FormTrementCoursePayment
              handlePaymentChannel={handlePaymentChannel}
              summaryTableCourses={summaryTableCourses}
              dataEdit={editMenu}
              statusError={errorPaymentChanel}
              setErrorPaymentChanel={setErrorPaymentChanel} /> */}

          </Card.Body>
        </Card>
      </div>
    </div>
  )
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} className={'w-100 overflow-hidden'} {...other}>
      {value === index && <Box className="pl-md-3">{children}</Box>}
    </div>
  )
}

function a11yProps(index: number | string) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  }
}
