import { Box, ClickAwayListener, Menu, MenuItem, Typography, makeStyles } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import bgCheckIn from 'assets/images/check-in/bg-check-in.svg'
import imgCheckIn from 'assets/images/check-in/img-check-in.svg'
import { getBranch, getClinicInfo, getToken, toBuddhistYear } from 'utils/app.utils'
import AboutUs from 'api/admin/clinic-manage/about.api'
import { useDispatch, useSelector } from 'react-redux'
import { branchInfo, editBranchInfo } from 'app/slice/user.slice'
import { KEY_STORAGE } from 'constants/common'
import moment from 'moment'
import Loading from 'component/Loading'
import { useTranslation } from 'react-i18next'
import IconLanguage from 'assets/icon/navigation/icon-language'
import { dateToView } from 'utils/date.utils'
import { MenuLang, dataLanguage } from 'component/Header/UserMenu'
import { BoxCheckIn, CardCheckIn } from './style'
import { ThemeIcon, colors } from 'constants/theme'
import ButtonCustom from 'component/Button/ButtonCustom'
import InputTextField from 'component/Input/InputTextField'
import zIndex from '@mui/material/styles/zIndex'
import { swalCustom } from 'component/Alert/Swal'
import './style.css'
import InputRadio from 'component/Input/InputRadio'
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import UseWindowSize from 'utils/useWindowSize'
import IconCheckInSuccess from 'assets/images/check-in/check-in-success'
import CheckInSuccess from './check-in-success'
import { event } from 'jquery'
import { useLocation } from 'react-router-dom'
import { branchInfoProps } from 'features/counter/register/qr-code/form-qr-code'
import CheckInApi, { checkInAppointmentProps, findAppointmentProps } from 'api/check-in/check-in.api'
import $ from 'jquery'
import ServicePointApi from 'api/setting/branch-service-points/service-point.api'
import SendExaminationApi, { SendQueue, Treatments } from 'api/master/sendExamination.api'

export type DataCheckInProps = {
    patientId: number
    appointmentId: number
    patientfullName: string
    patientfullNameEn: string
    doctorfullName: string
    doctorfullNameEn: string
    date: string
    timeStart: string
    timeEnd: string
    serviceText: string
    serviceTextEn: string
    servicePoint: string
    isCheckin: '1' | '0'
    time_range: string
    prefix: string
    prefixEn: string
    servicePointId: number
    servicePointName: string
    servicePointNameEn: string
    appointmentOperativeList: any
}

const initBranch = {
    branchId: '',
    branchLogo: '',
    clinicName: '',
    clinicNameEn: '',
    branchName: '',
    branchNameEn: '',
    servicePointId: '',
    servicePointName: '',
    servicePointNameEn: '',
}

const CheckIn = () => {
    const { t, i18n } = useTranslation()
    const location = useLocation()

    const [loading, setLoading] = useState<boolean>(true)
    const [branchesInfo, setBranchesInfo] = useState<branchInfoProps>(initBranch)

    const [currentTime, setCurrentTime] = useState(moment().format(i18n.language === 'en' ? 'HH:mm A' : 'HH:mm'));
    const [currentTimeUnit, setCurrentTimeUnit] = useState(i18n.language === 'en' ? '' : t('TIME_UNIT'))
    const [openLanguage, setOpenLanguage] = useState<boolean>(false)

    const [isCheckIn, setIsCheckIn] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [dataQRcode, setDataQRcode] = useState<string | null>(null)
    const [dataCheckIn, setDataCheckIn] = useState<DataCheckInProps[]>([])
    const [dataSelect, setDataSelect] = useState<number | null>(null)
    const [dataSuccess, setDataSuccess] = useState<DataCheckInProps | undefined>(undefined)
    const [inputPhone, setInputPhone] = useState<string>('')

    const [widthWindow, heightWindow] = UseWindowSize()
    const [scaleImg, setScaleImg] = useState<{ scale: number, right: number, bottom: number }>({ scale: 0, right: 0, bottom: 0 })

    const [maxBgLinear, setMaxBgLinear] = useState<number>(5.76)
    const [maxBgLinearFFF, setMaxBgLinearFFF] = useState<number>(50)

    const [dataServicePoint, setDataServicePoint] = useState<any>({})


    const loadData = async () => {
        const condition: any = {
            page: 1,
            pageLimit: 30,
            sortBy: 'createAt',
            sortType: "ASC"
        }

        let newServicePoint: any = {}
        const res = await ServicePointApi.findAll(condition)
        if (res.status === 200 && res?.data) {
            newServicePoint = res.data?.find((item: any) => item.pointOfService === 'COUNTER')
        }

        const dataClinic = getClinicInfo()

        const newData: branchInfoProps = {
            branchId: dataClinic?.branchId || '',
            branchLogo: dataClinic?.branchLogo || '',
            clinicName: dataClinic?.clinicName || '',
            clinicNameEn: dataClinic?.clinicNameEn || '',
            branchName: dataClinic?.branchName || '',
            branchNameEn: dataClinic?.branchNameEn || '',
            servicePointId: newServicePoint?.servicePointId || '',
            servicePointName: newServicePoint?.servicePointName || '',
            servicePointNameEn: newServicePoint?.servicePointNameEn || ''
        }

        setBranchesInfo(newData)
        setLoading(false)
    }

    useEffect(() => {
        setScaleImg(findScaleImg())
    }, [widthWindow])

    const findScaleImg = useCallback(() => {
        const minW = 1400
        const maxW = 1920
        if (widthWindow <= minW) {
            return { scale: 0.5, right: -450, bottom: -550 }
        } else if (widthWindow >= maxW) {
            return { scale: 1, right: -390, bottom: -460 }
        } else {
            const newScale = 0.5 + ((widthWindow - minW) * 0.5) / (maxW - minW);
            const newRight = -450 + ((widthWindow - minW) / (maxW - minW)) * ((-390) - (-450));
            const newBottom = -550 + ((widthWindow - minW) / (maxW - minW)) * ((-460) - (-550))
            return { scale: newScale, right: newRight, bottom: newBottom }
        }
    }, [widthWindow])

    const handleLoadBranch = () => {
        const TOKEN = getToken()
        if ((!location.search || location.search === '') && !location.state && TOKEN) {
            return loadData()
        }

        if (location.state) {
            const data: any = location.state
            const newQRcode = data?.qrCode || ''
            const newData: branchInfoProps = {
                branchId: data?.branchId || '',
                branchLogo: data?.branchLogo || '',
                clinicName: data?.clinicName || '',
                clinicNameEn: data?.clinicNameEn || '',
                branchName: data?.branchName || '',
                branchNameEn: data?.branchNameEn || '',
                servicePointId: data?.servicePointId || '',
                servicePointName: data?.servicePointName || '',
                servicePointNameEn: data?.servicePointNameEn || ''
            }
            setDataQRcode(newQRcode)
            setBranchesInfo(newData)
        } else {
            const queryString = location.search
            const params: any = new URLSearchParams(queryString);
            const queryParams: branchInfoProps = initBranch;
            for (const [key, value] of params.entries()) {
                queryParams[key] = value;
            }
            setBranchesInfo(queryParams)
        }

        setLoading(false)
    }

    useEffect(() => {
        handleLoadBranch()
    }, [location])

    const handleTextLang = (data: any, text: string, textEn?: string) => {
        if (!data) {
            return
        }
        if (i18n.language === 'en') {
            return textEn ? data[textEn] : data[`${text}En`] || ''
        }
        return data[text] || ''
    }

    const renderLanguage = () => {
        let newLang = "ไทย"
        for (const index in dataLanguage) {
            const newDataLang = dataLanguage[index]
            if (newDataLang.langCode === i18n.language.toLocaleUpperCase()) {
                newLang = newDataLang.langName
                break
            }
        }
        return newLang
    }

    const handleLanguage = (lang: string) => {
        setLoading(true)
        const newLang = lang.toLocaleLowerCase()
        moment.locale(newLang);
        localStorage.setItem(KEY_STORAGE.LANGUAGE, lang)
        i18n.changeLanguage(newLang)
        setLoading(false)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const format = i18n.language === 'en' ? 'HH:mm A' : 'HH:mm'
            setCurrentTime(moment().format(format));
            setCurrentTimeUnit(i18n.language === 'en' ? '' : t('TIME_UNIT'))
        }, 1000);

        return () => clearInterval(interval);
    }, [i18n.language]);

    const handleError = () => {
        const langServicePointName = i18n.language === 'th' ? 'servicePointName' : 'ServicePointNameEn'
        setLoading(false)
        swalCustom('',
            `<div>${t('CHECK_IN.MESSAGE.NOT_FOUND_APPOINTMENT')}</br>
            ${t('CHECK_IN.MESSAGE.CONTACT')}
            <span style=color:${colors.themeSecondColor}> ${branchesInfo[langServicePointName] || branchesInfo?.servicePointName || ''} </span>
            ${t('CHECK_IN.MESSAGE.THANK')}  </div>`,
            (res: any) => onReset(), 'warning',
            {
                confirmButton: {
                    showConfirmButton: false
                },
                cancelButton: {
                    cancelButtonText: t('CHECK_IN.BUTTON.ACCEPT'),
                    cancelClassName: 'btn-check-in-swal'
                }
            }
        )
    }

    const handleCheckIn = async () => {
        setLoading(true)
        const newProps: findAppointmentProps = { phone: inputPhone, branchId: Number(branchesInfo.branchId) }
        await CheckInApi.findAppointment(newProps).then((res) => {
            const checkAll = res?.data?.some((item: any) => item?.isCheckin === '0')
            if (checkAll) {
                let newSelect: number | null = null
                const newData = res?.data?.map((item: any, index: number) => {
                    if (newSelect === null && item?.isCheckin === '0') {
                        newSelect = index
                    }
                    const newServiceText = item?.appointmentOperativeList?.map((info: any) => {
                        return info?.operativeName || info?.operativeTypeName || ''
                    })
                    const newServiceTextEn = item?.appointmentOperativeList?.map((info: any) => {
                        return info?.operativeNameEn || info?.operativeTypeNameEn || info?.operativeName || info?.operativeTypeName || ''
                    })

                    return { ...item, serviceText: newServiceText.toString() || '-', serviceTextEn: newServiceTextEn.toString() || '-' }
                })

                setDataSelect(newSelect)
                setDataCheckIn(newData)
                setIsCheckIn(true)
                setLoading(false)
                if (newSelect !== null) {
                    const element = document.getElementById(`list-${newSelect}`);
                    element && $('.box-list-all').delay(300).animate({ scrollTop: element.offsetTop }, 300)
                }
            } else {
                handleError()
            }
        }).catch((e) => {
            handleError()
        })
    }

    const onSubmitCheckIn = async () => {
        setLoading(true)
        const newData = dataCheckIn.find((item, index) => index === dataSelect)
        if (newData) {
            const payload: checkInAppointmentProps = {
                appointmentId: newData.appointmentId,
                isCheckin: '1'
            }
            const resPatientCase = await SendExaminationApi.registerOpenVisit(newData.patientId).catch(() => setLoading(false))
            if (resPatientCase?.data) {
                const newPatient = resPatientCase.data
                const patientTreatments: Treatments[] = newData?.appointmentOperativeList?.length ? newData?.appointmentOperativeList.map((item: any) => ({ operativeTypeId: item?.operativeTypeId, operativeId: item?.operativeId })) : []
                const templateSendQueue: SendQueue = {
                    servicePointId: newData?.servicePointId || Number(branchesInfo?.servicePointId || 0),
                    importantSymptoms: newPatient?.importantSymptoms || '',
                    specialInfo: newPatient?.specialInfo || '',
                    appointmentId: newData?.appointmentId || 0,
                    treatments: patientTreatments,
                }
                const newPayload = { ...templateSendQueue, statusAppointment: 'ARRIVED', patientCaseId: newPatient?.patientCaseId }
                const sendQueueRegister = await SendExaminationApi.registerSendQueue(newPayload).catch(() => setLoading(false))
                if (sendQueueRegister?.data) {
                    await CheckInApi.checkInAppointment(payload).then((res) => {
                        setIsSuccess(true)
                        setDataSuccess(newData)
                    }).finally(() => {
                        setLoading(false)
                    })
                }
            }
        }
        return setLoading(false)
    }

    const onReset = () => {
        setIsCheckIn(false)
        setDataCheckIn([])
        setIsSuccess(false)
        setDataSuccess(undefined)
        setDataSelect(null)
        setInputPhone('')
    }

    return (
        <Box>
            <Loading show={loading} type='fullLoading' />
            <BoxCheckIn className={`w-100 h-100`}>
                <Box className={`w-100 h-100 d-flex`}>
                    <Box className={`box-clinic-detail`}>
                        <Box className={`box-clinic-img-xl w-100`}>
                            <img src={bgCheckIn} />
                        </Box>
                        <Box className={`box-clinic-content`}>
                            <Box className={`box-clinic-lang`} sx={{ position: 'relative', zIndex: '100' }}>
                                <ClickAwayListener onClickAway={() => widthWindow < 1000 && setOpenLanguage(false)}>
                                    <Box className={`box-select-lang`} onClick={() => setOpenLanguage(!openLanguage)} >
                                        <IconLanguage width={'32px'} height={'32px'} fill={'#808080'} />
                                        {openLanguage &&
                                            <MenuLang className={`menu-lang`}>
                                                <Box className={'menulang-content'}>
                                                    {dataLanguage.map((item) => {
                                                        return (
                                                            <Box
                                                                key={item.langCode}
                                                                className={'menulang-item-list'}
                                                                onClick={() => handleLanguage(item.langCode)}
                                                            >
                                                                <Box sx={{ fontSize: '18px' }}>
                                                                    {item.icon}
                                                                </Box>
                                                                <Box>
                                                                    {item.langName}
                                                                </Box>
                                                            </Box>
                                                        )
                                                    })}
                                                </Box>
                                            </MenuLang>}
                                    </Box>
                                </ClickAwayListener>
                            </Box>


                            {
                                (widthWindow > 1000 || !isCheckIn) ?
                                    <>
                                        <Box className={'box-clinic-logo'}>
                                            {branchesInfo?.branchLogo && branchesInfo.branchLogo !== '' && <img src={branchesInfo.branchLogo} />}
                                        </Box>
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Typography className={`text-clinicname`} sx={{ fontSize: '36px', fontWeight: '700' }}>
                                                {branchesInfo?.clinicName || ''}
                                            </Typography>
                                            <Typography className={`text-clinicnameen`} sx={{ fontSize: '36px', fontWeight: '0' }}>
                                                {branchesInfo?.clinicNameEn || ''}
                                            </Typography>
                                            <Typography className={`text-branch-name`} sx={{ fontSize: '32px', fontWeight: '0', color: '#5A5A5A' }}>
                                                {handleTextLang(branchesInfo, 'branchName')}
                                            </Typography>
                                            <Typography className={`text-welcome-check-in`} sx={{ fontSize: '20px' }}>
                                                {t('CHECK_IN.WELCOME')}
                                            </Typography>
                                        </Box>
                                        {dataQRcode && widthWindow < 1000 &&
                                            <Box className={`box-content-qr-code`}>
                                                <Box className={`text-qr-code`}>
                                                    {t('CHECK_IN.INPUT.ENTER_SCAN')}
                                                </Box>
                                                <Box className={`img-qr-code`}>
                                                    <img src={dataQRcode} />
                                                </Box>
                                            </Box>
                                        }
                                    </>
                                    :
                                    <>
                                        <Box className={`box-clinic-info-sm`}>
                                            <Box className={'box-clinic-logo-sm'}>
                                                {branchesInfo?.branchLogo && branchesInfo.branchLogo !== '' && <img src={branchesInfo.branchLogo} />}
                                            </Box>
                                            <Box>
                                                <Typography className={`text-clinicname-sm`} sx={{ fontSize: '18px', fontWeight: '700', lineHeight: '.7' }}>
                                                    {branchesInfo?.clinicName || ''}
                                                </Typography>
                                                <Typography className={`text-clinicnameen-sm`} sx={{ fontSize: '16px', fontWeight: '0' }}>
                                                    {branchesInfo?.clinicNameEn || ''}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {(!isSuccess && !dataSuccess) ?
                                            <>
                                                <Box className={`box-check-in-content-sm`}>
                                                    <Box className={`text-select-appointment`}>
                                                        {t('CHECK_IN.INPUT.SELECT_APPOINTMENT')}
                                                    </Box>
                                                    <Box
                                                        className={`box-container-list-sm`}
                                                        // sx={{ height: heightWindow > 800 ? widthWindow < 1000 ? '550px' : '570px' : heightWindow > 725 ? '480px' : '324px', }}
                                                        sx={{ height: 'calc(100% - 250px)' }}
                                                    >
                                                        <Box
                                                            className={`box-content-list-sm box-list-all`}
                                                            onScroll={(e: any) => {
                                                                const scrollTop = e.target?.scrollTop;
                                                                const scrollHeight = e.target?.scrollHeight;
                                                                const clientHeight = e.target?.clientHeight;

                                                                const maxScrollTop = scrollHeight - clientHeight
                                                                const minValue = 5.76;
                                                                const maxValue = 100;
                                                                const newBg = minValue + (scrollTop / maxScrollTop) * (maxValue - minValue);
                                                                setMaxBgLinear(newBg)

                                                                const minFFF = 50;
                                                                const maxFFF = 100;
                                                                const newFff = minFFF + ((newBg - minValue) / (maxValue - minValue)) * (maxFFF - minFFF);
                                                                setMaxBgLinearFFF(newFff);
                                                            }}
                                                        >

                                                            {dataCheckIn.map((item, index: number) => {
                                                                const isSelect = dataSelect === index ? '1' : '0'
                                                                return (
                                                                    <CardCheckInPatient
                                                                        key={index}
                                                                        index={index}
                                                                        isSelect={isSelect}
                                                                        setDataSelect={setDataSelect}
                                                                        item={item}
                                                                    />
                                                                )
                                                            })}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {dataCheckIn?.length && dataCheckIn?.length > 3 &&
                                                    <Box className={`box-linear-list`} />}

                                                <Box className={`btn-check-in`}
                                                    sx={{
                                                        position: 'absolute',
                                                        bottom: '82px',
                                                        margin: '0 -24px',
                                                        padding: '0px 24px',
                                                    }}>
                                                    <ButtonCustom
                                                        textButton={t('CHECK_IN.BUTTON.CHECK_IN')}
                                                        btnStyle={{ fontSize: '32px', maxWidth: '362px', width: '100%' }}
                                                        onClick={() => onSubmitCheckIn()}
                                                        disabled={dataSelect === null}
                                                    />
                                                </Box>
                                            </>
                                            :
                                            <CheckInSuccess
                                                dataSuccess={dataSuccess}
                                                onReset={onReset}
                                                branchesInfo={branchesInfo}
                                            />
                                        }
                                    </>
                            }
                        </Box>
                    </Box>

                    {!isCheckIn && !dataQRcode &&
                        <Box className={`box-container-input`}>
                            <Box className={`box-content-welcome-check-in`}>
                                <Box className={`box-content-input-phone`}>
                                    <Box className={`text-label mb-1`}>
                                        {t('CHECK_IN.PLEASE_CHECK_IN_YOURSELF')}
                                    </Box>
                                    <Box className={`box-input-phone`}>
                                        <InputTextField
                                            required
                                            label={t('CHECK_IN.INPUT.PHONE')}
                                            value={inputPhone}
                                            onchange={(event) => setInputPhone(event.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                            <Box className={`box-content-btn-check-in`}>
                                <Box className={`btn-check-in`}>
                                    <ButtonCustom
                                        textButton={t('CHECK_IN.BUTTON.CHECK_IN')}
                                        btnStyle={{ fontSize: '24px', width: '100%' }}
                                        onClick={() => handleCheckIn()}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    }

                    <Box className={`box-check-in-content`} >
                        {!isCheckIn && !dataQRcode &&
                            <Box className={`check-in-content-img`} sx={{ transform: `scale(${scaleImg.scale})`, right: scaleImg.right, bottom: scaleImg.bottom }} >
                                <img src={imgCheckIn} />
                            </Box>
                        }

                        <Box className={`box-container-check-in`}>
                            <Box className={`w-100 h-100`} sx={{ position: 'relative', display: 'flex', flexDirection: 'column', }}>
                                <Box className={`box-content-lang-date`}>
                                    <ClickAwayListener onClickAway={() => widthWindow > 1000 && setOpenLanguage(false)}>
                                        <Box className={`box-select-lang`} onClick={() => setOpenLanguage(!openLanguage)} >
                                            <IconLanguage width={'32px'} height={'32px'} fill={'#808080'} />
                                            <Box className={`text-lang-current`} sx={{ fontSize: '20px' }}>
                                                {renderLanguage()}
                                            </Box>

                                            {openLanguage &&
                                                <MenuLang className={`menu-lang`}>
                                                    <Box className={'menulang-content'}>
                                                        {dataLanguage.map((item) => {
                                                            return (
                                                                <Box
                                                                    key={item.langCode}
                                                                    className={'menulang-item-list'}
                                                                    onClick={() => handleLanguage(item.langCode)}
                                                                >
                                                                    <Box sx={{ fontSize: '18px' }}>
                                                                        {item.icon}
                                                                    </Box>
                                                                    <Box>
                                                                        {item.langName}
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        })}
                                                    </Box>
                                                </MenuLang>}
                                        </Box>
                                    </ClickAwayListener>

                                    <Box className={`box-show-date`}>
                                        <Box>
                                            <span>{currentTime}</span><span style={{ marginLeft: '4px' }}>{currentTimeUnit}</span>
                                        </Box>
                                        <Box>
                                            {toBuddhistYear(moment())}
                                        </Box>
                                    </Box>
                                </Box>

                                {!isSuccess && !dataSuccess ?
                                    (!isCheckIn ?
                                        <Box className={`box-content-welcome-check-in ${dataSuccess && 'gap-0'}`}>
                                            <Box className={`text-welcome-check-in`}>
                                                {t('CHECK_IN.WELCOME')}
                                            </Box>
                                            {!dataQRcode ?
                                                <>
                                                    <Box className={`box-content-input-phone`}>
                                                        <Box className={`text-label`}>
                                                            {t('CHECK_IN.PLEASE_CHECK_IN_YOURSELF')}
                                                        </Box>
                                                        <Box className={`box-input-phone`}>
                                                            <InputTextField
                                                                required
                                                                label={t('CHECK_IN.INPUT.PHONE')}
                                                                value={inputPhone}
                                                                onchange={(event) => setInputPhone(event.target.value)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box className={`btn-check-in`}>
                                                        <ButtonCustom
                                                            textButton={t('CHECK_IN.BUTTON.CHECK_IN')}
                                                            btnStyle={{ fontSize: '32px', maxWidth: '382px', width: '100%' }}
                                                            onClick={() => handleCheckIn()}
                                                        />
                                                    </Box>
                                                </>

                                                :
                                                <Box className={`box-content-qr-code`}>
                                                    <Box className={`text-qr-code`}>
                                                        {t('CHECK_IN.INPUT.ENTER_SCAN')}
                                                    </Box>
                                                    <Box className={`img-qr-code`}>
                                                        <img src={dataQRcode} />
                                                    </Box>
                                                </Box>
                                            }
                                        </Box>
                                        :
                                        <Box className={`box-content-list-appointment`} >
                                            <Box className={`text-select-appointment`} >
                                                {t('CHECK_IN.INPUT.SELECT_APPOINTMENT')}
                                            </Box>
                                            <Box
                                                className={`box-container-list`}
                                                sx={{ height: heightWindow > 800 ? widthWindow < 1000 ? '550px' : '570px' : heightWindow > 725 ? '480px' : '324px', }}
                                            >
                                                <Box className={`box-content-list box-list-all`} >
                                                    {dataCheckIn.map((item, index: number) => {
                                                        const isSelect = dataSelect === index ? '1' : '0'
                                                        return (
                                                            <CardCheckInPatient
                                                                key={index}
                                                                index={index}
                                                                isSelect={isSelect}
                                                                setDataSelect={setDataSelect}
                                                                item={item}
                                                            />
                                                        )
                                                    })}
                                                </Box>
                                            </Box>

                                            <Box className={`btn-check-in`} sx={{ marginTop: '32px', textAlign: 'right !important', }}>
                                                <ButtonCustom
                                                    textButton={t('CHECK_IN.BUTTON.CHECK_IN')}
                                                    btnStyle={{ fontSize: '32px', maxWidth: '362px', width: '100%' }}
                                                    onClick={() => onSubmitCheckIn()}
                                                    disabled={dataSelect === null}
                                                />
                                            </Box>
                                        </Box>
                                    )
                                    :
                                    <CheckInSuccess
                                        dataSuccess={dataSuccess}
                                        onReset={onReset}
                                        branchesInfo={branchesInfo}
                                    />
                                }
                            </Box>


                        </Box>

                    </Box>

                </Box>
            </BoxCheckIn>
        </Box>

    )
}

export default CheckIn

type CardCheckInPatientProps = {
    index: number
    isSelect: '1' | '0'
    setDataSelect: (index: number) => void
    item: DataCheckInProps
}

const CardCheckInPatient = (props: CardCheckInPatientProps) => {
    const { index, isSelect, setDataSelect, item } = props
    const { t, i18n } = useTranslation()
    const handleSelectCard = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        event.stopPropagation();
        event.preventDefault();
        setDataSelect(index)
    }

    const handleTextLang = (data: any, text: string, textEn?: string) => {
        if (!data) {
            return
        }
        if (i18n.language === 'en') {
            return textEn ? data[textEn] : data[`${text}En`] || ''
        }
        return data[text] || ''
    }

    return (
        <CardCheckIn
            id={`list-${index}`}
            isdisabled={item?.isCheckin}
            isselect={isSelect}
            onClick={(event) => handleSelectCard(event, index)}
        >
            <Box>
                <InputRadio
                    checked={isSelect === '1'}
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectCard(event, index)}
                    value={index}
                    disabled={item?.isCheckin === '1'}
                />
            </Box>
            <Box className={`card-checkin-detail`}>
                <Box className={`card-patient-name`}>
                    {t('CHECK_IN.INPUT.SIR')}{handleTextLang(item, 'patientfullName')}
                </Box>
                <Box className={`card-patient-detail`}>
                    <Box className={`card-container-patient-detail `}>
                        <span>
                            <EventIcon className={`card-patient-icon`} />
                        </span>
                        <span>
                            {toBuddhistYear(moment(item.date))} {t('TIME_UNIT')}
                        </span>
                    </Box>
                    <Box className={`card-container-patient-detail `}>
                        <span>
                            <AccessTimeIcon className={`card-patient-icon`} />
                        </span>
                        <span>
                            {item.time_range}
                        </span>
                    </Box>
                    <Box className={`card-container-patient-detail `}>
                        <Box className={`card-patient-icon-treatment`}>
                            <ThemeIcon.Treatment />
                        </Box>
                        <span className={`text-ellipsis`}>
                            {handleTextLang(item, 'serviceText')}
                        </span>
                    </Box>
                </Box>
            </Box>
            <Box className={`card-check-in-checked ${item?.isCheckin !== '1' && 'd-none'}`}>
                <Box>
                    <IconCheckInSuccess fill={colors.themeSecondColor} />
                </Box>
                <Box className={`card-check-in-status`} >
                    {t('CHECK_IN.INPUT.CHECK_IN_SUCCESS')}
                </Box>
            </Box>
        </CardCheckIn>
    )
}