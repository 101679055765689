import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from "react-i18next";
import _ from 'lodash';

/**CONSTANT */
import { Icons, icons, imgs, Imgs } from "constants/images";
import { colors } from 'constants/theme';

/**Component */
import CollapseContainer from 'component/Collapse/container';
import InputTextSearch from 'component/Input/InputTextSearch';
/**STYLE */
import * as UseStyle from './UseStyle';
import { ContentUpdate } from './content/content';
import { contentFeatureNo4, contentUpdateNo4 } from './content/contentData';


export default function UpdateSidebar() {
    const { t } = useTranslation();
    const arrayManualList: any[] = [
        { key: 'fixBranch', label: `${t('MANUAL.EDIT_BRANCH')}`, img: '' },
        { key: 'fixLOGO', label: `${t('MANUAL.EDIT_CLINICLOGO')}`, img: '' },
        { key: 'resetOther', label: `${t('MANUAL.RESET_USER_PASSWORD')}`, img: '' },
        { key: 'resetUser', label: `${t('MANUAL.RESET_OTHER_PASSWORD')}`, img: '' },
        { key: 'fixAddress', label: `${t('MANUAL.EDIR_CLINIC_ADRESS')}`, img: '' },
        { key: 'fixCost', label: `${t('MANUAL.EDIT_COST')}`, img: '' },
        { key: 'changeBranch', label: `${t('MANUAL.CHANGE_BRANCH')}`, img: '' },
        { key: 'addRoom', label: `${t('MANUAL.ADD_ROOM')}` },
        { key: 'addE-Mail', label: `${t('MANUAL.ADD_EMAIL')}`, img: '' }
    ]
    const arrayUpdateList: any[] = [
        // { no: 1, date: 'อัปเดตประจำวันที่ 20 กันยายน 2565', newFeature: `${t('UPDATEPAGE.SUB_DETAIL.NEW_FEATURE')}`, newUpdate: `${t('UPDATEPAGE.SUB_DETAIL.UPDATE')}`, checkUpdate: false, checkFeature: false, imgUpdate: '', imgFeature: '' },
        // { no: 2, date: 'อัปเดตประจำวันที่ 30 กันยายน 2565', newFeature: `${t('UPDATEPAGE.SUB_DETAIL.NEW_FEATURE')}`, newUpdate: `${t('UPDATEPAGE.SUB_DETAIL.UPDATE')}`, checkUpdate: false, checkFeature: false, imgUpdate: '', imgFeature: '' },
        // { no: 3, date: 'อัปเดตประจำวันที่ 20 ตุลาคม 2566', newFeature: `${t('UPDATEPAGE.SUB_DETAIL.NEW_FEATURE')}`, newUpdate: `${t('UPDATEPAGE.SUB_DETAIL.UPDATE')}`, checkUpdate: false, checkFeature: false, imgUpdate: '', imgFeature: '' },
        {
            no: 4,
            date: 'อัปเดตประจำวันที่ 4 เมษายน 2567',
            newFeature: `${t('UPDATEPAGE.SUB_DETAIL.NEW_FEATURE')}`,
            newUpdate: `${t('UPDATEPAGE.SUB_DETAIL.UPDATE')}`,
            checkUpdate: false,
            checkFeature: false,
            imgUpdate: '',
            imgFeature: '',
            contentUpdate: contentUpdateNo4,
            contentFeature: contentFeatureNo4
        }
    ]

    const [updatepage, setUpdatepage] = useState<string>("default");
    const [collapse, setCollapse] = useState<boolean>(false);
    const [menu, setMenu] = useState<"Update" | "Manual">("Update")
    const [selectManual, setSelectManual] = useState<string>("fixBranch");
    const [searchInput, setSearchInput] = useState<string>("");
    const [manualList, getManualList] = useState<any[]>(arrayManualList)
    const [update, getUpdate] = useState<any[]>(arrayUpdateList)

    const handleChange = (value: any) => {
        setSearchInput(value)
        if (searchInput.length > 0) {
            const render_filter: any[] = _.filter(arrayManualList, (item: any) => item.label.match(value))
            getManualList(render_filter)
        } else {
            getManualList(arrayManualList)
        }
    }

    const checkReading = (index: number, val: any, type: string) => {
        const data = _.merge({}, update)
        const datamerge = _.merge({}, data[index])
        if (type === 'update') datamerge.checkUpdate = Boolean(val)
        else datamerge.checkFeature = Boolean(val)
        data[index] = datamerge
        getUpdate(data)
    }

    return (
        <div className='h-100 w-100'>
            <UseStyle.HeaderUpdatePage>
                <p>{t('UPDATEPAGE.HEAD.UPDATE_LIST_AND_MANUAL')}</p>

                <UseStyle.ButtonGroup>
                    <UseStyle.UpdateListButton onClick={() => setMenu("Update")}
                        style={{
                            backgroundColor: `${menu === "Update" ? colors.white : colors.themeSecondColor}`,
                            color: `${menu === "Update" ? colors.themeSecondColor : colors.white}`
                        }}
                    >
                        {menu === "Update" ? <span><Icons.Gear /></span> : <img src={icons.white_gear} alt="updateicon_list" />}
                        {t('UPDATEPAGE.BUTTON.UPDATE_LIST')}
                    </UseStyle.UpdateListButton>
                    {/* <UseStyle.ManualButton onClick={() => setMenu("Manual")}
                        style={{
                            backgroundColor: `${menu === "Manual" ? colors.white : colors.themeSecondColor}`,
                            color: `${menu === "Manual" ? colors.themeSecondColor : colors.white}`
                        }}
                    >
                        {menu === "Manual" ? <span><Icons.Manual /></span> : <img src={icons.white_manual} alt="manual" />}
                        {t('UPDATEPAGE.BUTTON.MANUAL')}
                    </UseStyle.ManualButton> */}
                </UseStyle.ButtonGroup>
            </UseStyle.HeaderUpdatePage>

            {menu === "Update" && <CollapseContainer
                tooltipExpand={t('UPDATEPAGE.TOOLTIP.EXPAND_MENU')}
                tooltipShorten={t('UPDATEPAGE.TOOLTIP.SHORTEN_MENU')}
                styleComponentLeft={{ height: '100%' }}
                styleComponentRight={{ height: '100%' }}
                callBackStatusCollapse={() => setCollapse(!collapse)}
                componentLeft={
                    <div>
                        <Grid item className='col-12'>
                            <UseStyle.TextHeader>
                                {t('UPDATEPAGE.HEAD.UPDATE_LIST')}
                            </UseStyle.TextHeader>
                            {_.map(update, (item: any, index: number) => {
                                return (

                                    <UseStyle.SlideDataContainer defaultExpanded={false}>
                                        <UseStyle.SlideData aria-controls="panel2d-content" color={item.checkFeature === true && item.checkUpdate === true ? colors.textLightGray : colors.black}>
                                            <div className='d-flex flex-row justify-content-start align-items-center'>
                                                {item.checkFeature === true && item.checkUpdate === true ? <img src={icons.black_gear} alt='readupdate_icons' className='pr-2' /> : <span className='pr-2'><Icons.Gear /> </span>}
                                                <span style={{ color: item.checkFeature === true && item.checkUpdate === true ? colors.textLightGray : colors.black }}>{item.date}</span>

                                            </div>
                                        </UseStyle.SlideData>

                                        <UseStyle.ListUpdate>
                                            <Grid container spacing={0}>
                                                <Grid item className='col-12'>
                                                    <div onClick={() => setUpdatepage(item.date + 'feature')}>
                                                        <div className="d-flex flex-row pl-3 pt-1 cursor-pointer" onClick={() => checkReading(index, true, 'feature')}>
                                                            {item.checkFeature ? <img src={icons.black_gear} alt={item.no} /> : <span><Icons.Gear /> </span>}

                                                            <span style={{ fontSize: 16, fontWeight: 400, color: item.checkFeature ? colors.black : colors.themeSecondColor, padding: '0 8px' }}>{item.newFeature}</span>
                                                            {item.checkFeature ? <img src={icons.black_new} alt={item.no} /> : <span><Icons.New /></span>}

                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item className='col-12' >
                                                    <div onClick={() => setUpdatepage(item.date + 'update')}>
                                                        <div className="d-flex flex-row pl-3 pt-1 cursor-pointer" onClick={() => checkReading(index, true, 'update')}>
                                                            {item.checkUpdate ? <img src={icons.black_re} alt='unread_timeicon' /> : <span><Icons.NewUpdate /></span>}
                                                            <span style={{ fontSize: 16, fontWeight: 400, color: item.checkUpdate ? colors.black : colors.themeSecondColor, padding: '0 8px' }}>{item.newUpdate}</span>
                                                            {item.checkUpdate ? <img src={icons.black_new} alt={item.no} /> : <span><Icons.New /></span>}
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </UseStyle.ListUpdate>
                                    </UseStyle.SlideDataContainer>
                                )
                            })}

                        </Grid>
                    </div>
                }
                componentRight={
                    <div>
                        {_.map(update, (item: any, index: number) => (
                            updatepage === item.date + 'update' &&
                            <div>
                                <UseStyle.ShowContent>
                                    <div className='d-flex justify-content-center'>
                                        <img src={item.imgUpdate} alt='' className='w-75' />
                                    </div>
                                    <div className='w-100 mt-2'>
                                        {item?.contentUpdate && <ContentUpdate contentData={item?.contentUpdate} /> || ''}
                                        {/* <UseStyle.HeaderDetail> <img src={icons.purple_noti} alt='noti' className='mr-2' /> {t('UPDATEPAGE.DETAIL.NO_HAVE_NOTI')}</UseStyle.HeaderDetail> */}
                                        {/* <UseStyle.SubDetail>{t('UPDATEPAGE.DETAIL.NO_HAVE_NOTI_DETAIL')}</UseStyle.SubDetail> */}
                                    </div>
                                </UseStyle.ShowContent>
                            </div>
                        ))}

                        {_.map(update, (item: any) => (
                            updatepage === item.date + 'feature' &&
                            <div>
                                <UseStyle.ShowContent>
                                    <div className='d-flex justify-content-center'>
                                        <img src={item.imgFeature} alt='' className='w-75' />
                                    </div>
                                    <div className='w-100 mt-2'>
                                        {item?.contentFeature && <ContentUpdate contentData={item?.contentFeature} /> || ''}
                                        {/* <UseStyle.HeaderDetail> <span className='pr-2'><Icons.Noti /></span> {t('UPDATEPAGE.DETAIL.NO_HAVE_NOTI')}</UseStyle.HeaderDetail> */}
                                        {/* <UseStyle.SubDetail>{t('UPDATEPAGE.DETAIL.NO_HAVE_NOTI_DETAIL')}</UseStyle.SubDetail> */}
                                    </div>
                                </UseStyle.ShowContent>
                            </div>
                        ))}

                        {updatepage === 'default' &&
                            <div className='d-flex align-items-center justify-content-center'>
                                <UseStyle.ShowContent>
                                    <div className='d-flex justify-content-center'>
                                        <Imgs.DefaultUpdate />
                                    </div>
                                    <div className='w-100 d-flex align-items-center justify-content-center flex-column mt-2'>
                                        <UseStyle.HeaderDetail> <span className='pr-2'><Icons.Noti /></span> {t('UPDATEPAGE.DETAIL.NO_HAVE_NOTI')}</UseStyle.HeaderDetail>
                                        <UseStyle.SubDetail>{t('UPDATEPAGE.DETAIL.NO_HAVE_NOTI_DETAIL')}</UseStyle.SubDetail>
                                    </div>
                                </UseStyle.ShowContent>
                            </div>
                        }
                    </div>
                } ></CollapseContainer>}

            {menu === "Manual" && <CollapseContainer
                tooltipExpand={t('UPDATEPAGE.TOOLTIP.EXPAND_MENU')}
                tooltipShorten={t('UPDATEPAGE.TOOLTIP.SHORTEN_MENU')}
                styleComponentLeft={{ height: '100%' }}
                styleComponentRight={{ height: '100%' }}
                callBackStatusCollapse={() => setCollapse(!collapse)}
                componentLeft={
                    <div>
                        <Grid item className='col-12'>
                            <UseStyle.TextHeader>
                                {t('UPDATEPAGE.BUTTON.MANUAL')}
                            </UseStyle.TextHeader>
                            <div className='px-3 w-100 pb-3'>

                                <InputTextSearch
                                    keyInput={'search-update'}
                                    onchange={(e: any) => handleChange(e.target.value)}
                                    value={searchInput}
                                    onClear={(e: any) => handleChange(e.target.value)}
                                />
                            </div>
                            <UseStyle.SlideDataContainer defaultExpanded={false}>
                                <UseStyle.SlideData aria-controls="panel2d-content">
                                    <div className='d-flex flex-row justify-content-start align-items-center'>
                                        <span className='pr-2'><Icons.Gear />  </span>
                                        <span style={{ color: colors.black }}>{t('MANUAL.SETTING')}</span>
                                    </div>
                                </UseStyle.SlideData>

                                <UseStyle.ListUpdate>
                                    <UseStyle.ManualLiast>
                                        {_.map(manualList, (item: any) => (
                                            <li onClick={() => setSelectManual(item.key)} style={{ cursor: 'pointer', color: `${selectManual === item.key ? colors.themeSecondColor : colors.black}` }}>
                                                {selectManual === item.key ? <span className='pr-2' ><Icons.AC_DOT /></span> : <img src={icons.black_dot} alt='dot' className='pr-2' />}
                                                {item.label}
                                            </li>
                                        ))}
                                    </UseStyle.ManualLiast>
                                </UseStyle.ListUpdate>
                            </UseStyle.SlideDataContainer>
                        </Grid>
                    </div>
                }
                componentRight={
                    <div>
                        {_.map(manualList, (item: any) => (
                            selectManual === item.key &&
                            <div className='h-100 overflow-auto d-flex justify-content-center'>
                                <img src={item.img} alt='' className='px-3 w-75' />
                            </div>
                        ))}
                    </div>
                }
            />}
        </div>
    )
}